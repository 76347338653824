import { Button, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import VerifyIcon from "../../../assets/icons/verify.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FanSubscriptionPageModel from "../../../components/FanSubscriptionPageModel/FanSubscriptionPageModel";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import { IAllSubscription } from "../../../helpers/types";
import { subscriptionsService } from "../../../services";
import "./index.less";

const CreatorSubscriptionPage = () => {
  const { t } = useTranslation();
  const [fanSubscriptionModel, setFanSubscriptionModel] = useState(false);
  const [modifySubscriptionPlan, setModifySubscriptionPlan] = useState<IAllSubscription>();
  const [subscriptionData, setSubscriptionData] = useState<IAllSubscription[]>([]);
  const [skip, setSkip] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [hasNextPage, setHasNextPage] = useState<any>(false);
  const [totalData, setTotalData] = useState<any>(0);
  const navigate = useNavigate();
  const NB_USER_TO_SHOW = 10;

  async function subscriptions(NB_USER_TO_SHOW: any, skip: any) {
    setLoading(true);
    await subscriptionsService
      .getCreatorAllSubscriptions(NB_USER_TO_SHOW, skip)
      .then((response: any) => {
        setLoading(false);
        setTotalData(response?.total);
        if (subscriptionData.length === 0) {
          setSubscriptionData(response?.data);
        } else {
          setSubscriptionData((prevData) => Array.from(new Set([...prevData, ...(response?.data ?? [])])));
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(t("something-want-wrong"));
      });
  }

  async function loadMoreUsers() {
    const nextPage = skip + NB_USER_TO_SHOW;
    setSkip((prev: any) => prev + NB_USER_TO_SHOW);
    const totalPages = Math.ceil(totalData! / NB_USER_TO_SHOW);
    await subscriptions(NB_USER_TO_SHOW, nextPage);
    if (nextPage >= totalPages) {
      setHasNextPage(false);
    }
  }

  useEffect(() => {
    subscriptions(NB_USER_TO_SHOW, 0);
    setSubscriptionData([]);
  }, []);

  const unSubscribe = async (creatorId: string) => {
    subscriptionsService
      .unsubscribeCreator(creatorId)
      .then((res) => {
        message.success(t("unsubscribe-message"));
        setSkip(0);
        setSubscriptionData([]);
        setHasNextPage(false);
        setLoading(false);
        setTotalData(0);
        subscriptions(NB_USER_TO_SHOW, 0);
        setModifySubscriptionPlan(undefined);
      })
      .catch((error) => {
        message.error(t("something-want-wrong"));
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  return (
    <Layout>
      <Layout.Header className="header">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent />
          <Typography.Text className="header-title">{t("subscribe-label")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content creatorSubscriptionPageContentWrapper">
        {subscriptionData?.map((item, index) => {
          return (
            <Space key={index} className="w-full items-center justify-between mt-13">
              <Space className="items-center ">
                <img className="creatorSubscriptionPageAvatarImg" src={item?.creatorInfo?.avatar} alt="img" />
                <Space className="gap-0" direction="vertical">
                  <Space
                    className="items-center"
                    onClick={() => {
                      navigate(`/p/${item?.creatorInfo?.username}`);
                    }}
                  >
                    <Typography.Text className="creatorSubscriptionCreatorName">{item?.creatorInfo?.name}</Typography.Text>
                    <img src={VerifyIcon} alt="verify" />
                  </Space>
                  <Typography.Text className="creatorSubscriptionStatus">
                    {t(`renewal-label`)} : {item?.status === "active" ? t("active-label") : t("disabled-label")}
                  </Typography.Text>
                </Space>
              </Space>
              <Space className="items-center">
                <Button className={`creatorSubscriptionAssetButton ${item?.status === "active" ? "" : "opacity-7"}`}>
                  {item?.status === "active" ? t(`active-label-to`) : t(`inactive-lavel-to`)}
                </Button>
                <ThreeDotsComponent
                  className="mb-5"
                  onClick={() => {
                    setFanSubscriptionModel(true);
                    setModifySubscriptionPlan(item);
                  }}
                />
              </Space>
            </Space>
          );
        })}
        {modifySubscriptionPlan ? (
          <FanSubscriptionPageModel
            fanSubscriptionModel={fanSubscriptionModel}
            modifySubscriptionPlan={modifySubscriptionPlan}
            setFanSubscriptionModel={setFanSubscriptionModel}
            unSubscribe={unSubscribe}
            isCreator={true}
          />
        ) : null}
        {totalData !== subscriptionData?.length && totalData !== 0 && subscriptionData?.length !== 0 && !loading && (
          <Row className={`mx-auto bg-black`}>
            <Typography.Text
              onClick={() => {
                // setSeeAllResults(true);
                setHasNextPage(true);
              }}
              className="SearchSeeAllResults mx-auto"
            >
              {t(`see-all-results`)}
            </Typography.Text>
          </Row>
        )}
        {(loading || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
      </Layout.Content>
    </Layout>
  );
};

export default CreatorSubscriptionPage;
