class LanguageUtil {

  public AVAILABLE_LANGUAGES = {
    en: "en",
    fr: "fr",
    es: "es"
  };
  public AVAILABLE_LANGUAGES_OPTIONS = [
    {
      label: "Français",
      value: this.AVAILABLE_LANGUAGES.fr,
    },
    {
      label: "English",
      value: this.AVAILABLE_LANGUAGES.en,
    },
    {
      label: "Español",
      value: this.AVAILABLE_LANGUAGES.es,
    },
  ];
  public DEFAULT_LANGUAGE = this.AVAILABLE_LANGUAGES.fr;
  private LOCALSTORAGE_LANGUAGE_KEY = "lng";

  public getCurrentLanguage = () => {
    const language = localStorage.getItem(this.LOCALSTORAGE_LANGUAGE_KEY);
    return language || this.DEFAULT_LANGUAGE;
  }

  public setCurrentLanguage = async (i18n: any, language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem(this.LOCALSTORAGE_LANGUAGE_KEY, language);
  }

}

const languageUtil = new LanguageUtil();

export default languageUtil;