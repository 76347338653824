import { io } from "socket.io-client";
import { authService } from "./services";

const URL: string = process.env.REACT_APP_SOCKET_ENDPOINT || "http://localhost:8080";

export const socket = io(URL, {
  withCredentials: true,
  //transports: ["websocket", "polling"],
  auth: {
    token: authService.getToken(),
    userId: authService.getId(),
  },
});
