import { Col, Layout, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { REQUEST_LOADING_STATES } from "../../../helpers/constant";
import { getConversationMedias } from "../../../redux/messages/messagesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import "./index.less";
import PrivateGalleryMedia from "./PrivateGalleryMedia";

const PrivateGalleryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");
  const receiverId = searchParams.get("receiverId");
  const senderId = searchParams.get("senderId");
  const shouldAddWatermark = searchParams.get("shouldAddWatermark");
  const mediasData = useSelector((state: StoreState) => state.messages.mediaPushData.find((media) => media.conversationId === conversationId));
  const isLoading = useSelector((state: StoreState) => state.messages.loading);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (
        conversationId !== null &&
        receiverId !== null &&
        senderId !== null &&
        conversationId !== "" &&
        receiverId !== "" &&
        senderId !== "" &&
        conversationId !== "new"
      ) {
        if (!mediasData || mediasData.medias.length === 0) {
          dispatch(getConversationMedias({ conversationId, receiverId, senderId }));
        }
      }
    }
  }, [conversationId, receiverId]);

  return (
    <Layout>
      <Layout.Header className="header">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent />
          <Typography.Text className="header-title">{t("private-gallery")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content fanPrivateGalleryContentWrapper">
        <Row gutter={2}>
          {mediasData?.medias?.map((media, index) => {
            return (
              <Col key={media.fileId + index} span={8}>
                <PrivateGalleryMedia media={media} shouldAddWatermark={shouldAddWatermark === "true"} />
              </Col>
            );
          })}
          {isLoading === REQUEST_LOADING_STATES.PENDING && (
            <Col className="mt-40 mx-auto">
              <SpinnerComponent />
            </Col>
          )}
          {isLoading !== REQUEST_LOADING_STATES.PENDING && mediasData?.medias?.length === 0 && (
            <Col className="mt-40 mx-auto font-20-bold text-medium-green-color">{t("no-media-found")}</Col>
          )}
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PrivateGalleryPage;
