import { Badge } from "antd";
import React from "react";
import useUserOnlineHook from "../../helpers/hooks/useUserOnlineHook";
import AvatarComponent from "../AvatarComponent";
import FanAvatarComponent from "../FanAvatarComponent";

type props = {
  userId?: string;
  avatar?: string;
  name?: string;
};

const ConversationAvatarComponent: React.FC<props> = ({ userId, avatar, name }) => {
  const isOnline = useUserOnlineHook(userId);
  if (avatar) {
    if (isOnline) {
      return (
        <Badge dot={true} color="#00ff18" offset={[-15, 68]} className="avatarOnlineDot">
          <AvatarComponent image={avatar} size={72} />
        </Badge>
      );
    } else {
      return <AvatarComponent image={avatar} size={72} />;
    }
  } else {
    if (isOnline) {
      return (
        <Badge dot={true} color="#00ff18" offset={[-11, 60]} className="avatarOnlineDot">
          <FanAvatarComponent name={name} size={72} />
        </Badge>
      );
    } else {
      return <FanAvatarComponent name={name} size={72} />;
    }
  }
};

export default ConversationAvatarComponent;
