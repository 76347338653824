import { Radio, Row, Typography } from "antd";
import React from "react";
import "./index.less";

type props = {
  name: string;
  price: number;
  value: string;
};

const FanSubscribeTypeRadioButtonComponent: React.FC<props> = ({ name, price, value }) => {
  return (
    <Radio.Button className="fanSubscribeTypeRadioButton" value={value}>
      {name}
    </Radio.Button>
  );
};

export default FanSubscribeTypeRadioButtonComponent;
