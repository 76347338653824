import axios from "axios";
import { authService } from "../services";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080",
  headers: {
    Authorization: authService.getToken(),
  },
  validateStatus: function (status) {
    if (status === 403) {
      authService.signOut();
      window.location.href = "/auth/signIn";
    }
    // Resolve only if the status code is less than 500
    return status < 500;
  },
});

export default instance;
