import { Checkbox, Input, message, Row, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services";
import AuthButtonComponent from "../../AuthButtonCompnent";
import AuthGoogleButtonComponent from "../../AuthGoogleButtonComponent";
import AuthOrDividerComponent from "../../AuthOrDividerComponent";
import "../index.less";

const PublicLoginViewComponent: React.FC = () => {
  const { t } = useTranslation();
  const [usernameOrEmail, setUsernameOrEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(true);
  const navigate = useNavigate();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const handleFocus = () => {
    setIsKeyboardOpen(true);
  };

  const handleBlur = () => {
    setIsKeyboardOpen(false);
  };

  const login = () => {
    if (!usernameOrEmail || usernameOrEmail.length < 3) return message.warning(t("username-valid"));
    if (!password || password.length < 6) return message.warning(t("password-valid"));

    authService
      .login(usernameOrEmail, password, remember, navigate)
      .then(() => window.location.reload())
      .catch((error) => message.error(t(error)));
  };

  const googleLogin = (accessToken: string) => {
    authService
      .googleLogin(accessToken, navigate)
      .then((data: string) => {
        authService.setToken(data);
        window.location.reload();
      })
      .catch((error: string) => message.error(t(error)));
  };

  return (
    <div className={`login-view ${isKeyboardOpen ? "keyboard-open" : ""}`}>
      <Row className="justify-content-center">
        <Typography.Text className="public-login-title">{t("auth-signIn-title")}</Typography.Text>
      </Row>
      <Row>
        <AuthGoogleButtonComponent className="publicAuthGoogleBtn" label={t("with-google")!} onLoginSuccess={googleLogin} />
      </Row>
      <Row className="row-divider">
        <AuthOrDividerComponent text={t("auth-signUp-divider")} />
      </Row>
      <Row className="w-full">
        <Input
          className="customInput"
          placeholder={t("email-and-username") ?? ""}
          type="email"
          value={usernameOrEmail}
          onChange={(e) => setUsernameOrEmail(e.target.value)}
        />
      </Row>
      <Row className="w-full mt-16">
        <Input.Password
          className="customInput"
          placeholder={t("password-label") ?? ""}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </Row>
      <Row className="row-forgot-password">
        <Typography.Text className="authForgotPasswordLabel cursor-pointer" onClick={() => navigate("/auth/forgot")}>
          {t("auth-forgot-password-question-text")}
        </Typography.Text>
        <Checkbox className="authRememberMeCheckbox" checked={remember} onChange={(e) => setRemember(e.target.checked)}>
          {t("auth-remember-me")}
        </Checkbox>
      </Row>
      <Row className="row mt-20">
        <AuthButtonComponent label={t("auth-signIn-button")} height="50px" onClick={login} />
      </Row>
    </div>
  );
};

export default PublicLoginViewComponent;
