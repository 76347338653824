import axios from "../helpers/axios";
import { IGetMediaPush, IMediapush, IMediapushHistory, ISegmentations, Media, MediaPushPrice } from "../helpers/types";

class MediapushService {
  public createMediaPush = (data: FormData, setProgress: Function) => {
    let slowProgressInterval: any;
    return new Promise<IMediapush>((resolve, reject) => {
      axios
        .post(`/mediapush`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            let progress = Math.round((100 * event.loaded) / event.total!);
            if (progress < 50) {
              setProgress(progress);
            } else {
              slowProgressInterval = setInterval(() => {
                setProgress((prevProgress: number) => {
                  if (prevProgress < 99) {
                    return prevProgress + 1;
                  }
                  return prevProgress;
                });
              }, 2000);
            }
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            setProgress(100);
            resolve(response.data.data);
          } else if (response?.data?.message) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        })
        .finally(() => {
          clearInterval(slowProgressInterval);
        });
    });
  };

  public getSegmentations = () => {
    const url = `/mediapush/segmentation`;
    return new Promise<ISegmentations>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getPrivateGalleryMedia = (conversationId: string, receiverId: string, senderId: string) => {
    const url = `/mediapush/privateGallery/${conversationId}/${receiverId}/${senderId}`;
    return new Promise<Media[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updateMediaPushFiles = (payload: any) => {
    const url = `/mediapush/updateFiles`;
    return new Promise<Media[]>((resolve, reject) => {
      axios
        .put(url, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getMediaPushPrice = (mediaPushId: string) => {
    const url = `/mediapush/price/${mediaPushId}`;
    return new Promise<MediaPushPrice>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getMediaPush = (id: string | undefined | null) => {
    const url = `/mediapush/details/${id}`;
    return new Promise<IGetMediaPush>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public mediaPushHistory = () => {
    const url = `/earning/creator/mediaPushHistory?limit=10&offset=0`;
    return new Promise<IMediapushHistory>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public stripeMediaPush = (body: any) => {
    const url = `/payment/stripe/MediaPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public applePayMediaPush = (body: any) => {
    const url = `/payment/applePay/MediaPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public verifyPaymentStatus = (id: string) => {
    const url = `/payment/psp/verifyPaymentStatus/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const mediapushService = new MediapushService();
