import { Button, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentFailed from "../../assets/icons/fan/payment/payment-failed_resultat.webp";
import PaymentSuccess from "../../assets/icons/fan/payment/payment-success_resultat.webp";
import "./index.less";

type props = {
  paymentStatus?: any;
  setPaymentStatus?: any;
  username: string;
};

const FanPaymentPopUp: React.FC<props> = ({ paymentStatus, setPaymentStatus, username }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");
  //const creatorId = searchParams.get("creatorId");
  const PopUpClick = () => {
    if (paymentStatus === "created" || paymentStatus === "success" || paymentStatus === "succeeded") {
      if (redirectUrl) {
        if (redirectUrl.includes("?")) {
          navigate("/" + redirectUrl.concat("&from=paymentPage"));
        } else {
          navigate("/" + redirectUrl.concat("?from=paymentPage"));
        }
      } else {
        navigate(`/p/${username}`, { replace: true });
      }
    } else {
      setPaymentStatus("");
    }
  };

  useEffect(() => {
    if (!!paymentStatus) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [paymentStatus]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="modalFanPayment"
      open={!!paymentStatus}
      onCancel={() => setPaymentStatus("")}
      centered
      closable={false}
      footer={
        <div className="w-full fanModalFooter">
          {paymentStatus && paymentStatus !== "error" ? (
            <Button onClick={PopUpClick} className="paymentSuccessPopupBtn">
              {t(`everthing-is-ok-lable`)}
            </Button>
          ) : (
            paymentStatus &&
            paymentStatus === "error" && (
              <Button onClick={PopUpClick} className="paymentFailedPopupBtn">
                {t(`try-again-lable`)}
              </Button>
            )
          )}
        </div>
      }
    >
      {paymentStatus && paymentStatus !== "error" ? (
        <Row className="w-full justify-content-center">
          <img src={PaymentSuccess} alt="paymentSuccessTick" />
          <Typography.Text className="paymentSuccessMessage">{t(`payment-confim`)}</Typography.Text>
        </Row>
      ) : (
        paymentStatus &&
        paymentStatus === "error" && (
          <Row className="w-full justify-content-center">
            <div>
              <img src={PaymentFailed} alt="PaymentFailed" />
            </div>
            <Typography.Text className="paymentFailedMessage">{t(`payment-failed-lable`)}</Typography.Text>
          </Row>
        )
      )}
    </Modal>
  );
};

export default FanPaymentPopUp;
