import { configureStore } from "@reduxjs/toolkit";

import feedsReducer from "./feeds/feedsSlice";
import filesReducer from "./files/filesSlice";
import liveStreamStatsReducer from "./lives/liveDataSlice";
import livesReducer from "./lives/livesSlice";
import mediaPushReducer from "./mediaPush/mediaPushSlice";
import messagesReducer from "./messages/messagesSlice";
import quickMessagesReducer from "./messages/quickMessagesSlice";
import quickFileReducer from "./quickFile/quickFileSlice";
import reactionsReducer from "./reactions/reactionsSlice";
import monetizationsReducer from "./users/monetizationsSlice";
import usersReducer from "./users/usersSlice";
import CreatorSuggestion from "./CreatorSuggestion/CreatorSuggestion";

const store = configureStore({
  reducer: {
    users: usersReducer,
    monetizations: monetizationsReducer,
    feeds: feedsReducer,
    files: filesReducer,
    mediaPushes: mediaPushReducer,
    messages: messagesReducer,
    quickMessages: quickMessagesReducer,
    lives: livesReducer,
    reactions: reactionsReducer,
    quickFile: quickFileReducer,
    suggestion: CreatorSuggestion,
    liveStreamStats: liveStreamStatsReducer,
  },
});

export type StoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
