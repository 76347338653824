import { Button, Col, Layout, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DecryptLogoWhite from "../../../assets/icons/d_blanc.svg";
import CardSavedIcon from "../../../assets/icons/settings/cardSavedIcon.svg";
import Nuage from "../../../assets/icons/settings/nuage.svg";
import Monument from "../../../assets/images/loly_resultat_resultat.webp";
import PuzzleImg from "../../../assets/images/puzzle.webp";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FooterComponent from "../../../components/FooterComponent";
import { CREATOR, FAN, FOOTER_IDS, getCreatorSettings, getFanSettings } from "../../../helpers/constant";
import { authService } from "../../../services";
import "./index.less";
import { useEffect, useState } from "react";
import { settingService } from "../../../services/settings.service";

const SettingPage = () => {
  const { t } = useTranslation();
  const type = authService.getRole();
  const navigate = useNavigate();
  const user = authService.getUser();
  const creatorSettings = getCreatorSettings(t);
  const fanSettings = getFanSettings(t);
  const [payemntGateway, setPayemntGateway] = useState<any>(null)

  const itemClicked = (link: string | null | undefined, legal: boolean = false) => {
    if (!link) return;
    if (legal) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  const handleItemClick = (link: any, legal: boolean, isFunction: boolean) => {
    if (isFunction) {
      link();
    } else {
      itemClicked(link, legal);
    }
  };

  useEffect(() => {
    getCurrentPaymentGateway()
  }, []);

  const getCurrentPaymentGateway = async () => {
    await settingService.getPayentGateway().then((res: any) => setPayemntGateway(res?.paymentGateway));
  };

  return (
    <Layout>
      <Layout.Content className="content fanSettingContentWrapper gradient-background-blue-settings">
        <Row className="relative justify-content-center header-title-container-70">
          {type === CREATOR && <ArrowLeftIconComponent top={8} navigateTo="/creator/profile" />}
          <Typography.Text className="header-title-black-32">{type === FAN ? t("settings") : `${user?.firstName} ${user?.lastName}`}</Typography.Text>
        </Row>
        {type === FAN && (
          <>
            <Row className="two-columns-wrapper mt-20">
              <Col className={`fanSettingGroup ${(payemntGateway !== "shift4" || payemntGateway === null) && "cursor-disabled"}`} onClick={() => {
                if(payemntGateway === "shift4" && payemntGateway !== null){navigate("/common/savedCards")}
              }}>
                <Row className="mt-10">
                  <img src={CardSavedIcon} width={50} alt="Loly-cards" />
                </Row>
                <div className="bottom-text">
                  <Row>
                    <Typography.Text className="two-columns-title">{t("saved-cards")}</Typography.Text>
                  </Row>
                  <Row className="mt-3 mb-6">
                    <Typography.Text className="two-columns-text">{t("to-make-it-go-faster")}</Typography.Text>
                  </Row>
                </div>
              </Col>
              <Col className="fanSettingGroup" onClick={() => navigate("/fan/bookmarks")}>
                <Row className="mt-10">
                  <img src={Nuage} width={50} alt="Loly-Cloud" />
                </Row>
                <div className="bottom-text">
                  <Row>
                    <Typography.Text className="two-columns-title">{t("bookmarks")}</Typography.Text>
                  </Row>
                  <Row className="mt-3 mb-6">
                    <Typography.Text className="two-columns-text">{t("in-the-warmth-of-your-gallery")}</Typography.Text>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="professionalize-container mb-20">
              <Col className="professionalize-content">
                <Row>
                  <Typography.Text className="professionalize-title fanSection">{t("help-us-improve-our-application")}</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text className="professionalize-description fanSection">{t("suggestions-are-important-to-us")}</Typography.Text>
                </Row>
                <Row>
                  <Button
                    onClick={() => {
                      navigate("/common/helpContact");
                    }}
                    className="communityButton fanSection"
                  >
                    {t("make-a-recommendation")}
                  </Button>
                </Row>
              </Col>
              <Col className="professionalize-image">
                <img src={PuzzleImg} width={150} alt="" />
              </Col>
            </Row>
            {fanSettings.map((group, index) => {
              return (
                <Col key={index} className="fanSettingGroupItem">
                  {group.list.map((item, itemIndex) => {
                    return (
                      <Row
                        key={itemIndex}
                        className="fanSettingItem"
                        onClick={() => handleItemClick(item.link, item.legal, item.isFunction ?? false)}
                      >
                        <img className="mr-30" src={item.logo} width={18} />
                        <Typography.Text className="fanSettingItemTitle">{item.title}</Typography.Text>
                      </Row>
                    );
                  })}
                </Col>
              );
            })}
          </>
        )}
        {type === CREATOR && (
          <>
            <Row className="two-columns-wrapper mt-20">
              <Col className="fanSettingGroup" onClick={() => itemClicked("/creator/decrypt", false)}>
                <Row className="mt-10">
                  <img src={DecryptLogoWhite} width={50} alt="" />
                </Row>
                <div className="bottom-text">
                  <Row>
                    <Typography.Text className="two-columns-title">{t("decrypt-menu")}</Typography.Text>
                  </Row>
                  <Row className="mt-3 mb-6">
                    <Typography.Text className="two-columns-text">{t("Details-of-all-your-links-here")}</Typography.Text>
                  </Row>
                </div>
              </Col>
              <Col className="fanSettingGroup" onClick={() => navigate("/creator/quickLink")}>
                <Row className="mt-10">
                  <img src={Nuage} width={50} alt="" />
                </Row>
                <div className="bottom-text">
                  <Row>
                    <Typography.Text className="two-columns-title">{t("Quick-Files")}</Typography.Text>
                  </Row>
                  <Row className="mt-3 mb-6">
                    <Typography.Text className="two-columns-text">{t("Always-faster")}</Typography.Text>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="professionalize-container mb-20">
              <Col className="professionalize-content">
                <Row>
                  <Typography.Text className="professionalize-title">{t("Professionalize-your-activity")}</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text className="professionalize-description">
                    {t("Unmatched-advice-to-improve-your-activity-awaits-you")}
                  </Typography.Text>
                </Row>
                <Row>
                  <Button onClick={() => window.open("https://forms.fillout.com/t/dNs1WnbUg2us", "_blank")} className="communityButton">
                    {t("Join-the-community")}
                  </Button>
                </Row>
              </Col>
              <Col className="professionalize-image">
                <img src={Monument} width={160} alt="" />
              </Col>
            </Row>

            {creatorSettings.map((group, index) => {
              return (
                <Col key={index} className="fanSettingGroupItem">
                  {group.list.map((item, itemIndex) => {
                    return (
                      <Row
                        key={itemIndex}
                        className="fanSettingItem"
                        onClick={() => handleItemClick(item.link, item.legal, item.isFunction ?? false)}
                      >
                        <img className="mr-30" src={item.logo} width={18} alt="arrow right" />
                        <Typography.Text className="fanSettingItemTitle">{item.title}</Typography.Text>
                      </Row>
                    );
                  })}
                </Col>
              );
            })}
          </>
        )}
      </Layout.Content>
      {type === FAN && <FooterComponent type="creator" page={FOOTER_IDS.SETTINGS} />}
    </Layout>
  );
};

export default SettingPage;
