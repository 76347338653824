import { createBrowserRouter, Navigate } from "react-router-dom";
import CreatorPublicationSwiper from "../components/CreatorPublicationSwiper/CreatorPublicationSwiper";
import FanBookmarkFeedDetailsComponent from "../components/FanBookmarkFeedDetailsComponent";
import FanBookmarksComponent from "../components/FanBookmarksComponent";
import MainFeedDetailsComoponent from "../components/MainFeedDetailsComponent";
import { USER_TYPES } from "../helpers/constant";
import { PreviousLocationProvider } from "../helpers/PreviousLocationContext";
import MainLayout from "../layouts/MainLayout";
import AuthChangePasswordPage from "../pages/AuthPages/ChangePasswordPage";
import ConfirmPasswordChangedPage from "../pages/AuthPages/ConfirmPasswordChangedPage";
import EmailVerifiedPage from "../pages/AuthPages/EmailVerifiedPage";
import ForgotPasswordPage from "../pages/AuthPages/ForgotPasswordPage";
import IntroPage from "../pages/AuthPages/IntroPage";
import SignInPage from "../pages/AuthPages/SignInPage";
import SignUpPage from "../pages/AuthPages/SignUpPage";
import CategorySearchPage from "../pages/CommonPages/CategorySearchPage";
import ChangePasswordPage from "../pages/CommonPages/ChangePasswordPage";
import DecryptPage from "../pages/CommonPages/DecryptPage";
import DecryptPaymentPage from "../pages/CommonPages/DecryptPaymentPage";
import DecryptUnlockedPage from "../pages/CommonPages/DecryptUnlockedPage";
import HelpContactPage from "../pages/CommonPages/HelpContactPage";
import LanguagePage from "../pages/CommonPages/LanguagePage";
import NewMessagePage from "../pages/CommonPages/NewMessagePage";
import PaymentRedirectPage from "../pages/CommonPages/PaymentRedirectPage/PaymentRedirectPage";
import PaymentSuccess from "../pages/CommonPages/PaymentSuccess/PaymentSuccess";
import PersonalInformationsPage from "../pages/CommonPages/PersonalInformationsPage";
import PrivateGalleryPage from "../pages/CommonPages/PrivateGalleryPage";
import ProfilePage from "../pages/CommonPages/ProfilePage/ProfilePage";
import ProfilePageWrapper from "../pages/CommonPages/ProfilePage/ProfilePageWrapper";
import SearchPage from "../pages/CommonPages/SearchPage";
import SettingPage from "../pages/CommonPages/SettingPage";
import CreatorAddMediaComponent from "../pages/CreatorPages/CreatorAddMediaComponent";
import CreatorAlbumFeedsComponent from "../pages/CreatorPages/CreatorAlbumFeedsComponent";
import CreatorAllSubscribers from "../pages/CreatorPages/CreatorAllSubscribers";
import CreatorBillingAddress from "../pages/CreatorPages/CreatorBillingAddress";
import CreatorCodePromotionCreate from "../pages/CreatorPages/CreatorCodePromotionCreate";
import CreatorCodePromotions from "../pages/CreatorPages/CreatorCodePromotions";
import CreatorConversationPage from "../pages/CreatorPages/CreatorConversationPage";
import CreatorCreateDecryptPage from "../pages/CreatorPages/CreatorCreateDecryptPage";
import CreatorCreateNewAlbumComponent from "../pages/CreatorPages/CreatorCreateNewAlbumComponent";
import CreatorDecryptDetailsPage from "../pages/CreatorPages/CreatorDecryptDetailsPage";
import CreatorDecryptPage from "../pages/CreatorPages/CreatorDecryptPage";
import CreatorEditProfilePage from "../pages/CreatorPages/CreatorEditProfilePage";
import CreatorHistoryMediaPushPage from "../pages/CreatorPages/CreatorHistoryMediaPushPage";
import CreatorImportationPage from "../pages/CreatorPages/CreatorImportationPage";
import CreatorLiveEventDetailPage from "../pages/CreatorPages/CreatorLiveEventDetailPage";
import CreatorLivePage from "../pages/CreatorPages/CreatorLivePage";
import CreatorLivePublishPage from "../pages/CreatorPages/CreatorLivePublishPage";
import CreatorMediaPushPage from "../pages/CreatorPages/CreatorMediaPushPage";
import CreatorMessagePage from "../pages/CreatorPages/CreatorMessagePage";
import CreatorMonetizationPage from "../pages/CreatorPages/CreatorMonetizationPage";
import CreatorNewMessageResponsePage from "../pages/CreatorPages/CreatorNewMessageResponsePage";
import CreatorNewPaymentMethodPage from "../pages/CreatorPages/CreatorNewPaymentMethodPage";
import CreatorPaymentHistoryPage from "../pages/CreatorPages/CreatorPaymentHistoryPage";
import CreatorPaymentMethodPage from "../pages/CreatorPages/CreatorPaymentMethodPage";
import CreatorPerformancePage from "../pages/CreatorPages/CreatorPerformancePage";
import CreatorPublicationPage from "../pages/CreatorPages/CreatorPublicationPage";
import CreatorPutStoryPage from "../pages/CreatorPages/CreatorPutStoryPage";
import CreatorQuickLinkComponent from "../pages/CreatorPages/CreatorQuickFileComponent";
import CreatorRevenuePage from "../pages/CreatorPages/CreatorRevenuePage";
import CreatorSponsorshipPage from "../pages/CreatorPages/CreatorSponsorshipPage";
import CreatorSubscriptionPage from "../pages/CreatorPages/CreatorSubscriptionPage/CreatorSubscriptionPage";
import QuickMessagesPage from "../pages/CreatorPages/QuickMessagesPage/QuickMessagesPage";
import FanBugPage from "../pages/FanPages/FanBugPage";
import FanEventPage from "../pages/FanPages/FanEventPage";
import FanHomePage from "../pages/FanPages/FanHomePage";
import FanLivePage from "../pages/FanPages/FanLivePage";
import FanLivesPage from "../pages/FanPages/FanLivesPage";
import FanMessagePage from "../pages/FanPages/FanMessagePage";
import FanNotificationPage from "../pages/FanPages/FanNotificationPage";
import FanPaymentPage from "../pages/FanPages/FanPaymentPage";
import FanRequestMediaPage from "../pages/FanPages/FanRequestMediaPage";
import FanSubscribePage from "../pages/FanPages/FanSubscribePage";
import FanSubscriptionPage from "../pages/FanPages/FanSubscriptionPage/FanSubscriptionPage";
import { authService } from "../services";
import SaveCardPage from "../pages/CommonPages/SaveNewCardPage";
import SavedCardsPage from "../pages/CommonPages/SavedCardsPage/SavedCardsPage";
import AuthSignUpGoogleFanPage from "../pages/AuthPages/SignUpPage/AuthSignUpGoogleFanPage";

const LayoutWithPreviousLocation = () => (
  <PreviousLocationProvider>
    <MainLayout />
  </PreviousLocationProvider>
);

const publicRoutes = [
  {
    path: "/p",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        path: "/p/:username",
        element: <ProfilePageWrapper />,
      },
    ],
  },
];

const authRoutes = [
  {
    path: "/auth",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        index: true,
        element: <Navigate to="/auth/intro" replace />,
      },
      {
        path: "/auth/intro",
        element: <IntroPage />,
      },
      {
        path: "/auth/signIn",
        element: <SignInPage />,
      },
      {
        path: "/auth/signUp",
        element: <SignUpPage />,
      },
      {
        path: "/auth/signUp/google",
        element: <AuthSignUpGoogleFanPage />,
      },
      {
        path: "/auth/forgot",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/auth/forgot/changePassword",
        element: <AuthChangePasswordPage />,
      },
      {
        path: "/auth/forgot/confirm",
        element: <ConfirmPasswordChangedPage />,
      },
      {
        path: "/auth/emailVerified",
        element: <EmailVerifiedPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/auth/intro" replace />,
  },
];

const fanRoutes = [
  {
    path: "/fan",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        index: true,
        element: <Navigate to="/fan/home" replace />,
      },
      {
        path: "/fan/home",
        element: <FanHomePage />,
      },
      {
        path: "/fan/notification",
        element: <FanNotificationPage />,
      },
      {
        path: "/fan/message",
        element: <FanMessagePage />,
      },
      {
        path: "/fan/requestMedia/:conversationId",
        element: <FanRequestMediaPage />,
      },
      {
        path: "/fan/subscribe/:creatorId",
        element: <FanSubscribePage />,
      },
      {
        path: "/fan/payment",
        element: <FanPaymentPage />,
      },
      {
        path: "/fan/lives",
        element: <FanLivesPage />,
      },
      {
        path: "/fan/live/:id",
        element: <FanLivePage />,
      },
      {
        path: "/fan/event/:id",
        element: <FanEventPage />,
      },
      {
        path: "/fan/bookmarks",
        element: <FanBookmarksComponent />,
      },
      {
        path: "/fan/bookmarkFeed/:id",
        element: <FanBookmarkFeedDetailsComponent />,
      },
      {
        path: "/fan/subscription",
        element: <FanSubscriptionPage />,
      },
      {
        path: "/fan/spottedbug",
        element: <FanBugPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/fan/home" replace />,
  },
];

const creatorRoutes = [
  {
    path: "/creator",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        index: true,
        element: <Navigate to="/creator/profile" replace />,
      },
      {
        path: "/creator/revenue",
        element: <CreatorRevenuePage />,
      },
      {
        path: "/creator/monetization",
        element: <CreatorMonetizationPage />,
      },
      {
        path: "/creator/quick/message",
        element: <QuickMessagesPage />,
      },
      {
        path: "/creator/codePromotions",
        element: <CreatorCodePromotions />,
      },
      {
        path: "/creator/codePromotion/create",
        element: <CreatorCodePromotionCreate />,
      },
      {
        path: "/creator/sponsorship",
        element: <CreatorSponsorshipPage />,
      },
      {
        path: "/creator/paymentHistory",
        element: <CreatorPaymentHistoryPage />,
      },
      {
        path: "/creator/paymentMethod",
        element: <CreatorPaymentMethodPage />,
      },
      {
        path: "/creator/newPaymentMethod",
        element: <CreatorNewPaymentMethodPage />,
      },
      {
        path: "/creator/historyMediaPush",
        element: <CreatorHistoryMediaPushPage />,
      },
      {
        path: "/creator/performance/:mediaPushId",
        element: <CreatorPerformancePage />,
      },
      {
        path: "/creator/mediaPush",
        element: <CreatorMediaPushPage />,
      },
      {
        path: "/creator/importation",
        element: <CreatorImportationPage />,
      },
      {
        path: "/creator/live",
        element: <CreatorLivePage />,
      },
      {
        path: "/creator/live/publish/:id",
        element: <CreatorLivePublishPage />,
      },
      {
        path: "/creator/live/event/detail/:id",
        element: <CreatorLiveEventDetailPage />,
      },
      {
        path: "/creator/putStory",
        element: <CreatorPutStoryPage />,
      },
      {
        path: "/creator/publication",
        element: <CreatorPublicationPage />,
      },
      {
        path: "/creator/message",
        element: <CreatorMessagePage />,
      },
      {
        path: "/creator/conversation/:conversationId",
        element: <CreatorConversationPage />,
      },
      {
        path: "/creator/newResponse",
        element: <CreatorNewMessageResponsePage />,
      },
      {
        path: "/creator/profile",
        element: <ProfilePage isMyProfile={true} />,
      },
      {
        path: "/creator/editProfile",
        element: <CreatorEditProfilePage />,
      },
      {
        path: "/creator/decrypt",
        element: <CreatorDecryptPage />,
      },
      {
        path: "/creator/decrypt/:decryptId",
        element: <CreatorDecryptDetailsPage />,
      },
      {
        path: "/creator/createDecrypt",
        element: <CreatorCreateDecryptPage />,
      },
      {
        path: "/creator/quickLink",
        element: <CreatorQuickLinkComponent />,
      },
      {
        path: "/creator/createNewAlbum",
        element: <CreatorCreateNewAlbumComponent />,
      },
      {
        path: "/creator/AddMedia/:albumId",
        element: <CreatorAddMediaComponent />,
      },
      {
        path: "/creator/AlbumFeeds/:albumId",
        element: <CreatorAlbumFeedsComponent />,
      },
      {
        path: "/creator/allSubscribers",
        element: <CreatorAllSubscribers />,
      },
      {
        path: "/creator/subscription",
        element: <CreatorSubscriptionPage />,
      },
      {
        path: "/creator/billingAddress",
        element: <CreatorBillingAddress />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/creator/profile" replace />,
  },
];

const commonRoutes = [
  {
    path: "/common",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        path: "/common/category/search",
        element: <CategorySearchPage />,
      },
      {
        path: "/common/search/:categoryName",
        element: <SearchPage />,
      },
      {
        path: "/common/privateGallery",
        element: <PrivateGalleryPage />,
      },
      {
        path: "/common/setting",
        element: <SettingPage />,
      },
      {
        path: "/common/changePassword",
        element: <ChangePasswordPage />,
      },
      {
        path: "/common/personalInformations",
        element: <PersonalInformationsPage />,
      },
      {
        path: "/common/language",
        element: <LanguagePage />,
      },
      {
        path: "/common/helpContact",
        element: <HelpContactPage />,
      },
      {
        path: "/common/messages/new",
        element: <NewMessagePage />,
      },
      {
        path: "/common/payment",
        element: <FanPaymentPage />,
      },
      {
        path: "/common/subscribe/:creatorId",
        element: <FanSubscribePage />,
      },
      {
        path: "/common/feeds/:id/:userID",
        element: <MainFeedDetailsComoponent />,
      },
      {
        path: "/common/publications/:id/:userID",
        element: <CreatorPublicationSwiper />,
      },
      {
        path: "/common/savedCards",
        element: <SavedCardsPage />,
      },
      {
        path: "/common/saveNewCard",
        element: <SaveCardPage />,
      },
    ],
  },
];

const decryptRoutes = [
  {
    path: "/decrypt",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        path: "/decrypt",
        element: <DecryptPage />,
      },
      {
        path: "/decrypt/payment/:decryptId",
        element: <DecryptPaymentPage />,
      },
      {
        path: "/decrypt/unlocked",
        element: <DecryptUnlockedPage />,
      },
    ],
  },
];

const paymentRoutes = [
  {
    path: "/payment",
    element: <LayoutWithPreviousLocation />,
    children: [
      {
        path: "/payment/success",
        element: <PaymentSuccess />,
      },
      {
        path: "/payment/cancel",
        element: <PaymentSuccess />,
      },
      {
        path: "/payment/fail",
        element: <PaymentSuccess />,
      },
      {
        path: "/payment/redirect",
        element: <PaymentRedirectPage />,
      },
    ],
  },
];

let router: any;

if (!authService.isAuthenticated()) {
  router = createBrowserRouter([...authRoutes, ...decryptRoutes, ...publicRoutes, ...paymentRoutes]);
} else {
  if (authService.getRole() === USER_TYPES.FAN) {
    router = createBrowserRouter([...publicRoutes, ...fanRoutes, ...commonRoutes, ...decryptRoutes, ...paymentRoutes]);
  } else if (authService.getRole() === USER_TYPES.CREATOR) {
    router = createBrowserRouter([...publicRoutes, ...creatorRoutes, ...commonRoutes, ...decryptRoutes, ...paymentRoutes]);
  }
}

export default router;
