import type { TabsProps } from "antd";
import { Layout, Row, Tabs, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import CreatorPaymentHistoryPaymentTabComponent from "../../../components/CreatorPaymentHisotryPaymentTabComponent";
import CreatorPaymentHistoryMonthlyReportTabComponent from "../../../components/CreatorPaymentHistoryMonthlyReportTabComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IPayoutRequest } from "../../../helpers/types";
import { payoutRequestService } from "../../../services/payoutRequest.service";
import "./index.less";

const CreatorPaymentHistoryPage = () => {
  const { t } = useTranslation();
  const [payoutRequests, setPayoutRequests] = useState<IPayoutRequest[]>([]);
  const [totalRevenue, setTotalRevenuel] = useState<number>(0);
  const [reports, setReports] = useState<any>([]);

  useEffect(() => {
    const param = {
      limit: null,
      offset: null,
    };
    const resp = payoutRequestService.getPayoutHistory(param);
    resp
      .then((data: any) => {
        setPayoutRequests(data.data.payment);
        setTotalRevenuel(data.data.totalRevenue);
        setReports(data.data.monthReport);
      })
      .catch(async (error: string) => {
        await message.error(error);
      });
  }, []);

  const tabs: TabsProps["items"] = [
    {
      key: "payment",
      label: t("versement"),
      children: <CreatorPaymentHistoryPaymentTabComponent list={payoutRequests} />,
    },
    {
      key: "monthlyReport",
      label: t("monthly-reports"),
      children: <CreatorPaymentHistoryMonthlyReportTabComponent totalRevenue={totalRevenue} reports={reports} />,
    },
  ];

  return (
    <Layout>
      <Layout.Content className="content creatorPaymentHistoryContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={0} top={2} />
          <Typography.Text className="header-title-black-20">{t("payments-history")}</Typography.Text>
        </Row>
        <div className="tabs-container">
          <Tabs className="custom-tabs hideTab background-white" items={tabs} defaultActiveKey="payment" centered />
        </div>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorPaymentHistoryPage;
