import axios from "../helpers/axios";
import { IAllSavedCards, SavedCardPayload } from "../helpers/types";
import { authService } from "./auth.service";

class SavedCardService {
  public addNewCard(payload: SavedCardPayload) {
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(`/payment/saveCard`, payload)
        .then((response) => {
          if (response?.data?.status === 0) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public allSavedCards = () => {
    const url = `/payment/cards`;
    return new Promise<IAllSavedCards[]>((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data?.status === 0) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public deleteSavedCard(token: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/payment/deleteCard/${token}`, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }
}

export const SavedCardServices = new SavedCardService();
