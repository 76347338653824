import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ICreatorSuggestionSection } from "../../../helpers/types";
import AvatarComponent from "../../AvatarComponent";

interface SuggestionSectionTwoProps {
  creatorSuggestionSection: ICreatorSuggestionSection[];
}

const SuggestionSectionTwo: React.FC<SuggestionSectionTwoProps> = ({ creatorSuggestionSection }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {creatorSuggestionSection.length > 0 && (
        <>
          <Row className="mt-10">
            <Typography.Text className="suggestionsSubTitle">{t("suggestions-section-title-2")}</Typography.Text>
          </Row>
          <div className="suggestionsContainer">
            {creatorSuggestionSection.map((item) => (
              <div className="suggestionItem" key={item._id} onClick={() => navigate("/p/" + item.creator.username)}>
                <AvatarComponent image={item.creator.avatar} size={150} />
                <Typography.Text className="w-full suggestionText ellipsis-single-line">{item.creator.name}</Typography.Text>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestionSectionTwo;
