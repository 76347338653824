import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.less";
import { OnlineUsers } from "./helpers/types";
import { setOnlineUsers } from "./redux/messages/messagesSlice";
import { AppDispatch } from "./redux/store";
import router from "./routes";
import { socket } from "./socket";
import languageUtil from "./utls/LanguageUtil";

function App() {
  const { i18n } = useTranslation();

  /**
   * todo: remove this block before going to production
   */
  const [isConnected, setIsConnected] = useState(socket.connected);
  if (isConnected) {
    console.log(`WebSockets connection opened successfully`);
  } else {
    console.error(`WebSockets connection failed`);
  }
  /**
   * End
   */

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const init = async () => {
      const language = languageUtil.getCurrentLanguage();
      await languageUtil.setCurrentLanguage(i18n, language);
    };

    init();
  }, []);

  useEffect(() => {
    // todo: remove this block before going to production
    function onConnect() {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    /**
     * End
     */

    function handleUpdateOnlineUsers(data: OnlineUsers) {
      console.log("online users", data);
      dispatch(setOnlineUsers(data));
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    socket.on("users", handleUpdateOnlineUsers);

    return () => {
      // todo: remove this block before going to production
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      /**
       * End
       */

      socket.off("users", handleUpdateOnlineUsers);
    };
  }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      })
      .catch((error) => {
        console.error("Service worker unregistration failed:", error);
      });
  }

  if ("caches" in window) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
