import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space, Typography, Button, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import InputCompnent from "../../../components/InputComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import LockIcon from "../../../assets/icons/lock-grey.svg";
import "./index.less";
import { userService, utilService } from "../../../services";
import { IBankingSetting } from "../../../helpers/types";
import { useNavigate } from "react-router-dom";
import { SelectValue } from "antd/es/select";
import { FilterFunc } from "rc-select/lib/Select";

interface OptionItem {
  value: SelectValue;
  label: string;
}

const CreatorNewPaymentMethodPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>({});
  const [bankingSetting, setBankingSetting] = useState<IBankingSetting>({});
  const [countries, setCountries] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    const resp = userService.getMyProfile() as any;
    resp
      .then((data: any) => {
        setUser(data);
        changedItem("firstName", data.firstName);
        changedItem("lastName", data.lastName);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });

    const countriesList = utilService.getPhoneCoutries() as any;
    countriesList
      .then((data: any) => {
        let options: any = [];
        data.forEach((element: any) => {
          options.push({ label: element.name, value: element.code });
        });
        setCountries(options);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, []);

  const changedItem = (field: keyof IBankingSetting, value: any) => {
    setBankingSetting((prevBankingSetting) => ({
      ...prevBankingSetting,
      [field]: value,
    }));
  };

  const createBankingSetting = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const resp = userService.createBankingSetting(user._id, bankingSetting) as any;
    resp
      .then((data: any) => {
        setLoading(false);
        if (data.message) {
          message.error(data.message);
        } else if (data.data) {
          message.success(t("banking-setting-successfully-created"));
          navigate(-1);
        } else {
          message.error(t("something-want-wrong"));
        }
      })
      .catch((error: any) => {
        setLoading(false);
        message.error(error || t("something-want-wrong"));
      });
  };

  const filterOption: FilterFunc<OptionItem> | undefined = (inputValue: string, option?: OptionItem) => {
    return (option?.label.toLowerCase() ?? "").indexOf(inputValue.toLowerCase()) !== -1;
  };

  return (
    <Layout>
      <Layout.Content className="content creatorNewPaymentMethodConentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={4} top={0} />
          <Typography.Text className="header-title font-20-bold">{t("account-information")}</Typography.Text>
        </Row>
        <Row className="mt-20">
          <Space className="w-full gap-13" direction="vertical">
            <Row gutter={7} align="middle">
              <Col span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("identity")}</Typography.Text>
                  <InputCompnent
                    className="darkInput"
                    placeholder={t("first-name")!}
                    value={user.firstName}
                    suffix={<img src={LockIcon} alt="lock" />}
                    disabled={true}
                  />
                </Space>
              </Col>
              <Col span={12} className="mt-21">
                <Space className="w-full gap-6 mt-3" direction="vertical">
                  <InputCompnent
                    className="darkInput"
                    placeholder={t("last-name")!}
                    value={user.lastName}
                    suffix={<img src={LockIcon} alt="lock" />}
                    disabled={true}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("name-of-the-bank")}</Typography.Text>
                <InputCompnent
                  className="darkInput"
                  placeholder={t("name-of-the-bank")!}
                  value={bankingSetting.bankName}
                  onChange={(value) => {
                    changedItem("bankName", value.target.value);
                  }}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("swift-code-of-the-bank")}</Typography.Text>
                <InputCompnent
                  className="darkInput"
                  placeholder={t("swift-code-of-the-bank")!}
                  value={bankingSetting.bankSwiftCode}
                  onChange={(value) => {
                    changedItem("bankSwiftCode", value.target.value);
                  }}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("number-of-the-account")}</Typography.Text>
                <InputCompnent
                  type="text"
                  className="darkInput"
                  placeholder={t("number-of-the-account")!}
                  value={bankingSetting.bankAccount}
                  onChange={(value) => {
                    changedItem("bankAccount", value.target.value);
                  }}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("country-of-the-bank-account")}</Typography.Text>
                <Select
                  showSearch
                  className="select-billing dark"
                  placeholder={t("country-of-the-bank-account")!}
                  options={countries}
                  filterOption={filterOption}
                  value={bankingSetting.country}
                  onSelect={(value) => {
                    changedItem("country", value);
                  }}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("address")}</Typography.Text>
                <InputCompnent
                  className="darkInput"
                  placeholder={t("address")!}
                  value={bankingSetting.address}
                  onChange={(value) => {
                    changedItem("address", value.target.value);
                  }}
                />
              </Space>
            </Row>
            <Row gutter={7}>
              <Col span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("postal-code")}</Typography.Text>
                  <InputCompnent
                    className="darkInput"
                    placeholder={t("postal-code")!}
                    value={bankingSetting.postalCode}
                    onChange={(value) => {
                      changedItem("postalCode", value.target.value);
                    }}
                  />
                </Space>
              </Col>
              <Col span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("city")}</Typography.Text>
                  <InputCompnent
                    className="darkInput"
                    placeholder={t("city")!}
                    value={bankingSetting.city}
                    onChange={(value) => {
                      changedItem("city", value.target.value);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Space>
        </Row>
        <Row className="mt-35">
          <Button className="authBtn" onClick={createBankingSetting}>
            {t(loading ? "adding" : "add-a-payment-method")} {loading && "..."}
          </Button>
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorNewPaymentMethodPage;
