import React from "react";
import { Layout, Row, Col, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FooterComponent from "../../../components/FooterComponent";
import AvatarComponent from "../../../components/AvatarComponent";
import { getNotifications } from "../../../helpers/constant";
import VerifiyIcon from "../../../assets/icons/verify.svg";
import "./index.less";

const FanNotificationPage = () => {
  const { t } = useTranslation();

  const notifications = getNotifications(t);

  return (
    <Layout>
      <Layout.Header className="header border-bottom-grey-color">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent />
          <Typography.Text className="header-title">
            {t("notification")}
          </Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content fanNotificationContentWrapper">
        {notifications.map((group, index) => {
          return (
            <Col key={index} className="fanNotificationGroup">
              <Row className="fanNotificationGroupHeader">
                <Typography.Text className="content-title">
                  {group.header}
                </Typography.Text>
              </Row>
              {group.list.map((item, itemIndex) => {
                return (
                  <Space key={itemIndex} className="fanNotificationItem">
                    <AvatarComponent image={item.avatar} size={32} />
                    <Row className="fanNotificationItemContent">
                      <Typography.Text className="fanNotificationName">
                        {item.name}
                      </Typography.Text>
                      {item.verifiedUser && (
                        <img
                          className="fanNotificaitionVerifyIcon"
                          src={VerifiyIcon}
                          alt="verify"
                        />
                      )}
                      <Typography.Text className="fanNotificationContent">
                        {item.content}
                      </Typography.Text>
                      <Typography.Text className="fanNotificationTime">
                        {item.time}
                      </Typography.Text>
                    </Row>
                  </Space>
                );
              })}
            </Col>
          );
        })}
      </Layout.Content>
      <FooterComponent type="fan" page="" />
    </Layout>
  );
};

export default FanNotificationPage;
