import React from "react";
import { Col, Row, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import "./index.less";
import { EURO_SYMBOLE } from "../../helpers/constant";
import FanAvatarComponent from "../FanAvatarComponent";
import AvatarComponent from "../AvatarComponent";
import TipsImg from "../../assets/images/tips.webp";

const LiveTipsComponent: React.FC = () => {
  const liveTip = useSelector((state: StoreState) => state.liveStreamStats.tips.tips);

  return (
    <Space className="w-full flex-col-reverse gap-10" direction="vertical">
      {liveTip && (
        <Row className="live-tips-row">
          <Typography.Text className="pl-4 font-18-bold text-white-color username-text">{liveTip.name}</Typography.Text>
          <div className="tips-container">
            <img src={TipsImg} width={16} height={16} className="mr-5" />
            <Typography.Text className="font-14-bold text-white-color">
              {parseFloat(liveTip.amount).toFixed(2)} {EURO_SYMBOLE}
            </Typography.Text>
          </div>
        </Row>
      )}
    </Space>
  );
};

export default LiveTipsComponent;
