import { Skeleton } from 'antd'
import './index.less'
interface Props {
  loading: boolean
}

export const FanLivesPageSkelton = ({loading}: Props) => {
  return (
    <>
      {
        [0, 1].map((item, index) => <div key={index} className='fhp-main-skelton'>
        <Skeleton className='fhp-skelton-bg' loading={loading} active avatar paragraph={{ rows: 0 }}/>
        <Skeleton.Image className='fhp-skelton-bg' active style={{ width: "100%", height: 250 }} />
        </div>)
      }
    </>
    
  )
}
