import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IMonetizationState } from "../../helpers/types";
import { userService } from "../../services";

const initialState: IMonetizationState = {
    list: null,
    item: null,
    loading: false,
    error: null,
};

export const getCreatorMonetizations = createAsyncThunk(
    "monetizations/getCreatorMonetizations",
    async (id: string) => {
        return userService.getCreatorMonetizations(id);
    }
);

export const updateMonetizations = createAsyncThunk(
    "monetizations/updateMonetizations",
    async (data: any) => {
        return userService.updateMonetizations(data);
    }
);

const monetizationsSlice = createSlice({
    name: "monetizations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
        builder.addMatcher(
            isAnyOf(getCreatorMonetizations.fulfilled, updateMonetizations.fulfilled),
            (state, action) => {
                state.item = action.payload;
                state.loading = false;
                state.error = null;
            }
        );
        builder.addMatcher(
            isAnyOf(getCreatorMonetizations.pending, updateMonetizations.pending),
            (state, action) => {
                state.item = null;
                state.loading = true;
                state.error = null;
            }
        );
        builder.addMatcher(
            isAnyOf(getCreatorMonetizations.rejected, updateMonetizations.rejected),
            (state, action) => {
                state.item = null;
                state.loading = false;
                state.error = action.error.message || "Something Went Wrong";
            }
        );
    },
});

export default monetizationsSlice.reducer;