import type { UploadFile } from "antd";
import { Button, Layout, Row, Space, Spin, Typography, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/icons/plus-black.svg";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { quickFileService } from "../../../services/quickFile.service";
import AddCircleGrey from "../../../assets/icons/add-circle-grey.svg";
import "./index.less";

const CreatorAddMediaComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFileList(fileList);
  };

  async function handleMediaUpload() {
    setLoading(true);
    let formData = new FormData();
    fileList.forEach((file: UploadFile) => {
      if (file.status !== "removed") {
        formData.append("quickFiles", file.originFileObj as any);
      }
    });

    quickFileService
      .addMediaToAlbum(albumId, formData, setProgress)
      .then(() => {
        message.success(t("quick-file-media-uploaded-success-message"));
        message.info(t("big-file-info"));
        navigate("/creator/quickLink");
      })
      .catch((err) => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //TODO: check Upload component
  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
      <Layout className="gradient-background-blue-settings">
        <Layout.Content className="content CreatorAddMediaContentWrapper background-transparent">
          <Row className="relative justify-content-center header-title-container-70">
            <ArrowLeftIconComponent top={10} left={15} />
            <Typography.Text className="header-title-black-35">{t("media")}</Typography.Text>
          </Row>
          <Space direction="vertical" className="items-center w-full mt-30 addMediaAlbumWrapper">
            <AddMediaComponent disabled={loading} title=" " crop={false} onFilesChange={handleFilesChange}>
              <div>
                <img src={AddCircleGrey} width={250} alt="plus" />
              </div>
            </AddMediaComponent>
          </Space>
          <div className="full-width-container">
            <div className="centered-content">
              <Typography.Text className="creatorAddMediaText mt-5">{t("click-add-media-to-your-album")}</Typography.Text>
            </div>
          </div>

          <Space direction="vertical" className="items-center w-full mt-79 mb-20">
            <Button
              //loading={loading}
              disabled={loading}
              className={`AlbumMediaUploadButton ${fileList.length > 0 ? "bg-white" : "bg-grey"}`}
              onClick={() => {
                fileList.length > 0 && !loading && handleMediaUpload();
              }}
            >
              {t("auth-forgot-password-terminate-label")}
            </Button>
          </Space>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorAddMediaComponent;
