import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import dateUtil from "../../utls/DateUtil";
import ArrowBottomIcon from "../../assets/icons/arrow-bottom.svg";
import "./index.less";
import { useTranslation } from "react-i18next";
import localeFR from "antd/es/date-picker/locale/fr_FR";
import localeEN from "antd/es/date-picker/locale/en_US";
import languageUtil from "../../utls/LanguageUtil";

type props = {
  className?: string;
  date?: string | null;
  onChange?: (value: string | null) => void;
};

const DatePickerComponent: React.FC<props> = ({ className, date, onChange }) => {
  const [value, setValue] = useState<Dayjs | null>(null);
  const { t } = useTranslation();
  const minDate = dayjs().subtract(18, "year");

  useEffect(() => {
    if (date) {
      setValue(dayjs(dateUtil.convertDateTimeStringToDateString(date)));
    }
  }, [date]);

  function disabledDate(current : any) {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return current && current > eighteenYearsAgo;  
  }

  return (
    <DatePicker
      inputReadOnly
      className={`datePicker ${className}`}
      suffixIcon={<img src={ArrowBottomIcon} alt="arrow bottom" />}
      value={value}
      placeholder={t("date-of-birth-label") as string}
      locale={languageUtil.getCurrentLanguage() === "fr" ? localeFR : localeEN}
      format="DD/MM/YYYY"
      defaultPickerValue={minDate}
      onChange={(date) => {
        if (date && onChange) {
          onChange(date.format("YYYY-MM-DD"));
        }
      }}
      placement="bottomLeft"
      disabledDate={disabledDate}
    />
  );
};

export default DatePickerComponent;
