import dateUtil from "./DateUtil";
import { ISelectType } from "../helpers/types";

class LiveUtil {
  public getScheduleDateTimeStringForProfile(t: any, dateTime: string) {
    if (!dateTime) return null;

    let str = `${t("LIVE")} ${t("for")}`;

    const month = dateUtil.getMonthFromDateTimeString(dateTime);
    const date = dateUtil.getDateFromDateTimeString(dateTime);
    const hour = dateUtil.getHourFromDateTimeString(dateTime);
    const min = dateUtil.getMinFromDateTimeString(dateTime);

    if (!dateUtil.isCurrentYear(dateTime)) {
      const year = dateUtil.getYearFromDateTimeString(dateTime);
      str += ` ${year}`;
    }
    str += ` ${date}/${month} ${t("at")} ${hour}h${min}`;

    return str;
  }

  public getScheduleDateTime(t: any, dateTime: string) {
    if (!dateTime) return null;

    let str;

    const month = dateUtil.getMonthAbbreviationFromDateTimeString(dateTime, t("current-date"));
    const date = dateUtil.getDateFromDateTimeString(dateTime);
    const hour = dateUtil.getHourFromDateTimeString(dateTime);
    const min = dateUtil.getMinFromDateTimeString(dateTime);

    str = `${date} ${month}`;
    if (!dateUtil.isCurrentYear(dateTime)) {
      const year = dateUtil.getYearFromDateTimeString(dateTime);
      str += ` ${year}`;
    }
    str += ` ${t("at")} ${hour}h${min}`;

    return str;
  }

  public getDurationString(second: number) {
    return dateUtil.getHoursMinSecTimeStreaming(second);
    //return `${dateUtil.getMinuteFromSecond(second)}:${dateUtil.getSecondFromSecond(second)}`;
  }

  public getAccessTitleFromValue(accesses: ISelectType[], value: string | null): string | null {
    const access = accesses.find((item) => item.value === value);
    if (access) return access.label;
    else return null;
  }

  public getScheduleDayTimeString(t: any, dateTimeString: string) {
    let str = "";

    if (dateUtil.isToday(dateTimeString)) str += t("today");
    else {
      if (dateUtil.isCurrentWeek(dateTimeString)) str += dateUtil.getDayAbbreviationFromDateTimeString(dateTimeString, t("current-date"));
      else {
        const month = dateUtil.getMonthAbbreviationFromDateTimeString(dateTimeString, t("current-date"));
        const date = dateUtil.getDateFromDateTimeString(dateTimeString);
        str += `${date} ${month}`;
        if (!dateUtil.isCurrentYear(dateTimeString)) {
          const year = dateUtil.getYearFromDateTimeString(dateTimeString);
          str += ` ${year}`;
        }
      }
    }

    const hour = dateUtil.getHourFromDateTimeString(dateTimeString);
    const min = dateUtil.getMinFromDateTimeString(dateTimeString);

    str += ` ${hour}h${min}`;

    return str;
  }

  public getScheduleDayTimeOnlyHours(t: any, dateTimeString: string) {
    const hour = dateUtil.getHourFromDateTimeString(dateTimeString);
    const min = dateUtil.getMinFromDateTimeString(dateTimeString);

    return `${hour}h${min}`;
  }

  public getScheduleDayAndMonthTime(t: any, dateTimeString: string) {
  }
}

const liveUtil = new LiveUtil();

export default liveUtil;
