import axios from "../helpers/axios";
import { IAllSubscription, IVerifySubscription, VerifySubscriptionPayload } from "../helpers/types";

class SubscriptionsService {
  public verifySubscription(payload: VerifySubscriptionPayload) {
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(`/subscriptions/verifySubscription`, payload)
        .then((response) => {
          if (response?.data?.status === 0) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public VerifySubscription = (data: any) => {
    const url = `/subscriptions/verifySubscription`;
    return new Promise<IVerifySubscription>((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public applePay = (body: any) => {
    const url = `/payment/applePay/subscribe/creators`;
    return axios
      .post(url, body)
      .then((res) => {
        if (res?.data) {
          return res?.data;
        }
      })
      .catch((error) => {
        if (error?.response) {
          return error?.response;
        }
      });
  };

  public subscribeCreator = (paymentDetails: any) => {
    const url = `/payment/subscribe/creators`;
    return axios
      .post(url, paymentDetails)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        return error?.message?.data;
      });
  };

  public unsubscribeCreator = (creatorId: string) => {
    const url = `/payment/stripe/cancelSubscription`;
    const payload = {
      creatorId,
    };
    return axios
      .post(url, payload)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        return error?.message?.data;
      });
  };

  public allSubscriptions = (limit : Number, skip : Number) => {
    const url = `/subscriptions/fan/search?q=&limit=${limit}&offset=${skip}`;
    return new Promise<IAllSubscription[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getCreatorAllSubscriptions = (limit : Number , skip : Number) => {
    const url = `/subscriptions/creator/subscriptions?q=&limit=${limit}&offset=${skip}&sortBy&sort&userId&userIds&creatorId=&subscriptionType&fromDate&toDate`;
    return new Promise<IAllSubscription[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public create3dSecureTransaction = (transactionId: any , token : any) => {
    const url = `/payment/shift/createSubscription`;
    return axios
      .post(url , {
        transactionId,
        token
      })
      .then((res) => {
        if (res?.data) {
          return res?.data;
        }
      })
      .catch((error) => {
        if (error?.response) {
          return error?.response;
        }
      });
  };
  
  public removeSubscriber = (fanId: string) => {
    const url = `/payment/stripe/cancelSubscriptionByCreator`;
    const payload = {
      fanId,
    };
    return axios
      .post(url, payload)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error?.message?.data;
      });
  };
  
  public getCreatorAllSubscribers = (limit : Number , skip : Number) => {
    const url = `/subscriptions/creator/search?q=&limit=${limit}&offset=${skip}&sortBy&sort&userId&userIds&creatorId=&subscriptionType&fromDate&toDate`;
    return new Promise<IAllSubscription[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
 };

}

export const subscriptionsService = new SubscriptionsService();