import React from "react";
import { Layout, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { posts } from "../../../helpers/constant";
import CloseIcon from "../.././../assets/icons/close.svg";
import "./index.less";

const CreatorPutStoryPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.Content className="content creatorPutStoryContentWrapper">
        <Row className="imageWrapper">
          <img src={CloseIcon} alt="close" />
          <div
            className="imageContent"
            style={{ backgroundImage: `url("${posts[1].postImage}")` }}
          />
        </Row>
        <Row className="mt-27">
          <Button className="whiteButton max-w-211 font-16-bold pt-9 pb-9 br-25-5">
            {t("put-in-story")}
          </Button>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorPutStoryPage;
