import React from "react";
import { Layout, Typography } from "antd";
import "./index.less";

type props = {
  name: String | undefined;
  size: Number | undefined;
};

const FanAvatarComponent: React.FC<props> = ({ name, size }) => {
  //const [bgColor, setBgColor] = useState('#0a3f20');

  function getInitials() {
    const words = name?.split(/[\s.]+/);
    const firstLetters = words?.map((word) => word.charAt(0));
    return firstLetters?.join("");
  }

  /*const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

  useEffect(() => {
    setBgColor(getRandomColor());
  }, [name]);
  style={{backgroundColor: bgColor}}
  */

  return (
    <div className={`FanAvatarWrapper w-${size}`}>
      <Typography.Text className="label">{getInitials()}</Typography.Text>
    </div>
  );
};

export default FanAvatarComponent;
