import { Button, Row, Space, Spin, Typography, UploadFile } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StatusBlackIcon from "../../assets/icons/status-circle-black.svg";
import { ACCEPTED, FILE, MAX_MEDIAS_TO_SEND, PENDING, REJECTED, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import { mediapushService } from "../../services";
import AddMediaComponent from "../AddMediaComponent";
import MediaComponent from "../MediaComponent";
import MessagePriceComponent from "../MessagePriceComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";

type props = {
  message: IMessage;
  expired?: boolean;
  hideStatus?: boolean;
  isBlur?: boolean;
};

const MessageMediaItemComponent: React.FC<props> = ({ message, expired, hideStatus, isBlur = true }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFiles(fileList);
  };

  const handleSendMedias = async () => {
    if (!isEmpty(files) && !loading) {
      setLoading(true);
      const formData = new FormData();
      formData.set("messageId", message._id!);
      formData.set("mediaPushId", message.mediaPushId!);
      files.forEach((file: any) => {
        formData.append(`files`, file.originFileObj as any);
      });
      await mediapushService.updateMediaPushFiles(formData).finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <Space className={`messageMediaItem ${expired ? "expired" : ""}`} direction="vertical">
      <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
        <Row>
          <Space className="w-full gap-4" direction="vertical">
            <MessagePriceComponent message={message} income={false} />

            {message.medias && !isEmpty(message.medias) ? (
              <Row>
                {message.medias?.map((media) => {
                  let imageUrl = media.url;
                  let isVideo = false;
                  if (media.url.includes(".mp4")) {
                    imageUrl = media.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath;
                    isVideo = true;
                  }
                  return (
                    <MediaComponent
                      key={media.fileId}
                      width={60}
                      height={60}
                      borderRadius={11}
                      image={imageUrl}
                      url={media.url}
                      isVideo={isVideo}
                      username={""}
                      isPrivate={false}
                      isBlur={isBlur}
                      shouldAddWatermark={false}
                    />
                  );
                })}
              </Row>
            ) : (
              !expired &&
              message.status !== REJECTED && (
                <Row className="mt-5">
                  <AddMediaComponent
                    disabled={message.status === PENDING}
                    onFilesChange={handleFilesChange}
                    showTitle={false}
                    maxCount={MAX_MEDIAS_TO_SEND}
                  />
                  {/* <Space direction="vertical">
                    <Space className={"addMediaWrapper"} wrap>
                      <Tooltip title={t("send")}>
                        <div
                          className={`validationCircle validationBtn ${
                            isEmpty(files) || (message.status !== REJECTED && message.status === PENDING) ? "disabled" : ""
                          }`}
                          onClick={handleSendMedias}
                        >
                          <img src={CheckIcon} width={12} alt={"send"} />
                        </div>
                      </Tooltip>
                    </Space>
                  </Space> */}
                </Row>
              )
            )}
          </Space>
        </Row>
        {message.text && (
          <Row className="mt-7">
            <Typography.Text className="font-15-medium text-black-color">{message.text}</Typography.Text>
          </Row>
        )}
        {isEmpty(message.medias) && !expired && message.status !== REJECTED && (
          <Button
            className="sendMediaButton"
            onClick={handleSendMedias}
            disabled={isEmpty(files) || (message.status !== REJECTED && message.status === PENDING)}
          >
            {t("send")}
          </Button>
        )}
        {!hideStatus ? (
          <Row className="mt-10">
            <Space>
              <img src={StatusBlackIcon} alt={message.status} />
              <Typography.Text className="font-14-bold text-black-color">
                {t(message.status!)} {isEmpty(message.medias) && message.status === ACCEPTED && message.type === FILE ? `(${t(PENDING)})` : ""}
              </Typography.Text>
            </Space>
          </Row>
        ) : null}
      </Spin>
    </Space>
  );
};

export default MessageMediaItemComponent;
