import React, { createContext, useContext } from "react";
import { Location } from "react-router-dom";
import usePreviousLocation from "./hooks/usePreviousLocation";

const PreviousLocationContext = createContext<Location | undefined>(undefined);

export const PreviousLocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const previousLocation = usePreviousLocation();

  return <PreviousLocationContext.Provider value={previousLocation}>{children}</PreviousLocationContext.Provider>;
};

export const usePreviousLocationContext = () => useContext(PreviousLocationContext);
