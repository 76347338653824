import { Col, Layout, Row, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../../../components/FooterComponent";
import SearchComponent from "../../../components/SearchComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { FOOTER_IDS, categories } from "../../../helpers/constant";
import { IUser } from "../../../helpers/types";
import { AppDispatch, StoreState } from "../../../redux/store";
import { addRecentSeacrchUser, resetList, search, searchMoreUsers } from "../../../redux/users/usersSlice";
import RecentVisitedProfiile from "../RecentVisitedProfile";
import "./index.less";

const NB_USER_TO_SHOW = 15;

const CategorySearchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { list: creators, total, loading } = useSelector((state: StoreState) => state.users);
  const [searchValue, setSearchValue] = useState("");
  const [seeAllResults, setSeeAllResults] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showCancelButton, setshowCancelButton] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const recentRef = useRef<HTMLInputElement | null>(null);
  const [showRecentVisitedProfile, setShowRecentVisitedProfile] = useState(false);
  const [loaded, setLoaded] = useState<any>({});

  useEffect(() => {
    categories?.forEach((category) => {
      const img = new Image();
      img.src = category.img;
      img.onload = () => {
        setLoaded((prev: any) => ({ ...prev, [category.id]: true }));
      };
    });
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      const payload = {
        q: searchValue,
        limit: NB_USER_TO_SHOW,
      };
      const delayDebounceFn = setTimeout(() => {
        dispatch(search(payload));
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);

  const handleInputClick = () => {
    setShowRecentVisitedProfile(true);
    setshowCancelButton(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      recentRef.current &&
      !recentRef.current.contains(event.target as Node)
    ) {
      setShowRecentVisitedProfile(false);
      setshowCancelButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const categoryClicked = (categoryName: string) => {
    if (categoryName.includes("/")) {
      categoryName = categoryName.replace("/", "-");
    }
    navigate(`/common/search/${categoryName}`);
  };

  const searchCreators = (value: string) => {
    if (value !== undefined && value !== "") {
      setSearchValue(value);
      setIsSearching(true);
      setSeeAllResults(false);
      setHasNextPage(true);
      setPage(1);
    } else {
      cancelSearching();
    }
  };

  const cancelSearching = () => {
    setSearchValue("");
    setIsSearching(false);
    setSeeAllResults(false);
    setHasNextPage(false);
    setPage(1);
    dispatch(resetList());
  };

  const onProfileClick = (id: string, username: string) => {
    if (id !== "") {
      dispatch(addRecentSeacrchUser(id));
      navigate(`/p/${username}`);
    }
  };

  function loadMoreUsers() {
    if (isSearching) {
      const nextPage = page + 1;
      const totalPages = Math.ceil(total! / NB_USER_TO_SHOW);
      const payload = {
        q: searchValue,
        limit: NB_USER_TO_SHOW,
        offset: page * NB_USER_TO_SHOW,
      };
      dispatch(searchMoreUsers(payload));
      if (nextPage >= totalPages) {
        setHasNextPage(false);
      }
      setPage(nextPage);
    }
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  const getFullName = (creator: IUser) => {
    if (!creator) return;
    const firstName = creator.firstName ?? "";
    const lastName = creator.lastName ?? "";
    return firstName.concat(` ${lastName}`);
  };

  return (
    <Layout>
      <Layout.Content className="content fanCategorySearchContentWrapper gradient-background-black-blue">
        <Row className="header-title-container-80">
          <Typography.Text className="header-title-black-35">{t("search")}</Typography.Text>
        </Row>
        <Row className="items-center">
          <Col span={showCancelButton ? 19 : 24} ref={inputRef}>
            <SearchComponent
              onClick={handleInputClick}
              placeholder={`${t("search-2")}`}
              handleChange={(e) => searchCreators(e.target.value)}
              value={searchValue}
            />
          </Col>
          {showCancelButton && (
            <Col span={5} className="cancelText">
              <Typography.Text className="cancel-button cursor-pointer" onClick={cancelSearching}>
                {t("cancel")}
              </Typography.Text>
            </Col>
          )}
        </Row>
        {!showCancelButton && (
          <Row>
            <Typography.Text className="content-title mb-10 mt-20">{t("explore")}</Typography.Text>
          </Row>
        )}
        <Row className={`${showRecentVisitedProfile ? "mx-0 px-5 pt-5" : ""}`} gutter={isSearching ? 0 : 19}>
          {isSearching ? (
            <>
              {creators &&
                creators.map((creator, index) => {
                  return (index < NB_USER_TO_SHOW && !seeAllResults) || seeAllResults ? (
                    <Col span={24}>
                      <Row
                        key={creator._id}
                        onClick={() => onProfileClick(creator._id !== undefined ? creator._id : "", creator.username!)}
                        className="justify-between items-center mt-10"
                      >
                        <Col span={24}>
                          <Row className="items-center">
                            <img
                              className="avatar"
                              src={creator.avatar !== undefined ? creator.avatar : ""}
                              alt={creator.avatar !== undefined ? creator.avatar : ""}
                            />
                            <Space direction="vertical" className="recentVisitedTextWrapper">
                              <Typography.Text className="UserName">{t(`${creator.username !== undefined ? creator.username : ""}`)}</Typography.Text>
                              <Typography.Text className="FirstName">{getFullName(creator)}</Typography.Text>
                            </Space>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ) : null;
                })}

              {creators && !seeAllResults && total! > NB_USER_TO_SHOW ? (
                <Row className={`mt-16 mx-auto ${seeAllResults ? "d-none" : ""}`}>
                  <Typography.Text
                    onClick={() => {
                      setSeeAllResults(true);
                      setHasNextPage(true);
                    }}
                    className="SearchSeeAllResults mx-auto"
                  >
                    {t("see-all-results")}
                  </Typography.Text>
                </Row>
              ) : null}

              {(loading || hasNextPage) && seeAllResults && <SpinnerComponent refProp={sentryRef} />}
            </>
          ) : !isSearching && showRecentVisitedProfile ? (
            <Col span={24} className="mt-4" ref={recentRef}>
              <RecentVisitedProfiile />
            </Col>
          ) : (
            <div className="category-images-container">
              {categories.map((category, index) => (
                <Col span={index === 2 ? 24 : 12} key={index} className="image-col">
                  {loaded[category?.id] && (
                    <div className="image-wrapper" onClick={() => categoryClicked(category.id)}>
                      <img src={category.img} className="categoryItem" alt={category.name} />

                      <div className="image-overlay">
                        <div className={`title-category ${index === 2 ? "full-width-title" : ""}`}>{t(category.title)}</div>
                        <div className={`description ${index === 2 ? "full-width-description" : ""}`}>{t(category.description)}</div>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
            </div>
          )}
        </Row>
      </Layout.Content>
      <FooterComponent page={FOOTER_IDS.SEARCH} />
    </Layout>
  );
};

export default CategorySearchPage;
