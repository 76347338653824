import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IReactionState } from "../../helpers/types";
import { reactionService } from "../../services";

const initialState: IReactionState = {
  list: null,
  item: null,
  loading: false,
  error: null,
};

export const createReaction = createAsyncThunk("reactions/createReaction", async (payload: any) => {
  return reactionService.createReaction(payload);
});

export const deactivatedReaction = createAsyncThunk("reactions/deactivatedReaction", async (payload: any) => {
  return reactionService.deactivatedReaction(payload);
});

const reactionsSlice = createSlice({
  name: "reactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(createReaction.fulfilled, deactivatedReaction.fulfilled), (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(createReaction.pending, deactivatedReaction.pending), (state, action) => {
      state.list = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(createReaction.rejected, deactivatedReaction.rejected), (state, action) => {
      state.list = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default reactionsSlice.reducer;
