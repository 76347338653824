import { useState } from "react";
import { useTranslation } from "react-i18next";
import languageUtil from "../../utls/LanguageUtil";
import SelectComponent from "../SelectComponent";
import "./index.less";

const AuthSelectLanguageComponent = () => {
  const { i18n } = useTranslation();
  const [languageValue, setLanguageValue] = useState<string>(languageUtil.getCurrentLanguage());

  const changedLanguage = async (value: string) => {
    await languageUtil.setCurrentLanguage(i18n, value);
    setLanguageValue(languageUtil.getCurrentLanguage());
  };

  return (
    <SelectComponent
      className="authLanguage"
      defaultValue={languageUtil.DEFAULT_LANGUAGE}
      value={languageValue}
      options={languageUtil.AVAILABLE_LANGUAGES_OPTIONS}
      onSelect={changedLanguage}
    />
  );
};

export default AuthSelectLanguageComponent;
