import { Image } from "antd";
import React, { useEffect, useState } from "react";
import VideoIcon from "../../../assets/icons/jouer.svg";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { FALLBACK_IMAGE, THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IThumbnail } from "../../../helpers/types";
import { authService } from "../../../services";
import { addWatermark } from "../../../utls/FunctionsUtil";
import "./index.less";

type props = {
  media: any;
  shouldAddWatermark: boolean;
};

const PrivateGalleryMedia: React.FC<props> = ({ media, shouldAddWatermark = true }) => {
  const image = media.url?.includes(".mp4")
    ? media.thumbnails?.find((thumb: IThumbnail) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
    : media.url;
  const [watermarkedImageUrl, setWatermarkedImageUrl] = useState<string>(shouldAddWatermark ? "" : image);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>("");

  useEffect(() => {
    if (shouldAddWatermark) {
      addWatermark(image, authService.getUser().username)
        .then((newImageUrl: string) => {
          setWatermarkedImageUrl(newImageUrl);
        })
        .catch((err: any) => {
          console.log("Error generating watermarked image:", err);
          setWatermarkedImageUrl(image);
        });
    }
  }, [image, shouldAddWatermark]);

  const handleVideoIconClick = (url: string, isVisible: boolean) => {
    setCurrentVideoUrl(isVisible ? url : "");
    setVisible(isVisible);
  };

  if (watermarkedImageUrl === "") {
    return <></>;
  }

  return (
    <div className="galleryItem">
      <Image
        className={"galleryImage"}
        width={"100%"}
        height={"100%"}
        src={watermarkedImageUrl}
        fallback={FALLBACK_IMAGE}
        preview={
          !media.url?.includes(".mp4")
            ? {}
            : {
                destroyOnClose: true,
                visible: visible && currentVideoUrl === media.url,
                onVisibleChange: (value) => handleVideoIconClick(media.url, value),
                modalRender: () => {
                  if (currentVideoUrl === media.url) {
                    return (
                      <div className="video-preview-wrapper">
                        <VideoPlayer videoUrl={currentVideoUrl} thumbnailUrl={""} autoPlay={true} />
                      </div>
                    );
                  }
                  return undefined;
                },
              }
        }
      />
      {media.url?.includes(".mp4") && (
        <img src={VideoIcon} alt="Loly - video icon" className="albumVideoIcon" onClick={() => handleVideoIconClick(media.url, true)} />
      )}
    </div>
  );
};

export default PrivateGalleryMedia;
