import React, { useEffect } from "react";
import { Modal, Row, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Flag from "../../assets/images/flags.webp";
import "./index.less";

type props = {
  isOpen: boolean;
  title: string;
  paragraphs: string[];
  submitText: string;
  onClose: () => void;
  onSubmit: () => void;
};

const BecomingCreatorModalComponent: React.FC<props> = ({ isOpen, title, paragraphs, submitText, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const isMultipleParagraphs = paragraphs.length > 1;

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="requireSubscribeModalWrapper"
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="requireSubscribeFooterRpw">
            <div className="backgroundBtn">
              <Button className="acceptBtn" type="link" onClick={onSubmit}>
                {submitText}
              </Button>
            </div>
          </Row>
          <Row className="requireSubscribeFooterRpw">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    >
      <Row className="w-full justify-content-center">
        <img src={Flag} width={isMultipleParagraphs ? 180 : 250} alt="" />
      </Row>
      <Row className="w-full justify-content-center text-center mt-10">
        <Typography.Text className={isMultipleParagraphs ? "title-modal-small" : "title-modal"}>{title}</Typography.Text>
      </Row>
      <Row className="w-full justify-content-center text-center mt-20">
        {paragraphs.map((paragraph, index) => (
          <Typography.Text key={index} className="requireSubscribeModalDescription">
            {paragraph}
            {index < paragraphs.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </Typography.Text>
        ))}
      </Row>
    </Modal>
  );
};

export default BecomingCreatorModalComponent;
