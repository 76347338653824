import { Col, Layout, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../../../assets/icons/creator/unlock-link/white-eye.svg";
import ArrowRight from "../../../assets/icons/arrow-right-white.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IDecryptDTO } from "../../../helpers/types";
import { decryptService } from "../../../services/decrypt.service";
import "./index.less";
import { formatNumber } from "../../../utls/FunctionsUtil";

const CreatorDecryptPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const [decrypts, setDecrypts] = useState<IDecryptDTO[]>([]);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      decryptService.getDecrypts().then((data) => {
        setDecrypts(data.data);
      });
    }
  }, []);

  const getDecryptDetails = (id?: string) => {
    navigate(`/creator/decrypt/${id}`);
  };

  return (
    <Layout className="gradient-background-decrypt">
      <Layout.Content className="content unlockLinkContentWrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={12} />
          <Typography.Text className="header-title-black-35">{t("decrypt-link")}</Typography.Text>
        </Row>
        <Space className="w-full gap-21 mt-20" direction="vertical">
          <div className="unlockLinkWrapper">
            {decrypts &&
              decrypts.map((link) => {
                let expireIn: number = 0;
                let expireInLabel: string = "";
                if (dayjs(link.expiresOn).diff(new Date(), "days") > 0) {
                  expireIn = dayjs(link.expiresOn).diff(new Date(), "days");
                  expireInLabel = t("days");
                } else if (dayjs(link.expiresOn).diff(new Date(), "hours") > 0) {
                  expireIn = dayjs(link.expiresOn).diff(new Date(), "hours");
                  expireInLabel = t("hours");
                } else if (dayjs(link.expiresOn).diff(new Date(), "seconds") > 0) {
                  expireIn = dayjs(link.expiresOn).diff(new Date(), "seconds");
                  expireInLabel = t("seconds");
                }
                return (
                  <>
                    <Space
                      key={link._id}
                      className="subs-container mt-5"
                      onClick={() => {
                        getDecryptDetails(link._id);
                      }}
                    >
                      <Row className="w-full items-center">
                        <img
                          className="fanSubscriptionPageAvatarImg"
                          src={
                            link?.files[0]?.url?.includes(".mp4")
                              ? link?.files[0]?.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                              : link?.files[0]?.url
                          }
                          alt={"Loly - unlock link media"}
                        />
                        <Col className="detailsWrapper">
                          <Row className="topRow" align="middle">
                            <Space className="viewsWrapper gap-3">
                              <img className="eyeIcon" src={EyeIcon} width={10} alt="eye" />
                              <Typography.Text className="viewsNumber">
                                {link.numberOfClicks !== undefined ? formatNumber(link.numberOfClicks) : "0"}
                              </Typography.Text>
                            </Space>
                            <Typography.Text className="font-11-regular text-white-color">
                              {dayjs(link.expiresOn).isBefore(dayjs()) || link.status === "inactive" ? (
                                <>{t("expired-link")}</>
                              ) : (
                                <>
                                  {t("expire-in")} {expireIn} {expireInLabel}
                                </>
                              )}
                            </Typography.Text>
                          </Row>
                          <Row className="middleRow">
                            <Typography.Text className="title-decrypt-text">{link.title}</Typography.Text>
                          </Row>
                          <Row>
                            <Typography.Text className="text-white-color revenueWrapper">
                              <span className="font-12-regular revenue-label">{t("total-income")}</span>
                              <span className="font-14-bold">{link.totalEarning?.toFixed(2)} €</span>
                            </Typography.Text>
                          </Row>
                        </Col>
                        <div className="chevron-right">
                          <img width={15} src={ArrowRight} />
                        </div>
                      </Row>
                    </Space>
                  </>
                );
              })}
          </div>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorDecryptPage;
