import React, { useState } from "react";
import { Layout, Typography, Col, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { authService } from "../../../services";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import AuthInputComponent from "../../../components/AuthInputComponent";
import AuthSelectLanguageComponent from "../../../components/AuthSelectLanguageComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import logo from "../../../assets/icons/logo-black.svg";
import "./index.less";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");

  const forgotPassword = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmail = emailRegex.test(email);
    if (!email || !isEmail) {
      return message.warning(t("invalid-email"));
    }
    authService
      .forgotPassword(email)
      .then(() => {
        message.success(t("auth-forgot-password-response-success"));
      })
      .catch(async (error: string) => {
        await message.error(t(error));
      });
  };

  const handleChange = (e: { target: { value: any } }) => {
    setEmail(e.target.value);
  };

  return (
    <Layout>
      <Layout.Content className="authForgotPasswordContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={0} top={3} />
        </Row>

        <Row className="mt-100">
          <Typography.Text className="text-white-color font-36-bold text-center">{t("auth-forgot-password-title")}</Typography.Text>
        </Row>

        <Row className="w-full" style={{ marginBottom: "20px", marginTop: "40px" }}>
          <AuthInputComponent
            label={t("auth-forgot-password-email-label")}
            type="email"
            name="email"
            value={email || ""}
            handleChange={handleChange}
          />
        </Row>

        <Row className="w-full send-button">
          <AuthButtonComponent label={t("send")} onClick={forgotPassword} />
        </Row>

        {/* <Row className="w-full authRowFooter">
          <AuthSelectLanguageComponent />
        </Row> */}
      </Layout.Content>
    </Layout>
  );
};

export default ForgotPasswordPage;
