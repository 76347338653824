import React from "react";
import { Row, Space, Typography } from "antd";
import { ILiveComment } from "../../helpers/types";
import FanAvatarComponent from "../FanAvatarComponent";
import "./index.less";

type props = {
  comments: ILiveComment[] | null;
};

const LiveCommentsComponent: React.FC<props> = ({ comments }) => {
  return (
    <Space className="w-full flex-col-reverse gap-10" direction="vertical">
      {comments?.map((comment, index) => {
        return (
          <Row className="ml-10" key={index}>
            <Space className="gap-6" align="start">
              {/* <FanAvatarComponent name={comment?.user?.username} size={30} /> */}
              <Space className="gap-0" direction="vertical">
                <Typography.Text className="font-14-bold text-white-color">{comment?.user?.username}</Typography.Text>
                <Typography.Text className="font-12-regular text-white-color">{comment?.text}</Typography.Text>
              </Space>
            </Space>
          </Row>
        );
      })}
    </Space>
  );
};

export default LiveCommentsComponent;
