import { LoadingOutlined } from "@ant-design/icons";
import { Col, Layout, message, Row, Space, Spin, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../assets/icons/check-black.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { userService } from "../../../services";
import languageUtil from "../../../utls/LanguageUtil";
import "./index.less";

const LanguagePage = () => {
  const { i18n, t } = useTranslation();
  const [languageValue, setLanguageValue] = useState<string>(languageUtil.getCurrentLanguage());
  const [isLoading, setIsLoading] = useState(false);

  const changedLanguage = async (value: string) => {
    if (value !== languageUtil.getCurrentLanguage()) {
      setIsLoading(true);
      userService
        .updatePreferredLanguage(value)
        .then(async () => {
          await languageUtil.setCurrentLanguage(i18n, value);
          setLanguageValue(value);
        })
        .catch((error) => {
          message.error(t(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Layout className="gradient-background-black-blue no-scroll-page">
      <Layout.Header className="header background-transparent">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent top={7} />
          <Typography.Text className="header-title-black-28">{t("language")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content creatorLanguageContentWrapper background-transparent">
        <Space className="w-full gap-15" direction="vertical">
          <Row className="container-row" onClick={() => changedLanguage("fr")}>
            <Col className={`circle ${languageValue === "fr" ? "active" : ""}`}>
              {languageValue === "fr" && <img src={CheckIcon} alt="Loly check" />}
              {languageValue !== "fr" && isLoading && <Spin indicator={<LoadingOutlined spin className="spin" />} />}
            </Col>
            <Col className="text-container">
              <Typography.Text className={`text ${languageValue === "fr" ? "active" : ""}`}>Français</Typography.Text>
            </Col>
          </Row>
          <Row className="container-row" onClick={() => changedLanguage("en")}>
            <Col className={`circle ${languageValue === "en" ? "active" : ""}`}>
              {languageValue === "en" && <img src={CheckIcon} alt="Loly check" />}
              {languageValue !== "en" && isLoading && <Spin indicator={<LoadingOutlined spin className="spin" />} />}
            </Col>
            <Col className="text-container">
              <Typography.Text className={`text ${languageValue === "en" ? "active" : ""}`}>English</Typography.Text>
            </Col>
          </Row>
          <Row className="container-row" onClick={() => changedLanguage("es")}>
            <Col className={`circle ${languageValue === "es" ? "active" : ""}`}>
              {languageValue === "es" && <img src={CheckIcon} alt="Loly check" />}
              {languageValue !== "es" && isLoading && <Spin indicator={<LoadingOutlined spin className="spin" />} />}
            </Col>
            <Col className="text-container">
              <Typography.Text className={`text ${languageValue === "es" ? "active" : ""}`}>Español</Typography.Text>
            </Col>
          </Row>
        </Space>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default LanguagePage;
