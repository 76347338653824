import { Row, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import GiftIcon from "../../assets/images/gift.webp";
import TimeIcon from "../../assets/images/live-programmed.webp";
import PieceIcon from "../../assets/images/tips.webp";
import { PRMOTION_EXPIRATION_TIME_TYPE } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import CountDownComponent from "../CountDownComponent";
import "./index.less";

type props = {
  message: IMessage;
  income: boolean;
};

const MessagePriceComponent = ({ message, income }: props) => {
  const { t } = useTranslation();

  const validity = dayjs(message.mediaPushPrice?.expiresOn).diff(dayjs(new Date()), "seconds") | 0;

  return (
    <Row className={"priceRow"}>
      <Typography.Text className="text-white-color">
        {message.mediaPushPrice?.promo && (validity > 0 || message.mediaPushPrice?.expireTimeType === PRMOTION_EXPIRATION_TIME_TYPE.unlimited) ? (
          <>
            <Row className="containerDesign">
              <img src={GiftIcon} width={14} style={{ marginRight: "5px" }} alt="" />
              <span className={`font-12-bold promoPrice${income ? "-income" : ""}`} style={{ marginRight: "5px" }}>
                <span className="font-12-black">PROMO</span> {message.mediaPushPrice?.resultPrice} €
              </span>
              <span className={`font-12-bold oldPrice${income ? "-income" : ""}`}>{message.price} €</span>
            </Row>

            <Row className="containerDesign mt-7">
              <img src={TimeIcon} width={14} style={{ marginRight: "5px" }} alt="" />
              <Typography.Text className="text-white-color font-12-bold uppercase">
                <span className="font-12-black">{t("validity")} : </span>
                {message.mediaPushPrice?.expireTimeType !== PRMOTION_EXPIRATION_TIME_TYPE.unlimited ? (
                  <CountDownComponent expireAt={message.mediaPushPrice?.expiresOn} showTooltip={false} showSeconds={false} />
                ) : (
                  t("unlimited")
                )}
              </Typography.Text>
            </Row>
          </>
        ) : message.price !== undefined && parseInt(message.price, 10) > 0 ? (
          <Row className="containerDesign" align="middle">
            <img src={PieceIcon} width={16} style={{ marginRight: "5px" }} alt="" />
            <Typography.Text className="text-white-color font-13-bold"> {message.price} €</Typography.Text>
          </Row>
        ) : message.price !== undefined && parseInt(message.price, 10) === 0 ? (
          <Row className="containerDesign">
            <Typography.Text className="text-white-color font-13-bold">{t("free")}</Typography.Text>
          </Row>
        ) : (
          false
        )}
      </Typography.Text>
    </Row>
  );
};

export default MessagePriceComponent;
