import React from "react";
import DotIcon from "../../assets/icons/white-dot.svg";
import "./index.less";

type props = {
  className?: string;
  onClick?: () => void;
};

const ThreeDotsComponent: React.FC<props> = ({ className, onClick }) => {
  return (
    <div className="dots-wrapper cursor-pointer" onClick={onClick}>
      <div className={`d-flex dots-gap ${className}`}>
        <img src={DotIcon} alt="dot" />
        <img src={DotIcon} alt="dot" />
        <img src={DotIcon} alt="dot" />
      </div>
    </div>
  );
};

export default ThreeDotsComponent;
