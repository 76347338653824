import { Col, Layout, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "../../../assets/icons/green-icon.svg";
import logo from "../../../assets/icons/loly-logo-white.svg";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import "./index.less";

const EmailVerifiedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout className="white-background-color ">
      <Layout.Content className="emailVerifiedContentWrapper gradient-background">
        <Col>
          <Row className="logo" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <img src={logo} width={100} alt="logo" onClick={() => navigate("/")} />
          </Row>

          <Row className="justify-content-center" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography.Text className="title-verified-email">{t("email-verified-title")}</Typography.Text>
          </Row>
          <Row>
            <Space className="w-full" direction="vertical">
              <Row className="logo" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <img src={SuccessIcon} width={150} alt="SuccessIcon" />
              </Row>
              <Row className="justify-content-center" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Typography.Text className="text">{t("email-verified-text")}</Typography.Text>
              </Row>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <AuthButtonComponent label={t("auth-signIn-button")} onClick={() => navigate("/")} />
              </Row>
            </Space>
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default EmailVerifiedPage;
