import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Layout, Typography, Tabs, Row } from "antd";
import type { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import FanLivesSwiper from "../../../components/FanLivesSwiper";
import FanEventsListTabComponent from "../../../components/FanEventsListTabComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS, LIVE_TYPES } from "../../../helpers/constant";
import Flame from "../../../assets/images/flame.webp";
import Popcorn from "../../../assets/images/popcorn.webp";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import { getLivesForFan } from "../../../redux/lives/livesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./index.less";

const FanLivesPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [activeTabKey, setActiveTabKey] = useState<string>(searchParams.get("tab") || "schedule");
  const [isNewLiveAvailable, setIsNewLiveAvailable] = useState<boolean>(false);
  const [isReloadAvailable, setIReloadAvailable] = useState<boolean>(false);

  const newLivePublished = (data: any) => {
    if (!data?.stream) return;
    setIsNewLiveAvailable(true);
  };

  const tabs: TabsProps["items"] = [
    {
      key: LIVE_TYPES.NOW,
      label: (
        <span className="label-tabs">
          <img className="icon-tabs" src={Flame} alt="Flame" />
          {t("onlive")}
        </span>
      ),
      children: <FanLivesSwiper onNewLivePublished={newLivePublished} />,
    },
    {
      key: LIVE_TYPES.SCHEDULE,
      label: (
        <span className="label-tabs">
          <img className="icon-tabs" src={Popcorn} alt="Popcorn" />
          {t("events")}
        </span>
      ),
      children: <FanEventsListTabComponent />,
    },
  ];

  useEffect(() => {
    if (!activeTabKey) return;
    navigate(`/fan/lives?tab=${activeTabKey}`);
  }, [activeTabKey]);

  const getLives = async () => {
    await dispatch(getLivesForFan({ startType: LIVE_TYPES.NOW }));
    setIsNewLiveAvailable(false);
    setIReloadAvailable(false);
  };

  return (
    <Layout>
      <Layout.Content className="content fanLivesContentWrapper gradient-background">
        <Row className="header-live justify-content-between" align="middle">
          <Typography.Text className="header-title-black-35 uppercase">{t("lives")}</Typography.Text>
          {(isNewLiveAvailable || isReloadAvailable) && (
            <div className="new-live-button" onClick={getLives}>
              <img src={RefreshIcon} width={14} />
              <Typography.Text className="new-live-text">{t("new-live-refresh")}</Typography.Text>
            </div>
          )}
        </Row>
        <Tabs className="live-tabs" items={tabs} activeKey={activeTabKey} onTabClick={(key: string) => setActiveTabKey(key)} centered />
      </Layout.Content>
      <FooterComponent type="fan" page={FOOTER_IDS.LIVE} />
    </Layout>
  );
};

export default FanLivesPage;
