import React, { useEffect } from "react";
import { Modal, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose?: () => void;
  onYesClick?: () => void;
};

const CreatorDeletePaymentMethodModal: React.FC<props> = ({ isOpen, onClose, onYesClick }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="CreatorDeletePaymentMethodModal"
      title={t("do-you-want-to-delete-account")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="row">
            <Button type="link" className="confirmBtn" onClick={onYesClick}>
              <div className="confirm-btn-background">{t("modal-confirm-btn")}</div>
            </Button>
          </Row>
          <Row className="row">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default CreatorDeletePaymentMethodModal;
