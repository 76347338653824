import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Typography, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import logo from "../../../assets/icons/loly-logo-white.svg";
import SuccessIcon from "../../../assets/icons/green-icon.svg";
import AuthSelectLanguageComponent from "../../../components/AuthSelectLanguageComponent";

const ConfirmPasswordChangedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout className="white-background-color">
      <Layout.Content className="emailVerifiedContentWrapper gradient-background">
        <Col>
          <Row className="logo" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <img src={logo} width={100} alt="logo" />
          </Row>

          <Row className="justify-content-center" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography.Text className="title-password-confirm">{t("auth-forgot-password-confirm-title")}</Typography.Text>
          </Row>
          <Row>
            <Space className="w-full" direction="vertical">
              <Row className="logo" style={{ marginBottom: "20px" }}>
                <img src={SuccessIcon} width={150} alt="SuccessIcon" />
              </Row>
              <Row className="justify-content-center" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Typography.Text className="text">{t("auth-forgot-password-confirm-text")}</Typography.Text>
              </Row>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <AuthButtonComponent label={t("auth-signIn-button")} onClick={() => navigate("/")} />
              </Row>
            </Space>
          </Row>
          <Row className="w-full authRowFooter">
            <AuthSelectLanguageComponent />
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default ConfirmPasswordChangedPage;
