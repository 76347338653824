import axios from "../helpers/axios";

class ReportService {
  public createReport = (payload: any) => {
    const url = `/reports`;
    return new Promise<any>((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const reportService = new ReportService();
