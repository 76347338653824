import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {quickMessageService} from "../../services";
import {IQuickMessage} from "../../helpers/types";

interface QuickMessagesState {
  quickMessages: IQuickMessage[] | [],
  quickMessage: IQuickMessage,
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  error: any,
  action: string,
}

const initialState = {
  quickMessages: [],
  quickMessage: {},
  loading: "idle",
  error: "",
  action: ""
} as QuickMessagesState

export const getUserQuickMessages = createAsyncThunk(
  "quickMessages/getUserQuickMessages",
  async () => {
    return quickMessageService.getAllQuickMessages();
  })

export const getQuickMessageById = createAsyncThunk(
  "quickMessages/getQuickMessageById",
  async (quickMessageId: string) => {
    return quickMessageService.getQuickMessageById(quickMessageId);
  })

export const createQuickMessage = createAsyncThunk(
  "quickMessages/createQuickMessage",
  async (quickMessage: IQuickMessage) => {
    return quickMessageService.createQuickMessage(quickMessage);
  })

export const updateQuickMessage = createAsyncThunk(
  "quickMessages/updateQuickMessage",
  async (quickMessage: IQuickMessage) => {
    return quickMessageService.updateQuickMessage(quickMessage);
  })

export const deleteQuickMessage = createAsyncThunk(
  "quickMessages/deleteQuickMessage",
  async (quickMessageId: string) => {
    return quickMessageService.deleteQuickMessage(quickMessageId);
  })

const quickMessagesSlice = createSlice({
  name: "quickMessages",
  initialState,
  reducers: {},
  extraReducers: builder => {

    builder.addCase(getUserQuickMessages.fulfilled, (state, action) => {
      state.quickMessages = action.payload;
      state.loading = "succeeded";
    })

    builder.addCase(getQuickMessageById.fulfilled, (state, action) => {
      state.quickMessage = action.payload;
      state.loading = "succeeded";
    })

    builder.addCase(createQuickMessage.fulfilled, (state, action) => {
      state.quickMessages = [...state.quickMessages, action.payload];
      state.loading = "succeeded";
    })

    builder.addCase(updateQuickMessage.fulfilled, (state, action) => {
      state.quickMessages = state.quickMessages.map(message => {
        if (message._id?.toString() === action.payload._id?.toString()) {
          return action.payload;
        }
        return message;
      });
      state.loading = "succeeded";
    })

    builder.addCase(deleteQuickMessage.fulfilled, (state, action) => {
      state.quickMessages = state.quickMessages.filter(message => message._id?.toString() !== action.payload._id?.toString());
      state.loading = "succeeded";
    })

    builder.addMatcher(isAnyOf(getUserQuickMessages.pending, createQuickMessage.pending, updateQuickMessage.pending, deleteQuickMessage.pending),
      (state, action) => {
        state.loading = "pending";
        if (action.type.includes("get")) {
          state.action = "GET";
        } else if (action.type.includes("create")) {
          state.action = "CREATE";
        } else if (action.type.includes("update")) {
          state.action = "UPDATE";
        } else if (action.type.includes("delete")) {
          state.action = "DELETE";
        }
      })
    builder.addMatcher(isAnyOf(getUserQuickMessages.rejected, createQuickMessage.rejected, updateQuickMessage.rejected, deleteQuickMessage.rejected),
      (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })

  }
});

//export const {} = quickMessagesSlice.actions;

export default quickMessagesSlice.reducer;