import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { MessagesInterceptor } from "../../helpers/socketInterceptors/MessagesInterceptor";

const MainLayout = () => {
  return (
    <Layout className="theme-background-color">
      <Outlet />
      <MessagesInterceptor />
    </Layout>
  );
};

export default MainLayout;
