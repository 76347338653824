import { useEffect, useState } from "react";
import { Layout, Row, Col, Space, Typography, Button, message, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import InputCompnent from "../../../components/InputComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import "./index.less";
import { userService } from "../../../services";
import { SelectValue } from "antd/es/select";
import InputComponent from "../../../components/InputComponent";
import CountryDropdownComponent from "../../../components/CountryDropdownComponent/CountryDropdownComponent";
import { SavedCardServices } from "../../../services/savedCard.service";
import { useNavigate } from "react-router-dom";
import { settingService } from "../../../services/settings.service";

interface props {
  cardHolderName: any;
  country: string;
  cardType: string | null;
}

const SaveCardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>({});
  const [Key, setKey] = useState("");
  const [loading, setLoading] = useState<any>(false);
  const cardnumberReg = /^[0-9\s]*$/;
  const CardMonthYearReg = /^[0-9/]*$/;
  const [cardNumber, setCardNumber] = useState("");
  const [MonthYear, setMonthYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const cvvReg = /^\d*$/;
  const currentYear = new Date().getFullYear().toString().substring(2);
  const expCurrentYear = parseInt(currentYear, 10);
  const [cardDetails, setCardDetails] = useState<props>({ cardHolderName: { firstName: "", lastName: "" }, country: "", cardType: "" });
  const [month, year] = MonthYear.split("/");
  // check box
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    getCurrentPaymentGateway()
    const resp = userService.getMyProfile() as any;
    resp
      .then((data: any) => {
        setUser(data);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, []);

  const createBankingSetting = () => {
    // if (loading) {
    //   return;
    // }
    if (!cardNumber || !cvv || !MonthYear) {
      return message.warning("Please Fill all Fields");
    }
    if (cvv.length < 3) {
      return message.warning("Enter Valid CVV");
    }
    if (!cardDetails.cardType) {
      return;
    }
    if (error) {
      return message.warning(error);
    }
    setLoading(true);
    setIsDisabled(true)
    const payload = {
      cardHolderName: `${cardDetails?.cardHolderName?.firstName} ${cardDetails?.cardHolderName?.lastName}`,
      cardNumber: parseInt(cardNumber.replace(/\s/g, "")),
      expiryMonth: parseInt(month),
      expiryYear: 2000 + parseInt(year),
      cvv: Number(cvv),
      cardType: cardDetails?.cardType,
    };
    const resp = SavedCardServices.addNewCard(payload) as any;
    resp
      .then((data: any) => {
        setIsDisabled(false)
        setLoading(false);
        if (data.data) {
          message.success(data?.data);
          navigate("/common/savedCards");
        } else {
          message.error(t("something-want-wrong"));
        }
      })
      .catch((error: any) => {
        setIsDisabled(false)
        setLoading(false);
        message.error(error || t("something-want-wrong"));
      });
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  if (Key !== "Backspace") {
    if (cardNumber.length === 4 || cardNumber.length === 9 || cardNumber.length === 14) {
      setCardNumber(cardNumber + " ");
    }
    if (MonthYear.length === 2) {
      setMonthYear(MonthYear + "/");
    }
  }

  useEffect(() => {
    if (cardNumber.length === 19 && cvv.length === 3 && MonthYear.length === 5 && checkboxChecked && cardDetails?.cardHolderName?.firstName !== "" && cardDetails?.cardHolderName?.lastName !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [cardNumber, cvv, MonthYear, checkboxChecked]);

  function detectCardType(cardNumber: string): string | null {
    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");
    const cardPatterns: { [key: string]: RegExp } = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      MasterCard: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/,
      "American Express": /^3[47][0-9]{13}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
      Maestro: /^(?:5[0678]\d{2}|6\d{3})\d{12,15}$/,
      UnionPay: /^(62|88)\d{14,17}$/,
    };
    for (const cardType in cardPatterns) {
      if (cardPatterns[cardType].test(sanitizedCardNumber)) {
        return cardType;
      }
    }
    return null;
  }

  useEffect(() => {
    const cardType = detectCardType(cardNumber);
    setCardDetails((prev) => ({
      ...prev,
      cardType,
    }));
  }, [cardNumber]);

  const getCurrentPaymentGateway = async () => {
    await settingService.getPayentGateway().then((res: any) => {
      if(res?.paymentGateway === "Stripe"){
        navigate("/fan/home")
      }
    });
  };

  return (
    <Layout>
      <Layout.Header className="header">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent left={4} top={4} />
          <Typography.Text className="header-title font-20-bold">{t("add-new-card")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content SaveCardPageWrapper">
        <Row className="pr-35 mt-10">
          <Space className="w-full gap-13" direction="vertical">
            <Row>
              <Typography.Text className="font-18-bold text-white-color">{t("card-infos")}</Typography.Text>
            </Row>
            <Row gutter={7}>
              <Row className="w-full mt-10" gutter={8}>
                <Col span={12}>
                  <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel font-15-bold">{t("first-name-label")}</Typography.Text>
                    <InputComponent
                      maxLength={19}
                      className="custom-input"
                      onChange={(e) =>
                        setCardDetails({
                          ...cardDetails,
                          cardHolderName: {
                            ...cardDetails.cardHolderName,
                            firstName: e.target.value,
                          },
                        })
                      }
                      value={cardDetails?.cardHolderName?.firstName || ""}
                      placeholder={t("first-name-label") || ""}
                    />
                  </Space>
                </Col>
                <Col span={12}>
                  <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel font-15-bold">{t("last-name-label")}</Typography.Text>
                    <InputComponent
                      maxLength={19}
                      className="custom-input"
                      onChange={(e) =>
                        setCardDetails({
                          ...cardDetails,
                          cardHolderName: {
                            ...cardDetails.cardHolderName,
                            lastName: e.target.value,
                          },
                        })
                      }
                      value={cardDetails?.cardHolderName?.lastName || ""}
                      placeholder={t("last-name-label") || ""}
                    />
                  </Space>
                </Col>
              </Row>
              <Row className="w-full mt-20">
                <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel font-15-bold">{t("card-number")}</Typography.Text>
                  <InputComponent
                    maxLength={19}
                    className="darkInput"
                    onChange={(e) => {
                      if (e.target.value.match(cardnumberReg)) {
                        setCardNumber(e.target.value);
                      }
                    }}
                    value={cardNumber}
                    placeholder="**** **** **** ****"
                    onKeyDown={(e) => setKey(e.key)}
                  />
                </Space>
              </Row>
              <Row className="w-full mt-20" gutter={8}>
                <Col span="12">
                  <Space direction="vertical" className="w-full">
                    <Typography.Text className="fanPaymentLargeLabel w-full font-15-bold">{t("expiration-date")}</Typography.Text>
                    <InputComponent
                      className="darkInput"
                      placeholder="MM/YY"
                      maxLength={5}
                      onChange={(e) => {
                        if (e.target.value.match(CardMonthYearReg)) {
                          const [month, year] = e.target.value.split("/");
                          const inputYear = parseInt(year, 10);
                          const inputMonth = parseInt(month, 10);

                          setMonthYear(e.target.value);
                          if (inputYear) {
                            if (inputYear >= expCurrentYear && inputMonth <= 12) {
                              setMonthYear(e.target.value);
                              setError("");
                            } else {
                              setError("Enter Valid Expiry Month and Year");
                            }
                          }
                        }
                      }}
                      value={MonthYear}
                      onKeyDown={(e) => setKey(e.key)}
                    />
                  </Space>
                </Col>
                <Col span="12">
                  <Space direction="vertical" className="FanpaymentCvvInput">
                    <Typography.Text className="fanPaymentLargeLabel font-15-bold">{t("cryptogram")}</Typography.Text>
                    <InputComponent
                      className="darkInput"
                      placeholder="CVV"
                      onChange={(e) => {
                        if (e.target.value.match(cvvReg)) {
                          setCvv(e.target.value);
                        }
                      }}
                      value={cvv}
                      type="password"
                      maxLength={3}
                    />
                  </Space>
                </Col>
              </Row>
              <Row className="mt-20">
                <Checkbox className="paymentTermsCheckBox" onChange={handleCheckboxChange}>
                  {t("payment-terms")}
                </Checkbox>
              </Row>
            </Row>
          </Space>
        </Row>
        <Row className="pr-35 mt-24">
          <Button className="lightBlackButton font-15-bold pt-14 pb-14 br-23-5" onClick={createBankingSetting} disabled={isDisabled}>
            {t(loading ? "adding" : "add-new-card")} {loading && "..."}
          </Button>
        </Row>
      </Layout.Content>
      <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} />
    </Layout>
  );
};

export default SaveCardPage;
