import { useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

function usePreviousLocation(): Location | undefined {
  const { pathname } = useLocation();
  const currentLocation = useLocation();

  const previousLocation = useRef<Location | undefined>(undefined);

  useEffect(() => {
    previousLocation.current = currentLocation;
  }, [pathname]);

  return previousLocation.current;
}

export default usePreviousLocation;
