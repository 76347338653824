import type { TabsProps } from "antd";
import { Row, Tabs, Typography } from "antd";
import { t } from "i18next";
import React from "react";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import "./index.less";

type props = {
  tabs: TabsProps["items"];
  defaultActiveKey: string;
};

const AuthTabsCompnent: React.FC<props> = ({ tabs, defaultActiveKey }) => {
  return (
    <>
      <Row className="title-row justify-content-center">
        <div className="chevron-container">
          <ArrowLeftIconComponent top={10} left={8} color="white" />
        </div>
        <Typography.Text className="auth-title">{t("auth-signUp-title")}</Typography.Text>
      </Row>
      <div className="tabs-container">
        <Tabs className="custom-tabs hideTab" centered defaultActiveKey={defaultActiveKey} items={tabs} destroyInactiveTabPane={true} />
      </div>
    </>
  );
};

export default AuthTabsCompnent;
