import React, { useEffect } from "react";
import { Modal, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import Typography from "antd/es/typography/Typography";

type props = {
  isOpen: boolean;
  onClose?: () => void;
  description?: any;
};

const InfoModal: React.FC<props> = ({ isOpen, onClose, description }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="InfoModal"
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      title={<Typography className="description_text"> {description} </Typography>}
      footer={
        <div className="w-full">
          <Row className="description"></Row>
          <Row className="row">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("close")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default InfoModal;
