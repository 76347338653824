import { Layout } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import LiveSubscriberComponent from "../../../components/LiveSubscriberComponent";
import AgoraProvider from "../../../helpers/agoraProvider";
import { USER_TYPES } from "../../../helpers/constant";
import { addLiveComment, clearStreamStats, newLiveTip, updateStreamStats } from "../../../redux/lives/liveDataSlice";
import { getLiveById } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { socket } from "../../../socket";
import "./index.less";
import { FanLiveHeader } from "./FanLiveHeader";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { FanLiveFooter } from "./FanLiveFooter";
import { FanPaymentVerify } from "../../../components/FanPaymentVerifyComponent/FanPaymentVerify";
import { liveService } from "../../../services";

const FanLivePage = () => {
  const navigate = useNavigate();
  
  const { id } = useParams();
  const subscriberRef = useRef<any>(null);
  const joinedRef = useRef<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const live = useSelector((state: StoreState) => state.lives.item);
  //const token = useSelector((state: StoreState) => state.lives.token);
  const [token, setToken] = useState("");
  const isLoading = useSelector((state: StoreState) => state.lives.loading);

  useEffect(() => {    
    if (!id) return;
    dispatch(getLiveById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!live || !live.sessionId || !live.isPaymentVerified || joinedRef.current) return;
    const getToken = async (sessionId: string) => {
      await liveService.buildToken(sessionId).then(responseToken =>{
        if(responseToken && typeof responseToken === "string") {
          setToken(responseToken);
        }
      });
    }
    getToken(live.sessionId);
  }, [live, id, dispatch]);

  useEffect(() => {
    if (token) {
      const joinLive = async () => {
        if(!token) return;
        subscriberRef.current && (await subscriberRef.current.join(token));
        socket.emit("public-stream/join", { id });
    
      };
      joinLive();
    }
  }, [token]);
  


  const leaveLive = async () => {
    subscriberRef.current && (await subscriberRef.current.leave());
    socket.emit("public-stream/leave", { id, role: USER_TYPES.FAN });
  };

  const onStreamStatusChanged = (value: boolean) => {
    joinedRef.current = value;
  };

  const unSubscribed = async (streamingTime: number) => {
    socket.emit("public-stream/leave", { id, streamingTime, role: USER_TYPES.CREATOR });
  };

  const userJoined = async (data: any) => {
    if (data.streamStats) await dispatch(updateStreamStats(data.streamStats));
    if (data.comments) await dispatch(addLiveComment(data.comments));
    if (data.lastStreamTip) await dispatch(newLiveTip(data.lastStreamTip));
  };

  const userLeaved = async (data: any) => {    
    if (data?.streamStats) {
      await dispatch(updateStreamStats(data?.streamStats))
    };
    if (data?.isStreamingEnds) {
      await dispatch(clearStreamStats())
      navigate("/fan/lives");
    }
  };

  useEffect(() => {
    socket.on("user_joined", userJoined);
    socket.on("user_leaved", userLeaved);

    return () => {
      socket.off("user_joined", userJoined);
      socket.off("user_leaved", userLeaved);
      
      if (joinedRef.current) {
        leaveLive();
      }
    };
  }, [dispatch]);

  return (
    <AgoraProvider config={{ mode: "live", codec: "h264", role: "audience" }}>
      <Layout>
        
        <Layout.Content className="fanLiveContentWrapper">
       {
          isLoading ? <SpinnerComponent /> :
          live?.isPaymentVerified && (
            <>
              <FanLiveHeader live={live} isSubscribed={live?.isSubscribed || false} />
              <LiveSubscriberComponent
                sessionId={live?.sessionId || null}
                remoteId={live?.creator?._id || null}
                ref={subscriberRef}
                onStreamStatusChange={onStreamStatusChanged}
                unSubscribed={unSubscribed}
                isFromMobile={live.isFromMobile!}
              />
              <FanLiveFooter id={id!} isChatAvailable={live?.isChatAll || (live?.isChatSubscribers &&  live?.isSubscribed) || false} live={live} />
            </>
          )}
          {
            live && <FanPaymentVerify live={live} />
          }
        </Layout.Content>
      </Layout>
    </AgoraProvider>
  );
};

export default FanLivePage;
