import React from "react";
import { Radio } from "antd";
import "./index.less";

type props = {
  value: string;
  label: string;
};

const AuthGenderRadioButtonComponent: React.FC<props> = ({ label, value }) => {
  return (
    <Radio.Button className="w-full authInput authGenderRadioButton" value={value}>
      {label}
    </Radio.Button>
  );
};

export default AuthGenderRadioButtonComponent;
