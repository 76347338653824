import React from "react";
import {Typography} from "antd";
import {useTranslation} from "react-i18next";
import useUserOnlineHook from "../../helpers/hooks/useUserOnlineHook";

type props = {
  userId?: string;
  className?: string;
};

const ConversationOnlineStatusComponent: React.FC<props> = ({userId, className}) => {
  const {t} = useTranslation();

  return (
    <Typography.Text className={className !== undefined ? className : "font-10-regular text-grey-color"}>
      {useUserOnlineHook(userId) ? t("online") : t("offline")}
    </Typography.Text>
  );
};

export default ConversationOnlineStatusComponent;
