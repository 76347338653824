import { Col, Row, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VerifyIcon from "../../../assets/icons/verify.svg";
import { ICreatorSuggestionSection } from "../../../helpers/types";

interface SuggestionSectionFourProps {
  creatorSuggestionSection: ICreatorSuggestionSection[];
}

const SuggestionSectionFour: React.FC<SuggestionSectionFourProps> = ({ creatorSuggestionSection }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {creatorSuggestionSection.length > 0 && (
        <>
          <Space className="w-full justify-content-center text-center mt-30" direction="vertical">
            <Typography.Text className="weeklySelectionTitle">{t("suggestions-section-title-4-1")}</Typography.Text>
            <Typography.Text className="weeklySelectionTitle">{t("suggestions-section-title-4-2")}</Typography.Text>
          </Space>
          <div className="suggestionsWeeklySectionContainer mt-10">
            {creatorSuggestionSection.map((item) => (
              <div className="suggestionWeeklySectionItem" key={item.creator._id} onClick={() => navigate("/p/" + item.creator.username)}>
                <div className="suggestionWeeklySectionImageWrapper">
                  <img src={item.creator.avatar} alt={item.creator.name} className="suggestionImage" />
                  <div className="suggestionVerifiedOverlay">
                    <img className="verifyIcon" src={VerifyIcon} width={18} alt="verify" />
                    <Typography.Text className="w-full suggestionVerifiedTitle">{t("verified-artist-upp")}</Typography.Text>
                  </div>
                  <div className="suggestionInfoOverlay">
                    <Typography.Text className="w-full suggestionTitle ellipsis-single-line">{item.creator.name}</Typography.Text>
                    {item.creator.description && (
                      <Typography.Text className="w-full suggestionDescription ellipsis-single-line">{item.creator.description}</Typography.Text>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestionSectionFour;
