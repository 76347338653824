import { Input, Layout, Row, Spin, Switch, Typography, message } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { IFeed } from "../../../helpers/types";
import { getMyFeeds } from "../../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../../redux/store";
import { feedService } from "../../../services";
import "./index.less";

const CreatorPublicationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [feed, setFeed] = useState<IFeed>({ isPrivate: true, type: "photo", text: "" });
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const changedItem = (field: keyof IFeed, value: any) => {
    setFeed((prevFeed) => ({
      ...prevFeed,
      [field]: value,
    }));
  };

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFiles(fileList);
  };

  const createFeed = async () => {
    if (files !== undefined && files.length > 0) {
      setLoading(true);
      const feedData = new FormData();
      files.forEach((file: any) => {
        feedData.append(`files`, file.originFileObj);
      });
      feed.type = files[0].type.includes("video") ? "video" : "photo";
      for (const [key, value] of Object.entries(feed)) {
        feedData.set(key, value);
      }

      const resp = feedService.createFeed(feedData, setProgress);
      resp
        .then((data: any) => {
          if (data.message) {
            message.error(data.message);
          } else {
            message.success(t("feed-created-successfully"));
            message.info(t("big-file-info"));
            setLoading(false);
            dispatch(getMyFeeds());

            navigate("/creator/profile/newPost");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
      <Layout>
        <Layout.Content className="content creatorPublicationContentWrapper gradient-background">
          <Row className="relative justify-content-center header-title-container">
            <ArrowLeftIconComponent top={10} left={0} />
            <Typography.Text className="header-title-black-32">{t("publications")}</Typography.Text>
          </Row>
          <Row className="w-full mt-20 justify-content-center">
            <AddMediaComponent
              showTitle={false}
              maxCount={1}
              crop={true}
              onFilesChange={handleFilesChange}
              greenCircle={true}
              iconSize={80}
              addPublication={true}
            />
          </Row>
          <Row className="mt-20">
            <Input.TextArea
              className="captionInput"
              placeholder={`${t("add-a-caption")}...`}
              autoSize={{ minRows: 6, maxRows: 6 }}
              bordered={false}
              onChange={(value) => changedItem("text", value.target.value)}
            />
          </Row>
          <Row className="justify-content-center mt-30">
            <Row className="w-full justify-content-between">
              <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
              <Switch className="switch revenueSwitch" defaultChecked={feed.isPrivate} onChange={(value) => changedItem("isPrivate", value)} />
            </Row>
            <Row className="w-full justify-content-center mt-30">
              <AuthButtonComponent
                label={t("publish")}
                onClick={() => {
                  !loading && createFeed();
                }}
              />
            </Row>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorPublicationPage;
