import { Button, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFeedById } from "../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../redux/store";
import "./index.less";

type props = {
  isOpen: boolean;
  selectedFeed: string;
  onClose?: () => void;
};

const CreatorDeletePostModal: React.FC<props> = ({ isOpen, onClose, selectedFeed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleFeedDelete = async () => {
    const res = await dispatch(deleteFeedById(selectedFeed));
    if (res) {
      navigate("/creator/profile");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="deletePostModal"
      title={t("Do-you-really-want-delete-a-publication?")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full mt-20">
          <Row className="row">
            <Button type="link" className="actionBtn" onClick={handleFeedDelete}>
              <div className="confirm-btn-background">{t("Yes-delete")}</div>
            </Button>
          </Row>
          <Row className="row">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default CreatorDeletePostModal;
