import React, { ChangeEventHandler, ReactNode, KeyboardEventHandler, FocusEventHandler } from "react";
import { Input } from "antd";
import "./index.less";
// import Valid from "card-validator";

type props = {
  className?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string | number | null;
  suffix?: ReactNode;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  inputMode?: string;
};
type InputModeType = "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;

const InputComponent: React.FC<props> = ({
  className,
  name,
  type,
  placeholder,
  value,
  suffix,
  disabled,
  onChange,
  onKeyPress,
  maxLength,
  onKeyDown,
  onBlur,
  onFocus,
  inputMode,
}) => {
  return (
    <div className="w-full">
      {type && type === "password" && (
        <Input.Password
          className={className}
          name={name}
          placeholder={placeholder}
          suffix={suffix}
          onChange={onChange}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          value={value || ""}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      )}
      {(!type || type !== "password") && (
        <Input
          type={type}
          value={value || ""}
          className={className}
          name={name}
          placeholder={placeholder}
          suffix={suffix}
          disabled={disabled}
          onChange={onChange}
          onKeyPress={onKeyPress}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          inputMode={inputMode as InputModeType}
        />
      )}
    </div>
  );
};

export default InputComponent;
