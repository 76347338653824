import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMediapushState } from "../../helpers/types";
import { mediapushService } from "../../services";

const initialState: IMediapushState = {
  list: null,
  item: null,
  loading: false,
  error: null,
};

export const createMediapush = createAsyncThunk("mediapush/createMediapush", async (data: FormData) => {
  return mediapushService.createMediaPush(data, () => {});
});

const mediaPushSlice = createSlice({
  name: "mediaPushes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMediapush.fulfilled, (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createMediapush.pending, (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createMediapush.rejected, (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default mediaPushSlice.reducer;
