import { Layout, message, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Popcorn from "../../../assets/images/popcorn.webp";
import LiveIcon from "../../../assets/icons/live-2.svg";
import FooterComponent from "../../../components/FooterComponent";
import MainSwiper from "../../../components/MainSwiper";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IUser } from "../../../helpers/types";
import { authService, userService } from "../../../services";
import FanHomeSuggestionComponent from "../../../components/FanHomeSuggestionComponent";
import "./index.less";

const FanHomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { homeFeeds: feed, loadingHome: loading } = useSelector((state: any) => state.feeds);
  const [creatorSubscriptions, setCreatorSubscriptions] = useState(false);
  const user: IUser = authService.getUser();
  const [followers, setFollowers] = useState(-1);
  const [showSelectionOfWeek, setShowSelectionOfWeek] = useState(true);

  useEffect(() => {
    if ((feed.length <= 10 || followers <= 3) && !["pending", "idle"].includes(loading)) {
      setCreatorSubscriptions(true);
    } else {
      setCreatorSubscriptions(false);
    }
  }, [feed, followers, loading]);

  useEffect(() => {
    if (feed.length > 3) {
      setShowSelectionOfWeek(false);
    } else {
      setShowSelectionOfWeek(true);
    }
  }, [feed]);

  useEffect(() => {
    userService
      .getFollowedCreator()
      .then((data: any) => {
        setFollowers(data);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, []);

  return (
    <Layout>
      <Layout.Content className="content header-background-fan fanHomeContentFeedWrapper no-scrollbar">
        <div>
          <Row className="header-row" align="middle">
            <Typography.Text className="title-home-fan">
              {t("for-1")} {user.firstName}
            </Typography.Text>
            <img src={Popcorn} width={24} className="header-icon" alt="Loly-popcorn" />
            <div
              className="liveButton"
              onClick={() => {
                navigate("/fan/lives?tab=now");
              }}
            >
              <img src={LiveIcon} className="mr-5" width={24} alt="Loly-popcorn" />
              <Typography.Text className="text-white-color font-14-bold">LIVE</Typography.Text>
            </div>
          </Row>
          <MainSwiper />
          {creatorSubscriptions && <FanHomeSuggestionComponent showSection4={showSelectionOfWeek} />}
        </div>
      </Layout.Content>
      <FooterComponent type="fan" page={FOOTER_IDS.HOME} />
    </Layout>
  );
};

export default FanHomePage;
