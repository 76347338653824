import React from "react";
import { Select } from "antd";
import "./index.less";

type props = {
  defaultValue?: string;
  value?: string;
  options: object[];
  className?: string;
  onSelect?: (value: string) => void
  disabled?: boolean
};

const SelectComponent: React.FC<props> = ({
  defaultValue,
  options,
  className,
  value,
  onSelect,
  disabled
}) => {
  return (
    <>
    <Select
      className={`select ${className} ${disabled && "disabled"}`}
      options={options}
      defaultValue={defaultValue}
      value={value}
      onSelect={onSelect}
      disabled={disabled}
    />
    </>
  );
};

export default SelectComponent;
