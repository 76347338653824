import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Typography, Col, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import { authService } from "../../../services";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import AuthInputComponent from "../../../components/AuthInputComponent";
import LolyLogoWhite from "../../../assets/icons/loly-logo-white.svg";
import AuthSelectLanguageComponent from "../../../components/AuthSelectLanguageComponent";
import InputComponent from "../../../components/InputComponent";

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [repassword, setRepassword] = useState<string>("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const changePassword = () => {
    if (token) {
      const payload = {
        password,
        repassword,
      };
      authService
        .changePassword(payload, token)
        .then(() => {
          navigate("/auth/forgot/confirm");
        })
        .catch(async (error: string) => {
          await message.error(error);
        });
    } else {
      message.error("Token is missing in query.");
    }
  };

  return (
    <Layout className="gradient-background full-height-layout">
      <Layout.Content className="authSigninContentWrapper">
        <Col>
          <Row className="justify-content-center">
            <img src={LolyLogoWhite} alt="Loly" />
          </Row>

          <Row className="justify-content-center" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography.Text className="title text-white-color">{t("auth-forgot-password-title")}</Typography.Text>
          </Row>
          <Row>
            <Space className="w-full" direction="vertical">
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <InputComponent
                  placeholder={t("auth-forgot-password-password-label") ?? ""}
                  className="custom-input-signup"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Row>
              <Row style={{ marginBottom: "20px" }}>
                <InputComponent
                  placeholder={t("auth-forgot-password-confirm-password-label") ?? ""}
                  className="custom-input-signup"
                  type="password"
                  name="repassword"
                  value={repassword}
                  onChange={(e) => {
                    setRepassword(e.target.value);
                  }}
                />
              </Row>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <AuthButtonComponent label={t("auth-forgot-password-terminate-label")} onClick={changePassword} />
              </Row>
            </Space>
          </Row>
          <Row className="w-full authRowFooter">
            <AuthSelectLanguageComponent />
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default ChangePasswordPage;
