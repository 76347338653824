import React, { useEffect } from "react";
import { Modal, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose?: () => void;
  onReportClick?: () => void;
};

const FanReportCreatorModalComponent: React.FC<props> = ({ isOpen, onClose, onReportClick }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="reportCreatorThreeDotsModal"
      title={t("what-action-do-you-want-to-take")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full mt-10">
          <Row className="row cursor-pointer" onClick={onReportClick}>
            <Button type="link" className="actionBtn">
              <div className="confirm-btn-background">{t("report-profile")}</div>
            </Button>
          </Row>
          <Row className="row cursor-pointer" onClick={onClose}>
            <Button className="rejectBtn" type="link">
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default FanReportCreatorModalComponent;
