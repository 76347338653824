import { PlusOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Space, Tag, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/icons/close-black.svg";
import ModalLineIcon from "../../assets/icons/modal-line.svg";

import { getCreatorFooters, getCreatorHomeSubFooters, getFanFooters } from "../../helpers/constant";
import { authService } from "../../services";
import FooterItemComponent from "../FooterItemComponent";
import "./index.less";

type props = {
  type?: string;
  page: string;
};

const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, callback]);
};

const FooterComponent: React.FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCreatorHomeOpen, setIsCreatorHomeOpen] = useState<boolean>(false);
  const type = authService.getRole();
  const fanFooters = getFanFooters(t);
  const creatorFooters = getCreatorFooters(t);
  const creatorHomeSubFooters = getCreatorHomeSubFooters(t);
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const subMenuRef = useRef<HTMLDivElement>(null);
  const [isSlidingDown, setIsSlidingDown] = useState<boolean>(false);

  const handleMenuClick = () => {
    setIsCreatorHomeOpen(!isCreatorHomeOpen);
    setIsOverlayVisible(!isOverlayVisible);
  };

  /* const handleSubMenuClick = (link: string | undefined) => {
    if (link) {
      navigate(link);
      setIsCreatorHomeOpen(false);
      setIsOverlayVisible(false);
    }
  }; */

  const handleOverlayClick = () => {
    setIsCreatorHomeOpen(false);
    setIsOverlayVisible(false);
  };

  useClickOutside(subMenuRef, handleOverlayClick);

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleSlideDown = () => {
    setIsSlidingDown(true);
    setTimeout(() => {
      setIsCreatorHomeOpen(false);
      setIsOverlayVisible(false);
      setIsSlidingDown(false);
    }, 300);
  };

  return (
    <Layout.Footer className="footer">
      {type === "fan" &&
        fanFooters.map((footer) => {
          return (
            <FooterItemComponent
              key={footer.id}
              id={footer.id}
              name={footer.name}
              link={footer.link}
              icon={footer.icon}
              activeIcon={footer.activeIcon}
              isActive={page === footer.id}
            />
          );
        })}
      {type === "creator" &&
        creatorFooters.map((footer) => {
          if (footer.name === "Home") {
            return (
              <div className="creatorHomeFooterItemWrapper" key={footer.id} ref={subMenuRef}>
                <Tag className="creatorHomeFooterItem" onClick={handleMenuClick}>
                  <PlusOutlined style={{ color: "#000", fontSize: 26 }} />
                </Tag>
                {isOverlayVisible && <div className="items-overlay" onClick={handleOverlayClick}></div>}
                {isCreatorHomeOpen && (
                  <div className="footer-modal-overlay no-scroll" onClick={handleSlideDown}>
                    <div className={`footer-modal-content ${isSlidingDown ? "close-modal" : ""}`} onClick={handleModalClick}>
                      <Row className="w-full justify-content-center" onClick={handleSlideDown}>
                        <div className="modal-line-header"></div>
                      </Row>
                      <Typography.Text className="font-20-bold text-white-color mt-20">{t("what-do-you-want-to-do")}</Typography.Text>
                      <div className="sub-menu-content mt-10">
                        {creatorHomeSubFooters.map((subFooter) => {
                          return (
                            <Row
                              key={subFooter.id}
                              align="middle"
                              className="sub-menu-row"
                              onClick={() => {
                                if (subFooter.link) navigate(subFooter.link);
                              }}
                            >
                              <img className="footer-icon-img" src={subFooter.icon} alt="sub footer" />
                              <Col>
                                <Row>
                                  <Typography.Text className="sub-menu-title">{subFooter.name}</Typography.Text>
                                </Row>
                                <Row>
                                  <Typography.Text className="sub-menu-description">{subFooter.description}</Typography.Text>
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {/* {isCreatorHomeOpen && (
                  <Space
                    className="creatorHomeSubFooterItemWrapper creatorCloseFooter"
                    onClick={() => {
                      setIsCreatorHomeOpen(false);
                      setIsOverlayVisible(false);
                    }}
                  >
                    <Tag className="creatorHomeSubFooterCloseTag">
                      <img src={CloseIcon} alt="close" />
                    </Tag>
                  </Space>
                )} */}
              </div>
            );
          } else {
            return (
              <FooterItemComponent
                key={footer.id}
                id={footer.id}
                name={footer.name}
                link={footer.link || ""}
                icon={footer.icon || ""}
                activeIcon={footer.activeIcon || ""}
                isActive={page === footer.id}
              />
            );
          }
        })}
    </Layout.Footer>
  );
};

export default FooterComponent;
