import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IQuickFileState } from "../../helpers/types";
import { quickFileService } from "../../services/quickFile.service";

const initialState: IQuickFileState = {
  list: [],
  item: null,
  loading: false,
  error: null,
};

export const getQuickFileAlbum = createAsyncThunk("quickfile/getQuickFileAlbum", async () => {
  return quickFileService.getAlbum();
});

export const getQuickFileAlbumDetails = createAsyncThunk("quickfile/getQuickFileAlbumDetails", async (id: any) => {
  return quickFileService.getAlbumDetails(id);
});

const QuickFileSlice = createSlice({
  name: "quickfile",
  initialState,
  reducers: {
    updateQuickFile: (state, action) => {
      const fileToUpdate = action.payload.data;
      const albumId = action.payload.albumId;
      if (state.item._id === albumId) {
        state.item.files = state.item?.files?.map((file: any) => {
          if (file.fileId === fileToUpdate.fileId) {
            file = {
              ...file,
              url: fileToUpdate.absolutePath,
              thumbnails: fileToUpdate.thumbnails,
              status: fileToUpdate.status,
            };
          }
          return file;
        });
      }
    },
    deleteAlbum: (state, action) => {
      const albumId = action.payload.albumId;
      const updatedList = state.list;
      state.list = updatedList.filter((album) => album.id !== albumId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuickFileAlbum.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getQuickFileAlbum.pending, (state, action) => {
      state.error = null;
      state.list = [];
      state.loading = true;
    });
    builder.addCase(getQuickFileAlbum.rejected, (state, action) => {
      state.list = [];
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(getQuickFileAlbumDetails.fulfilled, (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getQuickFileAlbumDetails.pending, (state, action) => {
      state.error = null;
      state.item = null;
      state.loading = true;
    });
    builder.addCase(getQuickFileAlbumDetails.rejected, (state, action) => {
      state.item = null;
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
  },
});

export const { updateQuickFile, deleteAlbum } = QuickFileSlice.actions;

export default QuickFileSlice.reducer;
