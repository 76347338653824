import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import languageUtil from "./utls/LanguageUtil";

import translationEnglish from "./translations/English.json";
import translationFrench from "./translations/French.json";
import translationSpanish from "./translations/Spanish.json";

const fallbackLng = languageUtil.AVAILABLE_LANGUAGES.fr;

const resources = {
  en: {
    translation: translationEnglish,
  },
  fr: {
    translation: translationFrench,
  },
  es: {
    translation: translationSpanish,
  },
};

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng,
});

export default i18next;
