import React from "react";
import { Layout, Row, Space, Typography, Radio } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { posts } from "../../../helpers/constant";
import "./index.less";

const CreatorImportationPage = () => {
  const { t } = useTranslation();

  const mediaTypes = [
    {
      label: t("locker"),
      value: "private",
    },
    {
      label: t("unlock"),
      value: "public",
    },
  ];

  return (
    <Layout>
      <Layout.Header className="header border-bottom-grey-color">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent top={1} />
          <Typography.Text className="font-16-bold text-white-color">
            {t("importation")}
          </Typography.Text>
          <Typography.Text className="arrowRight font-16-bold text-medium-green-color">
            {t("next")}
          </Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content creatorImportationContentWrapper">
        <Row className="justify-content-center">
          <div
            className="mediaWrapper"
            style={{ backgroundImage: `url("${posts[0].postImage}")` }}
          ></div>
        </Row>
        <Row className="justify-content-center mt-33">
          <Radio.Group buttonStyle="solid">
            <Space>
              {mediaTypes.map((type, index) => {
                return (
                  <Radio.Button
                    className="mediaTypeRadioButton"
                    key={index}
                    value={type.value}
                  >
                    {type.label}
                  </Radio.Button>
                );
              })}
            </Space>
          </Radio.Group>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorImportationPage;
