import { Button, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  hideShareButton?: boolean;
  hideCancelSubButton?: boolean;
  onClose?: () => void;
  onPrivateGalleryClick?: () => void;
  onShareProfileClick?: () => void;
  onCancelSubscriptionClick?: () => void;
};

const ConversationThreeDotsModalComponent: React.FC<props> = ({
  isOpen,
  hideShareButton,
  hideCancelSubButton,
  onClose,
  onPrivateGalleryClick,
  onShareProfileClick,
  onCancelSubscriptionClick,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="requestMediaThreeDotsModal"
      title={t("what-action-do-you-want-to-take")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full mt-16">
          {!hideShareButton ? (
            <Row className="row">
              <Button type="link" className="actionBtn" onClick={onShareProfileClick}>
                <div className="confirm-btn-background"> {t("share-profile-via")}</div>
              </Button>
            </Row>
          ) : null}
          <Row className="row">
            <Button type="link" className="actionBtn" onClick={onPrivateGalleryClick}>
              <div className="confirm-btn-background">{t("private-gallery")}</div>
            </Button>
          </Row>
          {!hideCancelSubButton && (
            <Row className="row mt-10">
              <Button type="link" className="actionBtn" onClick={onCancelSubscriptionClick}>
                <div className="confirm-btn-background">{t("cancel-subcription-label")}</div>
              </Button>
            </Row>
          )}
          <Row className="row mt-20">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default ConversationThreeDotsModalComponent;
