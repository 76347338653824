import React, { useEffect } from "react";
import { Modal, Row, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import SponsorshipImg from "../../assets/images/sponsorship.webp";

import "./index.less";

type props = {
  isOpen: boolean;
  onClose: () => void;
};

const SponsorshipInfoModalComponent: React.FC<props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="requireSubscribeModalWrapper"
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="requireSubscribeFooterRpw">
            <Button className="understandBtn mb-20" type="link" onClick={onClose}>
              {t("i-understand")}
            </Button>
          </Row>
        </div>
      }
    >
      <Row className="w-full justify-content-center">
        <img src={SponsorshipImg} width={200} alt="" />
      </Row>
      <Row className="w-full justify-content-center text-center mt-20">
        <Typography.Text className="title-modal">{`${t("sponsor-a-designer")}`}</Typography.Text>
      </Row>
      <Row className="w-full justify-content-center text-center mt-10">
        <Typography.Text className="requireSubscribeModalDescription">{t("sponsorship-description")}</Typography.Text>
      </Row>
    </Modal>
  );
};

export default SponsorshipInfoModalComponent;
