import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip, elements } from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import dateUtil from "../../utls/DateUtil";
import dayjs from "dayjs";
interface props {
  statsData?: any;
  graphData?: string;
}

interface StatsData {
  count: number;
  label: string | number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      enabled: true,
    },
  },
};

const CreatorRevenueChartComponent: React.FC<props> = ({ statsData, graphData }) => {
  const { t } = useTranslation();
  const weekLabels = dateUtil.getWeekLabelsForRevenuesPage(t("current-date"));
  const yearLabels = dateUtil.getYearLabelsForRevenuesPage(t("current-date"));

  const [labels, setLabels] = useState<any>(weekLabels);
  const [count, setCount] = useState<any>([0]);

  useEffect(() => {
    if (graphData === "today") {
      let labels = [];
      for (let hour = 0; hour < 24; hour++) {
        let hourString = hour.toString().padStart(2, "0");

        for (let minute = 0; minute < 60; minute += 30) {
          let minuteString = minute.toString().padStart(2, "0");
          labels.push(`${hourString}:${minuteString}`);
        }
      }

      let data = new Array(labels.length).fill(0);
      statsData?.forEach((stat: any) => {
        let date = new Date(stat.label);
        let hour = date.getHours();
        let minute = date.getMinutes();
        let minuteIndex = minute === 0 ? 0 : 1;
        let labelIndex = hour * 2 + minuteIndex;
        data[labelIndex] += stat.count;
      });

      // Set the labels and data
      setLabels(labels);
      setCount(data);
    } else if (graphData === "month") {
      const labelsArray = dateUtil.getMonthDaysWithCurrentDayLast();
      setLabels(labelsArray)
      const statsMap = new Map<string, number>(statsData.map((data: StatsData) => [dayjs(data.label).format("DD/MM/YYYY"), data.count]));
      const countArray: number[] = [];
      labelsArray.forEach((day: string) => {
        const count = statsMap.get(day) || 0;
        countArray.push(count);
      
      });
      setCount(countArray);
    } else if (graphData === "year") {
      setLabels(yearLabels);
      const countArray: number[] = [];
      yearLabels.forEach((month) => {
        const matchingData = statsData && statsData.find((data: StatsData) => dateUtil.getMonthShortFromMonth(month).includes(data.label as string));
        countArray.push(matchingData ? matchingData.count : 0);
      });
      setCount(countArray);
    } else if (graphData === "total") {
      if (statsData?.length !== 0) {
        let label =
          statsData &&
          statsData.map((data: { label: number }) => {
            return data.label;
          });
        let count =
          statsData &&
          statsData.map((data: { count: number }) => {
            return data.count;
          });
        setLabels([""].concat(label));
        setCount([""].concat(count));
      } else {
        const currentYear = new Date().getFullYear();
        setLabels(["", currentYear]);
        setCount(["", ""]);
      }
    }
  }, [graphData, statsData]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        borderColor: "#7FFAA1",
        backgroundColor: "rgba(127, 250, 161, 0.2)",
        data: count,
        pointRadius: (context: any) => (context.raw > 0 ? 2 : 0),
        pointHoverRadius: 8,
        tension: 0.4,
        pointHitRadius: 10,
      },
    ],
  };
  return <Line options={options} data={data} height={263} />;
};

export default CreatorRevenueChartComponent;
