import AgoraRTC, { CameraVideoTrackInitConfig, ICameraVideoTrack, IMicrophoneAudioTrack, MicrophoneAudioTrackInitConfig } from "agora-rtc-sdk-ng";

class AgoraUtil {
  public async createLocalTracks(
    audioConfig: MicrophoneAudioTrackInitConfig,
    videoConfig: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    return [microphoneTrack, cameraTrack];
  }

  public async getDevices(): Promise<MediaDeviceInfo[]> {
    return await AgoraRTC.getDevices(true);
  }
}

const agoraUtil = new AgoraUtil();

export default agoraUtil;
