import { Button, Input, Layout, Row, Space, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { quickFileService } from "../../../services/quickFile.service";
import "./index.less";

const CreatorCreateNewAlbumComponent = () => {
  const { t } = useTranslation();
  const [newAblumName, setNewAlbumName] = useState();
  const navigate = useNavigate();

  async function handleCreateNewAlbum() {
    if (newAblumName) {
      quickFileService
        .addAlbum(newAblumName)
        .then((res) => {
          navigate("/creator/AddMedia/" + res?._id, { replace: true });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Header className="header background-transparent">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent top={10} navigateTo="/creator/quickLink" />
          <Typography.Text className="header-title-black-35">{t("New-album")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content CreatorCreateNewAlbumContentWrapper background-transparent">
        <Space className="w-full gap-21 mt-100" direction="vertical">
          <Row className="w-full justify-content-center">
            <Typography.Text className="font-18-bold text-white-color">{t("give-your-album-a-name")}</Typography.Text>
          </Row>
          <Input
            className="titleInput"
            bordered={false}
            size="large"
            step="0.01"
            value={newAblumName}
            onChange={(e) => setNewAlbumName(e.target.value)}
            placeholder={t("my-name")}
            autoFocus={true}
          />
          <div className={"divider-input"} />
          <Row justify="center">
            <Button
              className="authBtn mt-16"
              style={{ width: "40%", fontSize: 20, height: "65px" }}
              onClick={handleCreateNewAlbum}
              disabled={!newAblumName}
            >
              {t("create")}
            </Button>
          </Row>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorCreateNewAlbumComponent;
