import React, { useEffect, useRef, useState } from "react";
import { userService } from "../../services";
import VerifyIcon from "../../assets/icons/verify.svg";

type props = {
  userId?: string;
  verified?: boolean;
  fetch: boolean;
};

const VerifiedUserIconComponent: React.FC<props> = ({ userId, verified, fetch }) => {
  const shouldFetch = useRef(true);
  const [isVerified, setIsVerified] = useState<boolean>(verified || false);

  useEffect(() => {
    if (shouldFetch.current && userId !== undefined && fetch) {
      shouldFetch.current = false;
      userService.isVerified(userId).then((value) => {
        setIsVerified(value);
      });
    }
  }, [userId]);

  if (isVerified || verified) {
    return <img src={VerifyIcon} width={18} alt="verified" />;
  }
  return null;
};

export default VerifiedUserIconComponent;
