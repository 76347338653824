import React from "react";
import { useNavigate } from "react-router-dom";
import NewArrowLeftIcon from "../../assets/icons/new-arrow-left.svg";

type props = {
  left?: number;
  top?: number;
  navigateTo?: string;
  color?: string;
  applyClassName?: boolean;
  callback?: Function;
  style?: Object;
};
const ArrowLeftIconComponent: React.FC<props> = ({ left, top, navigateTo, color = "white", applyClassName = true, callback, style }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo !== undefined && navigateTo !== "") {
      navigate(navigateTo);
    } else {
      navigate(-1);
    }
    if (callback !== undefined) {
      callback();
    }
  };

  return (
    <img
      className={applyClassName ? "header-arrow-left" : ""}
      src={NewArrowLeftIcon}
      alt="arrow left"
      style={style !== undefined ? { left: left, top: top, ...style } : { left: left, top: top }}
      onClick={handleClick}
    />
  );
};

export default ArrowLeftIconComponent;
