import { Button, Row, Typography } from "antd";
import { ILive } from "../../helpers/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAYMENT_TYPES } from "../../helpers/constant";

interface Props {
  live: ILive | null;
}

export const FanEventPaymentVerify = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { live } = props;

  const onClickGetTicket = () => {
    navigate(`/fan/payment?creatorId=${live?.creator?._id}&type=${PAYMENT_TYPES.LIVE}&liveId=${live?._id}&redirectUrl=fan/event/${live?._id}`);
  };

  const onClickSubscribeToCreator = () => {
    navigate(`/fan/subscribe/${live?.creator?._id}?redirectUrl=fan/event/${live?._id}`);
  };

  const displayLiveOverlay = () => {
    if (live) {
      if (live.access === "all") {
        if (live.amount !== 0) {
          if (!live.isPaymentVerified) {
            return (
              <Row className="w-full">
                <Button className="reserveButton" onClick={onClickGetTicket}>
                  {t("get-a-ticket")}
                </Button>
              </Row>
            );
          } else {
            return (
              <Row className="w-full">
                <Button className="freeAccess" disabled>
                  {t("bought-ticket-live-event")}
                </Button>
              </Row>
            );
          }
        } else {
          return (
            <Row className="w-full">
              <Button className="freeAccess" disabled>
                {t("access-to-this-live-event-is-free")}
              </Button>
            </Row>
          );
        }
      } else {
        if (!live.isSubscribed) {
          return (
            <Row className="w-full">
              <Button className="reserveButton" onClick={onClickSubscribeToCreator}>
                {t("subscribe")}
              </Button>
            </Row>
          );
        } else {
          if (!live.isFreeForSubscribers && !live.isPaymentVerified) {
            return (
              <Row className="w-full">
                <Button className="reserveButton" onClick={onClickGetTicket}>
                  {t("get-a-ticket")}
                </Button>
              </Row>
            );
          } else {
            return (
              <Row className="w-full">
                <Button className="freeAccess" disabled>
                  {t("bought-ticket-live-event")}
                </Button>
              </Row>
            );
          }
        }
      }
    }

    return null;
  };

  return displayLiveOverlay();
};
