import React, { useEffect } from "react";
import { Modal, Row, Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose?: () => void;
  onYesClick?: () => void;
  onDelete?: () => void;
};

const CreatorSwitchBankAccountModalComponent: React.FC<props> = ({ isOpen, onClose, onYesClick, onDelete }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="switchAccountThreeDotsModal"
      title={t("payment-card")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Space className="row" direction="vertical">
            <Button type="link" className="confirmBtn" onClick={onYesClick}>
              <div className="confirm-btn-background"> {t("set-as-default")}</div>
            </Button>
            <Button className="deleteBtn mt-10" type="link" onClick={onDelete}>
              {t("delete-this-card")}
            </Button>
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Space>
        </div>
      }
    />
  );
};

export default CreatorSwitchBankAccountModalComponent;
