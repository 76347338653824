import React, { useEffect, useRef, useState } from "react";
import { Input, Row, Col, Checkbox, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import InputComponent from "../../InputComponent";
import { authService } from "../../../services";
import { FAN } from "../../../helpers/constant";
import AuthButtonComponent from "../../AuthButtonCompnent";
import "../index.less";
import { capitalizeFirstLetter } from "../../../utls/FunctionsUtil";

interface PublicRegisterViewComponentProps {
  onLoadingChange: (isLoading: boolean) => void;
  onClose: () => void;
}

const PublicRegisterViewComponent: React.FC<PublicRegisterViewComponentProps> = ({ onLoadingChange, onClose }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<{ [key: string]: string }>({});
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const handleFocus = () => {
    setIsKeyboardOpen(true);
  };

  const handleBlur = () => {
    setIsKeyboardOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "email") {
      setEmailError(false);
    }
  };

  const register = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmail = emailRegex.test(data.email || "");
    if (!data.firstName) return message.warning(t("first-name-required"));
    if (!data.lastName) return message.warning(t("last-name-required"));
    if (!data.username || data.username.length < 3) return message.warning(t("username-required"));
    if (!data.email || !isEmail) {
      setEmailError(true);
      return message.warning(t("invalid-email"));
    }
    if (!data.password || data.password.length < 6) return message.warning(t("password-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    onClose();
    onLoadingChange(true);

    authService
      .register({
        ...data,
        firstName: capitalizeFirstLetter(data.firstName),
        lastName: capitalizeFirstLetter(data.lastName),
        username: data.username.toLowerCase(),
        type: FAN,
        lang: localStorage.getItem("lng"),
      })
      .then(() => {
        window.location.reload();
        // message.success(t("register-success-message"), 10);
        // Navigate to sign in or any other page
      })
      .catch((error) => message.error(t(error)))
      .finally(() => onLoadingChange(false)); // Stop loading
  };

  return (
    <div className={`register-view ${isKeyboardOpen ? "keyboard-open" : ""}`}>
      <Col className="w-full">
        <Row gutter={11}>
          <Col span={12}>
            <Input
              className="public-register-custom-input"
              placeholder={t("first-name-label")!}
              type="text"
              name="firstName"
              value={data.firstName || ""}
              onChange={handleChange}
            />
          </Col>
          <Col span={12}>
            <Input
              className="public-register-custom-input"
              placeholder={t("last-name-label")!}
              type="text"
              name="lastName"
              value={data.lastName || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mt-16">
          <Input
            className="public-register-custom-input"
            placeholder={t("user-name-label")!}
            type="text"
            name="username"
            value={data.username || ""}
            onChange={handleChange}
          />
        </Row>
        <Row className="mt-16">
          <Input
            className={`public-register-custom-input ${emailError ? "input-error" : ""}`}
            placeholder={t("email-label")!}
            type="email"
            name="email"
            value={data.email || ""}
            onChange={handleChange}
          />
        </Row>
        <Row className="mt-16">
          <Space className="w-full gap-6" direction="vertical">
            <InputComponent
              className="public-register-custom-input"
              placeholder={t("password-label")!}
              name="password"
              onChange={handleChange}
              value={data.password || ""}
              type="password"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Space>
        </Row>
        <Row>
          <Checkbox className="mt-10 authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
            <span>{t("auth-terms-1")}</span>
            <span className="text-color-green">{t("auth-terms-2")}</span>
            <span>{t("auth-terms-3")}</span>
            <a href="https://loly.app/conditions-generales-de-vente/" target="_blank" rel="noopener noreferrer">
              {t("terms-and-conditions")}
            </a>
            {t("and")}
            <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank" rel="noopener noreferrer">
              {t("privacy-policy")}
            </a>
          </Checkbox>
        </Row>
        <Row className="mt-25 mb-10">
          <AuthButtonComponent label={t("auth-signUp-button")} onClick={register} />
        </Row>
      </Col>
    </div>
  );
};

export default PublicRegisterViewComponent;
