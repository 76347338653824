import { Row, Space, Tag, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";
interface props {
  dailyPreviousReport?: any;
  monthlyPreviousReport?: any;
  detailedPreviousReport?: any;
}
const CreatorRevenueTabComponent: React.FC<props> = ({ dailyPreviousReport, monthlyPreviousReport, detailedPreviousReport }) => {
  const { t } = useTranslation();

  const convertDate = (date: string) => {
    const createdAt = new Date(date);
    const formattedDate = createdAt
      .toLocaleDateString(`${t("current-language")}`, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
    return formattedDate;
  };

  const getMonthLabel = (monthShort: string): string => {
    let monthLabel = "";
    switch (monthShort) {
      case "Jan":
        monthLabel = t("january-label");
        break;
      case "Feb":
        monthLabel = t("february-label");
        break;
      case "Mar":
        monthLabel = t("march-label");
        break;
      case "Apr":
        monthLabel = t("april-label");
        break;
      case "May":
        monthLabel = t("may-label");
        break;
      case "Jun":
        monthLabel = t("june-label");
        break;
      case "Jul":
        monthLabel = t("july-label");
        break;
      case "Aug":
        monthLabel = t("august-label");
        break;
      case "Sep":
        monthLabel = t("september-label");
        break;
      case "Oct":
        monthLabel = t("october-label");
        break;
      case "Nov":
        monthLabel = t("november-label");
        break;
      case "Dec":
        monthLabel = t("december-label");
        break;
      default:
        break;
    }
    return monthLabel;
  };

  return (
    <Space className="w-full gap-10" direction="vertical">
      {dailyPreviousReport &&
        dailyPreviousReport.map(
          (
            revenue: {
              total_cost_month: number;
              stats: string;
              day: number;
              month: string;
            },
            index: number
          ) => {
            return (
              <Row className="justify-content-between mt-10" key={index}>
                <Space className="gap-23">
                  <Typography.Text className="font-16-medium text-white-color">
                    {revenue.day + " "}
                    {getMonthLabel(revenue.month)}
                  </Typography.Text>
                  <Tag className={`revenueTag ${revenue.stats.includes("+") ? "increased" : "decreased"}`}>{revenue.stats}</Tag>
                </Space>

                <Typography.Text className="font-18-bold text-white-color">
                  {revenue.total_cost_month.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  €
                </Typography.Text>
              </Row>
            );
          }
        )}
      {monthlyPreviousReport &&
        monthlyPreviousReport.map(
          (
            revenue: {
              year: number;
              month: string;
              total_cost_month: number;
              stats: string;
              isRefund: boolean;
            },
            index: number
          ) => {
            return (
              <Row className="justify-content-between" key={index}>
                <Typography.Text className="font-16-bold text-white-color">
                  {getMonthLabel(revenue.month)}
                  {" " + revenue.year}
                </Typography.Text>
                <Space className="gap-23">
                  <Tag className={`revenueTag ${revenue.stats.includes("+") ? "increased" : "decreased"}`}>{revenue.stats}</Tag>
                  <Typography.Text className="font-13-bold text-white-color">
                    {revenue.total_cost_month.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    €
                  </Typography.Text>
                </Space>
              </Row>
            );
          }
        )}
      {detailedPreviousReport &&
        detailedPreviousReport.map(
          (
            revenue: {
              isRefund: boolean;
              netPrice: number;
              isValidate: boolean;
              createdAt: string;
              transactionId: string;
              type: string;
            },
            index: number
          ) => {
            return (
              <Row className="justify-content-between" key={index}>
                <Space direction="vertical" className="gap-2">
                  <Typography.Text className="font-14-bold text-white-color">
                    {revenue.type === "monthly_subscription"
                      ? t("monthly-subscription")
                      : revenue.type === "trimestrial_subscription"
                        ? t("three-months-subscription")
                        : // : revenue.type === "half_yearly_subscription"
                          //   ? t("six-months-label") + " " + t("subscription")
                          //   : revenue.type === "yearly_subscription"
                          //     ? t("yearly-label") + " " + t("subscription")
                          t(revenue.type)}
                  </Typography.Text>
                  <Typography.Text className="font-14-bold text-white-color">{convertDate(revenue?.createdAt)}</Typography.Text>
                </Space>
                <Space className="gap-2" direction="vertical" align="end">
                  <Typography.Text className="font-14-bold text-white-color ms-auto">{revenue?.netPrice?.toFixed(2)}€</Typography.Text>
                  <Typography.Text className="font-14-bold text-white-color">
                    {revenue?.isRefund ? "🔴 " + t("refund") : revenue?.isValidate ? "🟢 " + t("validated") : "🟡 " + t("pending")}
                  </Typography.Text>
                </Space>
              </Row>
            );
          }
        )}
    </Space>
  );
};

export default CreatorRevenueTabComponent;
