import { Input, InputRef, Space, Typography } from "antd";
import { DefaultTFuncReturn } from "i18next";
import React, { ChangeEventHandler, forwardRef, Ref } from "react";
import "./index.less";

type Props = {
  label?: string | DefaultTFuncReturn;
  type: string;
  name?: string;
  value?: any;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string | undefined;
  disabled?: boolean;
  inputError?: boolean;
  inputMode?: "text" | "search" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;
};

const AuthInputComponent = forwardRef<InputRef, Props>(
  ({ label, type, name, value, handleChange, placeholder, inputMode, disabled, inputError }, ref: Ref<InputRef>) => {
    return (
      <Space className="w-full gap-6" direction="vertical">
        {label && <Typography.Text className="authInputLabel">{label}</Typography.Text>}
        {type === "password" ? (
          <Input.Password
            className="authInput"
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            ref={ref}
          />
        ) : (
          <Input
            className={`authInput ${inputError ? "input-error" : ""}`}
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            inputMode={inputMode}
            disabled={disabled}
            ref={ref}
          />
        )}
      </Space>
    );
  }
);

export default AuthInputComponent;
