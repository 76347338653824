import { Badge, Col, Layout, message, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { TARGET_PAGE } from "../../../helpers/constant";
import { IHelpContactRequest } from "../../../helpers/types";
import { authService } from "../../../services";
import JerryImg from "../../../assets/images/jerry.webp";
import GaethanImg from "../../../assets/images/Gaethan.webp";
import ChatImg from "../../../assets/images/chat.webp";
import TextAreaComponent from "../../../components/TextAreaComponent";
import InputComponent from "../../../components/InputComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import { FormEvent, useEffect, useState } from "react";
import { userService } from "../../../services";
import "./index.less";
import { copyToClipboard, copyToClipboardCustom } from "../../../utls/FunctionsUtil";

const WHATSAPP_NUMBER_CREATOR = "33 6 35 72 69 50";
const WHATSAPP_NUMBER_FAN = "33 6 29 66 10 21";

const HelpContactPage = () => {
  const { t } = useTranslation();
  const user = authService.getUser();
  const [isFormValid, setIsFormValid] = useState(false);
  const [helpContactRequest, setHelpContactRequest] = useState<IHelpContactRequest>({
    subject: "",
    description: "",
    userId: user.sourceId,
    target: user.source === "fan" ? TARGET_PAGE.fanHelpPage : TARGET_PAGE.creatorHelpPage,
  });

  const handleChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;
    setHelpContactRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { subject, description } = helpContactRequest;
    if (subject.trim() !== "" && description.trim() !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [helpContactRequest]);

  const handleSubmit = () => {
    userService
      .helpContactSupport(helpContactRequest)
      .then((data: any) => {
        if (data.message) {
          message.error(data.message);
        } else {
          setHelpContactRequest({
            subject: "",
            description: "",
            userId: user.sourceId,
            target: TARGET_PAGE.creatorHelpPage,
          });
          message.success(t("your-message-has-been-sent"));
        }
      })
      .catch((error) => {
        message.error(error || "Something went wrong.");
      });
  };

  const copyPhoneNumber = async (phoneNumber: string) => {
    await copyToClipboardCustom(phoneNumber, t("something-want-wrong"), t("link-copied") as string);
  };

  return (
    <Layout className="gradient-background-help-creator">
      <Layout.Content className="content creatorHelpContactContentWrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent left={0} top={10} />
          <Typography.Text className="ml-20 header-title-black-35">{t("help-and-contact")}</Typography.Text>
        </Row>
        <Row className="mt-30">
          <Typography.Text className="font-25-bold text-white-color">
            {user.firstName}, {t("help-creator-section-1-1")} <span className="text-grey-color">{t("help-creator-section-1-2")}</span>
          </Typography.Text>
        </Row>
        <Space className="w-full subs-container-help-contact">
          <Space className="items-center">
            <Badge dot={true} color="#7FFAA1" offset={[-25, 98]} className="avatarOnlineDotJerry">
              <img className="imgSection" src={user.source === "fan" ? GaethanImg : JerryImg} alt="jerry" />
            </Badge>
            <Col className="w-full ml-10">
              <Row>
                <Typography.Text className="firstSection">{t("whatsapp-only")}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="secondSection">{user.source === "fan" ? "Gaetan" : "Jerry"}</Typography.Text>
              </Row>
              <Row
                onClick={() => {
                  copyPhoneNumber(user.source === "fan" ? WHATSAPP_NUMBER_FAN : WHATSAPP_NUMBER_CREATOR);
                }}
              >
                <Typography.Text className="thirdSection">{user.source === "fan" ? WHATSAPP_NUMBER_FAN : WHATSAPP_NUMBER_CREATOR}</Typography.Text>
              </Row>
            </Col>
          </Space>
        </Space>
        <Row className="mt-50" align="middle">
          <Col xs={17}>
            <Typography.Text className="font-22-bold text-white-color">
              {t("you-can-also")} <span className="font-22-bold text-grey-color">{t("contact-us-by-mail")}</span>
            </Typography.Text>
          </Col>
          <Col xs={2} className="img-right">
            <img width={90} src={ChatImg} alt="chat" />
          </Col>
        </Row>
        <Row className="mt-10">
          <Space className="w-full gap-6" direction="vertical">
            <Typography.Text className="font-16-bold text-white-color">{t("subject")}</Typography.Text>
            <InputComponent
              className="helpMessageInput"
              placeholder={t("subject")!}
              type="text"
              name="subject"
              value={helpContactRequest.subject}
              onChange={handleChange}
            />
          </Space>
        </Row>
        <Row className="mt-10">
          <Space className="w-full gap-6" direction="vertical">
            <Typography.Text className="font-16-bold text-white-color">{t("description")}</Typography.Text>
            <TextAreaComponent
              className="helpMessageTextArea"
              name="description"
              placeholder={t("your-message...")!}
              value={helpContactRequest.description}
              onChange={handleChange}
            />
          </Space>
        </Row>
        <Row className="mt-30">
          <AuthButtonComponent label={t("send")} disabled={!isFormValid} onClick={handleSubmit} />
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default HelpContactPage;
