import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICreatorSuggestionState } from "../../helpers/types";
import { creatorService } from "../../services/creatorSuggestion.service";

const initialState: ICreatorSuggestionState = {
  list: [],
  suggestionByTypeList: [],
  loading: "idle",
  error: null,
  totalPages: -1,
};

export const getSugesstion = createAsyncThunk("suggestion/getSugesstion", async () => {
  return creatorService.getCreatorSuggestion();
});

export const getSuggestionByType = createAsyncThunk("suggestion/getSuggestionByType", (type: number) => {
  return creatorService.getCreatorSuggestionByType(type);
});

export const removeSuggestion = (creatorId: string) => ({
  type: 'suggestion/removeSuggestion',
  payload: creatorId,
});

const creatrorSuggestionSlice = createSlice({
  name: "suggestion",
  initialState,
  reducers: {
    removeSuggestion: (state, action) => {
      const creatorId = action.payload;
      state.list = state.list.map((suggestion) => ({
        ...suggestion,
        items: suggestion.items.filter((section) =>
          section.creator._id !== creatorId
        ),
      }));
      state.suggestionByTypeList = state.suggestionByTypeList.filter(
        (item) => item.creator._id !== creatorId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSugesstion.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = "succeeded";
      state.error = null;
    });
    builder.addCase(getSugesstion.pending, (state, action) => {
      state.list = [];
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(getSugesstion.rejected, (state, action) => {
      state.list = [];
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(getSuggestionByType.fulfilled, (state, action) => {
      state.suggestionByTypeList = action.payload;
      state.loading = "succeeded";
      state.error = null;
    });
    builder.addCase(getSuggestionByType.pending, (state, action) => {
      state.suggestionByTypeList = [];
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(getSuggestionByType.rejected, (state, action) => {
      state.suggestionByTypeList = [];
      state.loading = "failed";
      state.error = action.payload;
    });
  },
});

export default creatrorSuggestionSlice.reducer;
