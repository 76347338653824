import { Layout, message, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import QuickMessagesComponent from "../../../components/QuickMessageComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../../redux/store";
import { useEffect, useRef, useState } from "react";
import { deleteQuickMessage, getUserQuickMessages } from "../../../redux/messages/quickMessagesSlice";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import "./index.less";

const QuickMessagesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const { quickMessages, loading, error, action } = useSelector((state: StoreState) => state.quickMessages);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getUserQuickMessages());
    }
  }, []);

  useEffect(() => {
    if (loading === "failed" && error) {
      message.error("Something went wrong.");
    }
  }, [loading, error]);

  useEffect(() => {
    if (action === "DELETE" && loading === "succeeded") {
      setConfirmLoading(false);
    }
  }, [loading, action]);

  const deleteQuickAnswer = (messageId: string) => {
    dispatch(deleteQuickMessage(messageId));
  };

  return (
    <Layout>
      <Layout.Content className="content creatorQuickMessagesWrapper gradient-background">
        <Row className="w-full relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={8} />
          <Typography.Text className="header-title-black-30">{t("quick-message")}</Typography.Text>
        </Row>
        <div className="mb-60">
          <QuickMessagesComponent quickMessages={quickMessages} deleteQuickAnswer={deleteQuickAnswer} confirmLoading={confirmLoading} />
        </div>

        <Row className="bottom-sticky-container">
          <Row className="createQuickMessageButton" justify={"center"}>
            <AuthButtonComponent label={t("add-a-quick-message")} onClick={() => navigate("/creator/newResponse")} />
          </Row>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default QuickMessagesPage;
