import axios from "../helpers/axios";
import { IFeed, IReaction, IVerifyFollow } from "../helpers/types";

class ReactionService {
  public createReaction = (payload: any) => {
    const url = `/reactions`;
    return new Promise<IReaction>((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public verifyFollow = (data: any) => {
    const url = `/reactions/verifyFollow`;
    return new Promise<IVerifyFollow>((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public deactivatedReaction = (payload: any) => {
    const url = `/reactions/deactivated`;
    return new Promise<IReaction>((resolve, reject) => {
      axios
        .put(url, payload)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public BookmarksFeeds = () => {
    const url = `/feeds/bookmarkedList?sortBy=&sort`;
    return new Promise<IFeed[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data?.data) resolve(response?.data?.data?.data);
          else reject("something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("something went wrong");
        });
    });
  };
}

export const reactionService = new ReactionService();
