import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { GetFeedsParams, IFeedState } from "../../helpers/types";
import { feedService, reactionService } from "../../services";

const initialState: IFeedState = {
  list: [],
  currentUserFeeds: [],
  homeFeeds: [],
  bookmarkFeeds: [],
  item: {},
  loading: "idle",
  currentUserLoading: "idle",
  loadingHome: "idle",
  loadingBookmarkFeeds: "idle",
  error: null,
  errorBookmarkFeeds: null,
  totalPages: -1,
  total: -1,
};

export const getMyFeeds = createAsyncThunk("feeds/getMyFeeds", async () => {
  return feedService.getMyFeeds();
});

export const getHomePageFeeds = createAsyncThunk("feeds/getHomePageFeeds", async (page: number) => {
  return feedService.getHomePageFeeds(page);
});

export const getBookmarkFeeds = createAsyncThunk("feeds/getBookmarkFeeds", async () => {
  return reactionService.BookmarksFeeds();
});

export const loadMorePageFeeds = createAsyncThunk("feeds/loadMorePageFeeds", async (page: number) => {
  return feedService.getHomePageFeeds(page);
});

export const getFeedsByCreatorId = createAsyncThunk("feeds/getFeedsByCreatorId", async ({ creatorId, skip, limit }: GetFeedsParams) => {
  return feedService.getFeeds(creatorId, skip || 0, limit || null);
});

export const loadMoreFeedsByCreatorId = createAsyncThunk("feeds/loadMoreFeedsByCreatorId", async ({ creatorId, skip, limit }: GetFeedsParams) => {
  return feedService.getFeeds(creatorId, skip || 0, limit || null);
});

export const getPublicFeedsByCreatorUsername = createAsyncThunk("feeds/getPublicFeedsByCreatorUsername", async (username: string) => {
  return feedService.getPublicFeeds(username);
});

export const deleteFeedById = createAsyncThunk("feeds/deleteFeedById", async (feedId: string) => {
  return feedService.deleteFeed(feedId);
});

export const removeOldState = createAsyncThunk("feeds/removeOldState", async () => {
  return [];
});

const feedsSlice = createSlice({
  name: "feeds",
  initialState,
  reducers: {
    updateFile: (state, action) => {
      const fileToUpdate = action.payload;
      let found = false;
      state.currentUserFeeds = state.currentUserFeeds.map((feed) => {
        if (!found) {
          feed.files = feed.files?.map((file) => {
            if (file._id === fileToUpdate.fileId && !found) {
              file = {
                ...file,
                url: fileToUpdate.absolutePath,
                thumbnails: fileToUpdate.thumbnails,
                status: fileToUpdate.status,
              };
              found = true;
            }
            return file;
          });
        }
        return feed;
      });
    },
    updateBookmarkState: (state, action) => {
      const { feedId, isBookmark } = action.payload;
      state.homeFeeds = state.homeFeeds.map((post) => {
        if (post?._id === feedId) {
          post.isBookMarked = isBookmark;
        }
        return post;
      });
    },
    updateLikeState: (state, action) => {
      const { feedId, isLiked } = action.payload;
      state.homeFeeds = state.homeFeeds.map((post) => {
        if (post?._id === feedId) {
          post.isLiked = isLiked;
          if (post.totalLike !== undefined) {
            post.totalLike = isLiked ? post.totalLike + 1 : post?.totalLike - 1;
          }
        }
        return post;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageFeeds.pending, (state) => {
      state.loadingHome = "pending";
    });
    builder.addCase(getHomePageFeeds.fulfilled, (state, action) => {
      state.homeFeeds = action.payload.data || [];
      state.loadingHome = "succeeded";
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getHomePageFeeds.rejected, (state, action) => {
      state.loadingHome = "failed";
      state.error = action.error.message;
    });
    builder.addCase(getBookmarkFeeds.pending, (state) => {
      state.loadingBookmarkFeeds = "pending";
    });
    builder.addCase(getBookmarkFeeds.fulfilled, (state, action) => {
      state.bookmarkFeeds = action.payload || [];
      state.loadingBookmarkFeeds = "succeeded";
    });
    builder.addCase(getBookmarkFeeds.rejected, (state, action) => {
      state.loadingBookmarkFeeds = "failed";
      state.errorBookmarkFeeds = action.error.message;
    });
    builder.addCase(getPublicFeedsByCreatorUsername.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPublicFeedsByCreatorUsername.fulfilled, (state, action) => {
      state.list = action.payload || [];
      state.loading = "succeeded";
    });
    builder.addCase(getPublicFeedsByCreatorUsername.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.error.message;
    });

    builder.addCase(loadMorePageFeeds.pending, (state) => {
      //state.loading = "pending";
    });
    builder.addCase(loadMorePageFeeds.fulfilled, (state, action) => {
      state.homeFeeds = [...state.homeFeeds, ...action.payload.data];
      state.loading = "succeeded";
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(loadMorePageFeeds.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.error.message;
    });

    builder.addCase(deleteFeedById.fulfilled, (state, action) => {
      const id = action.payload._id;
      const newList = state.currentUserFeeds.filter((item) => item._id !== id);
      state.currentUserFeeds = newList;
      state.currentUserLoading = "succeeded";
      state.error = null;
    });
    builder.addCase(loadMoreFeedsByCreatorId.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadMoreFeedsByCreatorId.fulfilled, (state, action: any) => {
      state.list = [...state.list, ...action.payload.data];
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadMoreFeedsByCreatorId.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.error.message;
    });
    builder.addCase(removeOldState.fulfilled, (state, action: any) => {
      state.list = [];
      state.loading = "pending";
      state.total = null;
    });
    builder.addMatcher(isAnyOf(getMyFeeds.fulfilled), (state, action: any) => {
      state.currentUserFeeds = action.payload.data;
      state.currentUserLoading = "succeeded";
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getMyFeeds.pending), (state, action: any) => {
      state.currentUserLoading = "pending";
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getMyFeeds.rejected), (state, action: any) => {
      state.currentUserLoading = "failed";
      state.error = action.error.message || "Something went wrong";
    });
    builder.addMatcher(isAnyOf(deleteFeedById.pending), (state, action) => {
      state.currentUserLoading = "pending";
      state.error = null;
    });

    builder.addMatcher(isAnyOf(deleteFeedById.rejected), (state, action) => {
      state.currentUserLoading = "failed";
      state.error = action.error.message || "Something went wrong";
    });
    builder.addMatcher(isAnyOf(getFeedsByCreatorId.fulfilled), (state, action: any) => {
      state.list = action.payload.data;
      state.error = null;
      state.total = action?.payload?.total;
      state.loading = "succeeded";
    });
    builder.addMatcher(isAnyOf(getFeedsByCreatorId.pending), (state, action) => {
      state.list = [];
      state.loading = "pending";
      state.error = null;
      state.total = -1;
    });
    builder.addMatcher(isAnyOf(getFeedsByCreatorId.rejected), (state, action) => {
      state.loading = "failed";
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { updateFile, updateBookmarkState, updateLikeState } = feedsSlice.actions;

export default feedsSlice.reducer;
