import axios from "../helpers/axios";
import { IAlbum } from "../helpers/types";

class QuickFileService {
  public addAlbum(albumName: IAlbum) {
    return new Promise<void>((resolve, reject) => {
      const url = "quick-files/album";
      const data = {
        name: albumName,
      };
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public addMediaToAlbum(id: any, payload: FormData, setProgress: Function) {
    return new Promise<void>((resolve, reject) => {
      const url = "quick-files/upload/" + id;

      axios
        .post(url, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total!));
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public getAlbum() {
    return new Promise<any>((resolve, reject) => {
      const url = "quick-files/albums";
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public getAlbumDetails(AlbumId: string) {
    return new Promise<any>((resolve, reject) => {
      const url = "quick-files/album/" + AlbumId;
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public deleteAlbumFiles(payload: any) {
    return new Promise<any>((resolve, reject) => {
      const url = "quick-files/album/files";
      axios
        .delete(url, {
          data: payload,
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public deleteAlbum(AlbumId: string) {
    return new Promise<any>((resolve, reject) => {
      const url = "quick-files/album/" + AlbumId;
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }
}

export const quickFileService = new QuickFileService();
