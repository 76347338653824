import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IFileState } from "../../helpers/types";
import { fileService } from "../../services";

const initialState: IFileState = {
  list: null,
  item: null,
  loading: false,
  error: null
};

export const uploadPhoto = createAsyncThunk(
  "files/uploadPhoto",
  async (formData: FormData) => {
    return fileService.uploadPhoto(formData);
  }
);

export const uploadVideo = createAsyncThunk(
  "files/uploadVideo",
  async (formData: FormData) => {
    return fileService.uploadVideo(formData);
  }
);

export const deleteFile = createAsyncThunk(
  "files/deleteFile",
  async (id: string) => {
    return fileService.deleteFile(id);
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(uploadPhoto.fulfilled, uploadVideo.fulfilled), (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(uploadPhoto.pending, deleteFile.pending, uploadVideo.pending), (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(uploadPhoto.rejected, deleteFile.rejected, uploadVideo.rejected), (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong"
    });
  }
});

export default filesSlice.reducer;