import axios from "axios";
import { ICountry } from "../helpers/types";
import { COUNTRIES } from "../utls/CountryUtil";

class UtilService {
  public getPhoneCoutries = () => {
    return new Promise<ICountry[]>((resolve) => {
      resolve(COUNTRIES);
    });
    /* const url = `/countries/list`;
    return new Promise<ICountry[]>((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    }); */
  };

  public fetchUserCountry = async () => {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(`https://ipapi.co/country_name/`)
        .then((response) => {
          if (response?.data) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const utilService = new UtilService();
