import { Layout, Radio, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBottomGreenIcon from "../../../assets/icons/arrow-bottom-green.svg";
import ArrowRightGreenIcon from "../../../assets/icons/arrow-right-green.svg";
import DoubleArrowGreenIcon from "../../../assets/icons/double-arrow-green.svg";
import InfoBlackGreyIcon from "../../../assets/icons/info-black-grey.svg";
import EurFlagImg from "../../../assets/images/eur-flag.webp";
import CreatorRevenueChartComponent from "../../../components/CreatorRevenueChartComponent";
import CreatorRevenueTabComponent from "../../../components/CreatorRevenueTabComponent";
import FooterComponent from "../../../components/FooterComponent";
import InfoModal from "../../../components/InfoModal";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IRevenue } from "../../../helpers/types";
import { userService } from "../../../services";
import "./index.less";

const CreatorRevenuePage = () => {
  const { t } = useTranslation();
  const [revenue, setRevenue] = useState<IRevenue>();
  const [graphData, setGraphData] = useState<string>("today");
  const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
  const [infoContent, setInfoContent] = useState<any>(null);
  const [isPendingPaymentOpen, setIsPendingPaymentOpen] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<"daily" | "monthly">("monthly");

  /* const tabs: TabsProps["items"] = [
    {
      key: "daily",
      label: t("daily"),
      children: <CreatorRevenueTabComponent dailyPreviousReport={revenue?.dailyPreviousReport} />,
    },
    {
      key: "monthly",
      label: t("monthly"),
      children: <CreatorRevenueTabComponent monthlyPreviousReport={revenue?.monthlyPreviousReport} />,
    },
    {
      key: "detailed",
      label: t("detailed"),
      children: <CreatorRevenueTabComponent detailedPreviousReport={revenue?.detailPaymentReport} />,
    },
  ]; */

  useEffect(() => {
    userService
      .Revenue(graphData)
      .then((response) => {
        setRevenue(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [graphData]);

  return (
    <Layout className="gradient-background-black-blue">
      <Layout.Content className="content creatorRevenueContentWrapper background-transparent">
        <Row className="relative pl-20 header-title-container-60">
          <Typography.Text className="header-title-black-35">{t("revenues")}</Typography.Text>
        </Row>
        <Row className="payment-information" align="middle">
          <Row className="payment-information-first-container">
            <Row className="next-payment-container">
              <Row>
                <Typography.Text className="font-32-bold text-white-color mr-10">
                  {revenue &&
                    revenue.nextInstallment.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " €"}
                </Typography.Text>
                {!isPendingPaymentOpen ? (
                  <img
                    src={ArrowRightGreenIcon}
                    width={28}
                    alt="open"
                    onClick={() => {
                      setIsPendingPaymentOpen(true);
                    }}
                  />
                ) : (
                  <img
                    src={ArrowBottomGreenIcon}
                    width={28}
                    alt="open"
                    onClick={() => {
                      setIsPendingPaymentOpen(false);
                    }}
                  />
                )}
              </Row>
              <Row>
                <Typography.Text>
                  <span className="text-description-first">{t("validated-for-the-next-payment-1")}</span>{" "}
                  <span className="text-description-second">{t("validated-for-the-next-payment-2")}</span>
                </Typography.Text>
                <img
                  src={InfoBlackGreyIcon}
                  width={12}
                  alt="info"
                  onClick={() => {
                    setIsInfoModal(true);
                    setInfoContent(1);
                  }}
                />
              </Row>
            </Row>
            <div className="currency-img">
              <img src={EurFlagImg} width={45} alt="EUR" />
              <Typography.Text className="eur-text">EUR</Typography.Text>
            </div>
          </Row>
          {isPendingPaymentOpen && (
            <Row className="payment-information-seconde-container">
              <Row className="pending-payment-container">
                <Row>
                  <Typography.Text className="font-24-bold text-white-color mr-10">
                    {revenue &&
                      revenue.waitingForValidation.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " €"}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text>
                    <span className="text-description-first">{t("waiting-for-validation-1")}</span>{" "}
                    <span className="text-description-second">{t("waiting-for-validation-2")}</span>
                  </Typography.Text>
                  <img
                    src={InfoBlackGreyIcon}
                    width={12}
                    alt="info"
                    onClick={() => {
                      setIsInfoModal(true);
                      setInfoContent(2);
                    }}
                  />
                </Row>
              </Row>
            </Row>
          )}
        </Row>
        <Row className="revenue-radio-container">
          <Radio.Group
            defaultValue="today"
            onChange={(e) => {
              setGraphData(e.target.value);
            }}
            buttonStyle="solid"
          >
            <Space className="gap-0">
              <Radio.Button className="revenuePeriodRadio" value="today">
                {t("today")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="month">
                1 {t("month")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="year">
                1 {t("year")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="total">
                {t("all")}
              </Radio.Button>
            </Space>
          </Radio.Group>
        </Row>
        <Row className="chart-container">
          <Row className="chart-data-row">
            <div>
              <Space className="gap-0" direction="vertical">
                <Typography.Text className="chart-price-text">
                  {revenue &&
                    revenue.totalNetPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " €"}
                </Typography.Text>
                <Typography.Text className="chart-description-text">{t("since-the-account-was-created")}</Typography.Text>
              </Space>
            </div>
            <div>
              <Space className="gap-0" direction="vertical">
                <Typography.Text className="chart-price-text">
                  {revenue &&
                    revenue.previousMonthEarn.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " €"}
                </Typography.Text>
                <Typography.Text className="chart-description-text">{t("the-previous-month")}</Typography.Text>
              </Space>
            </div>
          </Row>
          <Row className="w-full">
            <CreatorRevenueChartComponent statsData={revenue?.statsData} graphData={graphData} />
          </Row>
        </Row>

        <Row className="last-report-container">
          <Row className="last-report-header">
            <Typography.Text className="font-18-bold text-white-color">{t("last-report")}</Typography.Text>
            <div
              className="information-management-button"
              onClick={() => {
                setActiveTab((prevTab) => (prevTab === "daily" ? "monthly" : "daily"));
              }}
            >
              <img src={DoubleArrowGreenIcon} width={12} alt="change" />
              <Typography.Text className="information-management-button-text">{activeTab === "daily" ? t("daily") : t("monthly")}</Typography.Text>
            </div>
          </Row>
          <Row className="last-report-content">
            {activeTab === "daily" ? (
              <CreatorRevenueTabComponent dailyPreviousReport={revenue?.dailyPreviousReport} />
            ) : (
              <CreatorRevenueTabComponent monthlyPreviousReport={revenue?.monthlyPreviousReport} />
            )}
          </Row>
        </Row>

        {/* <Row className="pl-24 pr-24 mt-13">
          <Tabs className="tabs w-full" items={tabs} defaultActiveKey="daily" centered />
        </Row> */}
      </Layout.Content>
      <FooterComponent page={FOOTER_IDS.REVENUES} type="creator" />
      <InfoModal
        isOpen={isInfoModal}
        onClose={() => {
          setIsInfoModal(false);
          setInfoContent(null);
        }}
        description={infoContent === 1 ? t("earnings-confirm-for-payemnt") : infoContent === 2 ? t("revenues-earning-validation-info") : ""}
      />
    </Layout>
  );
};

export default CreatorRevenuePage;
