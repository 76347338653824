import { createSlice } from "@reduxjs/toolkit";
import { ILiveCommentState, ILiveStreamStats, ILiveTipState } from "../../helpers/types";

const commentsState: ILiveCommentState = {
  list: [],
  item: null,
  loading: false,
  error: null,
};

const tipsState: ILiveTipState = {
  tips: null,
  loading: false,
  error: null,
};

const streamState: ILiveStreamStats = {
  stats: null,
  loading: false,
  error: null,
};

const initialState = {
  comments: commentsState,
  tips: tipsState,
  streamStats: streamState,
};

const liveDataStatsSlice = createSlice({
  name: "liveDataStats",
  initialState,
  reducers: {
    editLiveComment: (state, action) => {
      state.comments.item = action.payload;
    },
    addLiveComment: (state, action) => {
      state.comments.list = action.payload;
    },
    newLiveTip: (state, action) => {
      state.tips.tips = action.payload;
    },
    updateStreamStats: (state, action) => {
      state.streamStats.stats = action.payload;
    },
    clearStreamStats: (state) => {
      state.comments.list = [];
      state.tips.tips = null;
      state.streamStats.stats = null;
    },
  },
});

export const { addLiveComment, editLiveComment, newLiveTip, updateStreamStats, clearStreamStats } = liveDataStatsSlice.actions;

export default liveDataStatsSlice.reducer;
