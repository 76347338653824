import { Row, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import StatusIcon from "../../assets/icons/status-circle-black.svg";
import { FAN, HELLO_MESSAGE } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import "./index.less";

type props = {
  message: IMessage;
  userRole?: string;
};

const MessageTextItemComponent: React.FC<props> = ({ message, userRole }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="messageTextItem">
        <Typography.Text className="font-14-medium text-black-color">{message.text}</Typography.Text>

        {message.text !== HELLO_MESSAGE && userRole === FAN && (
          <Row className="mt-10">
            <Space>
              <img src={StatusIcon} alt={message.status} />
              <Typography.Text className="font-14-bold text-black-color">{t(message.status!)}</Typography.Text>
            </Space>
          </Row>
        )}
      </div>
    </>
  );
};

export default MessageTextItemComponent;
