// @ts-ignore
//import { FFmpeg } from "@ffmpeg/ffmpeg";
import { message } from "antd";
import { RcFile } from "antd/es/upload";
import imageCompression from "browser-image-compression";
import Clipboard from "clipboard";
import heic2any from "heic2any";
import { ILive } from "../helpers/types";
import { authService } from "../services";

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
export const copyToClipboard = async (messageToCopy: string) => {
  if (isMobile()) {
    const tempInput = document.createElement("input");
    tempInput.value = messageToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  } else {
    await navigator.clipboard.writeText(messageToCopy);
  }
};

// New copy method without execCommand
export const copyToClipboardCustom = async (messageToCopy: string, errorMessage: string, successMessage?: string) => {
  try {
    if (isMobile()) {
      const button = document.createElement("button");
      button.setAttribute("data-clipboard-text", messageToCopy);
      document.body.appendChild(button);

      const clipboard = new Clipboard(button);

      clipboard.on("success", () => {
        if (successMessage) {
          message.success(successMessage);
        }
      });

      clipboard.on("error", () => {
        message.error(errorMessage);
      });

      button.click();
      clipboard.destroy();
      document.body.removeChild(button);
    } else {
      await navigator.clipboard.writeText(messageToCopy);
      if (successMessage) {
        message.success(successMessage);
      }
    }
  } catch (err) {
    message.error(errorMessage);
  }
};

export function objectToFormData(obj: Record<string, any>, formData: FormData = new FormData(), parentKey: string = ""): FormData {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value instanceof File) {
        // If the value is a File, append it directly to FormData
        formData.append(fullKey, value);
      } else if (value instanceof Object && !(value instanceof Date)) {
        // If the value is an object (but not a Date), recursively process it
        objectToFormData(value, formData, fullKey);
      } else if (value instanceof Date) {
        // If the value is a Date, convert it to a string and append
        formData.append(fullKey, value.toISOString());
      } else if (Array.isArray(value)) {
        // If the value is an array, process each element
        value.forEach((item, index) => {
          const arrayKey = `${fullKey}[${index}]`;
          if (item instanceof Object) {
            // If the array element is an object, recursively process it
            objectToFormData(item, formData, arrayKey);
          } else {
            formData.append(arrayKey, item);
          }
        });
      } else {
        // For other types, simply append the key-value pair
        formData.append(fullKey, value);
      }
    }
  }

  return formData;
}

export const isImageAcceptedFormat = (fileType: string): boolean => {
  const acceptedFormats: string[] = ["image/jpeg", "image/png", "image/webp", "image/gif", "image/avif"];
  return acceptedFormats.includes(fileType);
};

export const convertHeicFile = async (file: RcFile): Promise<File> => {
  const convertedBlob: Blob = (await heic2any({
    blob: file,
    toType: "image/png",
    quality: 1,
  })) as Blob;

  const convertedFile: File = new File([convertedBlob], file.name.replace(/\..+$/, ".png"), {
    type: "image/png",
  });

  return convertedFile;
};

export const compressImage = async (file: RcFile | File): Promise<File | null> => {
  const options = {
    maxSizeMB: 2.5, // (2.5MB) Adjust the maximum size as needed
    maxWidthOrHeight: 1280, // Adjust based on your requirements
    useWebWorker: true,
  };

  try {
    // Compress the image file.
    const compressedBlob = await imageCompression(file, options);
    const compressedFile = new File([compressedBlob], file.name, {
      type: compressedBlob.type,
      lastModified: Date.now(),
    });
    return compressedFile;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/* export const compressVideo = async (file: RcFile | File): Promise<any | null> => {
  const ffmpeg = new FFmpeg();

  if (!ffmpeg.loaded) {
    await ffmpeg.load();
  }

  try {
    const { name } = file;
    await ffmpeg.writeFile(name, file as any);
    await ffmpeg.exec(["-i", name, "-vcodec", "libx264", "-crf", "28", `compressed_${name}`]);
    const data = await ffmpeg.readFile(`compressed_${name}`);
    const url = URL.createObjectURL(new Blob([data], { type: "video/mp4" }));
    console.log("file url ====> ", url);
    console.log("file ====> ", data);
    //return new File(data as any, name);
    return {
      file: data,
      url: url,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}; */

/* export const generateThumbnailURL = async (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);
    videoElement.onloadedmetadata = () => {
      videoElement.currentTime = 0;
    };
    videoElement.onseeked = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Failed to get canvas context");
        return;
      }
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject("Failed to convert canvas to blob");
          return;
        }
        const url = URL.createObjectURL(blob);
        resolve(url);
      }, "image/jpeg");
    };
    videoElement.onerror = (error) => reject(error);
  });
}; */

/* export const generateThumbnailURL_claude = async (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.preload = 'metadata';
    videoElement.muted = true;
    videoElement.playsInline = true;

    const fileURL = URL.createObjectURL(file);
    videoElement.src = fileURL;

    videoElement.onloadedmetadata = () => {
      videoElement.currentTime = 0.1; // Set to a small non-zero value
    };

    videoElement.oncanplay = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Failed to get canvas context"));
        return;
      }
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Failed to convert canvas to blob"));
          return;
        }
        const url = URL.createObjectURL(blob);
        resolve(url);
        
        // Clean up
        URL.revokeObjectURL(fileURL);
      }, "image/jpeg");
    };

    videoElement.onerror = (error) => {
      URL.revokeObjectURL(fileURL);
      reject(new Error(`Video error: ${videoElement.error?.message || 'Unknown error'}`));
    };
  });
}; */

export const generateThumbnailURL = async (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);
    videoElement.autoplay = true;
    videoElement.muted = true;
    videoElement.playsInline = true;
    videoElement.crossOrigin = "anonymous";

    const handleLoadedData = () => {
      setTimeout(() => {
        videoElement.currentTime = 0;
      }, 200); // Give it some time to ensure it can seek to the start
    };

    const handleSeeked = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Failed to get canvas context");
        return;
      }
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject("Failed to convert canvas to blob");
          return;
        }
        const url = URL.createObjectURL(blob);
        resolve(url);
      }, "image/jpeg");
    };

    videoElement.addEventListener("canplaythrough", handleLoadedData);
    videoElement.addEventListener("seeked", handleSeeked);
    videoElement.onerror = (error) => reject(error);
  });
};

export const generateURL = async (file: RcFile): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    //reader.onload = () => resolve(reader.result as string);
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = (error) => {
      console.error("File reading error:", error);
    };
    reader.readAsDataURL(file);
  });
};

/* export const addWatermarkWithVercelFunction = async (imageUrl: string, watermarkText: string): Promise<string> => {
  let url = `https://stg.loly.app/api/watermark?key=${imageUrl}&watermarkText=${watermarkText}`;
  return new Promise<string>((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
}; */

const watermarkCache = new Map<string, string>();

export const addWatermark = async (url: string, watermarkText: string): Promise<string> => {
  const cacheKey = `${url}-${watermarkText}`;

  // Check cache first
  if (watermarkCache.has(cacheKey)) {
    //console.log("Returning cached watermarked image.");
    return watermarkCache.get(cacheKey)!;
  }

  return new Promise<string>(async (resolve, reject) => {
    // Fetch the image with custom headers
    try {
      //console.log("url ===========================> ", url);

      const response = await fetch(url, {
        method: "GET",
      });

      //console.log("response -----------------> ", response);

      if (!response) {
        reject("Failed to fetch the image");
        return;
      }

      // Convert response to Blob
      const blob = await response.blob();
      //const blob = await response.data;

      // Create a new Image object from the Blob
      const image = new Image();
      const imageUrl = URL.createObjectURL(blob);
      image.src = imageUrl;

      image.crossOrigin = "anonymous"; // Required if the image is from another domain

      image.onload = () => {
        // Create canvas and draw the image
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject("Canvas context could not be created");
          return;
        }

        // Set canvas dimensions to match the image
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0);

        // Set text properties
        const imageArea = canvas.width * canvas.height;
        let fontSize = Math.sqrt(imageArea) * 0.06; // Font size is proportional to the area of the image
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = "rgba(255, 255, 255, 0.7)";
        ctx.strokeStyle = "rgba(128, 128, 128, 0.9)"; // Stroke color
        //ctx.lineWidth = 2; // Width of the stroke

        // Calculate text width and height for centering
        const textWidth = ctx.measureText(watermarkText).width;
        const textHeight = fontSize; // Approximate text height based on font size

        // Calculate the position for the text to be centered
        const x = (canvas.width - textWidth) / 2;
        const y = (canvas.height + textHeight) / 2;

        // Draw the watermark text on the canvas
        ctx.strokeText(watermarkText, x, y);
        ctx.fillText(watermarkText, x, y);

        const secondLineText = "www.loly.app";
        const secondLineWidth = ctx.measureText(secondLineText).width;
        const secondLineX = (canvas.width - secondLineWidth) / 2;
        const secondLineY = y + textHeight + fontSize * 0.5; // gap between lines

        ctx.strokeText(secondLineText, secondLineX, secondLineY);
        ctx.fillText(secondLineText, secondLineX, secondLineY);

        // Generate a new image URL from the canvas
        const newImageUrl = canvas.toDataURL("image/webp");
        watermarkCache.set(cacheKey, newImageUrl);
        resolve(newImageUrl);

        // Revoke the object URL after usage to free memory
        URL.revokeObjectURL(imageUrl);
      };

      image.onerror = (err) => {
        reject("Image loading failed");
      };
    } catch (error) {
      reject("Failed to fetch and process the image");
    }
  });
};

/** Function used to get the short date with the current user language FR|EN */
export const convertToShortDate = (date: string, currentLanguageFormat: string) => {
  const createdAt = new Date(date);
  const formattedDate = createdAt
    .toLocaleDateString(currentLanguageFormat, {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(".", "");
  return formattedDate;
};

export const validateEmail = (email: string) => {
  const emailRegex = /^(?=[a-zA-Z0-9._-]{3,}@[a-zA-Z0-9._-]{3,}\.[a-zA-Z]{2,})([a-zA-Z0-9._-]+)@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneRegex = /^(\d{9}|\d{2}\s\d{2}\s\d{2}\s\d{2}\s\d{2})$/;
  return phoneRegex.test(phoneNumber);
};

export function isDOBPlus18(dob: string): boolean {
  const birthDate = new Date(dob);
  const currentDate = new Date();
  const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
  if (ageDiff < 18) {
    return false;
  } else if (ageDiff === 18) {
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      return false;
    }
  }
  return true;
}

export const signOutClicked = () => {
  authService.signOut();
  window.location.reload();
};

/**
 * Formats a number as an abbreviation (K, M).
 * @param {number} number
 * @returns {string}
 */
export const formatNumber = (number: number): string => {
  if (number >= 1_000_000) {
    return `${(number / 1_000_000).toFixed(1).replace(/\.0$/, "")} M`;
  }
  if (number >= 1_000) {
    return `${(number / 1_000).toFixed(1).replace(/\.0$/, "")} K`;
  }
  return number.toString();
};

export const liveIsPrivate = (live: ILive): boolean => {
  if (live.isStreaming) {
    if (live.access === "all" && live.amount !== 0 && !live.isPaymentVerified) {
      return true;
    }
    if (live.access !== "all") {
      if (!live.isSubscribed) {
        return true;
      }
      if (!live.isFreeForSubscribers && !live.isPaymentVerified) {
        return true;
      }
    }
  }
  return false;
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

/**
 * This method used to check if we should refetch a based on the duration of refresh and the last refresh time
 * @param {number} lastRefreshTime
 * @param {number} refetchDuration
 * @returns {boolean}
 */
export const shouldRefetchData = (lastRefreshTime: number, refetchDuration: number) => {
  return new Date().getTime() - lastRefreshTime >= refetchDuration * 60000;
};

export const formatCardNumber = (number: string) => {
  const cleanedNumber = number.replace(/\D/g, "");
  const formattedNumber = cleanedNumber.match(/.{1,4}/g)?.join(" ") || "";
  return formattedNumber;
};
