import { Button, Col, Layout, Row, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import CreatorDeletePaymentMethodModal from "../../../components/CreatorDeletePaymentMethodModal";
import CreatorSwitchBankAccountModalComponent from "../../../components/CreatorSwitchBankAccountModalComponent";
import { userService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getMyProfile } from "../../../redux/users/usersSlice";
import RevoCard from "../../../assets/images/revo_card.webp";
import "./index.less";

const CreatorPaymentMethodPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paymentFrequency, setPaymentFrequency] = useState<any>();
  const [banks, setBanks] = useState<any>([]);
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState<boolean>(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false);
  const [payoutStatus, setPayoutStatus] = useState<boolean>(false);
  const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
  const [accountSelected, setAccountSelected] = useState<any>({});
  const user = useSelector((state: StoreState) => state.users.item);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getMyProfile());
    const resp = userService.getCreatorAllBank() as any;
    resp
      .then((data: any) => {
        setBanks(data);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      });

    const user = userService.getMyProfile() as any;
    user
      .then((data: any) => {
        setPaymentFrequency(data.paymentFrequency.toString());
        setSelectedMethod(data.paymentFrequency.toString());
        setPayoutStatus(data.payout_status);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      });
  }, []);

  useEffect(() => {
    if (user) {
      const status = !user?.payout_status || user?.payout_status === null ? false : user?.payout_status;
      setPayoutStatus(status);
    }
  }, [user]);

  const handleChangeSelect = (payoutStatus: boolean, paymentFrequency: string) => {
    const payload = {
      payout_status: payoutStatus,
      paymentFrequency: parseInt(paymentFrequency),
    };

    userService
      .updatePayoutFrequency(payload)
      .then(() => {
        setPayoutStatus(payoutStatus);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      })
      .finally(() => {
        setIsSwitchLoading(false);
      });
  };

  const bankAccountConvert = (value: any) => {
    const firstChar = value.substring(0, 2);
    return `${firstChar + "** **** **** **** "}${value.slice(-3)}`;
  };

  const switchAccountByDefault = async () => {
    await userService.setDefaultBankAccount(accountSelected._id);

    const resp = userService.getCreatorAllBank() as any;
    resp
      .then((data: any) => {
        setBanks(data);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      });
    setIsSwitchAccountModalOpen(false);
  };

  const deleteBankAccount = async () => {
    await userService.deleteBankAccount(accountSelected._id);
    setIsDeleteAccountModalOpen(false);
    setIsSwitchAccountModalOpen(false);

    const resp = userService.getCreatorAllBank() as any;
    resp
      .then((data: any) => {
        setBanks(data);
      })
      .catch((error: any) => {
        message.error(error || "Something went wrong.");
      });
  };

  const handleSelectMethod = (method: any) => {
    if (method === "30" || method === "7") {
      setSelectedMethod(method);
      if (payoutStatus) {
        handleChangeSelect(false, method);
      } else {
        handleChangeSelect(false, method);
      }
    } else {
      const oldMethode = selectedMethod;
      setSelectedMethod(method);
      handleChangeSelect(true, oldMethode!);
    }
  };

  return (
    <Layout>
      <Layout.Content className="content creatorPaymentMethodContentWrapper gradient-background-blue-settings">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={0} top={1} />
          <Typography.Text className="header-title-black-24 ml-10">{t("payment-method")}</Typography.Text>
        </Row>
        <Row className="w-full mt-50">
          <Typography.Text className="font-32-bold text-white-color">{t("whenever-you-want")}</Typography.Text>
        </Row>
        <Row className="select-method-block">
          <Col
            className={`select-method-col left ${selectedMethod === "7" && !payoutStatus ? "active" : ""}`}
            onClick={() => handleSelectMethod("7")}
          >
            <div className="select-method-content">
              <Typography.Text className="font-12-regular text-white-color select-method-top">{t("versement")}</Typography.Text>
              <Typography.Text className="font-18-bold text-white-color select-method-center">{t("every-seven-days")}</Typography.Text>
            </div>
          </Col>
          <div className="select-method-divider" />
          <Col className={`select-method-col ${selectedMethod === "30" && !payoutStatus ? "active" : ""}`} onClick={() => handleSelectMethod("30")}>
            <div className="select-method-content">
              <Typography.Text className="font-12-regular text-white-color select-method-top">{t("versement")}</Typography.Text>
              <Typography.Text className="font-18-bold text-white-color select-method-center">{t("every-thirty-days")}</Typography.Text>
            </div>
          </Col>
          <div className="select-method-divider" />
          <Col className={`select-method-col right ${payoutStatus ? "active" : ""}`} onClick={() => handleSelectMethod(!payoutStatus)}>
            <div className="select-method-content">
              <Typography.Text className="font-12-regular text-white-color select-method-top">{t("versement")}</Typography.Text>
              <Typography.Text className="font-18-bold text-white-color select-method-center">{t("on-pause")}</Typography.Text>
            </div>
          </Col>
        </Row>
        <Row className="style-bank">
          <Typography.Text className="font-32-bold text-white-color">{t("bank")}</Typography.Text>
        </Row>
        <Row>
          <Space className="w-full gap-21" direction="vertical">
            {banks.map(
              (
                item: { firstName: string; lastName: string; bankAccount: string; byDefault: boolean; status: string },
                index: React.Key | null | undefined
              ) => {
                return (
                  <Row
                    key={index}
                    onClick={() => {
                      setAccountSelected(item);
                      setIsSwitchAccountModalOpen(true);
                    }}
                  >
                    <Row className="w-full justify-content-center">
                      <Row className="image-container">
                        <img src={RevoCard} alt="RevoCard" className="responsive-image" />
                        <Row className="card-overlay">
                          <Row className="card-top-left">
                            <Typography.Text className="title-card text-gradient-color">{t("bank-account")}</Typography.Text>
                          </Row>
                          <Row className="card-top-right">{item.byDefault && <span className="default-option">{t("by-default")}</span>}</Row>
                          <Row className="card-bottom-left">
                            <Typography.Text className="username-card text-gradient-color">{item.firstName + " " + item.lastName}</Typography.Text>
                            <Typography.Text className="info-card text-gradient-color">{bankAccountConvert(item.bankAccount)}</Typography.Text>
                          </Row>
                        </Row>
                      </Row>
                    </Row>
                  </Row>
                );
              }
            )}
          </Space>
        </Row>
        <Row className="bottom-sticky-container">
          <Row className="mt-50 sticky-button-method-payment">
            <Button className="addAccountBtn" onClick={() => navigate("/creator/newPaymentMethod")}>
              {t("add-an-account")}
            </Button>
          </Row>
        </Row>
        <CreatorSwitchBankAccountModalComponent
          isOpen={isSwitchAccountModalOpen}
          onClose={() => setIsSwitchAccountModalOpen(false)}
          onYesClick={switchAccountByDefault}
          onDelete={() => {
            setIsDeleteAccountModalOpen(true);
            setIsSwitchAccountModalOpen(false);
          }}
        />
        <CreatorDeletePaymentMethodModal
          isOpen={isDeleteAccountModalOpen}
          onClose={() => {
            setIsDeleteAccountModalOpen(false);
            setIsSwitchAccountModalOpen(false);
          }}
          onYesClick={deleteBankAccount}
        />
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorPaymentMethodPage;
