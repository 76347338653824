import { useEffect, useState } from 'react';
import "./index.less"

const PaymentRedirectPage = () => {
  const [action, setAction] = useState<string>("");
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    onLoad();
  }, [action , value]);

  const onLoad = () => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const action_prop = params.get('action');
    const value_prop = params.get('value');
    if (action_prop && value_prop) {
      setAction(action_prop);
      setValue(value_prop);
      const formElement = document.getElementById('848c0411-c77c-438f-9c6b-e7da9e4b812c') as HTMLFormElement | null;
      if (formElement) {
        formElement.submit();
      }
    } else {
      alert("Missing action or value parameters");
    }
  };

  return (
    <div className='PaymentRedirectWrapper'>
      {action && value && (
        <form id="848c0411-c77c-438f-9c6b-e7da9e4b812c" name="848c0411-c77c-438f-9c6b-e7da9e4b812c" action={action} method="POST">
          <input type="hidden" name="data" id="data" value={value} />
        </form>
      )}
    </div>
  );
};

export default PaymentRedirectPage;
