import { AudioMutedOutlined, PauseCircleFilled, PlayCircleFilled, SoundFilled } from "@ant-design/icons";
import { Watermark } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { authService } from "../../services";
import "./index.less";

interface VideoPlayerProps {
  videoUrl: string;
  thumbnailUrl: string;
  watermarkText?: string;
  classNameProp?: string;
  autoPlay?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
  watermarkText = authService.getUser().username,
  classNameProp,
  autoPlay = false,
}) => {
  const [showThumbnail, setShowThumbnail] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [showControls, setShowControls] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const controlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleThumbnailClick = () => {
    setShowThumbnail(false);
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
          showControlsTemporarily();
        })
        .catch((error) => console.error("Error playing video:", error));
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current
          .play()
          .then(() => {
            setIsPlaying(true);
            showControlsTemporarily();
          })
          .catch((error) => console.error("Error playing video:", error));
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
        showControlsTemporarily();
      }
    }
  };

  const showControlsTemporarily = () => {
    setShowControls(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const seekVideo = (e: React.MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current && videoRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      videoRef.current.currentTime = pos * videoRef.current.duration;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("play", () => {
        setIsPlaying(true);
        showControlsTemporarily();
      });
      video.addEventListener("pause", () => {
        setIsPlaying(false);
        showControlsTemporarily();
      });

      // Set up IntersectionObserver for lazy loading
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && video) {
            video.preload = "auto"; // Preload when video is in view
          } else if (video) {
            video.preload = "none"; // Stop loading when out of view
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        threshold: 0.25,
      });

      observer.observe(video);

      if (autoPlay) {
        setShowThumbnail(false);
        video
          .play()
          .then(() => {
            setIsPlaying(true);
            showControlsTemporarily();
          })
          .catch((error) => console.error("Error playing video:", error));
      }

      return () => {
        video.removeEventListener("timeupdate", updateProgress);
        video.removeEventListener("play", () => setIsPlaying(true));
        video.removeEventListener("pause", () => setIsPlaying(false));
        if (controlsTimeoutRef.current) {
          clearTimeout(controlsTimeoutRef.current);
        }
        observer.disconnect();
      };
    }
  }, [autoPlay]);

  return (
    <div className="video-player" ref={videoContainerRef}>
      <div className="video-container">
        <video
          className={classNameProp ? classNameProp : ""}
          ref={videoRef}
          preload="none" // Prevent loading until user interaction or visible
          playsInline
          onClick={togglePlay}
          style={{ display: showThumbnail ? "none" : "block" }}
          onMouseMove={showControlsTemporarily}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {showThumbnail && (
          <div className="thumbnail-container" onClick={handleThumbnailClick}>
            <img src={thumbnailUrl} alt="video thumbnail" className="thumbnail-image" />
            <PlayCircleFilled className="play-icon" />
          </div>
        )}
        <Watermark content={[watermarkText, "www.loly.app"]} className="video-watermark" font={{ fontSize: 16, color: "rgba(255, 255, 255, 0.5)" }} />
        {!showThumbnail && (
          <div className={`controls ${!isPlaying ? "isPause" : ""} ${showControls ? "show" : "hide"}`}>
            <div className="progress-bar" ref={progressBarRef} onClick={seekVideo}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="buttons">
              <button className="control-button" onClick={togglePlay}>
                {isPlaying ? <PauseCircleFilled /> : <PlayCircleFilled />}
              </button>
              <button className="control-button" onClick={toggleMute}>
                {isMuted ? <AudioMutedOutlined /> : <SoundFilled />}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
