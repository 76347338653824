import { Skeleton } from 'antd'
import './index.less'
interface Props {
  loading: boolean
}

export const FanHomePageSkelton = ({loading}: Props) => {
  return (
    <div className='fhp-main-skelton'>
    <Skeleton className='fhp-skelton-bg' loading={loading} active avatar paragraph={{ rows: 0 }}/>
    <Skeleton.Image className='fhp-skelton-bg' active style={{ width: "100%", height: 450 }} />
    <Skeleton className='fhp-skelton-bg' loading={loading} active paragraph={{ rows: 2 }}/>
    </div>
  )
}
