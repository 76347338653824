import { Button, Row, Space, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useTranslation } from "react-i18next";
import StatusIcon from "../../assets/icons/status-circle.svg";
import { ACCEPTED, DELIVERED, FAN, FILE, HELLO_MESSAGE, PENDING, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import AvatarComponent from "../AvatarComponent";
import MediaComponent from "../MediaComponent";
import MessagePriceComponent from "../MessagePriceComponent";
import "./index.less";

type props = {
  message: IMessage;
  user?: string;
  userRole?: string;
  acceptMessage?: any;
  rejectMessage?: any;
  hideStatus?: boolean;
  username?: string;
};

const IncomeMessageComponent: React.FC<props> = ({ message, user, userRole, acceptMessage, rejectMessage, hideStatus, username }) => {
  const { t } = useTranslation();

  return (
    <div className={`incomeMessage ${message.medias && !isEmpty(message.medias) ? "min-width-media" : ""}`}>
      {user && <AvatarComponent image={user} size={23} />}
      <Row className="incomeMessageBox">
        <Space className="w-full gap-15" direction="vertical">
          {message.price !== undefined && <MessagePriceComponent message={message} income={true} />}

          {message.medias && !isEmpty(message.medias) && (
            <Row className={"mediaRow"}>
              {message.medias.map((media) => {
                let imageUrl = media.url;
                let isVideo = false;
                if (media.url.includes(".mp4")) {
                  imageUrl = media.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath;
                  isVideo = true;
                }
                const isPrivate = ![ACCEPTED, DELIVERED].includes(message.status !== undefined ? message.status : "") && media.isPrivate!;
                return (
                  <MediaComponent
                    key={media.fileId}
                    width={68}
                    height={68}
                    borderRadius={11}
                    image={imageUrl}
                    isPrivate={isPrivate}
                    isVideo={isVideo}
                    url={media.url}
                    username={username!}
                    shouldAddWatermark={true}
                  />
                );
              })}
            </Row>
          )}
          {message.text && <Typography.Text className="message-style">{message.text}</Typography.Text>}

          {message.status === PENDING && (
            <Row>
              <Space>
                <Button className="accept-media-button" onClick={() => acceptMessage(message)}>
                  {t("accept")}
                </Button>
                <Button className="reject-media-button" onClick={() => rejectMessage(message._id)}>
                  {t("refuse")}
                </Button>
              </Space>
            </Row>
          )}
          {message.text !== HELLO_MESSAGE && message.status !== PENDING && (userRole !== FAN || message.type === FILE) && !hideStatus && (
            <Row className="mt-10">
              <Space>
                <img src={StatusIcon} alt={message.status} />
                <Typography.Text className="font-14-bold text-white-color">
                  {t(message.status!)} {isEmpty(message.medias) && message.status === ACCEPTED && message.type === FILE ? `(${t(PENDING)})` : ""}
                </Typography.Text>
              </Space>
            </Row>
          )}
        </Space>
      </Row>
    </div>
  );
};

export default IncomeMessageComponent;
