import axios from "../helpers/axios";
import { ICodePromo, IErrorResponse, IPromotion, IVerifyPromoCode } from "../helpers/types";

class PromotionService {
  public create(data: IPromotion) {
    const url = `/promotions`;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.data) {
            resolve();
          } else if (response?.data?.error === "PROMOTION_CODE_EXIST") {
            reject("promo-code-already-exist-error-message");
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public GetAllPromotions = (id: string) => {
    const url = `/promotions/user/${id}`;
    return new Promise<ICodePromo>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public DeletePromo = (id: string) => {
    const url = `/promotions/${id}`;
    return new Promise<boolean>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public verifyPromoCode = (code: string, creatorId: string) => {
    const url = `/promotions/verifyPromoCode/${code}/${creatorId}`;
    return new Promise<IVerifyPromoCode | IErrorResponse>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          if (response?.data?.error) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const promotionService = new PromotionService();
