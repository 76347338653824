import axios from "../helpers/axios";
import {IQuickMessage} from "../helpers/types";
import {authService} from "./index";

class QuickMessagesService {

  public getAllQuickMessages() {
    return new Promise<IQuickMessage[]>((resolve, reject) => {
      axios.get(`/quickMessage/user/${authService.getId()}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getQuickMessageById(quickMessageId: string) {
    return new Promise<IQuickMessage>((resolve, reject) => {
      axios.get(`/quickMessage/${quickMessageId}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public createQuickMessage(quickMessage: IQuickMessage) {
    return new Promise<IQuickMessage>((resolve, reject) => {
      axios.post(`/quickMessage`, quickMessage)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public updateQuickMessage(quickMessage: IQuickMessage) {
    return new Promise<IQuickMessage>((resolve, reject) => {
      axios.patch(`/quickMessage/${quickMessage._id}`, quickMessage)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public deleteQuickMessage(quickMessageId: string) {
    return new Promise<any>((resolve, reject) => {
      axios.delete(`/quickMessage/${quickMessageId}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

}

export const quickMessageService = new QuickMessagesService();
