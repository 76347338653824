import { Layout, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LeftArrowIcon from "../../../assets/icons/new-arrow-left.svg";
import VerifiedIcon from "../../../assets/icons/verify.svg";
import EarthImg from "../../../assets/images/earth.webp";
import { FanEventPaymentVerify } from "../../../components/FanPaymentVerifyComponent/FanEventPaymentVerify";
import { usePreviousLocationContext } from "../../../helpers/PreviousLocationContext";
import { getLiveById } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import liveUtil from "../../../utls/LiveUtil";
import "./index.less";

const FanEventPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { item: live } = useSelector((state: StoreState) => state.lives);
  const previousLocation = usePreviousLocationContext();

  useEffect(() => {
    if (!id) return;
    dispatch(getLiveById(id));
  }, [id]);

  const handleNavigation = () => {
    if (previousLocation?.pathname !== undefined && ["/common/payment", "/fan/payment"].includes(previousLocation?.pathname)) {
      navigate("/fan/lives?tab=schedule");
    } else {
      navigate(-1);
    }
  };

  const navigateToProfile = () => {
    navigate("/p/" + live?.creator?.username);
  };

  return (
    <Layout>
      <Layout.Content className="content fanEventContentWrapper gradient-background-black no-scroll-horizontal">
        <div
          className="eventHeaderImg"
          style={{
            backgroundImage: `url(${live?.scheduleData?.media && typeof live?.scheduleData?.media === "object" ? live?.scheduleData?.media?.absolutePath : ""})`,
          }}
          onClick={navigateToProfile}
        >
          <img className="eventHeaderLeftArrow" width={30} src={LeftArrowIcon} alt="arrow left" onClick={handleNavigation} />
          <div className="w-full eventHeaderUserInfo">
            <Typography.Text className="fullname">{live?.creator?.username}</Typography.Text>
            {live?.scheduleData?.message && <Typography.Text className="descriptionEvent">{live?.scheduleData?.message}</Typography.Text>}
          </div>
        </div>
        <Row className="mainContent mt-16">
          <Row className="rowVerified">
            <img src={VerifiedIcon} width={14} alt="premium" />
            <Typography.Text className="font-12-bold text-white-color">{t("verified-artist-upp")}</Typography.Text>
          </Row>
          <Row className="titleEvent">{t("exclusive-live-show")}</Row>
          <Row className="timeInfo">
            <div className="divTimeInfo">
              {live?.scheduleData?.dateTime && (
                <Typography.Text className="timeText">{liveUtil.getScheduleDateTime(t, live.scheduleData.dateTime)}</Typography.Text>
              )}
            </div>
          </Row>
          <Space className="w-full" direction="vertical">
            <FanEventPaymentVerify live={live} />
          </Space>
          <Row className="row-img">
            <img src={EarthImg} alt="earth" />
          </Row>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default FanEventPage;
