import axios from "../helpers/axios";
import { IFanFeed, IFeed, PageableData } from "../helpers/types";
import { authService } from "./index";

class FeedService {
  public getMyFeeds() {
    const url = `/feeds/creators`;
    return new Promise<IFeed[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getFeeds = (id: string , skip : number , limit : number | null) => {
    const url = `/feeds/creators/search`;
    return new Promise<IFeed[]>((resolve, reject) => {
      axios
        .get(url, {
          params: {
            creatorId: id,
            offset : skip || 0,
            limit : limit || null
          },
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getPublicFeeds = (username: string) => {
    const url = `/feeds/creator/${username}`;
    return new Promise<IFeed[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data?.data) resolve(response.data.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getHomePageFeeds = (page: number) => {
    const url = `/feeds/homePage?page=${page}`;
    return new Promise<PageableData<IFeed>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public createFeed = (payload: FormData, setProgress: Function) => {
    const url = `/feeds/creators`;
    return new Promise<IFeed>((resolve, reject) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total!));
          },
        })
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getFanFeed = (id: string) => {
    const url = `/feeds/fan/${id}`;
    return new Promise<IFanFeed>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public deleteFeed = (id: string) => {
    const url = `/feeds/creators/${id}`;
    return new Promise<IFanFeed>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };
}

export const feedService = new FeedService();
