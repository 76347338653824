import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Layout, Row, Space, Typography } from "antd";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { mediapushService } from "../../../services";
import { convertToShortDate } from "../../../utls/FunctionsUtil";
import "./index.less";

const CreatorHistoryMediaPushPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mediaPushHistory, setMediaPushHistory] = useState<any>([]);
  useEffect(() => {
    mediapushService
      .mediaPushHistory()
      .then((response) => {
        setMediaPushHistory(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <Layout.Content className="content creatorHistoryMediaPushContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60 mb-20">
          <ArrowLeftIconComponent top={0} left={0} />
          <Typography.Text className="ml-25 mt-1 header-title-black-20">{t("history-media-push")}</Typography.Text>
        </Row>
        {mediaPushHistory &&
          mediaPushHistory.map(
            (
              item: {
                mediaPushId: string;
                createdAt: string;
                sale: number;
                totalEarn: number;
                targetPeople: number;
              },
              index: number
            ) => {
              return (
                <Row
                  onClick={() => navigate(`/creator/performance/${item.mediaPushId}`)}
                  className="justify-content-between items-start border-bottom-history-push"
                  key={index}
                >
                  <Col>
                    <Space className="gap-40">
                      <Typography.Text className="font-18-bold text-white-color">
                        {convertToShortDate(item.createdAt, t("current-language"))}
                      </Typography.Text>
                      <div className="subscriber-block">
                        <Typography.Text className="font-11-bold">
                          {item.targetPeople} {t("subscribers")}
                        </Typography.Text>
                      </div>
                    </Space>
                  </Col>
                  <Space className="gap-15">
                    <Typography.Text className="font-20-bold text-white-color">{item.totalEarn.toFixed(2)} €</Typography.Text>
                  </Space>
                </Row>
              );
            }
          )}
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorHistoryMediaPushPage;
