import { useState } from "react";
import { Layout, Row, Space, Typography, message } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import InputCompnent from "../../../components/InputComponent";
import { userService } from "../../../services";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import "./index.less";

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState<string | null>(null);

  const submitButtonClicked = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) return message.warning("Fill The Fields.");
    if (newPassword.length < 6) return message.warning(t("password-too-short"));
    if (newPassword !== confirmNewPassword) return message.warning(t("different-passwords"));
    if (currentPassword === newPassword) return message.warning(t("current-new-password-deiiferent"));

    userService
      .changePassword(currentPassword, newPassword, confirmNewPassword)
      .then((data: any) => {
        if (data.message) {
          message.error(data.message);
        } else {
          setConfirmNewPassword(null);
          setCurrentPassword(null);
          setNewPassword(null);
          message.success(t("auth-forgot-password-confirm-text"));
        }
      })
      .catch((error) => {
        message.error(error || "Something went wrong.");
      });
  };

  return (
    <Layout>
      <Layout.Content className="content creatorChangePasswordContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent top={1} left={-5} />
          <Typography.Text className="ml-20 header-title-black-22">{t("change-password")}</Typography.Text>
        </Row>
        <Space className="w-full gap-18" direction="vertical">
          <Row className="mt-40">
            <Space className="w-full gap-7" direction="vertical">
              <Typography.Text className="font-16-bold text-white-color">{t("old-password")}</Typography.Text>
              <InputCompnent
                className="darkInput"
                placeholder={t("old-password")!}
                type="password"
                value={currentPassword || ""}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Space>
          </Row>
          <Row>
            <Space className="w-full gap-7" direction="vertical">
              <Typography.Text className="font-16-bold text-white-color">{t("new-password")}</Typography.Text>
              <InputCompnent
                className="darkInput"
                placeholder={t("new-password")!}
                type="password"
                value={newPassword || ""}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Space>
          </Row>
          <Row>
            <Space className="w-full gap-7" direction="vertical">
              <Typography.Text className="font-16-bold text-white-color">{t("confirm-new-password")}</Typography.Text>
              <InputCompnent
                className="darkInput"
                placeholder={t("confirm-new-password")!}
                type="password"
                value={confirmNewPassword || ""}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Space>
          </Row>
        </Space>
        <Row className="mt-50">
          <AuthButtonComponent label={t("register")} onClick={submitButtonClicked} />
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default ChangePasswordPage;
