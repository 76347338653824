import { LoadingOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React, { useEffect, useState } from "react";
import VideoIcon from "../../assets/icons/jouer.svg";
import LockIcon from "../../assets/icons/lock-outlined-without-point.svg";
import { FALLBACK_IMAGE } from "../../helpers/constant";
import { authService } from "../../services";
import { addWatermark } from "../../utls/FunctionsUtil";
import VideoPlayer from "../VideoPlayerComponent";
import "./index.less";

type props = {
  image: string;
  width: number;
  height: number;
  borderRadius: number;
  isPrivate?: boolean;
  isBlur?: boolean;
  isVideo?: boolean;
  url?: string;
  username: string;
  shouldAddWatermark: boolean;
};

const MediaComponent: React.FC<props> = ({
  image,
  width,
  height,
  borderRadius,
  isPrivate,
  isVideo,
  url,
  username,
  isBlur = true,
  shouldAddWatermark = true,
}) => {
  const [watermarkedImageUrl, setWatermarkedImageUrl] = useState<string>(shouldAddWatermark && !isPrivate ? "" : image);

  useEffect(() => {
    //const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    //const imgUrl = proxyUrl + image;
    if (shouldAddWatermark && !isPrivate) {
      addWatermark(image, authService.getUser().username)
        .then((newImageUrl) => {
          setWatermarkedImageUrl(newImageUrl);
        })
        .catch((err) => {
          console.log("Error generating watermarked image:", err);
          setWatermarkedImageUrl(image);
        });
    }
  }, [image, shouldAddWatermark, isPrivate]);

  if (watermarkedImageUrl === "" && !isPrivate) {
    return (
      <div className={"d-flex justify-content-center items-center"} style={{ width: width, height: height }}>
        <LoadingOutlined style={{ color: "white", fontSize: "50px" }} />
      </div>
    );
  }

  return (
    <div className="mediaWrapper" style={{ width: width, height: height }}>
      <div
        className="mediaContent"
        style={{
          width: width,
          height: height,
          borderRadius: borderRadius,
          filter: isPrivate && isBlur ? "blur(5px)" : "",
        }}
      >
        {isVideo ? (
          <Image
            loading="lazy"
            width={width}
            height={height}
            src={watermarkedImageUrl}
            fallback={FALLBACK_IMAGE}
            style={{ borderRadius: borderRadius, objectFit: "cover" }}
            preview={
              isPrivate
                ? {}
                : {
                    destroyOnClose: true,
                    modalRender: () => (
                      <div className="video-preview-wrapper">
                        <VideoPlayer videoUrl={url!} thumbnailUrl={""} autoPlay={true} />
                      </div>
                    ),
                  }
            }
          />
        ) : (
          <Image
            loading="lazy"
            width={width}
            height={height}
            src={watermarkedImageUrl}
            fallback={FALLBACK_IMAGE}
            style={{ borderRadius: borderRadius, objectFit: "cover" }}
          />
        )}
      </div>
      {isPrivate && <img className="locksIcon" src={LockIcon} width={15} alt="lock" />}
      {isVideo && <img className="videosIcon" src={VideoIcon} alt="play" />}
    </div>
  );
};

export default MediaComponent;
