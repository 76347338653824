import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import AgoraRTC, { ClientConfig, IAgoraRTCClient } from "agora-rtc-sdk-ng";
import { AGORA_APP_ID } from "./config";

type AppConfiguration = {
  agoraAppId: string,
  agoraEnable: boolean
};

type AgoraRTCProviderState = {
  client: IAgoraRTCClient | null,
  appConfiguration: AppConfiguration | null,
  config: ClientConfig | null
};

const AgoraContext = createContext<AgoraRTCProviderState>({ client: null, appConfiguration: null, config: null });

type props = {
  children: React.ReactNode,
  config: ClientConfig
};

export const AgoraProvider: React.FC<props> = ({ config, children}) => {
  const [client, setClient] = useState<IAgoraRTCClient | null>(null);
  const [appConfiguration, setAppConfiguration] = useState<AppConfiguration | null>(null);

  const onbeforeunload = () => {
    if (client) {
      client.removeAllListeners();
    }
  };

  useEffect(() => {
    if (AGORA_APP_ID)
      setAppConfiguration({
        agoraAppId: AGORA_APP_ID,
        agoraEnable: true
      });

    window.addEventListener("beforeunload", onbeforeunload);

    const _client = AgoraRTC.createClient(config);

    if (_client)
      setClient(_client);

    return () => {
      window.removeEventListener("beforeunload", onbeforeunload);
    }
  }, [])

  const value = useMemo(
    () => ({ client, appConfiguration, config}),
    [client, appConfiguration, config]
  );

  return React.createElement(AgoraContext.Provider, { value }, children);
};

AgoraProvider.displayName = 'AgoraProvider';

export const useAgora = () => useContext(AgoraContext);

export default AgoraProvider;
