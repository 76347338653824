import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Modal, Row, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { getUserQuickMessages, deleteQuickMessage } from "../../redux/messages/quickMessagesSlice";
import ModalLineIcon from "../../assets/icons/modal-line.svg";
import CloseIcon from "../../assets/icons/close.svg";
import PlusIcon from "../../assets/icons/plus-white-back-black.svg";
import QuickMessagesComponent from "../QuickMessageComponent";
import "./index.less";

type props = {
  isOpen: boolean;
  onCloseClick: () => void;
  selectMessage: (message: string) => void;
};

const CreatorSavedResponsesModalComponent: React.FC<props> = ({ isOpen, onCloseClick, selectMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const { quickMessages, loading, error, action } = useSelector((state: StoreState) => state.quickMessages);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getUserQuickMessages());
    }
  }, []);

  useEffect(() => {
    if (loading === "failed" && error) {
      message.error("Something went wrong.");
    }
  }, [loading, error]);

  useEffect(() => {
    if (action === "DELETE" && loading === "succeeded") {
      setConfirmLoading(false);
    }
  }, [loading, action]);

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  const handleSelectMessage = (message: string | undefined) => {
    if (message) {
      selectMessage(message);
      onCloseClick();
    }
  };

  const deleteQuickAnswer = (messageId: string) => {
    setConfirmLoading(true);
    dispatch(deleteQuickMessage(messageId));
  };

  if (loading === "pending") {
    // TODO: add spinner or skeleton component
    return <div>Loading...</div>;
  }

  return (
    <Modal
      className="creatorSavedResponsesModalWrapper gradient-background-quick-message"
      open={isOpen}
      closable={false}
      onCancel={onCloseClick}
      footer={null}
    >
      <Row className="creatorSavedResponsesModalHeader">
        <Space className="w-full gap-15" direction="vertical">
          <img className="creatorSavedResponseModalHeaderLine" src={ModalLineIcon} alt="modal" />
          <Row className="w-full justify-content-between align-center">
            <img src={CloseIcon} width={20} alt="close" onClick={onCloseClick} className="mt-1 ml-5" />
            <Typography.Text className="header-title-black-22">{t("recorded-responses")}</Typography.Text>
            <img src={PlusIcon} width={40} alt="plus" onClick={() => navigate("/creator/newResponse")} />
          </Row>
        </Space>
      </Row>
      <QuickMessagesComponent
        quickMessages={quickMessages}
        handleSelectMessage={handleSelectMessage}
        deleteQuickAnswer={deleteQuickAnswer}
        confirmLoading={confirmLoading}
      />
    </Modal>
  );
};

export default CreatorSavedResponsesModalComponent;
