import React from "react";
import { Button } from "antd";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import GoogleLogo from "../../assets/images/google_logo_resultat.webp";
import "./index.less";

type props = {
  className?: string;
  label?: string;
  onLoginSuccess?: (accessToken: string) => void;
};

const AuthGoogleButtonComponent: React.FC<props> = ({ className, onLoginSuccess, label }) => {
  const { t } = useTranslation();

  const handleLoginSuccess = (tokenResponse: TokenResponse) => {
    if (tokenResponse.access_token && onLoginSuccess) {
      onLoginSuccess(tokenResponse.access_token);
    }
  };

  const login = useGoogleLogin({ onSuccess: handleLoginSuccess });

  return (
    <Button
      className={`${className ? className : "authGoogleBtn"}`}
      onClick={() => login()}
      icon={<img height={30} width={30} src={GoogleLogo} alt={"google"} />}
    >
      {label ? label : t("auth-social-google")}
    </Button>
  );
};

export default AuthGoogleButtonComponent;
