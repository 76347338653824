import React, { useEffect, useState } from "react";
import { Button, Col, InputNumber, Modal, Radio, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import LiveTipsImg from "../../assets/images/live-tips.webp";
import "./index.less";

const amounts = [
  {
    label: "5€",
    value: 5,
  },
  {
    label: "10€",
    value: 10,
  },
  {
    label: "15€",
    value: 15,
  },
  {
    label: "20€",
    value: 20,
  },
  {
    label: "45€",
    value: 45,
  },
  {
    label: "100€",
    value: 100,
  },
  {
    label: "150€",
    value: 150,
  },
  {
    label: "250€",
    value: 250,
  },
];

type props = {
  userName: string | null;
  isOpen: boolean;
  amount: number | null;
  isPersonalAmount: boolean;
  onChangeValue: (value: number) => void;
  setIsPersonalAmount: (value: boolean) => void;
  onSubmit: Function;
  onClose: () => void;
};

const LiveTipModalComponent: React.FC<props> = ({
  userName,
  isOpen,
  amount,
  isPersonalAmount,
  onChangeValue,
  setIsPersonalAmount,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (amount && amount > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [amount]);

  const handleCustomPriceChange = (value: number | null) => {
    if (value) {
      setIsPersonalAmount(false);
      onChangeValue(value);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal className="liveTipModalWrapper" open={isOpen} centered closable={false} onCancel={onClose} footer={null}>
      <Space className="w-full gap-22" direction="vertical">
        <Row className="w-full justify-content-center mt-10">
          <img src={LiveTipsImg} className="live-tips-img" alt="" />
        </Row>
        <Row>
          <Space className="w-full items-center" direction="vertical">
            <Typography.Text className="modal-title">
              {t("give-a-tip-to")} {userName}
            </Typography.Text>
          </Space>
        </Row>
        <Row>
          <Radio.Group
            className="w-full"
            value={amount}
            onChange={(e) => {
              setIsPersonalAmount(false);
              onChangeValue(e.target.value);
            }}
          >
            <Row gutter={8}>
              {amounts.map((amount, index) => {
                return (
                  <Col className="mt-3" key={index} span={6}>
                    <Radio.Button className="live-tips-radio-button" value={amount.value}>
                      {amount.label}
                    </Radio.Button>
                  </Col>
                );
              })}
            </Row>
          </Radio.Group>
        </Row>
        <Row>
          <Typography.Text className="personalized-amount-title">{t("personalized-amount")}</Typography.Text>
          <Row>
            <Space className="w-full gap-8" direction="vertical">
              <Row className="mt-5">
                {!showInput ? (
                  <div
                    className={amount ? "tipsInputCompleted" : "tipsInput"}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    {amount !== undefined && amount !== null ? amount : "0.00"} €
                  </div>
                ) : (
                  <InputNumber
                    className="tipsInputCompleted"
                    min={1}
                    bordered={false}
                    onChange={(value) => {
                      handleCustomPriceChange(value);
                    }}
                    value={amount}
                    autoFocus={true}
                    onBlur={() => {
                      setShowInput(false);
                    }}
                    inputMode="numeric"
                  />
                )}
              </Row>
            </Space>
          </Row>
        </Row>
        <Row className="row">
          <Button type="link" className={`actionBtn ${isButtonDisabled ? "disable" : ""}`} onClick={() => onSubmit()} disabled={isButtonDisabled}>
            {t("continue")}
          </Button>
        </Row>
        <Row className="row">
          <Button className="cancelBtn" type="link" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default LiveTipModalComponent;
