import React, {useEffect, useRef, useState} from "react";
import Player from "video.js/dist/types/player";
import videoJs from "video.js";
import { IAgoraTrack } from "../../helpers/types";
import "video.js/dist/video-js.min.css";
import "./index.less";

interface props extends IAgoraTrack {
  isBlur?: boolean,
  index?: number,
  sessionId?: string,
  isFromMobile: boolean
}
const options = {
  bigPlayButton: false,
  controls: false,
  autoPlay: true,
  playsInline: true,
  muted: true,
  controlBar: {
    playToggle: false,
    pictureInPictureToggle: false,
    volumePanel: false,
  },
}

const AgoraPlayerComponent: React.FC<props> = ({
 tracks,
 isBlur,
 index,
 sessionId,
 isFromMobile
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const ref = useRef<Player>();
  const [isRefUpdated, setIsRefUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!playerRef?.current)
      return;
    ref.current = videoJs(playerRef.current, options);
    setIsRefUpdated(true);
  }, [])
  
  useEffect(() => {
    if (!isRefUpdated || !ref?.current)
      return;

    if (tracks.length) {
      const mediaStreamTracks: MediaStreamTrack[] = tracks.map((track) =>  track.getMediaStreamTrack());           
      (ref.current.tech().el() as HTMLVideoElement).srcObject = new MediaStream(mediaStreamTracks);
      ref.current.tech_.el_.style.objectFit = isFromMobile ? 'cover' : 'contain';
    } else {
      (ref.current.tech().el() as HTMLVideoElement).srcObject = null;
      ref.current.tech_.el_.style.objectFit = 'contain';
    }
  }, [isRefUpdated, tracks])

  useEffect(() => {
    if (index !== undefined && !isNaN(index)) {
      const videoElement = document.getElementById(`video-js-${index}`);
      if (videoElement) {
        if (isBlur && !videoElement.classList.contains("blur"))
          videoElement.classList.add("blur");
        if (!isBlur && videoElement.classList.contains("blur"))
          videoElement.classList.remove("blur");
      }
    }
  }, [isBlur])

  return <video
    id={`video-js-${index}`}
    className={`vjs-matrix video-js vjs-fill`}
    ref={playerRef}
    autoPlay
    width="100%"
    height="100%"
    playsInline
    muted
  />;
};

export default AgoraPlayerComponent;