import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { authService } from "../../services";
import { addWatermark } from "../../utls/FunctionsUtil";
import VideoPlayer from "../VideoPlayerComponent";
import "./index.less";

type props = {
  shouldAddWatermark?: boolean;
  fileUrl: string;
  thumbnail: string;
  description: string;
  type: string;
  imageClassName: string;
  isPrivate: boolean;
  isMyProfile: boolean;
};

const FeedMediaComponent: React.FC<props> = ({
  shouldAddWatermark = true,
  fileUrl,
  thumbnail,
  description,
  type,
  imageClassName,
  isPrivate,
  isMyProfile,
}) => {
  const imgUrl = type === "video" ? thumbnail : fileUrl;
  const [watermarkedImageUrl, setWatermarkedImageUrl] = useState<string>(shouldAddWatermark && !isPrivate ? "" : imgUrl);

  useEffect(() => {
    if (shouldAddWatermark && !isPrivate && !isMyProfile) {
      addWatermark(imgUrl, authService.getUser().username)
        .then((newImageUrl) => {
          setWatermarkedImageUrl(newImageUrl);
        })
        .catch((err) => {
          console.log("Error generating watermarked image:", err);
          setWatermarkedImageUrl(imgUrl);
        });
    } else {
      setWatermarkedImageUrl(imgUrl);
    }
  }, [fileUrl, thumbnail, type, shouldAddWatermark, isPrivate, isMyProfile]);

  if (watermarkedImageUrl === "" && shouldAddWatermark && !isPrivate) {
    return (
      <div className="fhp-main-skelton">
        <Skeleton.Image className="fhp-skelton-bg" active style={{ width: "100%", height: 450 }} />
      </div>
    );
    /* return (
      <div className={"d-flex justify-content-center items-center h-full"}>
        <LoadingOutlined style={{ color: "white", fontSize: "100px" }} />
      </div>
    ); */
  }

  if (type === "video") {
    if (fileUrl !== "" && thumbnail !== "") {
      return <VideoPlayer videoUrl={fileUrl!} thumbnailUrl={watermarkedImageUrl!} />;
    }
  }

  return <img className={imageClassName} src={watermarkedImageUrl} alt={description} />;
};

export default FeedMediaComponent;
