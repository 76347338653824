import { Button, Modal, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PaymentFailed from "../../../assets/icons/fan/payment/payment-failed_resultat.webp";
import PaymentSuccessImg from "../../../assets/icons/fan/payment/payment-success_resultat.webp";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { subscriptionsService } from "../../../services";
import "./index.less";
import Cookies from "js-cookie";
import { decryptService } from "../../../services/decrypt.service";

const PaymentSuccess = () => {
  const [paymentStatus, setpaymentStatus] = useState<string>();
  // const [subscriptionData, setSubscriptionData] = useState<any>(null);
  const subscription_data: any = Cookies.get("subscription_data");
  const { t } = useTranslation();
  const navigate = useNavigate();
  if(!subscription_data){
    navigate("-1")
  }

  const verifySubscription = async () => {
    const subscriptionData = JSON.parse(subscription_data)
    const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const token: any = params.get("token");
    const liabilityShift: any = params.get("liabilityShift");
    if(subscriptionData.type === "decryptLink"){
      if (liabilityShift === "successful") {
        const res: any = await decryptService.create3dSecureTransaction(subscriptionData?.transactionId, token ,subscriptionData.email);
        if(res?.data?.transaction?.status === "success"){
          setTimeout(() => {
            navigate("/decrypt/unlocked", {
              state: {
                decryptId: subscriptionData.targetId,
                transactionId: subscriptionData.transactionId,
              },
            });
            Cookies.remove("subscription_data")
          }, 3500);
          setpaymentStatus("success");
        }else{
          setTimeout(() => {
            navigate(`/decrypt/payment/${subscriptionData?.decryptId}`);
            Cookies.remove("subscription_data")
          }, 3500);
          setpaymentStatus("error");
        }
      } else {
        setTimeout(() => {
          navigate(`/decrypt/payment/${subscriptionData?.decryptId}`);
          Cookies.remove("subscription_data")
        }, 3500);
        setpaymentStatus("error");
      }
    }
    else{
      if (liabilityShift === "successful") {
        sessionStorage.setItem("payment-page" , "True")
        const res: any = await subscriptionsService.create3dSecureTransaction(subscriptionData?.transactionId, token);
        if(res?.data?.transaction?.status === "success"){
          setTimeout(() => {
            navigate(`/p/${subscriptionData?.username}`);
            Cookies.remove("subscription_data")
          }, 3500);
          setpaymentStatus("success");
        }else{
          setTimeout(() => {
            navigate(`/common/payment?creatorId=${subscriptionData?.creatorId}&type=subscription&planId=${subscriptionData?.planId}&redirectUrl=`);
            Cookies.remove("subscription_data")
          }, 3500);
          setpaymentStatus("error");
        }
      } else {
        setTimeout(() => {
          navigate(`/common/payment?creatorId=${subscriptionData?.creatorId}&type=subscription&planId=${subscriptionData?.planId}&redirectUrl=`);
          Cookies.remove("subscription_data")
        }, 3500);
        setpaymentStatus("error");
      }
    }
  };

  useEffect(() => {
    verifySubscription();
  }, []);

  const PopUpClick = () => {
    setpaymentStatus("");
  };

  useEffect(() => {
    if (!!paymentStatus) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [paymentStatus]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="PaymentSuccess gradient-background">
        <Spin
          tip={<Typography.Text className="payment-title">{t("please-do-not-refresh-text")}</Typography.Text>}
          size="large"
          style={{ maxHeight: "100%" }}
          indicator={<SpinnerComponent />}
        ></Spin>
      </div>

      <Modal
        open={!!paymentStatus}
        onCancel={() => {
          PopUpClick();
          setpaymentStatus("");
        }}
        closable={false}
        footer={null}
        centered
        className="modalFanPayment"
      >
        {paymentStatus && paymentStatus !== "error" ? (
          <Row className="fanPaymentPopUp">
            <div>
              <img src={PaymentSuccessImg} alt="paymentSuccessTick" />
            </div>
            <Typography.Text className="paymentSuccessMessage">{t(`payment-confim`)}</Typography.Text>
            <Button onClick={PopUpClick} className="paymentSuccessPopupBtn">
              {t(`everthing-is-ok-lable`)}
            </Button>
          </Row>
        ) : (
          paymentStatus &&
          paymentStatus === "error" && (
            <Row className="fanPaymentPopUp">
              <div>
                <img src={PaymentFailed} alt="PaymentFailed" />
              </div>
              <Typography.Text className="paymentFailedMessage">{t(`payment-failed-lable`)}</Typography.Text>
              <Button onClick={PopUpClick} className="paymentFailedPopupBtn">
                {t(`try-again-lable`)}
              </Button>
            </Row>
          )
        )}
      </Modal>
    </>
  );
};

export default PaymentSuccess;
