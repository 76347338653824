import React, { ReactNode } from "react";
import { Radio, Row, Typography } from "antd";
import "./index.less";

type props = {
  name: string;
  value: string;
  suffix: ReactNode;
};

const FanPaymentRadioButtonComponent: React.FC<props> = ({
  name,
  value,
  suffix,
}) => {
  return (
    <Radio.Button className="fanPaymentRadioButton" value={value}>
      <Row className="w-full justify-content-between">
        <Typography.Text>{name}</Typography.Text>
        {suffix}
      </Row>
    </Radio.Button>
  );
};

export default FanPaymentRadioButtonComponent;
