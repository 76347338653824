import React from "react";
import { Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type TotalRevenueComponentProps = {
  totalRevenue?: number;
  title?: string;
  size?: string;
};

const TotalRevenueComponent: React.FC<TotalRevenueComponentProps> = ({ totalRevenue, title = "total-revenues", size = "small" }) => {
  const { t } = useTranslation();

  return (
    <Row className="outer-container">
      <Space className="gap-0 bordered-box" direction="vertical">
        <Typography.Text
          className={`text-white-color ${size === "small" ? "font-22-black-perf" : size === "medium" ? "font-32-black-perf" : "font-36-black-perf"}`}
        >
          {totalRevenue?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          €
        </Typography.Text>
        <Typography.Text
          className={`text-white-color ${size === "small" ? "font-10-regular" : size === "medium" ? "font-12-regular" : "font-14-regular"}`}
        >
          {t(title)}
        </Typography.Text>
      </Space>
    </Row>
  );
};

export default TotalRevenueComponent;
