import { Col, Divider, Modal, Row } from "antd";
import "./index.less";
import { useEffect } from "react";

type Props = {
  open: boolean;
  handleToggle: () => void;
  handleConfirm: () => void;
  confBtnMsg: string;
  canBtnMsg: string;
  bodyElement: any;
};

export const ConfirmationModal = (props: Props) => {
  const { open, handleConfirm, handleToggle, canBtnMsg, confBtnMsg, bodyElement } = props;

  const renderModalFooter = () => {
    return (
      <Row className="modal-footer-actions">
        <Col className="confirm-btn" onClick={handleConfirm}>
          <div className="confirm-btn-background">{confBtnMsg}</div>
        </Col>
        <Col className="cancel-btn" onClick={handleToggle}>
          {canBtnMsg}
        </Col>
      </Row>
    );
  };

  const renderModalBody = () => {
    return <div className="modal-content">{bodyElement}</div>;
  };

  useEffect(() => {
    if (open) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [open]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      open={open}
      centered
      className="confirm-modal-container"
      onOk={handleConfirm}
      onCancel={handleToggle}
      footer={renderModalFooter()}
      width={308}
    >
      {renderModalBody()}
    </Modal>
  );
};
