import type { RadioChangeEvent, UploadFile } from "antd";
import { Button, Col, Layout, Radio, Row, Space, Spin, Switch, Typography, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadIcon from "../../../assets/icons/new-upload.svg";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import DateTimePickerComponent from "../../../components/DateTimePIckerComponent";
import MessageTextAreaComponent from "../../../components/MessageTextAreaComponent";
import PriceInputComponent from "../../../components/PriceInputComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { LIVE_TYPES, MAX_PRICE, getLiveAccesses, getMediaAmounts } from "../../../helpers/constant";
import { ILive, IScheduleData } from "../../../helpers/types";
import { publishLive } from "../../../redux/lives/livesSlice";
import { AppDispatch } from "../../../redux/store";
import dateUtil from "../../../utls/DateUtil";
import { isMobile } from "../../../utls/FunctionsUtil";
import "./index.less";

const CreatorLivePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [live, setLive] = useState<ILive | null>(null);
  const [isPersonalAmount, setIsPersonalAmount] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const mediaAmounts = getMediaAmounts(t);
  const accesses = getLiveAccesses(t);
  const startOptions = [
    {
      label: t("start-now"),
      value: LIVE_TYPES.NOW,
    },
    {
      label: t("schedule"),
      value: LIVE_TYPES.SCHEDULE,
    },
  ];

  const onChangeItem = (field: keyof ILive, value: string | number | boolean | null) => {
    let temp = live;
    let amount = Number(value);
    temp = { ...temp, [field]: field === "amount" ? amount : value };
    setLive(temp);

    if (amount > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
    }
  };

  const onChangeScheduleData = (field: keyof IScheduleData, value: string | UploadFile[] | null) => {
    const temp: IScheduleData = live?.scheduleData ? live.scheduleData : { dateTime: null, message: null, media: null };
    if (field === "media") {
      if (value && typeof value !== "string" && value.length > 0) {
        temp[field] = value[0].originFileObj as any;
      }
    } else {
      if (typeof value === "string") {
        temp[field] = field === "dateTime" ? new Date(value as string).toString() : value;
      }
    }
    setLive({ ...live, scheduleData: temp });
  };

  function checkDateTime(dateInput: any) {
    let dateString = new Date(dateInput);
    let currentDate = new Date();
    if (dateString.toDateString() === currentDate.toDateString()) {
      let timeInput = new Date(live?.time); // Extract time part
      let hours = timeInput.getHours();
      let minutes = timeInput.getMinutes();
      if (
        hours > currentDate.getHours() ||
        (hours === currentDate.getHours() && minutes > currentDate.getMinutes()) ||
        (hours === currentDate.getHours() && minutes === currentDate.getMinutes())
      ) {
        return true;
      } else {
        message.warning(t("valid-time"));
        return false;
      }
    }
    return true;
  }

  function getCombineDate(date: any, time: any) {
    let timeInput = new Date(time);
    let dateInput = new Date(date);
    let hours = timeInput.getHours();
    let minutes = timeInput.getMinutes();
    const combineDateTime = new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate(), hours, minutes);
    return combineDateTime;
  }

  const onStartLive = async () => {
    if (!live?.access) return message.warning(t("live-access-empty-warning-message"));
    if (live?.amount === null || live?.amount === undefined) return message.warning(t("live-amount-empty-warning-message"));
    if (!live?.startType) return message.warning(t("live-start-type-empty-warning-message"));
    if (live.startType === LIVE_TYPES.SCHEDULE) {
      if (!live.date || !live.time) return message.warning(t("live-date-time-empty-warning-message"));
      if (!live?.scheduleData || !live?.scheduleData.media) return message.warning(t("live-media-empty-warning-message"));
      const combineDateTime = getCombineDate(live.date, live.time);
      const isCorrectDate = await checkDateTime(combineDateTime);
      if (!isCorrectDate) return;
    }
    if (live.amount > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
      return;
    }

    setLoading(true);
    let liveFormData = new FormData();
    liveFormData.append("access", live.access);
    liveFormData.append("amount", live.amount.toString());
    liveFormData.append("isChatSubscribers", (live.isChatSubscribers as any) || false);
    liveFormData.append("isChatAll", (live.isChatAll as any) || false);
    liveFormData.append("isFreeForSubscribers", (live.isFreeForSubscribers as any) || false);
    liveFormData.append("startType", live.startType);
    liveFormData.append("isFromMobile", isMobile() ? "true" : "false");
    if (live.scheduleData && live.startType === LIVE_TYPES.SCHEDULE) {
      const dateTime: any = getCombineDate(live.date, live.time);
      liveFormData.append("scheduleData[dateTime]", dateTime);
      live.scheduleData.dateTime = dateTime;
      liveFormData.append("scheduleData[message]", live.scheduleData.message || "");
      liveFormData.append("scheduleMedia", live.scheduleData.media as any);
    }
    const resp: any = await dispatch(publishLive(liveFormData));
    setLoading(false);
    if (resp.meta.requestStatus === "fulfilled" && resp.payload._id) {
      if (resp.payload.startType === LIVE_TYPES.NOW) {
        message.success(t("live-starting-success-message"));
        navigate(`/creator/live/publish/${resp.payload._id}`);
      } else if (resp.payload.startType === LIVE_TYPES.SCHEDULE) {
        message.success(t("live-scheduled-success-message"));
        navigate("/creator/profile");
      }
    } else {
      if (live.startType === LIVE_TYPES.NOW && resp.error.message === "live-daily-quota-consumed") {
        message.error(t(resp.error.message));
      }
      if (live.startType === LIVE_TYPES.SCHEDULE && resp.error.message === "programmed-live-already-exist") {
        message.error(t(resp.error.message));
      }
    }
  };

  const dateTimeChange = (field: string, data: string) => {
    if (field === "date") {
      let selectedDate = new Date(data).toString();
      setLive({ ...live, date: selectedDate });
    }
    if (field === "time") {
      let selectedTime = new Date(data).toString();
      setLive({ ...live, time: selectedTime });
    }
  };

  const isFormValidNow = () => {
    return live?.access && live?.amount !== null && live?.amount !== undefined;
  };

  const isFormValidSchedule = () => {
    return (
      live?.access &&
      live?.amount !== null &&
      live?.amount !== undefined &&
      live?.startType === LIVE_TYPES.SCHEDULE &&
      live?.date &&
      live?.time &&
      live?.scheduleData?.media
    );
  };

  const isFormValid = () => {
    if (live?.startType === LIVE_TYPES.NOW) {
      return isFormValidNow();
    } else if (live?.startType === LIVE_TYPES.SCHEDULE) {
      return isFormValidSchedule();
    }
    return false;
  };

  const handleButtonClick = (commentsType: "isChatSubscribers" | "isChatAll") => {
    const isChatSubscribersValue = commentsType === "isChatSubscribers"
      ? live?.isChatSubscribers === undefined || !live?.isChatSubscribers
      : false;

    const isChatAllValue = commentsType === "isChatAll"
      ? live?.isChatAll === undefined || !live?.isChatAll
      : false;

    setLive((prevState) => {
      const newState = {
        ...prevState,
        isChatSubscribers: isChatSubscribersValue,
        isChatAll: isChatAllValue,
      };
      return newState;
    });
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout>
        <Layout.Content className="content creatorLiveContentWrapper gradient-background">
          <Row className="relative justify-content-center header-title-container-60">
            <ArrowLeftIconComponent top={10} left={-4} />
            <Typography.Text className="header-title-black-35">{t("live")}</Typography.Text>
          </Row>
          <Space className="w-full gap-24 mt-10" direction="vertical">
            <Row>
              <Space className="w-full gap-9" direction="vertical">
                <Typography.Text className="font-30-bold text-white-color">{t("access")}</Typography.Text>
                <Radio.Group className="w-full" buttonStyle="solid" value={live?.access} onChange={(e) => onChangeItem("access", e.target.value)}>
                  <Row className="w-full" gutter={7}>
                    {accesses.map((access, index) => {
                      return (
                        <Col key={index} span={12}>
                          <Radio.Button className="targetRadioButtonLive" value={access.value}>
                            {access.label}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Space>
            </Row>
            <Row className="mt-10">
              <Space className="w-full gap-9" direction="vertical">
                <PriceInputComponent
                  customPrice={live?.amount}
                  handleCustomPriceChange={(value) => {
                    setIsPersonalAmount(true);
                    onChangeItem("amount", Number(value));
                  }}
                />
                <Radio.Group
                  className="w-full"
                  value={live?.amount?.toString()}
                  onChange={(e) => {
                    setIsPersonalAmount(false);
                    onChangeItem("amount", e.target.value);
                  }}
                >
                  <Row gutter={7}>
                    {mediaAmounts.map((amount, index) => {
                      return (
                        <Col className="mt-3" key={index} span={6}>
                          <Radio.Button className="mediaAmountRadioButton mediaPush" value={amount.value}>
                            {amount.label}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Space>
            </Row>

            {live && live.access !== accesses[1].value && live.amount !== null && live.amount !== undefined && live.amount > 0 && (
              <Row>
                <Space className="w-full gap-3" direction="vertical">
                  <Row className="justify-content-between" align="middle">
                    <Typography.Text className="font-18-bold text-white-color">{t("free-for-subscribers")}</Typography.Text>
                    <Switch
                      className="switch revenueSwitch"
                      checked={live?.isFreeForSubscribers}
                      onChange={(checked) => onChangeItem("isFreeForSubscribers", checked)}
                    />
                  </Row>
                </Space>
              </Row>
            )}

            <Row className="mt-10">
              <Space className="w-full gap-9" direction="vertical">
                <Typography.Text className="font-30-bold text-white-color">{t("chat")}</Typography.Text>
                <Row gutter={15}>
                  <Col span={8}>
                    <Button
                      className={`chatButtonLive ${live?.isChatSubscribers ? "active" : ""}`}
                      onClick={() => handleButtonClick("isChatSubscribers")}
                    >
                      {t("subscribers")}
                    </Button>
                  </Col>
                  {live?.access !== accesses[1].value && (
                    <Col span={10}>
                      <Button className={`chatButtonLive ${live?.isChatAll ? "active" : ""}`} onClick={() => handleButtonClick("isChatAll")}>
                        {t("everyone")}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Space>
            </Row>
          </Space>
          <Space className="w-full mt-40 gap-23" direction="vertical">
            <Typography.Text className="font-30-bold text-white-color">{t("when")}</Typography.Text>
            <Radio.Group className="w-full" value={live?.startType} onChange={(e: RadioChangeEvent) => onChangeItem("startType", e.target.value)}>
              <Row gutter={7}>
                {startOptions.map((option, index) => {
                  return (
                    <Col key={index} span={12}>
                      <Radio.Button className="targetRadioButtonLive when" value={option.value}>
                        {option.label}
                      </Radio.Button>
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
            {live?.startType === startOptions[1].value && (
              <DateTimePickerComponent
                date={live?.scheduleData?.dateTime || null}
                disabledDate={dateUtil.isPastDate}
                disabledTime={() => dateUtil.isPastTime(live.date)}
                onChange={dateTimeChange}
              />
            )}
          </Space>
          {live?.startType === startOptions[1].value && (
            <Space className="w-full gap-18 mt-30" direction="vertical">
              <AddMediaComponent
                isVideoAllowed={false}
                crop={true}
                title={t("coverage").toString()}
                maxCount={1}
                onFilesChange={(fileList) => onChangeScheduleData("media", fileList)}
                greenCircle={true}
                iconSize={40}
              >
                <img src={UploadIcon} width={100} alt="" />
              </AddMediaComponent>
              <Row className="mt-10">
                <MessageTextAreaComponent
                  title={t("description").toString()}
                  value={live?.scheduleData?.message || null}
                  handleChange={(e) => onChangeScheduleData("message", e.target.value)}
                />
              </Row>
            </Space>
          )}
          <Row className="mt-30">
            <Button disabled={!isFormValid()} className="validateButton" onClick={onStartLive}>
              {t("register")}
            </Button>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorLivePage;
