import React from "react";
import { Divider } from "antd";
import { DefaultTFuncReturn } from "i18next";
import "./index.less";

type props = {
  text?: string | DefaultTFuncReturn;
};

const AuthOrDividerComponent: React.FC<props> = ({ text }) => {
  return (
    <Divider className="authOrDivider" plain>
      {text}
    </Divider>
  );
};

export default AuthOrDividerComponent;
