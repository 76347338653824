import { Col, Layout, Radio, Row, Space, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import FooterComponent from "../../../components/FooterComponent";
import InputCompnent from "../../../components/InputComponent";
import {
  FOOTER_IDS,
  PRMOTION_EXPIRATION_TIME_TYPE,
  PROMOTION_DISCOUNT_TYPE,
  PROMOTION_PROMO_ON,
  getDiscountExpirePeriods,
  getDiscountTypes,
} from "../../../helpers/constant";
import { IPromotion } from "../../../helpers/types";
import { promotionService } from "../../../services";
import "./index.less";

const CreatorCodePromotionCreate = () => {
  const { t } = useTranslation();
  const [promotion, setPromotion] = useState<IPromotion | null>(null);
  const [expirationValue, setExpirationValue] = useState<string | null>(null);
  const navigate = useNavigate();

  const discountTypes = getDiscountTypes(t);
  const discountExpirePeriods = getDiscountExpirePeriods(t);

  const codeChanged = (value: string) => {
    const tempPromotion = { ...promotion };
    tempPromotion.code = value;
    setPromotion(tempPromotion);
  };

  const discountChanged = (value: string | number, type: string) => {
    let numericValue = typeof value === "string" ? parseInt(value) : value;

    if (type === PROMOTION_DISCOUNT_TYPE.percentage) {
      if (!isNaN(numericValue)) {
        if (numericValue > 100) {
          numericValue = 100;
        } else if (numericValue < 0) {
          numericValue = 0;
        }
      } else {
        numericValue = 0;
      }
    }

    const tempPromotion = { ...promotion };
    tempPromotion.discountType = type;
    tempPromotion.discount = numericValue;
    setPromotion(tempPromotion);
  };

  const promoOnChanged = (value: string) => {
    const tempPromotion = { ...promotion };
    tempPromotion.promoOn = value;
    setPromotion(tempPromotion);
  };

  const expireTimeChanged = (value: string) => {
    const tempPromotion = { ...promotion };

    if (value.includes(t("hours"))) {
      tempPromotion.expireTimeType = PRMOTION_EXPIRATION_TIME_TYPE.hours;
    } else if (value.includes(t("days"))) {
      tempPromotion.expireTimeType = PRMOTION_EXPIRATION_TIME_TYPE.days;
    } else if (value.includes(t("unlimited"))) {
      tempPromotion.expireTimeType = PRMOTION_EXPIRATION_TIME_TYPE.unlimited;
    }

    if (tempPromotion.expireTimeType) {
      tempPromotion.expireTime = Number(value.replace(/\D/g, ""));
    } else {
      tempPromotion.expireTime = null;
    }

    setPromotion(tempPromotion);
  };

  const createClicked = () => {
    if (!promotion) return message.warning(t("promo-details-empty-message"));
    if (!promotion?.code) return message.warning(t("promo-code-empty-message"));
    if (!promotion?.discount || !promotion.discountType) return message.warning(t("promo-discount-empty-message"));
    if (!promotion?.promoOn) return message.warning(t("promo-on-empty-message"));
    if (!promotion?.expireTimeType) return message.warning(t("promo-exp-time-empty-message"));

    promotionService
      .create(promotion)
      .then(() => {
        message.success(t("promo-code-created-success-message"), 5);
        navigate("/creator/codePromotions");
        setPromotion(null);
      })
      .catch((error) => message.error(t(error), 10));
  };

  useEffect(() => {
    let expirationValue = null;
    if (promotion) {
      if (promotion.expireTimeType === PRMOTION_EXPIRATION_TIME_TYPE.unlimited) expirationValue = t("unlimited");
      else if (promotion.expireTimeType === PRMOTION_EXPIRATION_TIME_TYPE.hours && promotion.expireTime)
        expirationValue = promotion.expireTime.toString() + " " + t("hours");
      else if (promotion.expireTimeType === PRMOTION_EXPIRATION_TIME_TYPE.days && promotion.expireTime)
        expirationValue = promotion.expireTime.toString() + " " + t("days");
      else expirationValue = null;
    }
    setExpirationValue(expirationValue);
  }, [promotion]);

  return (
    <Layout>
      <Layout.Content className="content creatorCodePromotionContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent left={0} top={10} />
          <Typography.Text className="header-title-black-35">{t("promo-code")}</Typography.Text>
        </Row>
        <Row className="pr-6 mt-20">
          <Space className="w-full gap-25" direction="vertical">
            <Row>
              <Space className="w-full" direction="vertical">
                <Typography.Text className="font-28-bold text-white-color">{t("code")}</Typography.Text>
                <InputCompnent
                  className="darkInput createPromoCode"
                  type="text"
                  placeholder="EX : AB123"
                  value={promotion?.code || ""}
                  onChange={(e) => {
                    codeChanged(e.target.value);
                  }}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full" direction="vertical">
                <Typography.Text className="font-28-bold text-white-color">{t("discount")}</Typography.Text>
                <Row>
                  <Col span={10}>
                    <InputCompnent
                      className={
                        promotion?.discountType === PROMOTION_DISCOUNT_TYPE.percentage
                          ? "mediumGreenInput createPromoCode"
                          : "darkInput createPromoCode"
                      }
                      type="text"
                      suffix="%"
                      value={promotion?.discountType === PROMOTION_DISCOUNT_TYPE.percentage && promotion?.discount ? promotion?.discount : ""}
                      onChange={(e) => discountChanged(e.target.value, PROMOTION_DISCOUNT_TYPE.percentage)}
                      inputMode="numeric"
                    />
                  </Col>
                  <Col className="d-flex items-center justify-content-center" span={4}>
                    <Typography.Text className="font-20-bold text-white-color">{t("auth-signUp-divider")}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <InputCompnent
                      className={
                        promotion?.discountType === PROMOTION_DISCOUNT_TYPE.currency
                          ? "mediumGreenInput createPromoCode"
                          : "darkInput createPromoCode"
                      }
                      type="text"
                      suffix="€"
                      value={promotion?.discountType === PROMOTION_DISCOUNT_TYPE.currency && promotion?.discount ? promotion?.discount : ""}
                      onChange={(e) => discountChanged(e.target.value, PROMOTION_DISCOUNT_TYPE.currency)}
                      inputMode="numeric"
                    />
                  </Col>
                </Row>
              </Space>
            </Row>
            <Row>
              <Space className="w-full" direction="vertical">
                <Typography.Text className="font-28-bold text-white-color">{t("discount-on")}</Typography.Text>
                <Radio.Group
                  className="w-full"
                  buttonStyle="solid"
                  value={promotion?.promoOn}
                  onChange={(e) => {
                    promoOnChanged(e.target.value);
                  }}
                >
                  <Row gutter={6}>
                    {Object.keys(PROMOTION_PROMO_ON).map((key, index) => {
                      return (
                        <Col key={index} span={10}>
                          <Radio.Button
                            className="mediaAmountRadioButton createCodePromo"
                            value={PROMOTION_PROMO_ON[key as keyof typeof PROMOTION_PROMO_ON]}
                          >
                            {discountTypes[key as keyof typeof PROMOTION_PROMO_ON]}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Space>
            </Row>
            <Row>
              <Space direction="vertical">
                <Typography.Text className="font-28-bold text-white-color">{t("duration-of-validity")}</Typography.Text>
                <Radio.Group value={expirationValue} onChange={(e) => expireTimeChanged(e.target.value)}>
                  <Row gutter={10}>
                    {discountExpirePeriods.map((period, index) => {
                      return (
                        <Col className="mt-3" key={index} span={8}>
                          <Radio.Button className="mediaAmountRadioButton createCodePromo" value={period.label}>
                            {period.label}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Space>
            </Row>
          </Space>
        </Row>
        <Row className="mt-50">
          <AuthButtonComponent label={t("create")} onClick={createClicked} />
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorCodePromotionCreate;
