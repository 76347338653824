import React, { ChangeEventHandler } from "react";
import { Input, Image } from "antd";
import SearchIcon from "../../assets/icons/search.svg";
import "./index.less";

type props = {
  placeholder: string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  onClick?: any;
  ref?: any;
};

const SearchComponent: React.FC<props> = ({ placeholder, handleChange, value, onClick, ref }) => {
  return (
    <Input
      ref={ref}
      className="searchWrapper"
      bordered={false}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      onClick={onClick}
      prefix={<Image src={SearchIcon} alt="search" preview={false} />}
    />
  );
};

export default SearchComponent;
