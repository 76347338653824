import React from "react";
import "./index.less";

type props = {
  icon?: string;
  label: string;
  isNew?: boolean;
  isActive?: boolean;
};

const MessageTabLabelComponent: React.FC<props> = ({ icon, label, isNew, isActive = false }) => {
  return (
    <div className="messageLabelWrapper">
      <img src={icon} />
      <div>{label}</div>
      {!isActive && isNew && <div className="messageNewIcon" />}
    </div>
  );
};

export default MessageTabLabelComponent;
