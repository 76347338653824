import { Button, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type props = {
  fanSubscriptionModel?: any;
  modifySubscriptionPlan?: any;
  setFanSubscriptionModel?: any;
  unSubscribe?: any;
  isCreator?: any;
};

const FanSubscriptionPageModel: React.FC<props> = ({
  fanSubscriptionModel,
  modifySubscriptionPlan,
  setFanSubscriptionModel,
  unSubscribe,
  isCreator,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //
  const convertdate = (date: string) => {
    const createdAt = new Date(date);
    const formattedDate = createdAt
      .toLocaleDateString(`${t("current-language")}`, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
    return formattedDate;
  };

  useEffect(() => {
    if (fanSubscriptionModel) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [fanSubscriptionModel]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };
  return (
    <div>
      <Modal
        className="FanSubscriptionPageModel"
        title={modifySubscriptionPlan.creatorInfo.name}
        open={fanSubscriptionModel}
        centered
        closable={false}
        onCancel={() => setFanSubscriptionModel(false)}
        footer={
          <>
            <Row className="justify-center">
              <Typography.Text className="fanSubscriptionValid w-100">
                {modifySubscriptionPlan.status === "active" ? (
                  <>
                    {t("subscribed-until")}
                    <br />
                    {convertdate(`${modifySubscriptionPlan?.expiredAt}`)}
                  </>
                ) : (
                  t("subscribe-for-an-exclusive-relationship")
                )}
              </Typography.Text>
              {/* <Typography.Text className="fanSubscriptionStatus">
                {t(`renewal-label`)} {modifySubscriptionPlan?.status === "active" ? t("active-label") : t("disabled-label")}
              </Typography.Text> */}
              {modifySubscriptionPlan?.status !== "active" && (
                <Typography.Text className="fanSubscriptionValidStatus w-100">
                  {t("subscription-valid")} {convertdate(`${modifySubscriptionPlan?.expiredAt}`)}
                </Typography.Text>
              )}
            </Row>
            <div className="w-full">
              <Row className={`row ${modifySubscriptionPlan.status !== "active" ? "p-0" : ""}`}>
                {modifySubscriptionPlan.status === "active" ? (
                  <Button onClick={() => unSubscribe(modifySubscriptionPlan.creatorInfo.id)} type="link" className="CancelBtn">
                    <div className="confirm-btn-background">{t("cancel-renewal-label")}</div>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      isCreator
                        ? navigate(`/common/subscribe/${modifySubscriptionPlan?.creatorInfo?.id}`)
                        : navigate(`/fan/subscribe/${modifySubscriptionPlan?.creatorInfo?.id}`);
                    }}
                    type="link"
                    className="SubscriptionExtendBtn"
                  >
                    <div className="confirm-btn-background without-obligation">{t("Subscribe-without-obligation")}</div>
                  </Button>
                )}
              </Row>
              <Row className="row">
                <Button className="closeBtn" type="link" onClick={() => setFanSubscriptionModel(false)}>
                  {t("cancel")}
                </Button>
              </Row>
            </div>
          </>
        }
      />
    </div>
  );
};

export default FanSubscriptionPageModel;
