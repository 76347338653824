import { Layout, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FILE_STATUS, THUMBNAIL_TYPES } from "../../helpers/constant";
import { getBookmarkFeeds } from "../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import { authService } from "../../services";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import MainSwiperSlideItemV2 from "../MainSwiperSlideItemV2/MainSwiperSlideItemV2";
import { FanHomePageSkelton } from "../SkeltonsComponents/FanHomePageSkelton";
import "./index.less";

const FanBookmarkFeedDetailsComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { id } = useParams();
  const shouldFetch = useRef(true);
  const targetRef = useRef<HTMLDivElement>(null);
  const { bookmarkFeeds: bookmarks, loadingBookmarkFeeds: isLoading } = useSelector((state: StoreState) => state.feeds);
  //const [bookmarks, setBookmarks] = useState<IFeed[]>([]);
  //const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (bookmarks.length === 0) {
        dispatch(getBookmarkFeeds());
      }
      //getBookmarksFeeds();
    }
  }, [id]);

  useEffect(() => {
    if (bookmarks.length > 0 && targetRef.current !== null) {
      targetRef.current.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
    }
  }, [bookmarks]);

  /* async function getBookmarksFeeds() {
    setIsLoading(true);
    await reactionService.BookmarksFeeds().then((data) => {
      setBookmarks(data);
      setIsLoading(false);
    });
  } */

  return (
    <Layout>
      <Layout.Header className="header">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent />
          <Typography.Text className="header-title">{t("bookmarks")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content fanBookmarksFeedDetailsContentWrapper">
        {isLoading === "pending" ? (
          <FanHomePageSkelton loading={isLoading === "pending"} />
        ) : (
          <div className="feed-content-container">
            {bookmarks?.map((feed, index) => {
              let thumbnail = "";
              let fileUrl = "";
              let isUploading: boolean = false;
              if (feed.files !== undefined) {
                isUploading = feed.files[0].status === FILE_STATUS.UPLOADING;
                if (!feed.isPrivateForCurrentUser) {
                  fileUrl = feed.files[0].url!;
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath!;
                } else {
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath!;
                  fileUrl = thumbnail;
                }
              }
              return (
                <MainSwiperSlideItemV2
                  refProp={id === feed._id ? targetRef : null}
                  feedId={feed._id!}
                  key={index}
                  name={feed.creatorInfo?.name!}
                  username={feed.creatorInfo?.username!}
                  avatar={feed?.creatorInfo?.avatar!}
                  creatorId={feed?.sourceId}
                  totalLikes={feed?.totalLike}
                  fileUrl={fileUrl}
                  thumbnail={thumbnail}
                  isLiked={feed?.isLiked}
                  isBookMarked={feed?.isBookMarked}
                  isPrivate={feed?.isPrivateForCurrentUser}
                  isCurrentUser={feed.creatorInfo?._id === authService.getId()}
                  type={feed.type!}
                  description={feed.text!}
                  isUploading={isUploading}
                />
              );
            })}
          </div>
        )}
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default FanBookmarkFeedDetailsComponent;
