import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

const useUserOnlineHook: (userId?: string) => boolean = (userId) => {
  const { onlineUsers } = useSelector((state: StoreState) => state.messages);
  const [isOnline, setIsOnline] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(onlineUsers)) {
      const index = onlineUsers.findIndex((id) => id === userId);
      if (index !== -1) {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
    }
  }, [onlineUsers, userId]);

  return isOnline;
};

export default useUserOnlineHook;
