import { useParams } from "react-router-dom";
import { authService } from "../../../services";
import ProfilePage from "./ProfilePage";
import PublicProfilePage from "./PublicProfilePage";

function ProfilePageWrapper() {
  const { username } = useParams();

  if (authService.isAuthenticated() && username) {
    return <ProfilePage />;
  } else {
    return <PublicProfilePage />;
  }
}

export default ProfilePageWrapper;
