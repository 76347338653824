import NumberOne from "../assets/icons/numbers/1.svg";
import NumberTwo from "../assets/icons/numbers/2.svg";
import NumberThree from "../assets/icons/numbers/3.svg";
import NumberFour from "../assets/icons/numbers/4.svg";
import NumberFive from "../assets/icons/numbers/5.svg";
import NumberSix from "../assets/icons/numbers/6.svg";
import NumberSeven from "../assets/icons/numbers/7.svg";
import NumberEight from "../assets/icons/numbers/8.svg";
import NumberNine from "../assets/icons/numbers/9.svg";
import NumberTen from "../assets/icons/numbers/10.svg";
import NumberEleven from "../assets/icons/numbers/11.svg";
import NumberTwelve from "../assets/icons/numbers/12.svg";
import NumberThirteen from "../assets/icons/numbers/13.svg";
import NumberFourteen from "../assets/icons/numbers/14.svg";
import NumberFifteen from "../assets/icons/numbers/15.svg";
import NumberSixteen from "../assets/icons/numbers/16.svg";
import NumberSeventeen from "../assets/icons/numbers/17.svg";
import NumberEighteen from "../assets/icons/numbers/18.svg";
import NumberNineteen from "../assets/icons/numbers/19.svg";

const numberIcons = [
  NumberOne,
  NumberTwo,
  NumberThree,
  NumberFour,
  NumberFive,
  NumberSix,
  NumberSeven,
  NumberEight,
  NumberNine,
  NumberTen,
  NumberEleven,
  NumberTwelve,
  NumberThirteen,
  NumberFourteen,
  NumberFifteen,
  NumberSixteen,
  NumberSeventeen,
  NumberEighteen,
  NumberNineteen,
];

export default numberIcons;
