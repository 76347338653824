import { Button, Layout, Row, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SelectedIcon from "../../../assets/icons/selected.svg";
import UnselectIcon from "../../../assets/icons/unselect.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import SavedCards from "../../../components/SaveCards/SaveCards";
import { SavedCardServices } from "../../../services/savedCard.service";
import RevoCard from "../../../assets/images/revo_card.webp";
import "./index.less";
import { settingService } from "../../../services/settings.service";

const SavedCardsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allCards, setAllCards] = useState<any>([]);

  useEffect(() => {
    getCurrentPaymentGateway()
    getAllCards();
  }, []);

  const getAllCards = async () => {
    await SavedCardServices.allSavedCards()
      .then((res: any) => {
        setAllCards(res?.data?.cards || []);
      })
      .catch((e: any) => {
        console.log(e);
        message.error(t("something-want-wrong"));
      });
  };

  const getCurrentPaymentGateway = async () => {
    await settingService.getPayentGateway().then((res: any) => {
      if(res?.paymentGateway === "stripe"){
        navigate("/fan/home")
      }
    });
  };

  const handleDelete = async (id: any) => {
    await SavedCardServices.deleteSavedCard(id)
      .then((res: any) => {
        if (res.status === 0) {
          const newData = allCards?.filter((item: any) => item?.id !== id);
          setAllCards(newData);
        } else {
          message.error(t("something-want-wrong"));
        }
      })
      .catch((e: any) => {
        console.log(e);
        message.error(t("something-want-wrong"));
      });
  };

  const bankAccountConvert = (value: any) => {
    const firstChar = value.substring(0, 2);
    return `${firstChar + "** **** **** **** "}${value.slice(-3)}`;
  };

  function formatCardNumber(first6 : any, last4 : any) {
    const hiddenSection = ' * '.repeat(6);
    
    // Combine the parts and ensure the output is exactly 16 characters long
    return `${first6}${hiddenSection}${last4}`;
}

  return (
    <Layout className="savedCardWrapper gradient-background ">
      <Layout.Header className="header savedCardbg">
        <Row className="relative justify-content-center">
          <ArrowLeftIconComponent left={0} />
          <Typography.Text className="header-title-black-24">{t("saved-cards")}</Typography.Text>
        </Row>
      </Layout.Header>
      <Layout.Content className="content creatorPaymentMethodContentWrapper ">
        <Row className="relative">
          <Typography.Text className="font-36-bold text-white-color">{t("efficient-payment")}</Typography.Text>
        </Row>

        <Row>
          <Space className="w-full gap-21" direction="vertical">
            {allCards?.length !== 0 ? (
              allCards?.map((item: any, index: React.Key) => {
                return (
                  <Row>
                    <Row className="w-full justify-content-center">
                      <Row className="save-card-image-container">
                        <img src={RevoCard} alt="RevoCard" className="responsive-image" />
                        <Row className="save-card-card-overlay">
                          <Row className="save-card-top-left">
                            <Typography.Text className="title-save-card text-gradient-color">{t("registered-card")}</Typography.Text>
                          </Row>
                          <Row className="save-card-top-right">
                            <Button className="delete-button" size="small" onClick={()=>handleDelete(item?.id)}>
                              {t("Delete")}
                            </Button>
                          </Row>
                          <Row className="save-card-bottom-left">
                            <Typography.Text className="username-save-card text-gradient-color">{item?.cardholderName}</Typography.Text>
                            <Typography.Text className="info-save-card text-gradient-color">{formatCardNumber(item?.first6 , item?.last4)}</Typography.Text>
                          </Row>
                        </Row>
                      </Row>
                    </Row>
                  </Row>
                  // <Row key={index} className="relative">
                  //   <SavedCards data={item} totalData={allCards?.length} className="gradient-background" isDelete onDelete={handleDelete} />
                  // </Row>
                );
              })
            ) : (
              <Row>
                <Typography.Text className="relative font-24-bold text-white mx-auto mt-40 mb-20">{t("no-card-found")}</Typography.Text>
              </Row>
            )}
          </Space>
        </Row>
        <Row className="mt-49">
          <Button className="whiteButton font-15-bold pt-14 pb-14 br-23-5" onClick={() => navigate("/common/saveNewCard")}>
            {t("add-card")}
          </Button>
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default SavedCardsPage;
