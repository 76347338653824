import { Layout, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PerformanceImage from "../../../assets/images/performance-image.webp";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FooterComponent from "../../../components/FooterComponent";
import MediaComponent from "../../../components/MediaComponent";
import TotalRevenueComponent from "../../../components/TotalRevenueComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import { mediapushService } from "../../../services";
import "./index.less";

const CreatorPerformancePage = () => {
  const { t } = useTranslation();
  const { mediaPushId } = useParams();
  const [mediaPushHistory, setMediaPushHistory] = useState<any>([]);
  const [singleMediaPushHistory, setSingleMediaPushHistory] = useState<any>({});
  const [mediaPushDetails, setMediaPushDetails] = useState<any>({});

  useEffect(() => {
    mediapushService
      .mediaPushHistory()
      .then((response) => {
        setMediaPushHistory(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    mediaPushHistory &&
      mediaPushHistory.filter(
        (item: {
          mediaPushId: string;
          createdAt: string;
          sale: number;
          totalSend: number;
          totalEarn: number;
          // eslint-disable-next-line array-callback-return
        }) => {
          if (item.mediaPushId === mediaPushId) {
            setSingleMediaPushHistory(item);
          }
        }
      );
  }, [mediaPushHistory, mediaPushId]);

  useEffect(() => {
    mediapushService
      .getMediaPush(mediaPushId)
      .then((response) => {
        setMediaPushDetails(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [mediaPushId]);

  return (
    <Layout>
      <Layout.Content className="content creatorPerformanceContentWrapper gradient-background-blue-settings">
        <Row className="relative justify-content-center header-title-container-80">
          <ArrowLeftIconComponent top={12} />
          <Typography.Text className="header-title-black-35">{t("performance")}</Typography.Text>
        </Row>
        <Row className="mt-20">
          <Space className="w-full gap-5" direction="vertical">
            <Row>
              <Typography.Text className="header-title-black-32">{t("statistics")}</Typography.Text>
            </Row>
            <Space className="price-component gap-20">
              <TotalRevenueComponent totalRevenue={singleMediaPushHistory.totalEarn} size="large" title="income-generated" />
              <TotalRevenueComponent totalRevenue={mediaPushDetails.price} size="small" title="price-per-unit" />
            </Space>
          </Space>
        </Row>
        <Row className="mt-20">
          <Space className="w-full gap-8" direction="vertical">
            <Row>
              <Typography.Text className="header-title-black-32">{t("summary-of-the-push")}</Typography.Text>
            </Row>
            <Row className="text-border">
              <Typography.Text className="max-w-206 font-15-light text-white-color">{mediaPushDetails.text}</Typography.Text>
            </Row>
            <Row>
              <Space className="mt-10 gap-5">
                {mediaPushDetails.fileIds &&
                  mediaPushDetails.fileIds.map((item: any, index: number) => {
                    return (
                      <MediaComponent
                        key={index}
                        image={item.url}
                        width={100}
                        height={100}
                        borderRadius={10}
                        username=""
                        shouldAddWatermark={false}
                      />
                    );
                  })}
              </Space>
            </Row>
          </Space>
        </Row>
        <Row className="centered-image">
          <img src={PerformanceImage} alt="performance" />
        </Row>
      </Layout.Content>
      <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} />
    </Layout>
  );
};

export default CreatorPerformancePage;
