import axios from "../helpers/axios";
import axiosApi from "axios";
import { IDecryptDetailsDTO, IDecryptDTO, PageableData } from "../helpers/types";
import { authService } from "./auth.service";

class DecryptService {
  /* public createDecrypt(data: FormData , setProgress: Function) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/unlock-link`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total!));
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  } */

  public createDecrypt(data: FormData, setProgress: Function) {
    return new Promise<any>((resolve, reject) => {
      let slowProgressInterval: any;

      axios
        .post(`/unlock-link`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            let progress = Math.round((100 * event.loaded) / event.total!);
            console.log("progress", progress);
            if (progress < 50) {
              setProgress(progress);
            } else {
              slowProgressInterval = setInterval(() => {
                setProgress((prevProgress: number) => {
                  if (prevProgress < 99) {
                    return prevProgress + 1;
                  }
                  return prevProgress;
                });
              }, 2000);
            }
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            setProgress(100);
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          reject(error.response?.data?.message || "Something went wrong.");
        })
        .finally(() => {
          clearInterval(slowProgressInterval);
        });
    });
  }

  public getDecrypts() {
    return new Promise<PageableData<IDecryptDTO>>((resolve, reject) => {
      axios
        .get(`/unlock-link/search?creatorId=${authService.getId()}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getDecryptDetails(id: string) {
    return new Promise<IDecryptDetailsDTO>((resolve, reject) => {
      axios
        .get(`/unlock-link/details/${id}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getDecryptInfos(slug: string | null, fromPayment?: boolean) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/unlock-link/${slug}?fromPayment=${fromPayment}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public expireLink(id: string) {
    return new Promise<IDecryptDTO>((resolve, reject) => {
      axios
        .put(`/unlock-link/expires/${id}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public purchaseMedia(payload: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/payment/stripe/unlock-link`, payload)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {            
            resolve(response.data);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getLink(payload: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/unlock-link/url/`, payload)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public applePayPurchaseMedia(payload: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/payment/applePay/unlock-link`, payload)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public editTitle(titleObject: { title: string }, id: string) {
    return new Promise<IDecryptDTO>((resolve, reject) => {
      axios
        .put(`unlock-link/changeTitle/${id}`, titleObject)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public create3dSecureTransaction = (transactionId: any , token : any , email : any) => {
    return new Promise<any>((resolve, reject) => {
      axiosApi
        .post(`${process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080"}/payment/shift/decryptThree3DS`, {
              transactionId,
              token,
              email
            } , {
              headers : {
                "Content-Type" : "Application/json",
                "Authorization" : ""
              }
            })
        .then((response) => {
          if (response?.data) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  };
}

export const decryptService = new DecryptService();
