import axios from "../helpers/axios";

class SettingService {
  public getPayentGateway = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/settings/defaultPaymentGateway`;
    return new Promise<any[]>((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getDecryptPayentGateway = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/settings/defaultPaymentGatewayGuest`;
    return new Promise<any[]>((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const settingService = new SettingService();
