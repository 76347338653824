import { Layout, Row, Typography, message } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LolyLogoWhite from "../../../assets/icons/loly-logo-white.svg";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import AuthGoogleButtonComponent from "../../../components/AuthGoogleButtonComponent";
import AuthSelectLanguageComponent from "../../../components/AuthSelectLanguageComponent";
import { authService } from "../../../services";
import "./index.less";

const IntroPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const googleLogin = (accessToken: string) => {
    authService
      .googleLogin(accessToken, navigate)
      .then((data: string) => {
        authService.setToken(data);
        window.location.reload();
      })
      .catch(async (error: string) => {
        await message.error(t(error));
      });
  };

  const handleSignInClick = () => {
    navigate("/auth/signIn");
  };

  return (
    <Layout className="intro-layout gradient-background-intro">
      <Layout.Content className="authContentWrapper">
        <div className="intro-container">
          <Row className="content-wrapper justify-content-center">
            <Row className="justify-content-center mb-15">
              <img src={LolyLogoWhite} width={60} alt="Loly" />
            </Row>
            <Typography.Text className="title-intro">{t("title-intro-page")}</Typography.Text>
            <Row className="row mt-20">
              <AuthButtonComponent label={t("auth-signIn-button")} onClick={handleSignInClick} />
            </Row>
            <Row className="row mt-20">
              <AuthGoogleButtonComponent onLoginSuccess={googleLogin} />
            </Row>
            <Row className="register-row">
              <Link to="/auth/signUp" className="registerLink">
                {t("register-for-free")}
              </Link>
            </Row>
            <Row className="justify-content-center mt-10">
              <AuthSelectLanguageComponent />
            </Row>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default IntroPage;
