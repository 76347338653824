import React, { useState, useEffect } from "react";
import { Typography, Row } from "antd";
import { useTranslation } from "react-i18next";
import PublicAuthViewComponent from "./ViewsComponents/PublicAuthViewComponent";
import PublicRegisterViewComponent from "./ViewsComponents/PublicRegisterViewComponent";
import NewArrowLeftIcon from "../../assets/icons/new-arrow-left.svg";
import PublicLoginViewComponent from "./ViewsComponents/PublicLoginViewComponent";
import "./index.less";

interface PublicAuthentificationModalComponentProps {
  avatar?: string;
  firstName?: string;
  lastName?: string;
  visible: boolean;
  onClose: () => void;
  onLoadingChange: (isLoading: boolean) => void;
}

const PublicAuthentificationModalComponent: React.FC<PublicAuthentificationModalComponentProps> = ({
  avatar,
  firstName,
  visible,
  onClose,
  onLoadingChange,
}) => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState<"auth-view" | "register-view" | "login-view">("auth-view");

  useEffect(() => {
    if (visible) {
      setCurrentView("auth-view");
    }
  }, [visible]);

  if (!visible) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleBackClick = () => {
    setCurrentView("auth-view");
  };

  const handleRegisterClick = () => setCurrentView("register-view");

  const handleLoginClick = () => setCurrentView("login-view");

  const renderView = () => {
    switch (currentView) {
      case "auth-view":
        return <PublicAuthViewComponent onRegisterClick={handleRegisterClick} onLoginClick={handleLoginClick} />;
      case "login-view":
        return <PublicLoginViewComponent />;
      case "register-view":
        return <PublicRegisterViewComponent onClose={onClose} onLoadingChange={onLoadingChange} />;
      default:
        return null;
    }
  };

  return (
    <div className="public-auth-modal-overlay" onClick={handleOverlayClick}>
      <div className={`public-auth-modal-content ${currentView === "login-view" ? "gradient-background" : ""}`} onClick={handleModalClick}>
        {currentView !== "login-view" ? (
          <div
            className="modal-img-header"
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          >
            <Row className="w-full justify-content-center mt-10">
              <div className="header-icon-grey" onClick={handleOverlayClick}></div>
            </Row>

            {currentView !== "auth-view" && (
              <img className="header-arrow-back" src={NewArrowLeftIcon} width={34} onClick={handleBackClick} alt="Back" />
            )}

            <div className="w-full modal-img-header-info">
              <Typography.Text className="modal-img-header-info-text">
                {t("public-profile-modal-title-1-1")} {t("public-profile-modal-title-2", { name: `${firstName}` })}
              </Typography.Text>
            </div>
          </div>
        ) : (
          <div className="header-public-login">
            <Row className="w-full justify-content-center mt-10">
              <div className="header-icon-grey" onClick={handleOverlayClick}></div>
            </Row>
            <img className="header-arrow-back" src={NewArrowLeftIcon} width={34} onClick={handleBackClick} alt="Back" />
          </div>
        )}

        <div className="modal-content">{renderView()}</div>
      </div>
    </div>
  );
};

export default PublicAuthentificationModalComponent;
