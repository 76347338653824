import { Col, Layout, Row, Select, Space, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { IBillingAddress } from "../../../helpers/types";
import "./index.less";
import { t } from "i18next";
import { userService, utilService } from "../../../services";
import AuthInputComponent from "../../../components/AuthInputComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import AuthOrDividerComponent from "../../../components/AuthOrDividerComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { FilterFunc } from "rc-select/lib/Select";
import { SelectValue } from "antd/es/select";

interface OptionItem {
  value: SelectValue;
  label: string;
}

const CreatorBillingAddress = () => {
  const initialBillingAddress: IBillingAddress = {
    companyName: "",
    address: "",
    city: "",
    postalCode: 0,
    country: "",
    vatNumber: "",
  };

  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [localBillingAddress, setLocalBillingAddress] = useState<IBillingAddress>(user?.billingAddress || initialBillingAddress);
  const [countries, setCountries] = useState<OptionItem[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const countriesList = utilService.getPhoneCoutries() as any;
    countriesList
      .then((data: any) => {
        let options: OptionItem[] = [];
        data.forEach((element: any) => {
          options.push({ label: element.name, value: element.code });
        });
        setCountries(options);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownOpen]);

  const updateBillingAddress = () => {
    if (!localBillingAddress) return message.warning(t("empty-form-error-message"));
    if (!localBillingAddress?.address) return message.warning(t("address-required"));
    if (!localBillingAddress?.city) return message.warning(t("city-required"));
    if (!localBillingAddress?.companyName) return message.warning(t("company-name-required"));
    if (!localBillingAddress?.country) return message.warning(t("country-required"));
    if (!localBillingAddress?.postalCode) return message.warning(t("postal-code-required"));
    if (!localBillingAddress?.vatNumber) return message.warning(t("vat-number-required"));
    if (!user?._id) return;

    userService
      .updateBillingAddress(user._id, localBillingAddress)
      .then(() => {
        message.success(t("billing-information-has-been-updated"));
        navigate(-1);
      })
      .catch((error) => message.error(t(error)));
  };

  const handleBillingChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setLocalBillingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const filterOption: FilterFunc<OptionItem> | undefined = (inputValue: string, option?: OptionItem) => {
    return (option?.label.toLowerCase() ?? "").indexOf(inputValue.toLowerCase()) !== -1;
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownOpen(open);
  };

  return (
    <>
      <Layout.Content className="content creatorPersonalInformationContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent left={0} top={12} />
          <Typography.Text className="header-title-black-35">{t("billing")}</Typography.Text>
        </Row>
        <Col className="mt-20">
          <Row>
            <Space className="w-full" direction="vertical">
              <Row className="justify-content-center"></Row>
              <Row>
                <AuthInputComponent
                  label={t("company-name")}
                  placeholder={t("company-name")!}
                  type="text"
                  name="companyName"
                  value={localBillingAddress?.companyName || ""}
                  handleChange={handleBillingChange}
                />
              </Row>
              <Row>
                <AuthInputComponent
                  label={t("address")}
                  placeholder={t("address")!}
                  type="text"
                  name="address"
                  value={localBillingAddress?.address || ""}
                  handleChange={handleBillingChange}
                />
              </Row>
              <Row gutter={7}>
                <Col span={12}>
                  <AuthInputComponent
                    label={t("city")}
                    placeholder={t("city")!}
                    type="text"
                    name="city"
                    value={localBillingAddress?.city || ""}
                    handleChange={handleBillingChange}
                  />
                </Col>
                <Col span={12}>
                  <AuthInputComponent
                    label={t("postal-code")!}
                    placeholder={t("postal-code")!}
                    type="text"
                    name="postalCode"
                    value={localBillingAddress?.postalCode || ""}
                    handleChange={handleBillingChange}
                  />
                </Col>
              </Row>
              <Row>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="authInputLabel">{t("country")}</Typography.Text>
                  <Select
                    showSearch
                    placeholder={t("country")!}
                    className="select-billing dark"
                    options={countries}
                    filterOption={filterOption}
                    value={localBillingAddress?.country}
                    onSelect={(country: any) => {
                      setLocalBillingAddress((prev) => ({ ...prev, country }));
                    }}
                    autoFocus={false}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ position: "fixed", zIndex: 1000 }}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                    onFocus={(e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                  />
                </Space>
              </Row>
              <Row>
                <AuthInputComponent
                  label={t("vat-number")}
                  placeholder={t("vat-number")!}
                  type="text"
                  name="vatNumber"
                  value={localBillingAddress?.vatNumber || ""}
                  handleChange={handleBillingChange}
                />
              </Row>
              <Row className="mt-49">
                <AuthButtonComponent label={t("continue-label")} onClick={updateBillingAddress} />
              </Row>
            </Space>
          </Row>
          <Row>
            <AuthOrDividerComponent />
          </Row>
        </Col>
      </Layout.Content>
    </>
  );
};

export default CreatorBillingAddress;
