import { Button, Col, Layout, Row, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { decryptService } from "../../../services/decrypt.service";
import PopcornImg from "../../../assets/images/popcorn.webp";
import { IDecryptDownloadLink } from "../../../helpers/types";
import "./index.less";

const DecryptUnlockedPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const [data, setData] = useState<IDecryptDownloadLink>();

  useEffect(() => {
    if (shouldFetch.current && location?.state?.decryptId !== undefined) {
      shouldFetch.current = false;
      const payload = {
        unlockLinkId: location.state.decryptId,
        transactionId: location.state.transactionId,
      };
      decryptService.getLink(payload).then((data) => {
        setData(data);
      });
    } else if (location?.state?.decryptId === undefined) {
      navigate("/404");
    }
  }, []);

  const downloadLink = () => {
    window.open(data?.url, "_blank");
  };

  const goToProfile = () => {
    const profileLink = `/p/${data?.creator.username}`;
    window.open(profileLink, "_blank");
  };

  return (
    <Layout>
      <Layout.Content className="content unlockLinkUnlockedContentWrapper gradient-background-blue-settings">
        <Row className="w-full justify-content-center">
          <Row className="decrypt-user-container mb-20">
            <Col className="decrypt-user-content">
              <Row>
                <Typography.Text className="decrypt-user-title mt-5">
                  {t("exclusive-content-free", { name: `${data?.creator.name.split(" ")[0]}` })} <img className="ml-3" src={PopcornImg} width={14} />
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="decrypt-user-description">{t("check-out-the-latest-posts-on-his-profile")}</Typography.Text>
              </Row>
              <Row className="w-full">
                <Button className="decrypt-user-button" onClick={goToProfile}>
                  {t("discover-his-profile")}
                </Button>
              </Row>
            </Col>
            <Col className="decrypt-user-image-col">
              <img className="decrypt-user-image" src={data?.creator.avatar} />
            </Col>
          </Row>
        </Row>
        <Row className="relative justify-content-center mt-20">
          <Typography.Text className="title-file">{t("your-file")}</Typography.Text>
        </Row>
        <Space className="w-full gap-21 mt-5" direction="vertical">
          <Row className="justify-content-center">
            {data?.url.includes(".mp4") ? (
              <div className="video-wrapper">
                <VideoPlayer classNameProp="linkVideo" videoUrl={data.url} thumbnailUrl={""} watermarkText={""} autoPlay={true} />
              </div>
            ) : (
              <img src={data?.url} alt="Loly Decrypt" className="linkImage" />
            )}
          </Row>
          <Row className="justify-content-center mt-5">
            <Button className={"downloadBtn"} onClick={downloadLink}>
              {t("download")}
            </Button>
          </Row>
          <Row className="justify-content-center text-center d-flex flex-col">
            <Typography.Text className="font-14-medium text-grey-color">{t("link-unlocked-description-part-1")}</Typography.Text>
            <Typography.Text className="font-14-medium text-grey-color">{t("link-unlocked-description-part-2")}</Typography.Text>
          </Row>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default DecryptUnlockedPage;
