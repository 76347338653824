import { Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MAX_DESCRIPTION_LENGTH } from "../../helpers/constant";
import "./index.less";

interface ReadMoreProps {
  description: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const shortDescription = description?.length > MAX_DESCRIPTION_LENGTH ? description?.substring(0, MAX_DESCRIPTION_LENGTH) + "..." : description;

  return (
    <>
      <Typography.Text className="slideItemLikeDescription">{isExpanded ? description : shortDescription}</Typography.Text>
      {description?.length > MAX_DESCRIPTION_LENGTH && (
        <Typography.Text className="slideItemLikeDescription" onClick={toggleReadMore}>
          {isExpanded ? t("read-less") : t("read-more")}
        </Typography.Text>
      )}
    </>
  );
};

export default ReadMore;
