import { Button, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ICreatorSuggestionSection } from "../../../helpers/types";
import numberIcons from "../../../utls/numberIcons";
import "../index.less";

interface SuggestionSectionOneAndThreeProps {
  title: string;
  creatorSuggestionSection: ICreatorSuggestionSection[];
  isFollowing: { [key: string]: boolean };
  follow: (creatorId: string) => void;
}

const SuggestionSectionOneAndThree: React.FC<SuggestionSectionOneAndThreeProps> = ({ creatorSuggestionSection, isFollowing, follow, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {creatorSuggestionSection.length > 0 && (
        <>
          <Row>
            <Typography.Text className="suggestionsSubTitle mt-10">{title}</Typography.Text>
          </Row>
          <div className="suggestionsRectangleContainer">
            {creatorSuggestionSection.map((item, index) => (
              <div className="suggestionItemWrapper" key={item._id}>
                <img src={numberIcons[index]} className="numberIcon" width={45} alt={`Number ${index + 1}`} />
                <div className="suggestionRectangleItem">
                  <div className="suggestionImageWrapper">
                    <img
                      src={item.creator.avatar}
                      alt={item.creator.name}
                      className="suggestionImage"
                      onClick={() => navigate("/p/" + item.creator.username)}
                    />
                    <div className="suggestionOverlay">
                      <Typography.Text className="w-full suggestionText ellipsis-single-line">{item.creator.name}</Typography.Text>
                      <Button
                        className="followButton"
                        onClick={() => {
                          if (!(item.creator.followed || isFollowing[item.creator._id!])) {
                            follow(item.creator._id!);
                          }
                        }}
                      >
                        {t("follow")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestionSectionOneAndThree;
