import { Button, Checkbox, Col, Input, InputRef, Layout, Row, Select, Space, Spin, Switch, Tag, Typography, Upload, message } from "antd";
import { SelectValue } from "antd/es/select";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { FilterFunc } from "rc-select/lib/Select";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CameraIcon from "../../assets/icons/camera.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import CompletedIcon from "../../assets/icons/green-icon.svg";
import BackIcon from "../../assets/icons/new-arrow-left.svg";
import { CREATOR, getMonetizationsInformations } from "../../helpers/constant";
import { IBillingAddress, ICountry, IMonetization, IUser } from "../../helpers/types";
import { authService, userService, utilService } from "../../services";
import { capitalizeFirstLetter, convertHeicFile, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import AuthButtonComponent from "../AuthButtonCompnent";
import AuthGenderInputComponent from "../AuthGenderInputComponent";
import AuthGoogleButtonComponent from "../AuthGoogleButtonComponent";
import AuthInputComponent from "../AuthInputComponent";
import AuthOrDividerComponent from "../AuthOrDividerComponent";
import AuthSelectLanguageComponent from "../AuthSelectLanguageComponent";
import DatePickerComponent from "../DatePickerComponent";
import InputComponent from "../InputComponent";
import PhoneInputComponent from "../PhoneInputCompnent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";
import { SUBSCRIPTION_PRICING_LIMITS } from "../../utls/constants";

const types = [
  "model",
  "influencer",
  "lgbtq",
  "artist",
  "cooking",
  "celebrity-backstage",
  "fitness-coaching",
  "gambler",
  "curvy-woman",
  "no-limit",
  "in-relationship",
  "foot-lover",
  "entrepreneurship",
  "asmr",
  "lingerie",
];

const initMonetization: IMonetization = {
  monthly: true,
  monthlyPrice: null,
  trimestrial: false,
  trimestrialPrice: null,
  half_yearly: false,
  half_yearlyPrice: null,
  yearly: false,
  yearlyPrice: null,
};

interface OptionItem {
  value: SelectValue;
  label: string;
}

type props = {
  setCurrentStep: any;
  curentStep?: any;
  usersId: string | null;
  userEmail: string | null;
  currentUser: any | null;
  setUsersId?: any;
  setCurrentUser?: any;
  setUserEmail: any;
};

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const AuthSignUpCreatorTabComponent: React.FC<props> = ({
  setCurrentStep,
  curentStep,
  usersId,
  setUsersId,
  userEmail,
  setUserEmail,
  currentUser,
  setCurrentUser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [curStep, setCurStep] = useState<number>(curentStep);
  const [user, setUser] = useState<IUser | null>(null);
  const [data, setData] = useState<IUser | null>(null);
  const [userId, setUserId] = useState<string | null>(usersId);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | null>(null);
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [monetization, setMonetization] = useState<IMonetization>(initMonetization);
  const [idCardFile, setIdCardFile] = useState<UploadFile | null>(null);
  const [selfieFile, setSelfieFile] = useState<UploadFile | null>(null);
  const [idCardImageUrl, setIdCardImageUrl] = useState<string>();
  const [selfieImageUrl, setSelfieImageUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any>();

  const monetizations = getMonetizationsInformations(t);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [sponsorId, setSponsorId] = useState<string | null>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [accountType, setAccountType] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const usernameInputRef = useRef<InputRef>(null);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    if (curStep === 1) {
      const element = usernameInputRef.current;
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 0);
      }
    }
  }, [curStep]);

  useEffect(() => {
    if (token) {
      setSponsorId(token);
    } else {
      setSponsorId(null);
    }
  }, [token]);

  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownOpen]);

  useEffect(() => {
    setCurrentStep(curStep);
    window.scrollTo({
      top: 0,
    });
  }, [curStep]);

  useEffect(() => {
    setUser({ ...user, ...currentUser });
    const countriesList = utilService.getPhoneCoutries() as any;
    countriesList
      .then((data: any) => {
        let options: any = [];
        data.forEach((element: any) => {
          options.push({ label: element.name, value: element.code });
        });
        setCountries(options);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, [currentUser]);

  const verifications = [
    {
      title: t("identity-title"),
      subTitle: t("identity-subTitle"),
      name: "idCard",
    },
    {
      title: t("selfie-title"),
      subTitle: t("selfie-subTitle"),
      name: "selfie",
    },
  ];

  const register = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmail = emailRegex.test(user?.email || "");
    if (!user) return message.warning(t("empty-form-error-message"));
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username || user?.username.length < 3) return message.warning(t("username-required"));
    if (!user?.dateOfBirth) return message.warning(t("dob-required"));
    if (!user?.email || !isEmail) {
      setEmailError(true);
      return message.warning(t("invalid-email"));
    }
    if (!user?.password || user?.password?.length < 6) return message.warning(t("password-required"));
    if (!user?.phone) return message.warning(t("phone-number-required"));
    if (!user.gender) return message.warning(t("gender-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    authService
      .register({
        ...user,
        firstName: capitalizeFirstLetter(user.firstName),
        lastName: capitalizeFirstLetter(user.lastName),
        username: user.username?.toLocaleLowerCase(),
        type: CREATOR,
        sponsorId: sponsorId,
        lang: localStorage.getItem("lng"),
      })
      .then((data: IUser) => {
        if (data._id) {
          setUserId(data._id);
          setUsersId(data._id);
          setUserEmail(data.email ?? "");
          setCurStep(2);
          setUser({ ...user, ...data });
        }
      })
      .catch((error) => message.error(t(error)));
  };

  const updateTypes = () => {
    if (!userId) return;
    if (selectedTypes?.length === 0) {
      message.warning(t("auth-content-types-note"));
      return;
    }
    userService
      .updateCreatorContentTypes(userId, selectedTypes)
      .then(() => setCurStep(3))
      .catch((error) => message.error(error));
  };

  const updateDateOfBirth = () => {
    if (!userId || !userEmail) return message.warning(t("email-required"));
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username) return message.warning(t("username-required"));
    if (!user?.dateOfBirth) return message.warning(t("dob-required"));
    if (!user?.phone) return message.warning(t("phone-number-required"));
    if (!user.gender) return message.warning(t("gender-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    userService
      .updateDateOfBirth(
        userId,
        userEmail,
        user?.firstName,
        user?.lastName,
        user?.username,
        user?.dateOfBirth,
        false,
        user?.phone,
        user?.phoneCode || "",
        user?.gender
      )
      .then((res: any) => setCurStep(2))
      .catch((error: any) => message.error(t(error)));
  };

  const updateMonetization = () => {
    if (!monetization.monthlyPrice) return message.warning(t("set-monthly-price-error-message"));
    if (monetization?.trimestrial && !monetization?.trimestrialPrice) return message.warning(t("set-quaterly-price"));
    if (!userId) return;
    userService
      .updateCreatorMonetization(userId, monetization)
      .then(() => setCurStep(4))
      .catch((error) => message.error(error));
  };

  const updateBillingAddress = () => {
    if (!billingAddress) return message.warning(t("empty-form-error-message"));
    if (!billingAddress?.address) return message.warning(t("address-required"));
    if (!billingAddress?.city) return message.warning(t("city-required"));
    if (!billingAddress?.companyName) return message.warning(t("company-name-required"));
    if (!billingAddress?.country) return message.warning(t("country-required"));
    if (!billingAddress?.postalCode) return message.warning(t("postal-code-required"));
    if (!billingAddress?.vatNumber) return message.warning(t("vat-number-required"));
    if (!userId) return;

    userService
      .updateBillingAddress(userId, billingAddress)
      .then(() => setCurStep(6))
      .catch((error) => message.error(t(error)));
  };

  const uploadIdFiles = () => {
    if (!idCardFile) return message.warning(t("card-id-error-message"));
    if (!selfieFile) return message.warning(t("selfie-error-message"));
    if (!userId) return;

    setLoading(true);
    const formData = new FormData();
    const idCard: File = idCardFile.originFileObj as any;
    const selfie: File = selfieFile.originFileObj as any;
    formData.append("idVerification", idCard);
    formData.append("selfie", selfie);

    userService
      .uploadIdFiles(userId, formData)
      .then(() => setCurStep(7))
      .catch((error) => message.error(error))
      .finally(() => setLoading(false));
  };

  const handleChange = (e: { target: { name: any; value: any }; code?: any; key?: any; keyCode?: any }) => {
    let filedValue = e.target.value;
    if (e.target.name === "username") {
      filedValue = filedValue.replace(/\s+/g, "");
    }
    if (e.target.name === "email") {
      setEmailError(false);
    }
    setUser({ ...user, [e.target.name]: filedValue });
  };

  const handleBillingChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setBillingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const typeTagClicked = (type: string) => {
    const tempSelectedTypes = [...selectedTypes];

    const index = selectedTypes.indexOf(type);
    if (index === -1) {
      tempSelectedTypes.push(type);
    } else {
      tempSelectedTypes.splice(index, 1);
    }
    setSelectedTypes(tempSelectedTypes);
  };

  const monetizationSelectedChanged = (frequency: string, value: boolean) => {
    switch (frequency) {
      case "trimestrial":
        setMonetization({
          ...monetization,
          trimestrial: value,
          trimestrialPrice: !value ? null : monetization.trimestrialPrice,
        });
        break;
      case "half_yearly":
        setMonetization({
          ...monetization,
          half_yearly: value,
          half_yearlyPrice: !value ? null : monetization.half_yearlyPrice,
        });
        break;
      case "yearly":
        setMonetization({
          ...monetization,
          yearly: value,
          yearlyPrice: !value ? null : monetization.yearlyPrice,
        });
        break;
      default:
        setMonetization(monetization);
    }
  };

  const monetizationPriceChanged = (frequency: string, value: string) => {
    const numberValue = value.replaceAll(/\D/g, "");

    if (frequency === "monthly" && Number(numberValue) > SUBSCRIPTION_PRICING_LIMITS.MONTHLY) {
      message.warning(t("monthly-price-cannot-exceed-300-euros"));
      return;
    } else if (frequency === "trimestrial" && Number(numberValue) > SUBSCRIPTION_PRICING_LIMITS.TRIMESTRIAL) {
      message.warning(t("quarterly-price-cannot-exceed-500-euros"));
      return;
    }

    switch (frequency) {
      case "monthly":
        if (monetization.monthly) {
          setMonetization({
            ...monetization,
            monthlyPrice: Number(numberValue),
          });
        }
        break;
      case "trimestrial":
        if (monetization.trimestrial) {
          setMonetization({
            ...monetization,
            trimestrialPrice: Number(numberValue),
          });
        }
        break;
      // case "half_yearly":
      //   if (monetization.half_yearly) {
      //     setMonetization({
      //       ...monetization,
      //       half_yearlyPrice: Number(numberValue),
      //     });
      //   }
      //   break;
      // case "yearly":
      //   if (monetization.yearly) {
      //     setMonetization({
      //       ...monetization,
      //       yearlyPrice: Number(numberValue),
      //     });
      //   }
      //   break;
      default:
        setMonetization(monetization);
    }
  };

  const fileChanged = (key: string, file: UploadFile<any> | null) => {
    if (file !== null && file?.type?.includes("image")) {
      getBase64(file?.originFileObj, (url) => {
        if (key === "idCard") setIdCardImageUrl(url);
        if (key === "selfie") setSelfieImageUrl(url);
      });
    }
    if (file === null || file?.type?.includes("image")) {
      if (key === "idCard") setIdCardFile(file);
      if (key === "selfie") setSelfieFile(file);
    }
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile, key: any): Promise<File | boolean | string> => {
    if (file !== null && file.type !== "" && !file?.type?.includes("image")) {
      message.error(t("only-images-allowed-error-message"));
      return false;
    }

    // If the file is in an accepted format, proceed without conversion
    if (isImageAcceptedFormat(file.type)) {
      return true;
    }

    try {
      if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false, key);
        // convert file
        const convertedFile = await convertHeicFile(file);
        handleConvertedFile(file.uid, convertedFile, true, key);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      fileChanged(key, null);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = (uid: string, file: File, converted: boolean, key: string) => {
    let fileObj: UploadFile;
    if (!converted) {
      fileObj = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
    } else {
      fileObj = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
    }
    fileChanged(key, fileObj);
  };

  const googleRegister = (accessToken: string) => {
    setLoading(true);
    authService
      .googleSignUp(accessToken, CREATOR)
      .then(async (response: { data: IUser; status: any; token: string }) => {
        if (response && response.data && response.status === 0) {
          const Data = response.data;
          setCurStep(-1);
          sessionStorage.setItem("fromGoogle", "true");
          setUserId(response.data._id!);
          setUsersId(response.data._id!);
          setUserEmail(response.data.email);
          setUser({
            ...user,
            ...Data,
            username: "",
          });
          setCurrentUser({
            ...currentUser,
            ...Data,
            username: "",
          });
          authService.setToken(response.token || "");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(t(error));
      });
  };

  const selectAccountType = () => {
    if (accountType === "business") {
      setCurStep(5);
    } else {
      setCurStep(6);
    }
  };

  const filterOption: FilterFunc<OptionItem> | undefined = (inputValue: string, option?: OptionItem) => {
    return (option?.label.toLowerCase() ?? "").indexOf(inputValue.toLowerCase()) !== -1;
  };

  const handleBackStep = () => {
    setCurStep(4);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownOpen(open);
  };

  const handleAuthCompletion = () => {
    const fromGoogle = sessionStorage.getItem("fromGoogle") === "true";
    const path = fromGoogle ? "/auth/intro" : "/auth/signIn";
    navigate(path);
    sessionStorage.removeItem("fromGoogle");
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout className={"background-transparent"}>
        <Layout.Content className="authSignupContentWrapper">
          {curStep === -1 && (
            <Col>
              <Row className="justify-content-center mt-40">
                <Typography.Text className="authHeader authSelectTypeHeader">{`${t("details")}`}</Typography.Text>
              </Row>
              <Row className="mb-10 mt-20">
                <Space className="w-full" direction="vertical">
                  <Row gutter={7}>
                    <Col span={12}>
                      <AuthInputComponent
                        label={t("first-name-label")}
                        placeholder={t("first-name-label")!}
                        type="text"
                        name="firstName"
                        value={user?.firstName || ""}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col span={12}>
                      <div className="mt-3">
                        <AuthInputComponent
                          label={t("last-name-label")}
                          placeholder={t("last-name-label")!}
                          type="text"
                          name="lastName"
                          value={user?.lastName || ""}
                          handleChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <AuthInputComponent
                      label={t("user-name-label")}
                      placeholder={t("user-name-label")!}
                      type="text"
                      name="username"
                      value={user?.username?.toLocaleLowerCase() || currentUser?.username?.toLocaleLowerCase() || ""}
                      handleChange={handleChange}
                      ref={usernameInputRef}
                    />
                  </Row>
                  <Row>
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("date-of-birth-label")}</Typography.Text>
                      <DatePickerComponent
                        className="darkDatePickerSignup"
                        date={user?.dateOfBirth || currentUser?.dateOfBirth}
                        onChange={(value) => setUser({ ...user, dateOfBirth: value })}
                      />
                    </Space>
                  </Row>
                  <Row>
                    <AuthInputComponent
                      label={t("email-label")}
                      placeholder={t("email-label")!}
                      type="email"
                      name="email"
                      value={user?.email || currentUser?.email || ""}
                      disabled
                    />
                  </Row>
                  <Row>
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("phone-number-label")}</Typography.Text>
                      <PhoneInputComponent
                        className="authDark"
                        countryCode={user?.countryCode || currentUser?.countryCode}
                        phoneCode={user?.phoneCode || currentUser?.countryCode}
                        phone={user?.phone || currentUser?.countryCode}
                        onChangePhone={(e) => setUser({ ...user, phone: e.target.value })}
                        onChangePhoneCode={(phoneCode, countryCode) => {
                          setUser({ ...user, phoneCode, countryCode });
                        }}
                      />
                    </Space>
                  </Row>
                  <Row>
                    <AuthGenderInputComponent name="gender" value={user?.gender || currentUser?.gender} handleChange={handleChange} />
                  </Row>
                  <Row className="mt-11">
                    <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
                      <span>{t("auth-terms-1")}</span>
                      <span className="text-color-green">{t("auth-terms-2")}</span>
                      <span>{t("auth-terms-3")}</span>
                      <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                        {t("terms-and-conditions")}
                      </a>
                      {t("and")}
                      <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                        {t("privacy-policy")}
                      </a>
                    </Checkbox>
                  </Row>
                  <Row className="mt-14">
                    <AuthButtonComponent label={t("continue-label")} onClick={updateDateOfBirth} />
                  </Row>
                </Space>
              </Row>
            </Col>
          )}

          {curStep === 1 && (
            <Col className="signUpContainer">
              <Row className="mt-5">
                <AuthGoogleButtonComponent onLoginSuccess={googleRegister} />
              </Row>
              <Row className="mt-5">
                <AuthOrDividerComponent text={t("auth-signUp-divider")} />
              </Row>
              <Row className="mb-10">
                <Space className="w-full" direction="vertical">
                  <Row gutter={7}>
                    <Col span={12}>
                      <AuthInputComponent
                        label={t("identity")}
                        placeholder={t("first-name-label")!}
                        type="text"
                        name="firstName"
                        value={user?.firstName || ""}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col span={12}>
                      <div className="mt-3">
                        <AuthInputComponent
                          label=""
                          placeholder={t("last-name-label")!}
                          type="text"
                          name="lastName"
                          value={user?.lastName || ""}
                          handleChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-7">
                    <AuthInputComponent
                      label={t("user-name-label")}
                      placeholder={t("user-name-label")!}
                      type="text"
                      name="username"
                      value={user?.username?.toLocaleLowerCase() || ""}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="mt-7">
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("date-of-birth-label")}</Typography.Text>
                      <DatePickerComponent
                        className="darkDatePickerSignup"
                        date={user?.dateOfBirth}
                        onChange={(value) => setUser({ ...user, dateOfBirth: value })}
                      />
                    </Space>
                  </Row>
                  <Row className="mt-7">
                    <AuthInputComponent
                      label={t("email-label")}
                      placeholder={t("email-label")!}
                      type="email"
                      name="email"
                      value={user?.email || ""}
                      handleChange={handleChange}
                      inputError={emailError}
                    />
                  </Row>
                  <Row className="mt-7">
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("password-label")}</Typography.Text>
                      <InputComponent
                        className="custom-input-signup"
                        placeholder={t("password-label")!}
                        onChange={handleChange}
                        value={user?.password || ""}
                        name="password"
                        type="password"
                      />
                    </Space>
                  </Row>
                  <Row className="mt-7">
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("phone-number-label")}</Typography.Text>
                      <PhoneInputComponent
                        className="authDark"
                        countryCode={user?.countryCode}
                        phoneCode={user?.phoneCode}
                        phone={user?.phone}
                        onChangePhone={(e) => setUser({ ...user, phone: e.target.value })}
                        onChangePhoneCode={(phoneCode, countryCode) => {
                          setUser({ ...user, phoneCode, countryCode });
                        }}
                      />
                    </Space>
                  </Row>
                  <Row className="mt-7">
                    <AuthGenderInputComponent name="gender" value={user?.gender} handleChange={handleChange} />
                  </Row>
                  <Row className="mt-7">
                    <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
                      <span>{t("auth-terms-1")}</span>
                      <span className="text-color-green">{t("auth-terms-2")}</span>
                      <span>{t("auth-terms-3")}</span>
                      <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                        {t("terms-and-conditions")}
                      </a>
                      {t("and")}
                      <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                        {t("privacy-policy")}
                      </a>
                    </Checkbox>
                  </Row>
                  <Row className="mt-14">
                    <AuthButtonComponent label={t("auth-signUp-button")} onClick={register} />
                  </Row>
                </Space>
              </Row>
              <Row className={`w-full authRowFooter`}>
                <AuthSelectLanguageComponent />
              </Row>
            </Col>
          )}

          {curStep === 2 && (
            <Col className="padding-container">
              <Row className="justify-content-center mt-20">
                <Typography.Text className="authHeader authSelectTypeHeader">{t("auth-content-types-title")}</Typography.Text>
              </Row>
              <Row className="justify-content-center mt-30">
                <Typography.Text className="authSelectTypeDescription">{t("auth-content-types-note")}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Space className="flex-wrap justify-content-center" direction="horizontal">
                  {types.map((type, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`authTypeTag ${selectedTypes.includes(type) ? "authSelectedTypeTag" : ""}`}
                        onClick={() => typeTagClicked(type)}
                      >
                        {t(type)}
                      </Tag>
                    );
                  })}
                </Space>
              </Row>
              <Row className="mt-40 padding-container">
                <AuthButtonComponent label={t("continue-label")} onClick={updateTypes} />
              </Row>
            </Col>
          )}

          {curStep === 3 && (
            <Col className="padding-container">
              <Row className="justify-content-center mt-20 padding-container">
                <Typography.Text className="authHeader authRevenueHeader">{t("subscription-price")}</Typography.Text>
              </Row>
              <Row className="justify-content-center mt-7">
                <Typography.Text className="authSelectTypeDescription">{t("you-can-change-it-later")}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Space className="w-full gap-39" direction="vertical">
                  {monetizations.map((item, index) => {
                    return (
                      <Col key={index}>
                        <Row>
                          <Space>
                            <Typography.Text className="authRevenueItemTitle">{item.title}</Typography.Text>
                            {item.important && <Typography.Text className="authRevenueItemImportantTitle">*</Typography.Text>}
                          </Space>
                        </Row>
                        <Row className="mt-10 justify-content-between items-center">
                          <Space className="d-flex items-end">
                            <Input
                              className="authInput authRevenueInput"
                              value={
                                item.frequency === "monthly"
                                  ? monetization.monthlyPrice || ""
                                  : item.frequency === "trimestrial"
                                    ? monetization.trimestrialPrice || ""
                                    : item.frequency === "half_yearly"
                                      ? monetization.half_yearlyPrice || ""
                                      : monetization.yearlyPrice || ""
                              }
                              placeholder="0.00€"
                              onChange={(e) => monetizationPriceChanged(item.frequency, e.target.value)}
                              inputMode="numeric"
                            />
                            <Typography.Text className="authRevenueItemSubTitle">{item.subTitle}</Typography.Text>
                          </Space>
                          <Switch
                            className={`authSwitch monetizationSwitch ${item.frequency === "monthly" && monetization.monthly ? "switchcolor" : ""} 
                          ${item.frequency === "trimestrial" && monetization.trimestrial ? "switchcolor" : ""}
                          ${item.frequency === "half_yearly" && monetization.half_yearly ? "switchcolor" : ""}
                          ${item.frequency === "yearly" && monetization.yearly ? "switchcolor" : ""}`}
                            checked={
                              item.frequency === "monthly"
                                ? monetization.monthly
                                : item.frequency === "trimestrial"
                                  ? monetization.trimestrial
                                  : item.frequency === "half_yearly"
                                    ? monetization.half_yearly
                                    : monetization.yearly
                            }
                            onChange={(checked: boolean) => monetizationSelectedChanged(item.frequency, checked)}
                          />
                        </Row>
                      </Col>
                    );
                  })}
                </Space>
              </Row>
              {/* <Row className="mt-24">
                <Typography.Text className="font-10-regular text-black-color text-center">{t("monetization-terms")}</Typography.Text>
              </Row> */}
              <Row className="mt-40">
                <AuthButtonComponent label={t("continue-label")} onClick={updateMonetization} />
              </Row>
            </Col>
          )}

          {curStep === 4 && (
            <Col className="padding-container">
              <Row className="justify-content-center mt-20">
                <Typography.Text className="authHeader authStatusHeader">{t("your-status")}</Typography.Text>
              </Row>
              <Row className="justify-content-center mt-7">
                <Typography.Text className="authSelectTypeDescription">{t("professional-company-number")}</Typography.Text>
              </Row>
              <Space className="w-full mt-100 gap-20" direction="vertical">
                <Button className={`btn-status ${accountType === "private" ? "active" : ""}`} onClick={() => setAccountType("private")}>
                  {t("private-individual")}
                </Button>
                <Button className={`btn-status mt-10 ${accountType === "business" ? "active" : ""}`} onClick={() => setAccountType("business")}>
                  {t("business-number")}
                </Button>
              </Space>
              <Row className="mt-130">
                <AuthButtonComponent label={t("continue-label")} onClick={selectAccountType} disabled={accountType === ""} />
              </Row>
            </Col>
          )}

          {curStep === 5 && (
            <Col className="padding-container">
              <Row>
                <Space className="w-full gap-20" direction="vertical">
                  <Row onClick={() => setCurrentStep(4)}>
                    <img className="mt-10" src={BackIcon} width={32} onClick={handleBackStep} />
                    <Typography.Text className="authHeader authBillingHeader mt-5">{t("billing")}</Typography.Text>
                  </Row>
                  <Row className="mt-20">
                    <AuthInputComponent
                      label={t("company-name")}
                      placeholder={t("company-name")!}
                      type="text"
                      name="companyName"
                      value={billingAddress?.companyName || ""}
                      handleChange={handleBillingChange}
                    />
                  </Row>
                  <Row>
                    <AuthInputComponent
                      label={t("address")}
                      placeholder={t("address")!}
                      type="text"
                      name="address"
                      value={billingAddress?.address || ""}
                      handleChange={handleBillingChange}
                    />
                  </Row>
                  <Row gutter={7}>
                    <Col span={12}>
                      <AuthInputComponent
                        label={t("city")}
                        placeholder={t("city")!}
                        type="text"
                        name="city"
                        value={billingAddress?.city || ""}
                        handleChange={handleBillingChange}
                      />
                    </Col>
                    <Col span={12}>
                      <AuthInputComponent
                        label={t("postal-code")!}
                        placeholder={t("postal-code")!}
                        type="text"
                        name="postalCode"
                        value={billingAddress?.postalCode || ""}
                        handleChange={handleBillingChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Space className="w-full gap-6" direction="vertical">
                      <Typography.Text className="authInputLabel">{t("country")}</Typography.Text>
                      <Select
                        showSearch
                        placeholder={t("country")!}
                        className="select-billing dark"
                        options={countries}
                        filterOption={filterOption}
                        value={user?.country}
                        onSelect={(country: any) => {
                          setBillingAddress({ ...billingAddress, country });
                        }}
                        autoFocus={false}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ position: "fixed", zIndex: 1000 }}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        onFocus={(e) => e.preventDefault()}
                        onClick={(e) => e.preventDefault()}
                      />
                    </Space>
                  </Row>
                  <Row>
                    <AuthInputComponent
                      label={t("vat-number")}
                      placeholder={t("vat-number")!}
                      type="text"
                      name="vatNumber"
                      value={billingAddress?.vatNumber || ""}
                      handleChange={handleBillingChange}
                    />
                  </Row>
                  <Row className="mt-20">
                    <AuthButtonComponent label={t("continue-label")} onClick={updateBillingAddress} />
                  </Row>
                </Space>
              </Row>
              <Row>
                <AuthOrDividerComponent />
              </Row>
            </Col>
          )}

          {curStep === 6 && (
            <Col>
              <Row className="justify-content-center mt-20">
                <Typography.Text className="authHeader authVerifyIdentity">{t("auth-identity-verification-title")}</Typography.Text>
              </Row>
              <Row className="mt-24">
                <Space className="w-full gap-20 mt-10" direction="vertical">
                  {verifications.map((verification, index) => {
                    const imgUrl = index === 0 ? (idCardImageUrl ? idCardImageUrl : undefined) : selfieImageUrl ? selfieImageUrl : undefined;
                    const imgSize = imgUrl !== undefined ? "100%" : "";
                    return (
                      <Col key={index}>
                        <Row className="justify-content-center">
                          <div className="authIdUploadWrapper">
                            <img
                              src={imgUrl || DocumentIcon}
                              width={imgSize}
                              height={imgSize}
                              style={{ borderRadius: imgSize, objectFit: "contain" }}
                              alt="document"
                            />
                            <Upload
                              accept="image/*"
                              showUploadList={false}
                              fileList={index === 0 ? (idCardFile ? [idCardFile] : undefined) : selfieFile ? [selfieFile] : undefined}
                              beforeUpload={async (file: RcFile) => {
                                return await beforeUpload(file, verification.name as any);
                              }}
                              onChange={(info: any) => {
                                fileChanged(verification.name, info.file);
                              }}
                              onRemove={() => fileChanged(verification.name, null)}
                            >
                              <img className="authIdUploadCameraIcon" src={CameraIcon} alt="camera" />
                            </Upload>
                          </div>
                        </Row>
                        <Row className="justify-content-center mt-24">
                          <Typography.Text className="authRevenueItemTitle text-center">{verification.title}</Typography.Text>
                        </Row>
                        <Row className="justify-content-center mt-10">
                          <Typography.Text className="authRevenueItemSubTitle text-center">{verification.subTitle}</Typography.Text>
                        </Row>
                        {index == 0 && (
                          <Row className="mt-10">
                            <div className="divider-identity" />
                          </Row>
                        )}
                      </Col>
                    );
                  })}
                </Space>
              </Row>
              <Row className="mt-40">
                <AuthButtonComponent label={t("terminate-label")} onClick={uploadIdFiles} />
              </Row>
            </Col>
          )}

          {curStep === 7 && (
            <Col className="padding-container">
              <Row className="justify-content-center mt-20">
                <img src={CompletedIcon} alt="completed" />
              </Row>
              <Row className="justify-content-center mt-35">
                <Typography.Text className="authCompletedTitle">
                  <span className="authCompletedTitleSpan">{t("auth-signUp-finish-title-1")}</span>
                  {t("auth-signUp-finish-title-2")}
                </Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent">{t("auth-signUp-receive-email-notification-label")}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent text-medium-green-color">{userEmail}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent">{t("auth-signUp-with-status")}</Typography.Text>
              </Row>
              <Row className="justify-content-center mt-40">
                <AuthButtonComponent label={t("terminate-label")} onClick={handleAuthCompletion} />
              </Row>
            </Col>
          )}
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default AuthSignUpCreatorTabComponent;
