import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import { CONVERSATION_TYPES, REQUEST_LOADING_STATES } from "../../helpers/constant";
import { IConversation, IConversationData, IConversationDTO, IGalleryMediaData, IMessageData } from "../../helpers/types";
import { authService, mediapushService, messageService } from "../../services";
import { StoreState } from "../store";

interface MessagesState {
  conversationsData: IConversationData[] | [];
  mediaPushData: IGalleryMediaData[] | [];
  messagesData: IMessageData[] | [];
  conversations: IConversation[] | [];
  searchConversations: IConversation[] | [];
  selectedConversation: IConversation;
  loading: REQUEST_LOADING_STATES;
  error: any;
  onlineUsers: string[];
  totalPages: number;
}

const initialState = {
  conversationsData: [],
  messagesData: [],
  mediaPushData: [],
  conversations: [],
  searchConversations: [],
  selectedConversation: {},
  loading: REQUEST_LOADING_STATES.IDLE,
  error: "",
  onlineUsers: [],
  totalPages: -1,
} as MessagesState;

export const getUserConversations = createAsyncThunk("messages/getUserConversations", async (payload: { page: number; type: string }) => {
  const response = await messageService.getAllConversations(payload.page, payload.type);
  return { ...response, type: payload.type, page: payload.page };
});

export const searchConversation = createAsyncThunk("messages/searchConversation", async (payload: string) => {
  return messageService.searchConversation(payload);
});

export const getConversationById = createAsyncThunk("messages/getConversationById", async (conversationId: string) => {
  return messageService.getConversationById(conversationId);
});

export const getConversationMessages = createAsyncThunk("messages/getConversationMessages", async (conversationId: string) => {
  return messageService.getConversationById(conversationId);
});

export const getConversationMedias = createAsyncThunk(
  "messages/getConversationMedias",
  async (payload: { conversationId: string; receiverId: string; senderId: string }) => {
    const medias = await mediapushService.getPrivateGalleryMedia(payload.conversationId, payload.receiverId, payload.senderId);
    return { medias, conversationId: payload.conversationId };
  }
);

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    selectConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    updateConversations: (state, action) => {
      const newConversation = formatConversation(action.payload);
      newConversation.hasNewMessages = true;
      const index = state.conversationsData.findIndex((conversation) => conversation.type === newConversation.conversationType);
      if (index !== -1) {
        state.conversationsData[index].data = uniqBy([...state.conversationsData[index].data, newConversation], "_id").sort(sortByUpdatedAtDesc);
        state.conversationsData[index].lastDataLoad = new Date().getTime();
      } else {
        //TODO this is an advanced feature that we need change and to implement later if necessary
        // state.conversationsData = [
        //   ...state.conversationsData,
        //   {
        //     type: newConversation.conversationType!,
        //     lastDataLoad: new Date().getTime(),
        //     totalPages: 1,
        //     data: [newConversation],
        //   },
        // ];
      }
    },
    resetUserConversations: (state) => {
      state.selectedConversation = {};
    },
    readAllMessagesInConversation: (state, action) => {
      const { conversationId, conversationType } = action.payload;
      const index = state.conversationsData.findIndex((conversation) => conversation.type === conversationType);
      if (index !== -1) {
        const conversationIndex = state.conversationsData[index].data.findIndex(
          (conversation) => conversation._id?.toString() === conversationId.toString()
        );
        if (conversationIndex !== -1) {
          const conversationToEdit = state.conversationsData[index].data[conversationIndex];
          conversationToEdit.hasNewMessages = false;
          state.conversationsData[index].data[conversationIndex] = conversationToEdit;
          state.conversationsData[index].lastDataLoad = new Date().getTime();
          state.conversationsData[index].data = state.conversationsData[index].data.sort(sortByUpdatedAtDesc);
        }
      }
    },
    updateConversationHasNewMessages: (state, action) => {
      const payload = action.payload;
      const index = state.conversationsData.findIndex((conversation) => conversation.type === payload.conversationType);
      if (index !== -1) {
        const conversationIndex = state.conversationsData[index].data.findIndex(
          (conversation) => conversation._id?.toString() === payload.conversationId.toString()
        );
        if (conversationIndex !== -1) {
          const conversationToEdit = state.conversationsData[index].data[conversationIndex];
          if (payload.isReceiver) {
            if (state.selectedConversation._id !== payload.conversationId) {
              conversationToEdit.hasNewMessages = true;
            }
          }
          conversationToEdit.updatedAt = payload.createdAt;
          state.conversationsData[index].data[conversationIndex] = conversationToEdit;
          state.conversationsData[index].lastDataLoad = new Date().getTime();
          state.conversationsData[index].data = state.conversationsData[index].data.sort(sortByUpdatedAtDesc);
        }
      }
    },

    updateConversationsOrder: (state, action) => {
      const { conversationType, conversationId, createdAt } = action.payload;
      const index = state.conversationsData.findIndex((conversation) => conversation.type === conversationType);
      if (index !== -1) {
        const conversationIndex = state.conversationsData[index].data.findIndex(
          (conversation) => conversation._id?.toString() === conversationId.toString()
        );
        if (conversationIndex !== -1) {
          const conversationToEdit = state.conversationsData[index].data[conversationIndex];
          conversationToEdit.updatedAt = createdAt;
          state.conversationsData[index].data[conversationIndex] = conversationToEdit;
          state.conversationsData[index].lastDataLoad = new Date().getTime();
          state.conversationsData[index].data = state.conversationsData[index].data.sort(sortByUpdatedAtDesc);
        }
      }
    },

    updateConversationsMediaPush: (state, action) => {
      const mediaIndex = state.mediaPushData.findIndex((media) => media.conversationId === action.payload.conversationId);
      if (mediaIndex === -1) {
        // const newMedia: IGalleryMediaData = {
        //   conversationId: action.payload.conversationId,
        //   medias: action.payload.medias,
        //   shouldUpdate: false,
        // };
        // state.mediaPushData = [...state.mediaPushData, newMedia];
      } else {
        const mediaToUpdate = state.mediaPushData[mediaIndex];
        mediaToUpdate.medias = [...action.payload.medias, ...state.mediaPushData[mediaIndex].medias];
        state.mediaPushData[mediaIndex] = mediaToUpdate;
      }
    },

    updateMessagesData: (state, action) => {
      const messageIndex = state.messagesData.findIndex((media) => media.conversationId === action.payload.conversationId);
      if (messageIndex === -1) {
        const newMessageData: IMessageData = {
          conversationId: action.payload.conversationId,
          messages: uniqBy([...action.payload.data], "_id").sort((a, b) => {
            if (a.createdAt && b.createdAt) {
              let da: any = new Date(a.createdAt),
                db: any = new Date(b.createdAt);
              return da - db;
            }
            return 1;
          }),
          total: action.payload.total,
          totalPages: action.payload.totalPages,
          page: action.payload.page,
        };
        state.messagesData = [...state.messagesData, newMessageData];
      } else {
        const messageToUpdate = state.messagesData[messageIndex];
        state.messagesData[messageIndex].totalPages = action.payload.totalPages;
        if (state.messagesData[messageIndex].page < action.payload.totalPages) {
          state.messagesData[messageIndex].page = action.payload.page;
        }
        state.messagesData[messageIndex].total = action.payload.total;

        messageToUpdate.messages = uniqBy([...state.messagesData[messageIndex].messages, ...action.payload.data], "_id").sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            let da: any = new Date(a.createdAt),
              db: any = new Date(b.createdAt);
            return da - db;
          }
          return 1;
        });
        state.messagesData[messageIndex] = messageToUpdate;
      }
    },

    handleNewMessageData: (state, action) => {
      const messageIndex = state.messagesData.findIndex((message) => message.conversationId === action.payload.conversationId);
      if (messageIndex === -1) {
        const newMessageData: IMessageData = {
          conversationId: action.payload.conversationId,
          messages: uniqBy([action.payload], "_id").sort((a, b) => {
            if (a.createdAt && b.createdAt) {
              let da: any = new Date(a.createdAt),
                db: any = new Date(b.createdAt);
              return da - db;
            }
            return 1;
          }),
          total: action.payload.total,
          totalPages: action.payload.totalPages,
          page: action.payload.page,
          //oldestMessageId: action.payload._id !== undefined ? action.payload._id.toString() : null,
        };
        state.messagesData = [...state.messagesData, newMessageData];
      } else {
        const messageToUpdate = state.messagesData[messageIndex];
        state.messagesData[messageIndex].totalPages = action.payload.totalPages;
        if (state.messagesData[messageIndex].page < action.payload.totalPages) {
          state.messagesData[messageIndex].page = action.payload.page;
        }
        state.messagesData[messageIndex].total = action.payload.total;
        messageToUpdate.messages = uniqBy([...state.messagesData[messageIndex].messages, action.payload], "_id");

        state.messagesData[messageIndex] = messageToUpdate;
      }
    },

    handleEditMessage: (state, action) => {
      const messageDataIndex = state.messagesData.findIndex((media) => media.conversationId === action.payload.conversationId);
      if (messageDataIndex !== -1) {
        const messageIndex = state.messagesData[messageDataIndex].messages.findIndex((msg) => msg._id === action.payload._id);
        if (messageIndex !== -1) {
          state.messagesData[messageDataIndex].messages[messageIndex] = action.payload;
        }
      }
    },

    handleConversationTypeChange: (state, action) => {
      const typeIndex = state.conversationsData.findIndex((typeConversation) => typeConversation.type === action.payload.from);
      if (typeIndex !== -1) {
        const conversationIndex = state.conversationsData[typeIndex].data.findIndex(
          (conversation) => conversation._id === action.payload.conversationId
        );
        if (conversationIndex !== -1) {
          // const conversationToEdit = state.conversationsData[typeIndex].data[conversationIndex];
          // state.conversationsData[typeIndex].data.splice(conversationIndex, 1);
          // if (action.payload.isToDeliver) {
          //   const newTypeIndex = state.conversationsData.findIndex((typeConversation) => typeConversation.type === CONVERSATION_TYPES.TO_DELIVER);
          //   if (newTypeIndex !== -1) {
          //     state.conversationsData[newTypeIndex].data = [conversationToEdit, ...state.conversationsData[newTypeIndex].data];
          //   }
          // } else {
          //   const newTypeIndex = state.conversationsData.findIndex((typeConversation) => typeConversation.type === action.payload.to);
          //   if (newTypeIndex !== -1) {
          //     state.conversationsData[newTypeIndex].data = [conversationToEdit, ...state.conversationsData[newTypeIndex].data];
          //   }
          // }
          const conversationToEdit = state.conversationsData[typeIndex].data.splice(conversationIndex, 1)[0];
          const targetType = action.payload.isToDeliver ? CONVERSATION_TYPES.TO_DELIVER : action.payload.to;
          const newTypeIndex = state.conversationsData.findIndex((typeConversation) => typeConversation.type === targetType);
          if (newTypeIndex !== -1) {
            state.conversationsData[newTypeIndex].data.unshift(conversationToEdit);
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserConversations.pending, (state) => {
      state.loading = REQUEST_LOADING_STATES.PENDING;
    });
    builder.addCase(getUserConversations.fulfilled, (state, action) => {
      const newConversation: any = action.payload.data.map((conversation) => {
        return formatConversation(conversation);
      });
      state.loading = REQUEST_LOADING_STATES.SUCCEEDED;
      const conversationIndex = state.conversationsData.findIndex((conversation) => conversation.type === action.payload.type);
      if (conversationIndex !== -1) {
        state.conversationsData[conversationIndex].data = uniqBy(
          [...state.conversationsData[conversationIndex].data, ...newConversation],
          "_id"
        ).sort(sortByUpdatedAtDesc);
        state.conversationsData[conversationIndex].lastDataLoad = new Date().getTime();
        state.conversationsData[conversationIndex].totalPages = action.payload.totalPages;
        if (state.conversationsData[conversationIndex].page < action.payload.totalPages) {
          state.conversationsData[conversationIndex].page = action.payload.page;
        }
        state.conversationsData[conversationIndex].total = action.payload.total;
      } else {
        state.conversationsData = [
          ...state.conversationsData,
          {
            type: action.payload.type,
            lastDataLoad: new Date().getTime(),
            totalPages: action.payload.totalPages,
            data: newConversation.sort(sortByUpdatedAtDesc),
            total: action.payload.total,
            page: action.payload.page,
          },
        ];
      }
    });
    builder.addCase(getUserConversations.rejected, (state, action) => {
      state.loading = REQUEST_LOADING_STATES.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(searchConversation.pending, (state) => {
      state.loading = REQUEST_LOADING_STATES.PENDING;
    });
    builder.addCase(searchConversation.fulfilled, (state, action) => {
      const newConversation: any = action.payload.map((conversation) => {
        return formatConversation(conversation);
      });
      state.searchConversations = [...newConversation].sort(sortByUpdatedAtDesc);
      state.loading = REQUEST_LOADING_STATES.SUCCEEDED;
    });
    builder.addCase(searchConversation.rejected, (state, action) => {
      state.loading = REQUEST_LOADING_STATES.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(getConversationById.fulfilled, (state, action) => {
      if (state.selectedConversation._id) {
        if (state.selectedConversation._id === action.payload._id) {
          state.selectedConversation = formatConversation(action.payload);
        }
      } else {
        state.selectedConversation = formatConversation(action.payload);
      }
      if (state.conversationsData.length > 0) {
        const index = state.conversationsData.findIndex((conversation) => conversation.type === action.payload.conversationType);
        if (index !== -1) {
          const conversationIndex = state.conversationsData[index].data.findIndex((conversation) => conversation._id === action.payload._id);
          if (conversationIndex !== -1) {
            state.conversationsData[index].data[conversationIndex] = formatConversation({
              ...action.payload,
              hasNewMessages: state.conversationsData[index].data[conversationIndex].hasNewMessages,
            });
          }
        }
      }
    });

    builder.addCase(getConversationById.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(getConversationMedias.pending, (state) => {
      state.loading = REQUEST_LOADING_STATES.PENDING;
    });
    builder.addCase(getConversationMedias.fulfilled, (state, action) => {
      const conversationIndex = state.mediaPushData.findIndex((media) => media.conversationId === action.payload.conversationId);
      if (conversationIndex === -1) {
        const newMedia: IGalleryMediaData = {
          conversationId: action.payload.conversationId,
          medias: action.payload.medias,
          shouldUpdate: false,
        };
        state.mediaPushData = [...state.mediaPushData, newMedia];
      } else {
        //TODO: the rest of logic
      }
      state.loading = REQUEST_LOADING_STATES.SUCCEEDED;
    });
    builder.addCase(getConversationMedias.rejected, (state, action) => {
      state.loading = REQUEST_LOADING_STATES.FAILED;
      state.error = action.error.message;
    });
  },
});

const formatConversation = (conversation: IConversationDTO) => {
  const currentUserId = authService.getId();
  const currentUser = conversation.members?.find((member) => member.userId === currentUserId);
  const member = conversation.members?.find((member) => member.userId !== currentUserId);
  return {
    ...omit(conversation, ["members", "isMuted", "createdBy"]),
    currentUser: currentUser,
    interlocutor: member,
  };
};

const sortByUpdatedAtDesc = (a: IConversation, b: IConversation) => {
  if (a.updatedAt && b.updatedAt) {
    let da: any = new Date(a.updatedAt),
      db: any = new Date(b.updatedAt);
    return db - da;
  }
  return 1;
};

export const isConversationDataExist = (state: StoreState, type: string) => {
  return state.messages.conversationsData.findIndex((conversation) => conversation.type === type) !== -1;
};

export const {
  selectConversation,
  setOnlineUsers,
  updateConversations,
  readAllMessagesInConversation,
  updateConversationHasNewMessages,
  resetUserConversations,
  updateConversationsOrder,
  updateConversationsMediaPush,
  updateMessagesData,
  handleNewMessageData,
  handleEditMessage,
  handleConversationTypeChange,
} = messagesSlice.actions;

export default messagesSlice.reducer;
