import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import TimePickerComponent from "./TimePickerComponent";
import "./index.less";

dayjs.extend(advancedFormat);

type props = {
  date: string | null;
  disabledDate: (date: Dayjs) => boolean;
  disabledTime: (current: Dayjs | null) => any;
  onChange: (type: string, data: any) => void;
};

const DateTimePickerComponent: React.FC<props> = ({ date, disabledDate, disabledTime, onChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (date) {
      setValue(dayjs(date));
    } else {
      setValue(null);
    }
  }, [date]);

  const handleChange = (selectedDate: dayjs.Dayjs | null) => {
    setValue(selectedDate);
    onChange("date", selectedDate);
  };

  return (
    <>
      <Row gutter={7}>
        <Col span={12}>
          <DatePicker
            className="dateTimePicker"
            placeholder={t("select-date").toString()}
            suffixIcon={null}
            showTime={false}
            disabledDate={disabledDate}
            onChange={(date) => handleChange(date)}
            inputReadOnly={true}
          />
        </Col>
        <Col span={12}>
          <TimePickerComponent date={value?.toString() ?? null} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
};

export default DateTimePickerComponent;
