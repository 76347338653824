import { Badge, Row, Space, Typography, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONVERSATION_TYPES, CREATOR, HOURS_TO_EXPIRE, REQUEST_LOADING_STATES } from "../../helpers/constant";
import { IConversation, IConversationData, IConversationDTO } from "../../helpers/types";
import {
  getUserConversations,
  isConversationDataExist,
  resetUserConversations,
  selectConversation,
  updateConversationHasNewMessages,
  updateConversations,
} from "../../redux/messages/messagesSlice";
import type { AppDispatch, StoreState } from "../../redux/store";
import { authService } from "../../services";
import { socket } from "../../socket";
import ConversationAvatarComponent from "../ConversationAvatarComponent";
import ConversationOnlineStatusComponent from "../ConversationOnlineStatusComponent";
import CountDownComponent from "../CountDownComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import VerifiedUserIconComponent from "../VerifiedUserIconComponent/VerifiyedUserIconComponent";
import "./index.less";
import { shouldRefetchData } from "../../utls/FunctionsUtil";
type props = {
  user: string;
  type: string;
};

const MessageTabListComponent: React.FC<props> = ({ user, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { searchConversations, loading, error } = useSelector((state: StoreState) => state.messages);
  const conversationsData = useSelector((state: StoreState) => state.messages.conversationsData.find((conversation) => conversation.type === type));
  const shouldFetch = useRef(true);
  const page = conversationsData?.page || 1;
  const hasNextPage = (conversationsData && conversationsData.data.length > 0 && conversationsData?.page < conversationsData?.totalPages) || false;
  //const [conversations, setConversations] = useState<IConversation []>([]);
  //const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    dispatch(resetUserConversations());
    if (shouldFetch.current && type !== CONVERSATION_TYPES.SEARCH) {
      shouldFetch.current = false;
      if (!conversationsData) {
        dispatch(getUserConversations({ page, type }));
      }
      // else {
      //   console.log('calinng first useeffect')
      //   //updateConversationData(conversationsData);
      // }
    }

    // socket.on("message", handleMessage);
    // socket.on("conversation-created", handleConversationCreated);

    // return () => {
    //   socket.off("message", handleMessage);
    //   socket.off("conversation-created", handleConversationCreated);
    //   //dispatch(resetUserConversations());
    // };
  }, []);

  // useEffect(() => {
  //   if(conversationsData) {
  //     console.log('calinng useeffect')
  //     //updateConversationData(conversationsData);
  //   }
  // }, [conversationsData?.data, conversationsData?.type])

  // const updateConversationData = (conversationsData: IConversationData) => {
  //   //setConversations(conversationsData.data);
  //   //setTotalPages(conversationsData.totalPages);
  //   //setPage(conversationsData.page);
  //   //if (totalPages !== -1 && page >= totalPages) {
  //     console.log(' conversationsData.data.length --- >', conversationsData.data.length > 0)
  //     console.log('page > totalPages---> 2', page > totalPages)
  //     console.log('condi', conversationsData.data.length > 0 && page > totalPages)
  //     //setHasNextPage(conversationsData.data.length > 0 && page > totalPages);
  //   //}
  // }

  useEffect(() => {
    if (loading === REQUEST_LOADING_STATES.FAILED && error) {
      message.error("Something went wrong.");
    }
  }, [loading, error]);

  function loadMoreConversations() {
    console.log("hasN", hasNextPage);
    if (hasNextPage) {
      const nextPage = page + 1;
      dispatch(
        getUserConversations({
          page: nextPage,
          type: type,
        })
      );
    }
  }

  // function handleConversationCreated(data: IConversationDTO) {
  //   if(conversationsTypeExist)
  //     dispatch(updateConversations(data));
  // }

  // function handleMessage(data: any) {
  //   dispatch(
  //     updateConversationHasNewMessages({
  //       conversationId: data.conversationId,
  //       createdAt: data.createdAt,
  //       conversationType: data.conversationType
  //     })
  //   );
  // }

  const handleClick = (conversation: IConversation) => {
    dispatch(selectConversation(conversation));
    if (authService.getRole() === CREATOR) {
      navigate(`/creator/conversation/${conversation._id}`);
    } else {
      navigate(`/fan/requestMedia/${conversation._id}`);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading === REQUEST_LOADING_STATES.PENDING,
    hasNextPage,
    onLoadMore: loadMoreConversations,
  });

  const conversationsToDisplay = type === CONVERSATION_TYPES.SEARCH ? searchConversations : conversationsData?.data || [];

  return (
    <Space className="w-full gap-14" direction="vertical">
      {loading === REQUEST_LOADING_STATES.SUCCEEDED && conversationsToDisplay.length === 0 ? (
        <Typography.Text className="not-conversation-text">{t("you-dont-have-conversations")}</Typography.Text>
      ) : (
        conversationsToDisplay.map((conversation) => {
          const remainingTime = dayjs(conversation?.undeliveredMessage?.createdAt).add(HOURS_TO_EXPIRE, "hours").diff(dayjs(), "seconds");

          return (
            <Row key={conversation._id} className="justify-content-between items-center cursor-pointer" onClick={() => handleClick(conversation)}>
              <Space className="gap-14">
                <ConversationAvatarComponent
                  userId={conversation.interlocutor?.userId}
                  avatar={conversation.interlocutor?.avatar}
                  name={conversation.interlocutor?.name}
                />
                <Space className="gap-4" direction="vertical">
                  <Space className="items-center">
                    <Typography.Text className="messageNameLabel">
                      {conversation.interlocutor?.name === "user-not-found" ? t("user-not-found") : conversation.interlocutor?.name}
                    </Typography.Text>
                    <VerifiedUserIconComponent
                      userId={conversation.interlocutor?.userId}
                      verified={conversation.interlocutor?.isVerified}
                      fetch={false}
                    />
                  </Space>
                  <Typography.Text className={`messageStatusLabel ${conversation.hasNewMessages ? "text-white-color" : ""}`}>
                    {conversation.hasNewMessages ? (
                      <span>{t("new-conversation-message")}</span>
                    ) : (
                      <ConversationOnlineStatusComponent userId={conversation.interlocutor?.userId} className={"messageStatusLabel"} />
                    )}
                  </Typography.Text>
                </Space>
              </Space>

              <div className="d-flex items-center">
                {type === CONVERSATION_TYPES.TO_DELIVER && remainingTime > 0 ? (
                  <div className="clock-background">
                    <CountDownComponent targetDate={conversation?.undeliveredMessage?.createdAt!} showSeconds={false} />
                  </div>
                ) : null}
                {conversation.hasNewMessages && <Badge className="messageNewBadge" status="success" />}
              </div>
            </Row>
          );
        })
      )}
      {(loading === REQUEST_LOADING_STATES.PENDING || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
    </Space>
  );
};

export default MessageTabListComponent;
