import { LockOutlined } from "@ant-design/icons";
import { Space, Typography, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VideoIcon from "../../assets/icons/jouer.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white-2.svg";
import { Media } from "../../helpers/types";
import { compressImage, convertHeicFile, generateThumbnailURL, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import "./index.less";

type props = {
  showTitle?: boolean;
  crop?: boolean;
  disabled?: boolean;
  title?: string;
  maxCount?: number;
  defaultList?: any[];
  onChange?: (fileMap: Map<string, Media>, fileList?: UploadFile<any>[]) => void;
  onFilesChange?: (fileList: UploadFile<[]>[]) => void;
  children?: any;
  isVideoAllowed?: boolean;
  greenCircle?: boolean;
  addPublication?: boolean;
  iconSize?: number;
};

const AddMediaComponent: React.FC<props> = ({
  showTitle = true,
  crop = false,
  disabled = false,
  title,
  maxCount,
  defaultList,
  onFilesChange,
  children,
  isVideoAllowed = true,
  greenCircle = false,
  addPublication = false,
  iconSize = 15,
}) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (onFilesChange !== undefined) {
      onFilesChange(fileList);
    }
  }, [fileList]);

  const customItemRender = (originNode: React.ReactNode, file: UploadFile) => {
    let lock: boolean = false;
    let isVideo: boolean = file.type?.includes("video")!;
    defaultList?.forEach((e) => {
      if (file.uid === e.uid && e.isPrivate) {
        lock = true;
      }
    });
    return (
      <>
        {originNode}
        {lock && <LockOutlined className="lockIcon" />}
        {isVideo && <img src={VideoIcon} style={!lock ? { right: "-78px" } : {}} alt="video icon" className="videoIcon" />}
      </>
    );
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      //console.log("in before **upload** file", file.uid, file);
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        //console.log("beforeUpload 1 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        //handleConvertedFile(file.uid, file, true);
        return false;
      } else if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false);
        // convert file
        const convertedFile = await convertHeicFile(file);
        //console.log("beforeUpload 2 convertedFile", convertedFile);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        //console.log("beforeUpload 3 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type.includes("video/") && isVideoAllowed) {
        handleConvertedFile(file.uid, file, true);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const beforeCrop: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      //console.log("in before ++crop++ file", file.uid, file);
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        //console.log("beforeCrop 1 compressedImage", compressedImage);
        if (compressedImage !== null) {
          return compressedImage;
        }
        return true;
      } else if (file.type === "image/heic" || file.type === "") {
        // convert file
        const convertedFile = await convertHeicFile(file);
        //console.log("beforeCrop 2 convertedFile", convertedFile);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        //console.log("beforeCrop 3 compressedImage", compressedImage);
        if (compressedImage !== null) {
          return compressedImage;
        }
        return convertedFile;
      } else if (file.type.includes("video/")) {
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  //console.log("fileList", fileList);

  const handleConvertedFile = async (uid: string, file: File, converted: boolean) => {
    //console.log("uid, converted, file :>> ", uid, converted, file);
    if (!converted) {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
      setFileList((prevList) => [fileObj, ...prevList]);
    } else {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
      if (file.type.includes("video")) {
        const result = await generateThumbnailURL(file as RcFile);
        fileObj.url = result;
      }
      setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    }
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const onRemove = async (file: UploadFile<any>) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
  };

  // const onPreview = async (file: UploadFile) => {
  //   let src = file.url as string;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj as RcFile);
  //       reader.onload = () => resolve(reader.result as string);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };

  return (
    <Space
      className={`gap-13 
        ${
          children === undefined
            ? !addPublication
              ? greenCircle
                ? "addMediaWrapperCercleGreen"
                : fileList.length === 0
                  ? "w-full addMediaWrapper"
                  : "w-full addMediaSquareDash"
              : "addMediaWrapperPublication"
            : "childrenContainer"
        } `}
      direction="vertical"
    >
      {showTitle && <Typography.Text className="font-30-bold text-white-color">{title || t("media")}</Typography.Text>}
      {crop ? (
        <Upload
          accept="image/*,video/*"
          disabled={disabled}
          action=""
          itemRender={(originNode: any, file: UploadFile) => customItemRender(originNode, file)}
          defaultFileList={defaultList}
          fileList={fileList}
          listType="picture-card"
          maxCount={maxCount}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          isImageUrl={isImageUrl}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
          }}
        >
          {children !== undefined ? (
            children
          ) : maxCount ? (
            fileList.length < maxCount && (
              <div className={`${greenCircle ? "addCircleGreen" : "addCircle"}  ${disabled ? "disabled" : ""}`}>
                <img src={PlusIcon} width={iconSize} alt="plus" />
              </div>
            )
          ) : (
            <div className={`addCircle ${disabled ? "disabled" : ""}`}>
              <img src={PlusIcon} width={greenCircle ? 40 : 15} alt="plus" />
            </div>
          )}
        </Upload>
      ) : (
        <Upload
          accept="image/*,video/*"
          disabled={disabled}
          action=""
          itemRender={(originNode: any, file: any) => customItemRender(originNode, file)}
          defaultFileList={defaultList}
          fileList={fileList}
          listType="picture-card"
          maxCount={maxCount}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          isImageUrl={isImageUrl}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
          }}
          multiple={true}
        >
          {children !== undefined ? (
            children
          ) : maxCount ? (
            fileList.length < maxCount && (
              <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                <img src={PlusWhiteIcon} width={25} alt="plus" />
              </div>
            )
          ) : (
            <div className={`addCircle ${disabled ? "disabled" : ""}`}>
              <img src={PlusWhiteIcon} width={25} alt="plus" />
            </div>
          )}
        </Upload>
      )}
    </Space>
  );
};

export default AddMediaComponent;
