import type { UploadFile } from "antd";
import { Button, Col, Input, InputNumber, Layout, Row, Space, Spin, Typography, message } from "antd";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../../assets/icons/creator/unlock-link/cross.svg";
import FingerPrint from "../../../assets/icons/d_blanc.svg";
import ArrowLeftIcon from "../../../assets/icons/new-arrow-left.svg";
import DecryptLogo from "../../../assets/images/logo_decrypt_blanc_resultat.webp";
import AddUnlockLinkMediaComponent from "../../../components/AddMediaComponent/AddUnlockLinkMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import UnlockLinkInfoModalComponent from "../../../components/UnlockLinkInfoModalComponent";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { MAX_PRICE } from "../../../helpers/constant";
import { IDecrypt } from "../../../helpers/types";
import { fileService } from "../../../services";
import { decryptService } from "../../../services/decrypt.service";
import { copyToClipboardCustom } from "../../../utls/FunctionsUtil";
import "./index.less";

type decryptIsCreated = {
  created: boolean;
  token: string;
  url: string;
};

type decryptIsCreatedWOCreated = Omit<decryptIsCreated, "created">;

const CreatorCreateDecryptPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //const [showLinkSettings, setShowLinkSettings] = useState<boolean>(false);
  const [showDecryptTitle, setShowDecryptTitle] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [quickFiles, setQuickFiles] = useState<any>("");
  //const [isMultiple, setIsMultiple] = useState<boolean>(true);
  //const [numberMaxOfUnlocks, setNumberMaxOfUnlocks] = useState<number>(1000);
  const [decrypt, setDecrypt] = useState<decryptIsCreated>({
    created: false,
    token: "",
    url: "",
  });
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const handlePriceChange = (value: number | null) => {
    if (value !== null) {
      setPrice(value);

      // maximum price alert 500€
      if (value > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
      }
    }
  };

  const params: any = new URLSearchParams(window.location.search);
  const paramsFiles: Array<any[]> = JSON.parse(params.get("selected"));
  const [paramIds, setParamIds] = useState<any>([]);

  const getAllSelectedFiles = async (fileIds: Array<any>) => {
    await fileService.allSelectedFiles(fileIds).then((response: any) => {
      response.forEach((item: any) => {
        let fileType = "";
        let fileExt = "";
        let isVideo = false;
        if (item.url.includes(".mp4")) {
          fileType = "video/mp4";
          fileExt = "mp4";
          isVideo = true;
        } else {
          fileType = "image/webp";
          fileExt = "webp";
        }
        let originFileObj2 = {
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: "",
          type: "quick-link",
        };
        let JsonString = JSON.stringify(originFileObj2);
        let blob = new Blob([JsonString], { type: fileType });
        let file = new File([blob], `newQuickFile.${fileExt}`, { type: fileType });
        setFileList((prevFiles: any) => [
          ...prevFiles,
          {
            ...item,
            imgType: "quick-file",
            isPrivate: true,
            status: "done",
            uid: item._id,
            name: item._id,
            percent: 0,
            thumbUrl: isVideo ? item?.thumbnailUrl : item?.url,
            type: fileType,
            originFileObj: file,
          },
        ]);
        setQuickFiles(item?._id);
        setParamIds(null);
      });
    });
  };

  useEffect(() => {
    if (paramsFiles?.length !== 0) {
      setParamIds(paramsFiles);
    }
    return () => {
      setParamIds([]);
      //TODO: fix this code
      //window.history.replaceState(null, "", window.location.pathname);
    };
  }, []);

  useEffect(() => {
    if (paramIds !== null && paramIds !== undefined && paramIds?.length !== 0) {
      getAllSelectedFiles(paramIds);
    }
  }, [paramIds]);

  /* const handleIsMultipleLinkChange = (e: RadioChangeEvent) => {
    setIsMultiple(e.target.value);
  };

  const handleNumberMaxOfUnlocksChange = (value: number) => {
    setNumberMaxOfUnlocks(value);
  }; */

  const validateMedia = async () => {
    if (price !== undefined && price < 5) {
      message.error(t("unlock-link-price-error-message"));
    } else {
      //setShowLinkSettings(true);
      setShowDecryptTitle(true);
    }
  };

  const generateLink = async () => {
    if (price !== undefined && price > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
      return;
    }
    setLoading(true);
    const file: any = fileList[0];
    if (file?.imgtype && file?.imgtype === "quick-file") {
      setQuickFiles(file?.name);
      setFileList([]);
    }
    const linkToCreate: Partial<IDecrypt> = {
      title: title,
      price: price !== undefined ? price : -1,
      isMultipleLink: true, //isMultiple,
      isInfiniteUnlocks: true, //numberMaxOfUnlocks === 1000,
      numberMaxOfUnlocks: 1000, //numberMaxOfUnlocks,
    };
    const formData = new FormData();
    for (const [key, value] of Object.entries(linkToCreate)) {
      formData.set(key, value);
    }
    {
      !quickFiles && formData.append("files", file?.originFileObj as any);
    }
    formData.append("quickFiles", quickFiles);
    await decryptService
      .createDecrypt(formData, setProgress)
      .then((data: decryptIsCreatedWOCreated) => {
        setDecrypt({
          created: true,
          token: data.token,
          url: data.url,
        });
      })
      .catch((e) => {
        console.log("Error in posting Decrypt: ", e);
        message.error(t("something-want-wrong"));
      })
      .finally(() => {
        console.log("execute finally --------------------------- ");
        setLoading(false);
      });
  };

  const copyLink = async () => {
    const link = `${window.location.origin}/decrypt?token=${decrypt?.token}`;
    await copyToClipboardCustom(link, t("something-want-wrong"), t("link-copied") as string);
  };

  const showModal = () => {
    setShowInfoModal(true);
  };

  const onCloseInfoModal = () => {
    setShowInfoModal(false);
    navigate("/creator/profile");
  };

  /* const formatter = (value: number | undefined) => {
    if (value === 1000) {
      return "∞";
    }
    return `${value}`;
  }; */

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
      <Layout>
        <Layout.Content className={`content creatorUnlockLinkContentWrapper gradient-background`}>
          {!decrypt.created ? (
            <Row className="relative justify-content-center header-title-container">
              {!showDecryptTitle ? (
                <>
                  <ArrowLeftIconComponent top={0} />
                </>
              ) : (
                <>
                  <img className="header-arrow-left" src={ArrowLeftIcon} alt="arrow left" onClick={() => setShowDecryptTitle((r) => !r)} />
                </>
              )}
              <Typography.Text className="header-title mt-6">
                <img src={FingerPrint} width={30} style={{ marginRight: "12px" }} alt="Loly - Finger Print" />
                <img src={DecryptLogo} width={150} alt="Loly - Decrypt logo" />
              </Typography.Text>
            </Row>
          ) : (
            <Col span={24}>
              <div className="headerRow relative justify-content-center">
                <Typography.Text className="font-40-bold text-white-color">{t("everything-is-ready")}</Typography.Text>
              </div>
              <div className="text-center shareDescritpion mt-10">
                <Typography.Text className="font-13-medium text-grey-color">{t("share-link-description")}</Typography.Text>
              </div>
            </Col>
          )}
          {!decrypt.created ? (
            <>
              {!showDecryptTitle ? (
                <Space className="w-full gap-21" direction="vertical">
                  <Row>
                    <AddUnlockLinkMediaComponent fileList={fileList} setFileList={setFileList} setQuickFiles={setQuickFiles} />
                  </Row>

                  <div className={"w-full unlockLinkPriceWrapper"}>
                    <div className={"divider-decrypt"} />
                    <Typography.Text className="font-20-medium text-grey-color mt-30">{t("your-price")}</Typography.Text>

                    {!showInput ? (
                      <div
                        className={price ? "unlockLinkPriceInputCompleted" : "unlockLinkPriceInput"}
                        onClick={() => {
                          setShowInput(true);
                        }}
                      >
                        {price !== undefined ? price : "0.00"} €
                      </div>
                    ) : (
                      <InputNumber
                        className={"unlockLinkPriceInputCompleted"}
                        min={5}
                        bordered={false}
                        onChange={(value) => {
                          handlePriceChange(value);
                        }}
                        value={price}
                        autoFocus={true}
                        onBlur={() => {
                          setShowInput(false);
                        }}
                        inputMode="numeric"
                      />
                    )}
                    {price !== undefined && (
                      <div className={"w-full unlockLinkPriceWrapper"}>
                        <Typography.Text className="font-15-regular text-grey-color">
                          {t("you-will-receive")} {(price * 0.9).toFixed(2)} €
                        </Typography.Text>
                      </div>
                    )}
                  </div>

                  <Row>
                    <AuthButtonComponent label={t("generate-link")} onClick={validateMedia} disabled={isEmpty(fileList) || price === undefined} />
                  </Row>
                </Space>
              ) : (
                // <>
                //   <div className={"mt-40"}>
                //     <Radio.Group onChange={handleIsMultipleLinkChange} value={isMultiple} style={{ width: "100%" }}>
                //       <Row className="justify-content-between">
                //         <Typography.Text className="font-27-bold text-white-color">{t("unique-link")}</Typography.Text>
                //         <Radio className="radio" value={false} />
                //       </Row>
                //       <Typography.Text className=".font-20-medium text-grey-color">{t("unique-link-description")}</Typography.Text>

                //       <Row className="justify-content-between mt-35">
                //         <Typography.Text className="font-27-bold text-white-color">{t("multiple-link")}</Typography.Text>
                //         <Radio className="radio" value={true} />
                //       </Row>
                //       <Typography.Text className=".font-20-medium text-grey-color">{t("multiple-link-description")}</Typography.Text>
                //     </Radio.Group>

                //     <div>
                //       <div className={"infinityWrapper"}>
                //         {numberMaxOfUnlocks === 1000 ? <img src={InfinityGreenIcon} alt={"infinity"} /> : <img src={InfinityIcon} alt={"infinity"} />}
                //       </div>

                //       <Slider
                //         value={numberMaxOfUnlocks}
                //         disabled={!isMultiple}
                //         min={2}
                //         max={1000}
                //         onChange={handleNumberMaxOfUnlocksChange}
                //         tooltip={{ formatter: formatter }}
                //       />
                //     </div>
                //   </div>

                //   <Row>
                //     <AuthButtonComponent label={t("generate-link")} onClick={generateLink} />
                //   </Row>
                // </>
                <>
                  <div className="container-title horizontal-center">
                    <Space className="w-full gap-21" direction="vertical">
                      <Typography.Text className="font-20-bold text-white-color">{t("give-your-decrypt-a-name")}</Typography.Text>
                      <Input
                        className="titleInput"
                        bordered={false}
                        size="large"
                        step="0.01"
                        onChange={(value) => {
                          setTitle(value.target.value);
                        }}
                        value={title}
                        placeholder={t("my-name")!}
                        autoFocus={true}
                      />
                      <Row justify="center">
                        <div className="divider-input"></div>
                      </Row>
                      <Row justify="center">
                        <Button className="authBtn mt-9" style={{ width: "40%", fontSize: 20 }} onClick={generateLink} disabled={!title}>
                          {t("create")}
                        </Button>
                      </Row>
                    </Space>
                  </div>
                </>
              )}
            </>
          ) : (
            <Space className="w-full gap-21 mt-16" direction="vertical">
              <Row className="justify-content-center">
                {decrypt.url.includes(".mp4") ? (
                  <VideoPlayer videoUrl={decrypt?.url!} thumbnailUrl={""} watermarkText={""} autoPlay={true} />
                ) : (
                  <img src={decrypt.url} width={170} alt="Loly Decrypt" className="linkImage" />
                )}
              </Row>
              <Row className="justify-content-center mt-20">
                <Button className={"copyLinkBtn"} size={"large"} onClick={copyLink}>
                  {t("copy-link")}
                </Button>
              </Row>
              <Row className="justify-content-center mt-20">
                <img src={CrossIcon} alt="cross" className={"cursor-pointer"} onClick={showModal} />
              </Row>
            </Space>
          )}
        </Layout.Content>
        <UnlockLinkInfoModalComponent isOpen={showInfoModal} onClose={onCloseInfoModal} />
      </Layout>
    </Spin>
  );
};

export default CreatorCreateDecryptPage;
