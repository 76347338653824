import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClockIcon from "../../assets/icons/countdownlogo.svg";
import { HOURS_TO_EXPIRE } from "../../helpers/constant";
import "./index.less";

type Props = {
  targetDate?: string;
  showTooltip?: boolean;
  expireAt?: any;
  hoursToExpire?: number;
  showDays?: boolean;
  showSeconds?: boolean;
};

function CountDownComponent({ targetDate, expireAt, hoursToExpire, showTooltip = true, showDays = false, showSeconds = true }: Props) {
  const { t } = useTranslation();

  const target = expireAt !== undefined ? dayjs(expireAt) : dayjs(targetDate).add(hoursToExpire || HOURS_TO_EXPIRE, "hours");

  const calculateTimeRemaining = () => {
    const days = target.diff(dayjs(), "days");
    const hours = showDays ? target.diff(dayjs().add(days, "days"), "hours") : target.diff(dayjs(), "hours");
    const minutes = showDays
      ? target.diff(dayjs().add(days, "days").add(hours, "hours"), "minutes")
      : target.diff(dayjs().add(hours, "hours"), "minutes");
    const seconds = showDays
      ? target.diff(dayjs().add(days, "days").add(hours, "hours").add(minutes, "minutes"), "seconds")
      : target.diff(dayjs().add(hours, "hours").add(minutes, "minutes"), "seconds");
    return { days, hours, minutes, seconds };
  };

  const [remaining, setRemaining] = useState(calculateTimeRemaining());
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const newRemaining = calculateTimeRemaining();
      if (newRemaining.hours <= 0 && newRemaining.minutes <= 0 && newRemaining.seconds <= 0) {
        clearInterval(intervalRef.current as NodeJS.Timeout);
      }
      setRemaining(newRemaining);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const displayRemainingTime = () => {
    let time = "";
    if (remaining.days <= 0 && remaining.hours <= 0 && remaining.minutes <= 0 && remaining.seconds <= 0) {
      time = t("expired");
    } else {
      if (remaining.days > 0 && showDays) {
        time += remaining.days + t("d") + " ";
      }
      if (remaining.hours > 0) {
        time += remaining.hours + "h ";
      }
      if (remaining.minutes > 0) {
        time += remaining.minutes + "min ";
      }
      if (showSeconds && remaining.seconds > 0) {
        time += remaining.seconds + "s";
      }
    }
    return time;
  };

  if (showTooltip) {
    return (
      <Tooltip className="messageClockWrapper" title={t("media-condition-message")}>
        <img src={ClockIcon} width={20} alt="clock" />

        <span
          className={`messageClockLabel ${remaining.hours <= 0 && remaining.minutes <= 0 && (!showSeconds || remaining.seconds <= 0) ? t("expired") : ""}`}
        >
          {displayRemainingTime()}
        </span>
      </Tooltip>
    );
  } else {
    return <>{displayRemainingTime()}</>;
  }
}

export default CountDownComponent;
