import React, { ChangeEventHandler } from "react";
import { Input } from "antd";
import "./index.less";

type props = {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

const TextAreaComponent: React.FC<props> = ({ className, placeholder, disabled, value, onChange, name }) => {
  return <Input.TextArea className={className} name={name} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} />;
};

export default TextAreaComponent;
