import React, { useEffect, useState } from "react";
import "./index.less";

import CardChip from "../../assets/images/grey-card.webp";
import { Button, Card, Row, Typography } from "antd";
import { t } from "i18next";
import { ConfirmationModal } from "../ModalConfirmation";

interface props {
  data: any;
  totalData: number;
  className?: string;
  selectedCard?: any;
  handleClick?: any;
  isDelete?: boolean;
  onDelete?: any;
}

const SavedCards: React.FC<props> = ({ data, totalData, className, selectedCard, handleClick, isDelete = false, onDelete }) => {
  const [isConfirmationModal, setIsConfirmationModal] = useState<any>(false);

  const handleDeleteClick = () => {
    setIsConfirmationModal(true);
  };

  function formatCardNumber(first6: any, last4: any) {
    const hiddenSection = " * ".repeat(6);
    return `${first6}${hiddenSection}${last4}`;
  }

  return (
    <div className={`${selectedCard === data ? "selected-card" : ""}`}>
      <Card onClick={handleClick}>
        <Row className="gap-7 w-full">
          <Row className="d-flex justify-between align-center w-full">
            <Row className="d-flex gap-8">
              <Typography.Text className="text-gradient-color font-18-bold">{t("registered-card")}</Typography.Text>
            </Row>
            <Row className="mr-2">
              <Button className={`select-card-button ${selectedCard === data ? "unselect" : ""}`}>
                {selectedCard === data ? t("Unselect") : t("Select")}
              </Button>
            </Row>
          </Row>
        </Row>
        <Row className="d-flex flex-column mt-79">
          <Typography.Text className="text-gradient-color holder-name-card">{data?.cardholderName}</Typography.Text>
          <Typography.Text className="text-gradient-color font-14-regular mt-5">{formatCardNumber(data?.first6, data?.last4)}</Typography.Text>
        </Row>
      </Card>
      <ConfirmationModal
        bodyElement={<p className="modal-confirm-body-text">{t("save-card-delete-confirm")}</p>}
        canBtnMsg={t("modal-cancel-btn")}
        confBtnMsg={t("modal-confirm-btn")}
        handleConfirm={() => {
          onDelete(data?.id);
          setIsConfirmationModal(false);
        }}
        handleToggle={() => setIsConfirmationModal(false)}
        open={isConfirmationModal}
      />
    </div>
  );
};

export default SavedCards;
