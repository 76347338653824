import { Row } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import "./index.less";

interface Props {
  currentValue?: string;
  setCurrentValue?: any;
  countries: any;
}

const CountryDropdownComponent: React.FC<Props> = ({ currentValue, setCurrentValue, countries }) => {
  const [showCountry, setShowCountry] = useState(true);
  const handleChange = (e: any) => {
    if (e.target.value !== t("country")) {
      setShowCountry(false);
    }
    if (setCurrentValue) {
      setCurrentValue(e?.target?.value);
    }
  };

  return (
    <Row className="countryDropdown w-100 mb-20">
      <select className="countrySelect w-full" onChange={handleChange} value={currentValue}>
        {showCountry && <option value={t("country") as string}>{t("country")}</option>}
        {countries?.map((country: any) => (
          <option key={country?.name} value={country?.name}>
            {country?.name}
          </option>
        ))}
      </select>
    </Row>
  );
};

export default CountryDropdownComponent;
