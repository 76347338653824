import { Layout, message, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS, TARGET_PAGE } from "../../../helpers/constant";
import "./index.less";
import { FormEvent, useEffect, useState } from "react";
import { authService } from "../../../services";
import { IHelpContactRequest } from "../../../helpers/types";
import InputComponent from "../../../components/InputComponent";
import TextAreaComponent from "../../../components/TextAreaComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import { userService } from "../../../services";

function FanBugPage() {
  const { t } = useTranslation();
  const user = authService.getUser();
  const [isFormValid, setIsFormValid] = useState(false);
  const [helpContactRequest, setHelpContactRequest] = useState<IHelpContactRequest>({
    subject: "",
    description: "",
    userId: user.sourceId,
    target: TARGET_PAGE.bugPage,
  });

  const handleChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;
    setHelpContactRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { subject, description } = helpContactRequest;
    if (subject.trim() !== "" && description.trim() !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [helpContactRequest]);

  const handleSubmit = () => {
    userService
      .helpContactSupport(helpContactRequest)
      .then((data: any) => {
        if (data.message) {
          message.error(data.message);
        } else {
          setHelpContactRequest({
            subject: "",
            description: "",
            userId: user.sourceId,
            target: TARGET_PAGE.bugPage,
          });
          message.success(t("your-message-has-been-sent"));
        }
      })
      .catch((error) => {
        message.error(error || "Something went wrong.");
      });
  };

  return (
    <div>
      <Layout className="gradient-background-help-fan no-scroll-page">
        <Layout.Header className="header background-transparent">
          <Row className="relative justify-content-center align-items-center">
            <ArrowLeftIconComponent top={6} />
            <Typography.Text className="ml-20 header-title-black-26">{t("spotted-a-bug")}</Typography.Text>
          </Row>
        </Layout.Header>
        <Layout.Content className="content helpandcontactwraper background-transparent">
          <Row className="mt-10">
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="font-20-bold text-white-color">{t("subject")}</Typography.Text>
              <InputComponent
                className="helpMessageInput"
                placeholder={t("subject")!}
                type="text"
                name="subject"
                value={helpContactRequest.subject}
                onChange={handleChange}
              />
            </Space>
          </Row>
          <Row className="mt-10">
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="font-20-bold text-white-color">{t("description")}</Typography.Text>
              <TextAreaComponent
                className="helpMessageTextArea"
                name="description"
                placeholder={t("your-message...")!}
                value={helpContactRequest.description}
                onChange={handleChange}
              />
            </Space>
          </Row>
          <Row className="mt-20">
            <AuthButtonComponent height="60px" fontSize="18px" label={t("send")} disabled={!isFormValid} onClick={handleSubmit} />
          </Row>
        </Layout.Content>
        <FooterComponent type="fan" page={FOOTER_IDS.HOME} />
      </Layout>
    </div>
  );
}

export default FanBugPage;
