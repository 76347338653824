import React from "react";
import { Col, Space, Row, Typography, Tag } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import TotalRevenueComponent from "../TotalRevenueComponent";

type props = {
  totalRevenue?: number;
  reports?: any;
};

const CreatorPaymentHistoryMonthlyReportTabComponent: React.FC<props> = ({ totalRevenue, reports }) => {
  const { t } = useTranslation();

  return (
    <Col className="monthlyReportTab">
      <TotalRevenueComponent totalRevenue={totalRevenue} />

      <Space className="w-full mt-24 g-15" direction="vertical">
        {reports.map((item: { month: string; year: number; stats: string; total_cost_month: number }, index: React.Key | null | undefined) => {
          return (
            <Row className="justify-content-between items-center" key={index}>
              <Typography.Text className="font-16-bold text-white-color">
                {item.month} {item.year}
              </Typography.Text>
              <Space>
                <Tag className={`tag ${item.stats.at(0) === "-" ? "" : "increased"}`}>{item.stats}</Tag>
                <Typography.Text className="font-13-bold text-white-color">
                  {item.total_cost_month.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  €
                </Typography.Text>
              </Space>
            </Row>
          );
        })}
      </Space>
    </Col>
  );
};

export default CreatorPaymentHistoryMonthlyReportTabComponent;
