import { ConnectionState, IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { Watermark } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAgora } from "../../helpers/agoraProvider";
import { AGORA_APP_ID } from "../../helpers/config";
import { USER_TYPES } from "../../helpers/constant";
import { IAgoraTrack } from "../../helpers/types";
import { authService } from "../../services";
import AgoraPlayerComponent from "../AgoraPlayerComponent";
import LiveJoinWaitComponent from "../LiveJoinWaitComponent";

type props = {
  sessionId: string | null;
  remoteId: string | null;
  onStreamStatusChange?: Function;
  unSubscribed?: Function;
  isBlur?: boolean;
  isDisableAudio?: boolean;
  index?: number;
  ref: React.Ref<any>;
  isFromMobile: boolean;
};

const LiveSubscriberComponent: React.FC<props> = React.forwardRef<any, props>(
  ({ sessionId, remoteId, onStreamStatusChange, unSubscribed, isBlur, isDisableAudio, index, isFromMobile }: props, ref: React.Ref<any>) => {
    const { client } = useAgora();
    const { t } = useTranslation();
    const userId = authService.getId();
    const [tracks, setTracks] = useState<IAgoraTrack | null>(null);
    const clientRef = useRef<any>();
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const currentUser = authService.getUser();
    const watermarkText = currentUser.username;

    const join = async (token: string) => {
      if (!client || !AGORA_APP_ID || !sessionId) {
        // TODO this code to debuging on stg
        console.log("client ", client);
        console.log("sessionId ", sessionId);

        return handleError(t("something-want-wrong") + " " + t("try-again-later"));
      }

      await client.join(AGORA_APP_ID, sessionId, token, userId);
    };

    const leave = () => {
      clientRef.current?.uid && clientRef.current.leave();
      setTracks(null);
      if (clientRef.current?.remoteUsers) {
        clientRef.current.remoteUsers.forEach((remoteUser: any) => {
          remoteUser.audioTrack.stop();
        });
      }
    };

    const subscribe = async (user: IAgoraRTCRemoteUser, mediaType: "audio" | "video") => {
      if (!client) {
        console.log("subscribe ", client);
        return handleError(t("something-want-wrong") + " " + t("try-again-later"));
      }
      await client.subscribe(user, mediaType);

      const remoteUser = client.remoteUsers.find((uid) => uid.uid === remoteId);
      if (!remoteUser) {
        console.log("removte users ", client.remoteUsers);
        console.log("removte remoteUser ", remoteUser);

        return handleError(t("something-want-wrong") + " " + t("try-again-later"));
      }

      if (mediaType === "audio" && remoteUser.audioTrack) {
        if (!isDisableAudio) {
          remoteUser.audioTrack.play();
        }
      }
      if (mediaType === "video" && remoteUser.videoTrack) setTracks({ tracks: [remoteUser.videoTrack] });

      setIsStarted(true);
      onStreamStatusChange && onStreamStatusChange(true);
    };

    const unSubscribe = (user: IAgoraRTCRemoteUser, mediaType: "audio" | "video") => {
      if (mediaType === "video") {
        clientRef.current?.uid && clientRef.current.leave();
        const remoteUser = user.uid === remoteId;
        if (remoteUser) {
          setTracks(null);
        }
        if (clientRef.current?.remoteUsers) {
          clientRef.current.remoteUsers.forEach((remoteUser: any) => {
            remoteUser.audioTrack.stop();
          });
        }
        onStreamStatusChange && onStreamStatusChange(false);
        const stats = clientRef.current?.getRTCStats();
        unSubscribed && unSubscribed(stats?.Duration);
        clientRef.current = null;
      }
    };

    const handleError = (error: string) => {
      setErrorMessage(error);
    };

    const connectionStateChanged = (state: ConnectionState) => {};

    useEffect(() => {
      clientRef.current = client;

      if (!client) return;
      client.on("user-published", subscribe);
      client.on("user-unpublished", unSubscribe);
      client.on("connection-state-change", connectionStateChanged);
    }, [client, remoteId]);

    useEffect(() => {
      return () => {
        leave();
      };
    }, []);

    React.useImperativeHandle(ref, () => ({
      join,
      leave,
      handleError,
    }));

    return isStarted ? (
      <>
        <Watermark content={[watermarkText, "www.loly.app"]} className="live-watermark" font={{ fontSize: 16, color: "rgba(255, 255, 255, 0.5)" }} />
        <AgoraPlayerComponent tracks={tracks?.tracks || []} isBlur={isBlur} index={index} isFromMobile={isFromMobile} />
      </>
    ) : (
      <LiveJoinWaitComponent type={USER_TYPES.FAN} errorMessage={errorMessage} />
    );
  }
);

export default LiveSubscriberComponent;
