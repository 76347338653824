import React, { ChangeEventHandler, useState } from "react";
import { Space, Typography, Input } from "antd";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import CreatorSavedResponsesModalComponent from "../CreatorSavedResponsesModalComponent";
import MessageIcon from "../../assets/icons/message-2.svg";
import "./index.less";

type props = {
  title?: string;
  value?: string | null;
  handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

const MessageTextAreaComponent: React.FC<props> = ({ value, handleChange, title }) => {
  const { t } = useTranslation();

  const [isSavedResponsesModalOpen, setIsSavedResponsesModalOpen] = useState<boolean>(false);

  const selectQuickMessage = (message: string) => {
    if (message && !isEmpty(message) && handleChange) {
      const value: any = {
        target: {
          value: message,
        },
      };
      handleChange(value);
    }
  };

  return (
    <Space className="w-full gap-13" direction="vertical">
      <Typography.Text className="font-30-bold text-white-color">{title || t("message")}</Typography.Text>
      <div className="messageTextAreaWrapper">
        <Input.TextArea placeholder={`${t("your-message")}....`} value={value || ""} onChange={handleChange} />
        <img src={MessageIcon} width={16} alt="message" onClick={() => setIsSavedResponsesModalOpen(true)} />
      </div>
      <CreatorSavedResponsesModalComponent
        isOpen={isSavedResponsesModalOpen}
        onCloseClick={() => setIsSavedResponsesModalOpen(false)}
        selectMessage={selectQuickMessage}
      />
    </Space>
  );
};

export default MessageTextAreaComponent;
