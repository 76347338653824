import axios from "../helpers/axios";
import { IConversationDTO, PageableData } from "../helpers/types";
import { authService } from "./index";

type membersPayload = {
  fanId: string;
  creatorId: string;
};

class MessagesService {
  public getAllConversations(page: number, type: string) {
    return new Promise<PageableData<IConversationDTO>>((resolve, reject) => {
      axios
        .get(`/conversations/user/${authService.getId()}?page=${page}&type=${type}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public searchConversation(search: string) {
    return new Promise<IConversationDTO[]>((resolve, reject) => {
      axios
        .get(`/conversations/user/${authService.getId()}/search?search=${search}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getConversationById(conversationId: string) {
    return new Promise<IConversationDTO>((resolve, reject) => {
      axios
        .get(`/conversations/${conversationId}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getConversationMessages(conversationId: string) {
    return new Promise<IConversationDTO>((resolve, reject) => {
      axios
        .get(`/conversations/${conversationId}`)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public isConversationExist(members: membersPayload) {
    return new Promise<string | null>((resolve, reject) => {
      axios
        .get(`/conversations/exist/${members.fanId}/${members.creatorId}`)
        .then((response) => {
          if (response?.data?.status === 0) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getTotalNotReadMessages() {
    return new Promise<string | null>((resolve, reject) => {
      axios
        .get(`/conversations/stats/counting-not-read-conversations`)
        .then((response) => {
          if (response?.data?.status === 0) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }

  public getUnreadByTypes(types: string[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/conversations/stats/get-unread-by-types/${JSON.stringify(types)}`)
        .then((response) => {
          if (response?.data?.status === 0) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            reject(error.response.data.message);
          } else {
            reject("Something went wrong.");
          }
        });
    });
  }
}

export const messageService = new MessagesService();
