import React from "react";
import { ILive } from "../../../helpers/types";
import { Button, Row, Space, Tag, Typography } from "antd";
import AvatarComponent from "../../../components/AvatarComponent";
import VerifyIcon from "../../../assets/icons/verify.svg";
import CloseIcon from "../../../assets/icons/close-with-shadow.svg";
import LikeFilledIcon from "../../../assets/icons/like-filled.svg";
import LikeIcon from "../../../assets/icons/like.svg";
import UserIcon from "../../../assets/icons/user-white.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { clearLiveData, editLive } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { reactionService } from "../../../services";
import { clearStreamStats } from "../../../redux/lives/liveDataSlice";
import { formatNumber } from "../../../utls/FunctionsUtil";

interface Props {
  isSubscribed: boolean;
  live: ILive | null;
}

export const FanLiveHeader = (props: Props) => {
  const { isSubscribed, live } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const streamStats = useSelector((state: StoreState) => state.liveStreamStats.streamStats.stats);
  const fromPaymentPage = searchParams.get("from");

  const onClickClose = () => {
    dispatch(clearLiveData());
    dispatch(clearStreamStats());
    if (fromPaymentPage) {
      navigate("/fan/lives?tab=now");
    } else navigate(-1);
  };

  const goToSubscriptionPage = () => {
    let url = location.pathname.substring(1);
    const param = url.split("/")[2];
    if (param === "new") {
      url = "creator/message";
    }
    navigate(`/common/subscribe/${live?.creator?._id}?redirectUrl=${url}`);
  };

  async function handleLikeUnlikeClick() {
    if (!live) return;

    const payload = {
      action: "follow",
      objectId: live.creator?._id,
      objectType: "creator",
    };

    if (live.isFollowed) {
      const isFollowed = await reactionService.deactivatedReaction(payload).then((result) => result);
      dispatch(
        editLive({
          ...live,
          isFollowed: !isFollowed,
        })
      );
    } else {
      const result = await reactionService.createReaction(payload).then((result) => result);
      dispatch(
        editLive({
          ...live,
          isFollowed: result && result.activated,
        })
      );
    }
  }

  const navigateToProfile = () => {
    navigate("/p/" + live?.creator?.username);
  };
  return (
    <Row className="justify-content-between pl-15 pr-3 z-fan-live" align="middle">
      <Space className="header-container-left-live gap-1">
        <AvatarComponent image={live?.creator?.avatar} size={30} onClick={navigateToProfile} />
        <Typography.Text className="fullname" onClick={navigateToProfile}>
          {live?.creator?.firstName} {live?.creator?.lastName}
        </Typography.Text>
        {live && live.creator?.verifiedEmail && <img src={VerifyIcon} alt="verify" />}
        {!live?.isFollowed && (
          <Button className="follow-button" onClick={handleLikeUnlikeClick}>
            {t("follow")}
          </Button>
        )}
      </Space>
      <Space className="gap-20">
        {live && streamStats && (
          <div className="spectateursTag">
            <img src={UserIcon} width={10} className="mr-5" />
            <Typography.Text className="font-12-bold text-white-color">{formatNumber(streamStats.members)}</Typography.Text>
          </div>
        )}
        <img src={CloseIcon} width={22} alt="close" onClick={onClickClose} />
      </Space>
    </Row>
  );
};
