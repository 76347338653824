import React, { useEffect, useRef, useState } from "react";
import { Col, Layout, Row, Space, Typography, Button, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import TextAreaComponent from "../../../components/TextAreaComponent";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getLiveById, getReservations, getTotalPrice, deleteLive } from "../../../redux/lives/livesSlice";
import liveUtil from "../../../utls/LiveUtil";
import numberUtil from "../../../utls/NumberUtil";
import { getLiveAccesses } from "../../../helpers/constant";
import LiveProgrammed from "../../../assets/images/live-programmed.webp";
import "./index.less";
import { ConfirmationModal } from "../../../components/ModalConfirmation";
import TotalRevenueComponent from "../../../components/TotalRevenueComponent";

const CreatorLiveEventDetailPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const live = useSelector((state: StoreState) => state.lives.item);
  const shouldFetch = useRef(true);
  const [open, setOpen] = useState(false);

  const accesses = getLiveAccesses(t);

  const handleToggle = () => setOpen(!open);

  const onClickDelete = async () => {
    if (!id) return;
    const result = await dispatch(deleteLive(id));
    if (result.meta.requestStatus === "fulfilled") {
      message.success(t("deleted-live-success"));
      navigate("/creator/profile");
    }
    handleToggle();
  };

  //TODO : this code needs to be changed and well optimized 
  useEffect(() => {
    if (id && shouldFetch.current && !live) {
      shouldFetch.current = false;
      dispatch(getLiveById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && live?._id) {
      shouldFetch.current = false;
      dispatch(getReservations(id));
      dispatch(getTotalPrice(id));
    }
  }, [live?._id])

  return (
    <Layout>
      <Layout.Content className="content creatorLiveEventDetailContentWrapper gradient-background-blue">
        <div className="liveProgrammedImg" style={{ backgroundImage: `url(${LiveProgrammed})` }}>
          <ArrowLeftIconComponent />
        </div>
        <Row>
          <Typography.Text className="text-white-color font-32-bold mt-20">{t("scheduled-live")}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text className="text-white-color font-16-regular mt-10">
            {t("the")} {live?.scheduleData?.dateTime && liveUtil.getScheduleDateTime(t, live.scheduleData.dateTime)}, {t("you-have")}{" "}
            {live?.reservations || 0} {live?.reservations !== undefined && live.reservations <= 1 ? t("reservation-min") : t("reservations")}
          </Typography.Text>
        </Row>
        <Space className="price-component gap-20 mt-20">
          <TotalRevenueComponent totalRevenue={live?.totalPrice ?? 0} size="medium" title="income-generated" />
          <TotalRevenueComponent totalRevenue={live?.amount ?? 0} size="small" title="price-per-unit" />
        </Space>
        <Row className="mt-20">
          <Space className="w-full" direction="vertical">
            <Typography.Text className="text-white-color font-32-bold">{t("description")}</Typography.Text>
            <Row className="text-border">
              <Typography.Text className="max-w-206 font-15-regular text-white-color">{live?.scheduleData?.message || ""}</Typography.Text>
            </Row>
          </Space>
        </Row>
        <Row className="mt-20">
          <Button className="liveEventDeleteButton" onClick={handleToggle}>
            {t("delete-event")}
          </Button>
        </Row>
      </Layout.Content>
      <ConfirmationModal
        open={open}
        handleToggle={handleToggle}
        handleConfirm={onClickDelete}
        confBtnMsg={t("modal-confirm-btn")}
        canBtnMsg={t("modal-cancel-btn")}
        bodyElement={
          <p>
            {t("modal-delete-confirmation-message")}
            {t("fans-will-be-refunded")}
          </p>
        }
      />
    </Layout>
  );
};

export default CreatorLiveEventDetailPage;
