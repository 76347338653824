import { Button, Col, Layout, Row, Space, Spin, Typography, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftGrey from "../../../assets/icons/arrow-left-grey.svg";
import BookmarkIcon from "../../../assets/icons/bookmark.svg";
import BurgerMenuIcon from "../../../assets/icons/burger-menu.svg";
import ChatIcon from "../../../assets/icons/chat-icon.svg";
import VideoIcon from "../../../assets/icons/jouer.svg";
import LiveIcon from "../../../assets/icons/live-icon.svg";
import LocationIcon from "../../../assets/icons/location.svg";
import LockIcon from "../../../assets/icons/lock-outlined-without-point.svg";
import NewBookmarkFilledIcon from "../../../assets/icons/new-bookmark-filled.svg";
import NewShareIcon from "../../../assets/icons/new-share.svg";
import Share2Icon from "../../../assets/icons/share-2.svg";
import VerifyIcon from "../../../assets/icons/verify.svg";
import BellImg from "../../../assets/images/bell.webp";
import AvatarComponent from "../../../components/AvatarComponent";
import FanReportCreatorModalComponent from "../../../components/FanReportCreatorModalComponent";
import FooterComponent from "../../../components/FooterComponent";
import FeedMediaComponent from "../../../components/MediaComponent/FeedMediaComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import { CREATOR, FAN, FILE_STATUS, FOOTER_IDS, PAYMENT_TYPES, THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IConversation } from "../../../helpers/types";
import { getFeedsByCreatorId, getMyFeeds, loadMoreFeedsByCreatorId, updateFile } from "../../../redux/feeds/feedsSlice";
import { getCreatorScheduleLive } from "../../../redux/lives/livesSlice";
import { getConversationById, selectConversation } from "../../../redux/messages/messagesSlice";
import { createReaction, deactivatedReaction } from "../../../redux/reactions/reactionsSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getMyProfile, getProfileById } from "../../../redux/users/usersSlice";
import { authService, liveService, messageService, reactionService, reportService, subscriptionsService, userService } from "../../../services";
import { socket } from "../../../socket";
import { formatNumber } from "../../../utls/FunctionsUtil";
import liveUtil from "../../../utls/LiveUtil";
import "./index.less";

type props = {
  isMyProfile?: boolean;
};

const MAX_NAME_LENGTH = 16;

const ProfilePage: React.FC<props> = ({ isMyProfile }) => {
  const limit = 15;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { username } = useParams();
  const tokenId = authService.getId();
  const type = authService.getRole();
  const shouldFetchUserId = useRef(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const { item: user, currentUserProfile, error } = useSelector((state: StoreState) => state.users);
  const feeds = useSelector((state: StoreState) => state.feeds.list);
  const feedsIsLoading = useSelector((state: StoreState) => state.feeds.loading);
  const currentUserFeedsLoading = useSelector((state: StoreState) => state.feeds.currentUserLoading);
  const currentUserFeeds = useSelector((state: StoreState) => state.feeds.currentUserFeeds);
  const totalPages = useSelector((state: StoreState) => state.feeds.total);
  const scheduleLive = useSelector((state: StoreState) => state.lives.item);
  //const reaction = useSelector((state: StoreState) => state.reactions.item);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [followed, setFollowed] = useState<any>(false);
  const [skip, setSkip] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [hasMore, setHasMore] = useState<any>(false);
  //const [isCreatorType, setIsCreatorType] = useState<any>(null);
  const [isVerifyPaymentForScheduleLive, setIsVerifyPaymentForScheduleLive] = useState<boolean>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [id, setId] = useState("");
  //const [feedsItems, setFeedsItems] = useState<IFeed[]>([]);
  const fullName = `${isMyProfile ? currentUserProfile?.firstName : user?.firstName} ${isMyProfile ? currentUserProfile?.lastName : user?.lastName}`;
  const isLongName = fullName.length > MAX_NAME_LENGTH;
  //const fontSize = isLongName ? "long" : "";
  const iconSize = isLongName ? "16px" : "22px";

  //TODO: this needs to be changed it's a quick fix
  const [showContent, setShowContent] = useState<boolean>(isMyProfile ?? false);

  useEffect(() => {
    const timer = setTimeout(() => {
      !isMyProfile && setShowContent(true);
    }, 270);

    function handleFileUpdated(data: { fileId: string; absolutePath: string; thumbnails: any; status: string }) {
      dispatch(updateFile(data));
    }

    socket.on("file-updated", handleFileUpdated);

    return () => {
      socket.off("file-updated", handleFileUpdated);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (authService.isAuthenticated() && username && id === "" && shouldFetchUserId.current) {
      shouldFetchUserId.current = false;
      userService
        .getUserIdByUsername(username)
        .then((id) => {
          setId(id);
        })
        .catch((error) => {
          message.error(t(error));
        });
    }
  }, [username]);

  useEffect(() => {
    if (scheduleLive?._id && id && !isMyProfile && id !== tokenId) {
      liveService
        .verifyPayment(scheduleLive._id, tokenId)
        .then((value) => setIsVerifyPaymentForScheduleLive(value))
        .catch((error) => console.log(error));
    }
  }, [scheduleLive]);

  useEffect(() => {
    if (feeds.length !== 0 && totalPages !== -1) {
      setCurrentPage(Math.ceil((totalPages - feeds?.length) / limit));
      setSkip(feeds?.length || 0);
      setHasMore(feeds.length < totalPages || feeds.length !== totalPages);
    }
  }, [feeds, totalPages, currentPage]);

  const loadMoreFeed = () => {
    if (hasMore && id !== "") {
      dispatch(loadMoreFeedsByCreatorId({ creatorId: id, skip: skip, limit }));
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: feedsIsLoading === "pending",
    hasNextPage: hasMore,
    onLoadMore: loadMoreFeed,
  });

  useEffect(() => {
    if (isMyProfile || id === tokenId) {
      if (!currentUserProfile) {
        dispatch(getMyProfile());
      }
      //if (currentUserFeeds?.length === 0) {
      dispatch(getMyFeeds());
      //}
      dispatch(getCreatorScheduleLive(tokenId));
    } else if (id !== "") {
      dispatch(getProfileById(id));
      dispatch(getCreatorScheduleLive(id));
      //if (user?._id !== id || feeds.length === 0) {
      dispatch(getFeedsByCreatorId({ creatorId: id, skip: 0, limit }));
      //}
    }
  }, [id, isMyProfile]);

  /* useEffect(() => {
    if (isMyProfile || id === tokenId) {
      setFeedsItems(currentUserFeeds);
    } else {
      if (id !== "") {
        setFeedsItems(feeds);
      }
    }
  }, [currentUserFeeds, feeds, id, isMyProfile, user?._id]); */

  useEffect(() => {
    if (user?._id && !(isMyProfile || id === tokenId)) {
      const data = {
        creatorId: user?._id,
        fanId: authService.getId(),
      };
      subscriptionsService
        .verifySubscription(data)
        .then((response) => {
          setSubscribed(response);
        })
        .catch((error) => {
          console.log(error);
        });
      reactionService
        .verifyFollow(data)
        .then((response) => {
          setFollowed(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const onSubscribeClick = (creatorId: string) => {
    if (error !== "user-not-found") {
      if (subscribed) {
        message.success(t("already-subscribed-creator"));
      } else {
        navigate(`/common/subscribe/${creatorId}`);
      }
    }
  };

  const goToSettings = () => {
    navigate("/common/setting");
  };

  const follow = async (creatorId: string) => {
    if (error !== "user-not-found") {
      const payload = {
        action: "follow",
        objectId: creatorId,
        objectType: "creator",
      };
      await dispatch(createReaction(payload));
      setFollowed(true);
      message.success(t("you-are-now-following") + " " + user?.firstName);
    }
  };

  const unfollow = async (creatorId: string) => {
    if (error !== "user-not-found") {
      const payload = {
        action: "follow",
        objectId: creatorId,
        objectType: "creator",
      };
      await dispatch(deactivatedReaction(payload));
      setFollowed(false);
    }
  };

  async function onReportClick(creatorId: string) {
    const payload = {
      target: "creator",
      targetId: creatorId,
      title: "report",
      description: "",
    };
    const resp = await reportService.createReport(payload);
    if (resp?.data) {
      message.success(t("report-success"));
    } else if (resp?.message) {
      message.error(resp.message);
    }
    setIsReportModalOpen(false);
  }

  const onClickGetTicket = () => {
    if (!scheduleLive) return;
    navigate(
      `/fan/payment?creatorId=${scheduleLive?.creator?._id}&type=${PAYMENT_TYPES.LIVE}&liveId=${scheduleLive._id}&redirectUrl=common/profile/${user?._id}`
    );
  };

  const onClickJoinLive = () => {
    if (!scheduleLive && !scheduleLive!.isStreaming) return;
    navigate(`/fan/live/${scheduleLive?._id}`);
  };

  const onClickLiveEventDetails = () => {
    if (!scheduleLive && scheduleLive!.isStreaming) return;
    navigate(`/fan/event/${scheduleLive?._id}`);
  };

  const displayActionBtn = () => {
    if (isMyProfile || id === tokenId) {
      const liveStartTime = dayjs(scheduleLive?.scheduleData?.dateTime) as Dayjs;
      const timeNow = dayjs();
      if (liveStartTime.isAfter(timeNow)) {
        return (
          <Button
            className="editLiveButton"
            onClick={() => {
              navigate(`/creator/live/event/detail/${scheduleLive!._id}`);
            }}
          >
            {t("edit")}
          </Button>
        );
      } else if (liveStartTime.isBefore(timeNow.add(10, "minute"))) {
        return (
          <Button
            className="editLiveButton"
            onClick={() => {
              navigate(`/creator/live/publish/${scheduleLive?._id}`);
            }}
          >
            {t("start")}
          </Button>
        );
      } else {
        return <React.Fragment />;
      }
    } else if (!isMyProfile && id !== tokenId) {
      if (!isVerifyPaymentForScheduleLive) {
        return (
          <Button className="editLiveButton" onClick={onClickGetTicket}>
            {t("take-ticket")}
          </Button>
        );
      } else {
        const isLive = scheduleLive?.isStreaming === true;
        return (
          <Button className="editLiveButton" onClick={isLive ? onClickJoinLive : onClickLiveEventDetails}>
            {t(isLive ? "join" : "view-details")}
          </Button>
        );
      }
    }
  };

  const writeClicked = async () => {
    if (!user || error === "user-not-found") {
      return;
    }
    const currentUserRole = authService.getRole();
    let urlToNavigate = currentUserRole === CREATOR ? "/creator/conversation/new" : "/fan/requestMedia/new";
    const currentUserId = authService.getId();
    const conversationId = await messageService.isConversationExist({
      fanId: currentUserId,
      creatorId: user._id !== undefined ? user._id : "",
    });
    if (conversationId === null) {
      const newConversation: IConversation = {
        _id: "new",
        fanCanSendMessage: true,
        interlocutor: {
          userId: user._id,
          avatar: user.avatar,
          name: user.name,
          role: CREATOR,
        },
        currentUser: {
          userId: currentUserId,
          avatar: "",
          name: "",
          role: FAN,
        },
      };
      dispatch(selectConversation(newConversation));
      urlToNavigate = urlToNavigate + `?newConversation=${JSON.stringify(newConversation)}`;
    } else {
      await dispatch(getConversationById(conversationId));
      urlToNavigate = urlToNavigate.replace("new", conversationId);
    }
    navigate(urlToNavigate, { replace: false });
  };

  const feedsToRender = isMyProfile || id === tokenId ? currentUserFeeds : feeds;
  const userToRender = isMyProfile || id === tokenId ? currentUserProfile : user;
  const fromPayment = sessionStorage.getItem("payment-page") === "True";

  const handleNavigate = () => {
    if (fromPayment) {
      navigate("/common/category/search");
      sessionStorage.removeItem("payment-page");
    } else {
      navigate(-1);
    }
  };

  return (
    <Layout className="gradient-background-profile no-scroll-horizontal">
      <Layout.Content className="content fanProfileContentWrapper background-transparent">
        {showContent && (
          <>
            {(isMyProfile || id === tokenId) && (
              <>
                <Row className="header-container">
                  <Typography.Text className="header-my-profile-text">@{userToRender?.username}</Typography.Text>
                  <div className="settings-icon-container" onClick={goToSettings}>
                    <img src={BurgerMenuIcon} width={20} alt="settings" />
                  </div>
                </Row>

                <div className="fanProfileTopWrapper">
                  <Row align="middle">
                    <Col className="colAvatar mr-10">
                      <AvatarComponent image={userToRender?.avatar || ""} size={125} fontSize={16} fontWeight={true} />
                    </Col>
                    <Col className="colDetails">
                      <Row className="nameRow" align="middle">
                        <Row className="ml-20 mt-10 max-w-80 justify-content-between" align="middle">
                          <Space>
                            <Col className="colValues">
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.totalFeeds || 0}</Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("publications")}</Typography.Text>
                              </Row>
                            </Col>
                            <Col
                              className="colValues cursor-pointer"
                              onClick={() => {
                                navigate("/creator/allSubscribers");
                              }}
                            >
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">
                                  {formatNumber(userToRender?.stats?.subscribers || 0)}
                                </Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("subscribers")}</Typography.Text>
                              </Row>
                            </Col>
                            <Col className="colValues">
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.followers || 0}</Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("Followers")}</Typography.Text>
                              </Row>
                            </Col>
                          </Space>
                        </Row>
                      </Row>

                      <Row className="buttonRow mt-20" align="middle">
                        <Space>
                          <Col>
                            <Button className="editProfileBtn" onClick={() => navigate("/creator/editProfile")}>
                              {t("edit-my-profile")}
                            </Button>
                          </Col>
                          <Col>
                            <img src={NewShareIcon} className="share-icon" alt="share" onClick={() => setIsShareModalOpen(true)} />
                          </Col>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="labelWrapper">
                  <Typography.Text className="nameTitle">
                    {error !== "user-not-found" ? (
                      <>
                        {userToRender?.firstName} {userToRender?.lastName}
                      </>
                    ) : (
                      t(error as string)
                    )}
                  </Typography.Text>
                  {userToRender?.verifiedAccount && <img className="verifyIcon" src={VerifyIcon} width={14} alt="verify" />}
                  {userToRender?.city && (
                    <Row className="">
                      <Col className="colValues location mt-5">
                        <img className="locationImg mr-5" src={LocationIcon} width={16} alt="location" />
                        <Typography.Text className="fanProfileLocationLabel">{userToRender.city}</Typography.Text>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-10">
                    <div
                      className="fanProfileDescriptionLabel"
                      dangerouslySetInnerHTML={{
                        __html: (userToRender?.description || "").replace(/\n/g, "<br />"),
                      }}
                    />
                  </Row>
                </div>
              </>
            )}
            {!isMyProfile && id !== tokenId && (
              <>
                <Row className="header-container">
                  <img
                    src={ArrowLeftGrey}
                    width={35}
                    alt="arrow left"
                    onClick={() => {
                      handleNavigate();
                    }}
                  />
                  <Typography.Text className="header-text-other-profile">@{userToRender?.username}</Typography.Text>
                  <div>
                    <img className="mr-10" src={Share2Icon} width={35} alt="share" onClick={() => setIsShareModalOpen(true)} />

                    {followed ? (
                      <img src={NewBookmarkFilledIcon} width={35} alt="bookmark" onClick={() => unfollow(id !== "" ? id : "")} />
                    ) : (
                      <img src={BookmarkIcon} width={35} onClick={() => follow(id !== "" ? id : "")} />
                    )}
                  </div>
                </Row>
                <div className="fanProfileTopWrapper">
                  <Row align="middle">
                    <Col className="colAvatar mr-10">
                      <AvatarComponent image={userToRender?.avatar || ""} size={105} fontSize={16} fontWeight={true} />
                    </Col>
                    <Col className="colDetails">
                      <Row className="nameRow" align="middle">
                        <Typography.Text className={`nameTitleOtherProfile`}>
                          {error !== "user-not-found" ? (
                            <>
                              {userToRender?.firstName} {userToRender?.lastName}
                            </>
                          ) : (
                            t(error as string)
                          )}
                        </Typography.Text>
                        {userToRender?.verifiedAccount && <img className="verifyIcon" src={VerifyIcon} width={iconSize} alt="verify" />}
                      </Row>

                      <Row className="mt-5" align="middle">
                        <Space>
                          {/* <Col className={`${!subscribed ? "border-btn" : ""}`}> */}
                          <Button className={`subscribeBtn ${subscribed ? "unsubscribe" : ""}`} onClick={() => onSubscribeClick(id !== "" ? id : "")}>
                            {t(`${subscribed ? "subscriber" : "Subscribe-without-obligation"}`)}
                          </Button>
                          {/* </Col> */}
                          <img
                            className="mt-3"
                            width={44}
                            src={ChatIcon}
                            onClick={() => {
                              writeClicked();
                            }}
                            alt="chat"
                          />
                          {/* <Button
                        className={"fanProfileWriteBtn btn-size"}
                        onClick={() => {
                          writeClicked();
                        }}
                      >
                        {t("write")}
                      </Button> */}
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="labelWrapper">
                  {userToRender?.city && (
                    <Row className="m-5 ml-10">
                      <Col className="colValues location">
                        <img className="locationImg mr-5" src={LocationIcon} width={16} alt="location" />
                        <Typography.Text className="fanProfileAttributeTitle">{userToRender.city}</Typography.Text>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-10">
                    <div
                      className="fanProfileDescriptionLabel"
                      dangerouslySetInnerHTML={{
                        __html: (userToRender?.description || "").replace(/\n/g, "<br />"),
                      }}
                    />
                  </Row>

                  <Row className="ml-10 mt-10 max-w-80 justify-content-between" align="middle">
                    <div className="leftItems">
                      <Space>
                        <Col className="colValues">
                          <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.totalFeeds || 0}</Typography.Text>
                          <Typography.Text className="fanProfileAttributeTitle">{t("publications")}</Typography.Text>
                        </Col>
                      </Space>
                    </div>

                    <ThreeDotsComponent onClick={() => setIsReportModalOpen(true)} />
                  </Row>
                </div>
              </>
            )}

            {scheduleLive?.scheduleData?.dateTime && error !== "user-not-found" && (
              <Row className="editLive-container mb-20 mt-10">
                <Col className="editLive-content">
                  <Row>
                    <Typography.Text className="editLive-title mt-5">
                      <img src={LiveIcon} width={30} alt="" /> {liveUtil.getScheduleDateTimeStringForProfile(t, scheduleLive.scheduleData.dateTime)}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text className="editLive-description">
                      {isMyProfile || id === tokenId ? t("modify-cancel-live") : t("take-ticket-live")}
                    </Typography.Text>
                  </Row>
                  <Row className="w-full">{displayActionBtn()}</Row>
                </Col>
                <Col className="editLive-image">
                  <img src={BellImg} width={120} alt="" />
                </Col>
              </Row>
            )}

            <Col>
              <Row className="mt-20 pr-4 pl-4" gutter={2}>
                {feedsToRender?.map((feed) => {
                  if (feed.files) {
                    const lock = !subscribed && id !== tokenId && feed.isPrivate;
                    const isVideo = feed.type === "video";
                    const isUploading: boolean = feed.files[0].status === FILE_STATUS.UPLOADING;
                    const feedThumbnail = isUploading
                      ? ""
                      : isVideo
                        ? feed.isPrivateForCurrentUser
                          ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                          : feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                        : feed.isPrivateForCurrentUser
                          ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                          : feed.files[0].thumbnails?.findIndex((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR) !== -1
                            ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                            : feed.files[0].url;
                    return isUploading && !isMyProfile ? (
                      <></>
                    ) : (
                      <Col
                        className="fanProfilePostWrapper"
                        key={feed._id}
                        span={8}
                        onClick={() => {
                          navigate(`/common/publications/${feed._id}/${userToRender?._id}?isMyProfile=${isMyProfile}`);
                        }}
                      >
                        {/* <div
                          className={`fanProfilePost ${!isMyProfile && lock ? "fanProfilePrivatePost" : ""}`}
                          style={{
                            backgroundImage: `url(${feedThumbnail})`,
                          }}
                        /> */}
                        <FeedMediaComponent
                          shouldAddWatermark={!isMyProfile!}
                          description=""
                          fileUrl={feedThumbnail!}
                          thumbnail=""
                          type="image"
                          imageClassName={`fanProfilePost ${!isMyProfile && lock ? "fanProfilePrivatePost" : ""}`}
                          isPrivate={feed.isPrivateForCurrentUser!}
                          isMyProfile={isMyProfile!}
                        />
                        {lock && <img className="fanProfilePostLockIcon" src={LockIcon} alt="lock" />}
                        {isVideo && <img src={VideoIcon} style={!lock ? { right: "11px" } : {}} alt="video icon" className="feedVideoIcon" />}
                        <Spin spinning={isUploading} className="feedSpinner" indicator={<SpinnerComponent />} />
                      </Col>
                    );
                  }
                  return <></>;
                })}
              </Row>
              {!isMyProfile && id !== tokenId && hasMore && <SpinnerComponent refProp={sentryRef} />}
              {currentUserFeeds.length === 0 && currentUserFeedsLoading === "pending" && (isMyProfile || id === tokenId) && <SpinnerComponent />}
              {feeds.length === 0 && !isMyProfile && id !== tokenId && !hasMore && feedsIsLoading === "pending" && <SpinnerComponent />}
              <Row className="text-center mt-40 d-flex justify-content-center"></Row>
            </Col>
            <ShareModalComponent
              isOpen={isShareModalOpen && error !== "user-not-found"}
              onClose={() => setIsShareModalOpen(false)}
              id={id !== "" ? id : tokenId}
              username={userToRender?.username}
            />
            <FanReportCreatorModalComponent
              isOpen={isReportModalOpen && error !== "user-not-found"}
              onClose={() => setIsReportModalOpen(false)}
              onReportClick={() => userToRender && userToRender._id && onReportClick(userToRender._id)}
            />
          </>
        )}
      </Layout.Content>
      <FooterComponent type={type || ""} page={id !== "" ? FOOTER_IDS.SEARCH : FOOTER_IDS.PROFILE} />
    </Layout>
  );
};

export default ProfilePage;
