import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Select } from "antd";
import "./index.less";
dayjs.extend(advancedFormat);

type props = {
  onChange: (type: string, date: any) => void;
  date: string | null;
};

interface ITime {
  label: string;
  value: string;
}

function generateTimeArray(date: string | null) {
  const times = [];
  const now = dayjs();
  const startTime = dayjs(date ?? new Date().toString());

  const isToday = startTime.isSame(now, "day");

  let time;
  if (isToday) {
    const minutes = Math.ceil(now.minute() / 15) * 15;
    time = now.minute(minutes).second(0).millisecond(0);
    if (time.isBefore(now)) {
      time = time.add(15, "minute");
    }
  } else {
    time = startTime.startOf("day");
  }

  while (time.isBefore(startTime.endOf("day"))) {
    const value = time.format("HH:mm");
    times.push({
      label: value,
      value,
    });
    time = time.add(15, "minute");
  }

  return times;
}

const TimePickerComponent: React.FC<props> = ({ onChange, date }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<ITime[]>();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    setOptions(generateTimeArray(date));
  }, [date]);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChangeTime = (e: string) => {
    const time = e;
    const formattedTime = dayjs()
      .hour(parseInt(time.split(":")[0]))
      .minute(parseInt(time.split(":")[1]))
      .second(0)
      .millisecond(0);
    onChange("time", formattedTime);
    setDropdownVisible(false);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownVisible(open);
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const preventDefault: React.EventHandler<React.SyntheticEvent> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onTouchStart={preventDefault} onMouseDown={preventDefault}>
      <Select
        className={`dateTimePicker time ${dropdownVisible ? 'dropdown-open' : ''}`}
        showSearch
        placeholder={t("select-time").toString()}
        optionFilterProp="children"
        onChange={handleChangeTime}
        filterOption={filterOption}
        options={options}
        size="small"
        autoFocus={false}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ position: 'fixed', zIndex: 1000 }}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        onFocus={(e) => e.preventDefault()}
        onClick={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default TimePickerComponent;
