import React from "react";
import { Space, Avatar, Typography, Button } from "antd";
import VerifyIcon from "../../assets/icons/verify.svg";
import "./index.less";

type props = {
  className?: string;
  size: number;
  image: string | undefined;
  name?: string;
  firstName?: string;
  lastName?: string;
  fontSize?: number;
  fontWeight?: boolean;
  lineHeight?: number;
  isShowVeritify?: boolean;
  fromSuggestions?: boolean;
  isLive?: boolean;
  onClick?: () => void;
};

const AvatarComponent: React.FC<props> = ({
  className,
  size,
  image,
  name,
  fontSize,
  fontWeight,
  lineHeight,
  isShowVeritify,
  onClick,
  firstName,
  lastName,
  fromSuggestions,
  isLive = false,
}) => {
  return (
    <Space className={`${className} gap-10`} direction="vertical" align="center">
      <Space className={`avatarWrap ${isLive ? "live-border" : ""}`}>
        <div className={`${isLive ? "avatarBorder" : ""}`}>
          <Avatar className="avatar" size={size} src={image !== undefined ? image : ""} alt={"avatar"} onClick={onClick} />
          {isLive && <div className="live-badge">LIVE</div>}
        </div>
      </Space>
      {name && (
        <Space align="center">
          <Typography.Text
            className={`responsive-name text-white-color ${fontWeight ? "primary-font-bold" : "primary-font-regular"}`}
            style={{
              fontSize: fontSize,
              lineHeight: `${lineHeight}px`,
            }}
          >
            {firstName ? `${firstName} ${lastName}` : name}
          </Typography.Text>
          {isShowVeritify && <img src={VerifyIcon} alt="verify" />}
        </Space>
      )}
    </Space>
  );
};

export default AvatarComponent;
