import { Col, Layout, Row, Space, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "../../../assets/images/recentVisited/delete-icon.svg";
import AvatarComponent from "../../../components/AvatarComponent";
import { AppDispatch, StoreState } from "../../../redux/store";
import { deleteRecentSerachUser, getRecentSearch } from "../../../redux/users/usersSlice";
import "./index.less";

const RecentVisitedProfiile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const recentSearch = useSelector((state: StoreState) => state.users.recentList);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getRecentSearch());
    }
  }, []);

  const handleRemove = async (id: String) => {
    await dispatch(deleteRecentSerachUser(id));
    await dispatch(getRecentSearch());
  };

  return (
    <Layout className="recentVisted">
      <Layout.Header className="RecentVisitedProfileHeader">
        <Row className="justify-between mt-7">
          <Col span={12}>
            <Typography.Text className="recentText">{t("recent")}</Typography.Text>
          </Col>
          {/* <Col span={12} className="text-end">
            <Typography.Text className="SeeEverythingText">
              {t("See-everything")}
            </Typography.Text>
          </Col> */}
        </Row>
      </Layout.Header>
      <Layout.Content className="mt-10">
        {recentSearch?.map((item, index) => {
          return (
            <Row key={index} className="justify-between items-center mt-5">
              <Col
                span={20}
                onClick={() => {
                  navigate(`/p/${item?.username}`);
                }}
              >
                <Row className="row-recent-search">
                  <div className="mt-10">
                    <AvatarComponent image={`${item?.avatar}` || ""} size={56} name={""} fontSize={16} fontWeight={true} />
                  </div>
                  <Space direction="vertical" className="recentVisitedTextWrapper">
                    <Typography.Text className="recentVistedUserName">{item?.username}</Typography.Text>
                    <Typography.Text className="recentVisitedFirstName">{item?.name}</Typography.Text>
                  </Space>
                </Row>
              </Col>
              <Col
                span={4}
                className="text-end"
                onClick={() => {
                  handleRemove(`${item?.creatorId}`);
                }}
              >
                <img className="cursor-pointer" src={RemoveIcon} alt="Remove" />
              </Col>
            </Row>
          );
        })}
      </Layout.Content>
    </Layout>
  );
};

export default RecentVisitedProfiile;
