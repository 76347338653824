import React, { useEffect } from "react";
import { Modal, Row, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import LockImage from "../../assets/images/lock.webp";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  creatorName?: string;
};

const FanRequireSubscribeModalComponent: React.FC<props> = ({ isOpen, onClose, onSubmit, creatorName }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="requireSubscribeModalWrapper"
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="requireSubscribeFooterRpw">
            <div className="backgroundBtn" onClick={onSubmit}>
              <Button className="acceptBtn" type="link">
                {t("subscribe")}
              </Button>
            </div>
          </Row>
          <Row className="requireSubscribeFooterRpw">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    >
      <Row className="w-full justify-content-center">
        <img src={LockImage} width={170} alt="" />
      </Row>
      <Row className="w-full justify-content-center text-center mt-20">
        <Typography.Text className="title-modal">{`${t("you-are-not-a-subscriber")} ${creatorName}`}</Typography.Text>
      </Row>
      <Row className="w-full justify-content-center text-center mt-10">
        <Typography.Text className="requireSubscribeModalDescription">{`${creatorName} ${t("only-talks-to-his-subscribers")}`}</Typography.Text>
        <Typography.Text className="requireSubscribeModalDescription">{t("request-media-subscribe-terms")}</Typography.Text>
      </Row>
    </Modal>
  );
};

export default FanRequireSubscribeModalComponent;
