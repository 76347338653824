import React, { useEffect } from 'react'
import { socket } from '../../socket';
import { getConversationById, handleConversationTypeChange, handleEditMessage, handleNewMessageData, updateConversationHasNewMessages, updateConversations } from '../../redux/messages/messagesSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { IConversationDTO, IMessage, ISwitchConversationType } from '../types';

export const MessagesInterceptor = () => {

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {

    socket.on("message", handleMessage);
    socket.on("conversation-created", handleConversationCreated);
    socket.on("update-message", handleUpdateMessage);
    socket.on("switch-conversation-type", handleSwitchConversationType);
    socket.on("conversation-updated", handleConversationUpdated);

    return () => {
      socket.off("message", handleMessage);
      socket.off("conversation-created", handleConversationCreated);
      socket.off("update-message", handleUpdateMessage);
      socket.off("switch-conversation-type", handleSwitchConversationType);
      socket.off("conversation-updated", handleConversationUpdated);
    };

  }, [])


  function handleMessage(data: any) {
    dispatch(
      updateConversationHasNewMessages({
        conversationId: data.conversationId,
        createdAt: data.createdAt,
        conversationType: data.conversationType,
        isReceiver: data.isReceiver
      })
    );
    dispatch(handleNewMessageData(data));
  }

  function handleConversationCreated(data: IConversationDTO) {
      dispatch(updateConversations(data));
  }

  function handleUpdateMessage(data: IMessage) {
      dispatch(handleEditMessage(data));
  }

  function handleSwitchConversationType(data: ISwitchConversationType) {
    dispatch(handleConversationTypeChange(data));
  }

  function handleConversationUpdated(data: IConversationDTO) {
      dispatch({
        type: getConversationById.fulfilled.type,
        payload: data,
      });
  }

  return (
    <>
    </>
  )
}
