import { Button, message, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteAlbum } from "../../redux/quickFile/quickFileSlice";
import { AppDispatch } from "../../redux/store";
import { quickFileService } from "../../services/quickFile.service";
import "./index.less";

type props = {
  isOpen: boolean;
  albumId: string;
  onClose?: () => void;
};

const CreatorDeleteAlbumModal: React.FC<props> = ({ isOpen, onClose, albumId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const handleAlbumDelete = async () => {
    try {
      await quickFileService.deleteAlbum(albumId);
      if (onClose) {
        onClose();
      }
      dispatch(deleteAlbum({ albumId }));
    } catch (error) {
      message.error(t("live-menu-error"));
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="deleteAlbumModal"
      title={t("Do-you-really-want-delete-a-album")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="row">
            <Button type="link" className="actionBtn" onClick={handleAlbumDelete}>
              <div className="confirm-btn-background">{t("Yes-delete")}</div>
            </Button>
          </Row>
          <Row className="row">
            <Button className="rejectBtn" type="link" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Row>
        </div>
      }
    />
  );
};

export default CreatorDeleteAlbumModal;
