import { ICountry } from "../helpers/types";

export const COUNTRIES: ICountry[] = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AF.svg",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AL.svg",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DZ.svg",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AS.svg",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AD.svg",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AO.svg",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AI.svg",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AQ.svg",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AG.svg",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AR.svg",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AM.svg",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AW.svg",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AU.svg",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AT.svg",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AZ.svg",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BS.svg",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BH.svg",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BD.svg",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BB.svg",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BY.svg",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BE.svg",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BZ.svg",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BJ.svg",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BM.svg",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BT.svg",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BO.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BA.svg",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BW.svg",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BR.svg",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IO.svg",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BN.svg",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BG.svg",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BF.svg",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BI.svg",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KH.svg",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CM.svg",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CA.svg",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CV.svg",
  },
  {
    name: "Cayman Islands",
    dial_code: "+345",
    code: "KY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KY.svg",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CF.svg",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TD.svg",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CL.svg",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CN.svg",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CX.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CC.svg",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CO.svg",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KM.svg",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CG.svg",
  },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CD.svg",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CK.svg",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CR.svg",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CI.svg",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/HR.svg",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CU.svg",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CY.svg",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CZ.svg",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DK.svg",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DJ.svg",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DM.svg",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DO.svg",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/EC.svg",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/EG.svg",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SV.svg",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GQ.svg",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ER.svg",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/EE.svg",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ET.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FK.svg",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FO.svg",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FJ.svg",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FI.svg",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FR.svg",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GF.svg",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PF.svg",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GA.svg",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GM.svg",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GE.svg",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/DE.svg",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GH.svg",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GI.svg",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GR.svg",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GL.svg",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GD.svg",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GP.svg",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GU.svg",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GT.svg",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GG.svg",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GN.svg",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GW.svg",
  },
  {
    name: "Guyana",
    dial_code: "+592",
    code: "GY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GY.svg",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/HT.svg",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VA.svg",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/HN.svg",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/HK.svg",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/HU.svg",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IS.svg",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IN.svg",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ID.svg",
  },
  {
    name: "Iran, Islamic Republic of",
    dial_code: "+98",
    code: "IR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IR.svg",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IQ.svg",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IE.svg",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IM.svg",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IL.svg",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/IT.svg",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/JM.svg",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/JP.svg",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/JE.svg",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/JO.svg",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KZ.svg",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KE.svg",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KI.svg",
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KP.svg",
  },
  {
    name: "Korea, Republic of",
    dial_code: "+82",
    code: "KR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KR.svg",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KW.svg",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KG.svg",
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
    code: "LA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LA.svg",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LV.svg",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LB.svg",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LS.svg",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LR.svg",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LY.svg",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LI.svg",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LT.svg",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LU.svg",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MO.svg",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MK.svg",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MG.svg",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MW.svg",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MY.svg",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MV.svg",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ML.svg",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MT.svg",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MH.svg",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MQ.svg",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MR.svg",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MU.svg",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/YT.svg",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MX.svg",
  },
  {
    name: "Micronesia, Federated States of",
    dial_code: "+691",
    code: "FM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/FM.svg",
  },
  {
    name: "Moldova, Republic of",
    dial_code: "+373",
    code: "MD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MD.svg",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MC.svg",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MN.svg",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ME.svg",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MS.svg",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MA.svg",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MZ.svg",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MM.svg",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NA.svg",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NR.svg",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NP.svg",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NL.svg",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AN.svg",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NC.svg",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NZ.svg",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NI.svg",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NE.svg",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NG.svg",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NU.svg",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NF.svg",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MP.svg",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/NO.svg",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/OM.svg",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PK.svg",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PW.svg",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PS.svg",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PA.svg",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PG.svg",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PY.svg",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PE.svg",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PH.svg",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PN.svg",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PL.svg",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PT.svg",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PR.svg",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/QA.svg",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/RO.svg",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/RU.svg",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/RW.svg",
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/RE.svg",
  },
  {
    name: "Saint Barthélemy",
    dial_code: "+590",
    code: "BL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/BL.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SH.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/KN.svg",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LC.svg",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/MF.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/PM.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VC.svg",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/WS.svg",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SM.svg",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ST.svg",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SA.svg",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SN.svg",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/RS.svg",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SC.svg",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SL.svg",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SG.svg",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SK.svg",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SI.svg",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SB.svg",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SO.svg",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ZA.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GS.svg",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ES.svg",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/LK.svg",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SD.svg",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SS.svg",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SR.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SJ.svg",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SZ.svg",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SE.svg",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/CH.svg",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/SY.svg",
  },
  {
    name: "Taiwan, Province of China",
    dial_code: "+886",
    code: "TW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TW.svg",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TJ.svg",
  },
  {
    name: "Tanzania, United Republic of",
    dial_code: "+255",
    code: "TZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TZ.svg",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TH.svg",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TL.svg",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TG.svg",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TK.svg",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TO.svg",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TT.svg",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TN.svg",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TR.svg",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TM.svg",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TC.svg",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/TV.svg",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/UG.svg",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/UA.svg",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AE.svg",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/GB.svg",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/US.svg",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/UY.svg",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/UZ.svg",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VU.svg",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
    code: "VE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VE.svg",
  },
  {
    name: "Viet Nam",
    dial_code: "+84",
    code: "VN",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VN.svg",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VG.svg",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/VI.svg",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/WF.svg",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/YE.svg",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ZM.svg",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/ZW.svg",
  },
  {
    name: "Åland Islands",
    dial_code: "+358",
    code: "AX",
    flag: "https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/AX.svg",
  },
];

class CountryUtil {
  public getCountryFromCode(countries: ICountry[], code: string) {
    const country = countries.find((country) => country.code === code);
    return country ? country : null;
  }
}

const countryUtil = new CountryUtil();

export default countryUtil;
