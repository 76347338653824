import { Layout, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../redux/store";
import { ICreatorSuggestion, ICreatorSuggestionSection } from "../../helpers/types";
import { createReaction } from "../../redux/reactions/reactionsSlice";
import { useEffect, useState } from "react";
import { getSugesstion, removeSuggestion } from "../../redux/CreatorSuggestion/CreatorSuggestion";
import SuggestionSectionOneAndThree from "./FanHomeSuggestionSectionsComponent/SuggestionSectionOneAndThree";
import SuggestionSectionTwo from "./FanHomeSuggestionSectionsComponent/SuggestionSectionTwo";
import SuggestionSectionFour from "./FanHomeSuggestionSectionsComponent/SuggestionSectionFour";
import "./index.less";

type IsFollowingStateType = {
  [key: string]: boolean;
};

interface FanHomeSuggestionComponentProps {
  showSection4?: boolean;
}

const FanHomeSuggestionComponent: React.FC<FanHomeSuggestionComponentProps> = ({ showSection4 = true }) => {
  const { t } = useTranslation();
  const suggestion = useSelector((state: StoreState) => state.suggestion.list);
  const dispatch = useDispatch<AppDispatch>();
  const [isFollowing, setIsFollowing] = useState<IsFollowingStateType>({});

  /**
   * Fetch suggestions from the server if they are not already present in the store.
   */
  useEffect(() => {
    if (suggestion.length === 0) {
      dispatch(getSugesstion());
    }
  }, []);

  /**
   * Handles following a creator.
   * @param {string} creatorId - The ID of the creator to follow.
   */
  const follow = async (creatorId: string) => {
    const payload = {
      action: "follow",
      objectId: creatorId,
      objectType: "creator",
    };
    await dispatch(createReaction(payload));
    setIsFollowing((prevState) => ({
      ...prevState,
      [creatorId]: true,
    }));
    dispatch(removeSuggestion(creatorId));
  };

  /**
   * Renders the appropriate suggestion section based on the section index.
   * @param {number} index - The index of the section.
   * @param {ICreatorSuggestionSection[]} creatorSuggestionSection - The list of suggestions for the section.
   * @returns {JSX.Element | null} The rendered section component.
   */
  const renderSuggestionSection = (index: number, creatorSuggestionSection: ICreatorSuggestionSection[]): JSX.Element | null => {
    switch (index) {
      case 1:
        return (
          <SuggestionSectionOneAndThree
            title={t("suggestions-section-title-1")!}
            creatorSuggestionSection={creatorSuggestionSection}
            isFollowing={isFollowing}
            follow={follow}
          />
        );
      case 2:
        return <SuggestionSectionTwo creatorSuggestionSection={creatorSuggestionSection} />;
      case 3:
        return (
          <SuggestionSectionOneAndThree
            title={t("suggestions-section-title-3")!}
            creatorSuggestionSection={creatorSuggestionSection}
            isFollowing={isFollowing}
            follow={follow}
          />
        );
      case 4:
        return showSection4 ? <SuggestionSectionFour creatorSuggestionSection={creatorSuggestionSection} /> : null;
      default:
        return null;
    }
  };

  return (
    <Layout className="background-transparent">
      <Space direction="vertical" className="suggestionPageWrapper">
        {suggestion.length > 0 &&
          suggestion.map((creatorSuggestion: ICreatorSuggestion, index: number) => (
            <div key={index}>{renderSuggestionSection(creatorSuggestion.index, creatorSuggestion.items)}</div>
          ))}
      </Space>
    </Layout>
  );
};

export default FanHomeSuggestionComponent;
