import { CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, MenuProps, Popconfirm, Row, Space, Typography, Upload, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload/interface";
import { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePickerComponent from "../../../components/DatePickerComponent";
import InputComponent from "../../../components/InputComponent";
import TextAreaComponent from "../../../components/TextAreaComponent";
import { IUser } from "../../../helpers/types";
import { AppDispatch, StoreState } from "../../../redux/store";
import { editProfile, getMyProfile, updateAvatar, updateProfile } from "../../../redux/users/usersSlice";
import { convertHeicFile, isImageAcceptedFormat } from "../../../utls/FunctionsUtil";
import EditCover1 from "../../../assets/images/edit-cover-1.webp";
import EditCover2 from "../../../assets/images/edit-cover-2.webp";
import EditIcon from "../../../assets/icons/edit-pen.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import "./index.less";

const CreatorEditProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  const [openAvatarOptions, setOpenAvatarOptions] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth > 680);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      getProfile();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth > 680);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenAvatarOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getProfile = () => {
    dispatch(getMyProfile());
  };

  const changedItem = (field: keyof IUser, value: string | null) => {
    if (field === "dateOfBirth" && value) {
      const birthDate = new Date(value);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      if (ageDiff < 18) {
        message.warning(t("minimum-age"));
      } else if (ageDiff === 18) {
        if (
          currentDate.getMonth() < birthDate.getMonth() ||
          (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
        ) {
          message.warning(t("minimum-age"));
        }
      }
      return;
    }
    dispatch(editProfile({ ...user, [field]: value }));
    setIsModified(true);
  };

  const uploadAvatarImage = async (file: File) => {
    const formData = new FormData();
    formData.append("avatar", file);
    const resp = await dispatch(updateAvatar(formData));
    if (resp.meta.requestStatus === "fulfilled") {
      getProfile();
      setLoadingAvatar(false);
      await message.success(t("avatar-updated-success-message"));
    }
  };

  const removeAvatarImage = async () => {
    const data = {
      avatar: "removed",
    };
    const resp = await dispatch(updateProfile(data));
    if (resp.meta.requestStatus === "fulfilled") {
      getProfile();
      setOpenAvatarOptions(false);
      message.success(t("avatar-removed-success-message"));
    }
  };

  const registerClicked = async () => {
    if (!user) return;

    const resp = await dispatch(updateProfile(user));
    if (resp.meta.requestStatus === "fulfilled") {
      message.success(t("profile-updated-success-message"));
      navigate("/creator/profile");
    }
  };

  const beforeUploadAvatar: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    setLoadingAvatar(true);
    // If the file is in an accepted format, proceed without conversion
    if (isImageAcceptedFormat(file.type)) {
      uploadAvatarImage(file);
      return false;
    }

    try {
      if (file.type === "image/heic" || file.type === "") {
        // convert file
        const convertedFile = await convertHeicFile(file);
        uploadAvatarImage(convertedFile);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleAvatarMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key !== "1") {
      setOpenAvatarOptions(false);
    }
  };

  const avatarActions: MenuProps["items"] = [
    {
      label: (
        <Upload showUploadList={false} beforeUpload={beforeUploadAvatar} disabled={loadingAvatar} accept={"image/*"}>
          <span>{t("edit-avatar")}</span>
        </Upload>
      ),
      key: "0",
      icon: <EditOutlined />,
    },
    {
      label: (
        <Popconfirm
          placement="bottom"
          disabled={user?.avatar?.includes("default.jpg")}
          title={t("delete-avatar")}
          description={t("delete-avatar-confirm-message")}
          onConfirm={removeAvatarImage}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <span>{t("delete-avatar")}</span>
        </Popconfirm>
      ),
      key: "1",
      icon: <DeleteOutlined />,
      disabled: user?.avatar?.includes("default.jpg"),
    },
    {
      label: <span>{t("cancel")}</span>,
      key: "2",
      icon: <CloseOutlined />,
    },
  ];

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content creatorEditProfileContent background-transparent">
        <Row className="relative justify-content-center header-title-container-30">
          <ArrowLeftIconComponent left={16} top={2} />
          <Typography.Text className="header-title-black-24">{t("edit-my-profile")}</Typography.Text>
        </Row>
        <Row className="w-full justify-content-between mt-20">
          <Col className="vertical-center pl-11">
            <img src={EditCover1} width={90} alt="" />
          </Col>
          <Col className="editProfileAvatarWrapper mt-20">
            <div className="editProfileAvatarUploadContent" ref={!isScreenLarge ? dropdownRef : null}>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: avatarActions,
                  onClick: handleAvatarMenuClick,
                }}
                open={openAvatarOptions}
                onOpenChange={(open) => {
                  setOpenAvatarOptions(open);
                }}
              >
                <img src={EditIcon} width={28} onClick={() => setOpenAvatarOptions(!openAvatarOptions)} />
              </Dropdown>
            </div>
            <div className="editProfileAvatarContent">
              <div
                className="editProfileAvatarImage"
                style={{
                  backgroundImage: `url("${user?.avatar}")`,
                }}
              >
                {loadingAvatar ? <LoadingOutlined style={{ fontSize: "32px" }} /> : null}
              </div>
            </div>
          </Col>
          <Col className="vertical-center">
            <img src={EditCover2} width={95} alt="" />
          </Col>
        </Row>
        <Row className="w-full mt-24 pl-20 pr-20 mt-20">
          <Space className="w-full gap-13" direction="vertical">
            <Row gutter={7} align="middle">
              <Col span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("identity")}</Typography.Text>
                  <InputComponent className="editInput" value={user?.firstName || ""} disabled />
                </Space>
              </Col>
              <Col span={12}>
                <Space className="w-full gap-6 mt-22" direction="vertical">
                  <InputComponent className="editInput" value={user?.lastName || ""} disabled />
                </Space>
              </Col>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("email-label")}</Typography.Text>
                <InputComponent className="editInput" type="text" value={user?.email || ""} disabled />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("username")}</Typography.Text>
                <InputComponent className="editInput" type="text" value={user?.username || ""} disabled />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("date-of-birth-label")}</Typography.Text>
                <DatePickerComponent
                  className="editDarkDatePicker"
                  date={user?.dateOfBirth || ""}
                  onChange={(value) => changedItem("dateOfBirth", value)}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("position")}</Typography.Text>
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  selectProps={{
                    placeholder: t("position"),
                    defaultInputValue: user?.city || "",
                    onChange: (location) => {
                      if (location) changedItem("city", location?.label);
                    },
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        color: "#FFF",
                        backgroundColor: "rgba(77, 77, 77, 0.4)",
                        paddingLeft: 15,
                        border: "unset",
                        borderRadius: 15,
                        height: 42,
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#FFF",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#FFF",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                    },
                  }}
                />
                {/*<InputComponent className="darkInput" type="text" />*/}
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("description")}</Typography.Text>
                <TextAreaComponent
                  className="bioEditTextArea"
                  value={user?.description || ""}
                  onChange={(e) => changedItem("description", e.target.value)}
                />
              </Space>
            </Row>
            <Button className="saveBtn" onClick={registerClicked} disabled={!isModified}>
              {t("register")}
            </Button>
            {/* <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-13-bold text-white-color">{t("gender-label")}</Typography.Text>
                <Radio.Group className="w-full" value={user?.gender} buttonStyle="solid" onChange={(e) => changedItem("gender", e.target.value)}>
                  <Row gutter={8}>
                    {genders.map((gender, index) => {
                      return (
                        <Col className="mt-5" key={index} span={8}>
                          <Radio.Button className="mediaAmountRadioButton" value={gender.value}>
                            {gender.label}
                          </Radio.Button>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Space>
            </Row> */}
          </Space>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorEditProfilePage;
