import React from "react";
import { Row, Col, Space, Typography, Radio } from "antd";
import { useTranslation } from "react-i18next";
import AuthGenderRadioButtonComponent from "../AuthGenderRadioButtonComponent";
import { getGenders } from "../../helpers/constant";

type props = {
  name?: string;
  value?: any;
  handleChange?: Function;
};

const AuthGenderInputComponent: React.FC<props> = ({
  name,
  value,
  handleChange,
}) => {
  const { t } = useTranslation();
  const genders = getGenders(t);

  return (
    <Space className="w-full gap-6" direction="vertical">
      <Typography.Text className="authInputLabel">{t("gender-label")}</Typography.Text>
      <Radio.Group className="w-full" name={name} value={value} onChange={(e)=> {
        if (handleChange) {
          handleChange(e)
        }
      }}>
        <Row gutter={5}>
          {genders.map((gender, index) => {
            return (
              <Col span={8} key={index}>
                <AuthGenderRadioButtonComponent
                  label={gender.label}
                  value={gender.value}
                />
              </Col>
            );
          })}
        </Row>
      </Radio.Group>
    </Space>
  );
};

export default AuthGenderInputComponent;
